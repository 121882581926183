import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { db } from 'src/app/db/json';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from "../../modal/modal.component";
import { Router } from '@angular/router';

interface Space {
  id: number;
  name: string;
  keys: SafeAccess[];
}

@Component({
  selector: 'app-key-space-manager',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, ModalComponent],
  templateUrl: './key-space-manager.component.html',
  styleUrl: './key-space-manager.component.scss'
})
export class KeySpaceManagerComponent implements OnInit {
  @Output() done = new EventEmitter<string>();
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  allKeys: SafeAccess[] = [];
  allSpacesKeys: SafeAccess[] = [];
  spaces!: { id: number; name: any; keys: SafeAccess[]; }[];

  constructor(private db: db, private apiSvc: ApiService, private translate: TranslateService, private router: Router) { }

  async ngOnInit() {
    await this.init();
  }

  async init() {
    this.allKeys = await this.apiSvc.getAccesses('device');
    this.allSpacesKeys = this.allKeys.filter(key => key.mode == 'admin' || key.spaceIds.length == 0);

    this.allKeys = this.allKeys.filter(key => key.mode != 'admin');

    const archives = this.db.spaces;
    this.spaces = archives.map(archive => ({
      id: archive.sid,
      name: archive.m.spaceName || `Space ${archives.indexOf(archive) + 1}`,
      keys: this.keysOfSpace(archive.sid)
    }));
  }

  private keysOfSpace(spaceId: number): SafeAccess[] {
    return this.allKeys.filter(key => key.spaceIds && key.spaceIds.includes(spaceId));
  }

  toggleKey(space: Space, key: SafeAccess) {
    const index = space.keys.findIndex(k => k.accessID === key.accessID);
    if (index > -1) {
      // Remove key from space
      space.keys.splice(index, 1);
      key.spaceIds = key.spaceIds?.filter(id => id !== space.id);
      if (key.spaceIds.length === 0) {
        this.allSpacesKeys.push(key);
      }
    } else {
      // Add key to space
      space.keys.push(key);
      const index = this.allSpacesKeys.indexOf(key);
      if (index > -1) {
        this.allSpacesKeys.splice(index, 1);//remove from allSpacesKeys
      }
      key.spaceIds.push(space.id);
    }
    this.updateKeySpaces(key);
  }

  isKeyInSpace(space: Space, key: SafeAccess): boolean {
    return space.keys.some(k => k.accessID === key.accessID);
  }

  async updateKeySpaces(key: SafeAccess) {
    Console.log('Updating key spaces', key);
    await this.apiSvc.updateAccess(key);
  }

  getKeyType(key: SafeAccess): string {
    return key.mode === 'admin' ? 'APP_CONFIG.SPACES.ADMIN' : key.mode === 'ro' ? 'APP_CONFIG.SPACES.READ_ONLY' : 'APP_CONFIG.SPACES.READ_WRITE';
  }

  async deleteSpace(space: Space) {
    const regret = this.translate.instant('APP_CONFIG.SPACES.DELETE.REGRET');
    const response = await this.modalController.displayInput(this.translate.instant('APP_CONFIG.SPACES.DELETE.TITLE'), this.translate.instant('APP_CONFIG.SPACES.DELETE.MSG'), false);
    if (response === regret) {
      await this.db.deleteSpace(space.id);
      this.spaces = this.spaces.filter(s => s.id !== space.id);
    }
  }

  close() {
    this.done.emit();
  }
  keyAdmin(key: SafeAccess) {
    Console.log('keyAdmin', key);
    this.close();
   this.done.emit(key.accessID);
  }
}

