import { Injectable } from "@angular/core";
import { AbstractState } from "./abstractState";
import { UploadFileSelectedState } from "./uploadFileSelectedState";
import {  LocalMessagesState } from "./localMessagesState";
@Injectable()
export class StartState extends AbstractState {
  constructor() { super() }
  async init() {
    this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.TITLE');

    if (this.modal.file) {
      this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.MSG');
      this.button1Text = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.LOCAL');
      this.button2Text = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.CLOUD');
    } else {
      this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.SELECT_MSG');
      this.button2Text = this.modal.translate.instant("CANCEL");
    }
  }

  override click1(): void {
    if (!this.modal.file) {
      this.modal.respond(null);
      return;
    }
    this.modal.local = true;
    this.modal.setState(new LocalMessagesState());
  }

  override click2(): void {
    if (!this.modal.file) {
      this.modal.respond(null);
      return;
    }
    this.modal.local = false;
    this.modal.setState(new UploadFileSelectedState());
  }

  override onFileSelected(event: any): void {
    this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.MSG');
    this.button2Text = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.CLOUD');
    this.button1Text = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.LOCAL');
    this.modal.file = event.target.files[0];
    if (!this.modal.file) {
      this.modal.respond(null);
    }
  }
}
