import { Injectable } from '@angular/core';
import { Archive, Location } from 'src/app/interfaces/archive';
import { CallBack, EmptyAction } from 'src/app/interfaces/callback';
import { AuthzService } from './authz.service';
import { fromBase64 } from '@aws-sdk/util-base64-browser'
import { AppConfig } from '../interfaces/appConfig';
import { RegistrationResponseJSON } from '@simplewebauthn/types';
import { SafeAccess } from '../interfaces/safeAccess';
import { startAuthentication } from '@simplewebauthn/browser';
import { SafeCrypto } from '../lib/safeCrypto';
import { BroadcastChannelHandler } from '../lib/broadcastChannelHandler';
import { Console } from '../lib/console';
import { ErrorService, ErrorType, SafeError } from './error.service';
import { Subject } from 'rxjs';
import { UserHandleConverter } from '../lib/userHandleConverter';
import { NetworkService } from './network.service';
import { TranslateService } from '@ngx-translate/core';
import { EyesEncryptionService } from './eyesEncryption.service';
import base64url from 'base64url';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public updateAvailable: any // set by main component when new version detected, used by config component to show update option vaule will be the hash of the new version
  public deferredInstallPrompt: Event | undefined; // Used by config component to show PWA install option

  // Private variable to hold the current spaceID value
  private _spaceID = 0;

  // Subject to emit spaceID changes
  private spaceIDSubject = new Subject<number>();

  // Expose the observable part of the Subject
  public spaceID$ = this.spaceIDSubject.asObservable();
  private locations: Location[] | null = null;
  archivesMoved = false; //after moving archives, set to true to skip cache in getArchives call as file moves are async
  public haveNewMessages = false; // set at authentication to indicate to the client that there are new messages
  public serviceworker = !!navigator.serviceWorker;

  // Getter and setter for spaceID
  public get spaceID(): number {
    return this._spaceID;
  }

  public currentLocationID = '';
  public setSpaceID(value: number, locationID: string) {
    this.currentLocationID = locationID;
    // Check if the new value is different before emitting
    if (value !== this._spaceID) {
      this._spaceID = value;
      this.spaceIDSubject.next(value);
    }
  }

  public getCurrentLocationID() {
    if (!this.currentLocationID) {
      this.currentLocationID = this.getAppConfig().locID;
    }
    return this.currentLocationID;
  }

  private uploadPOSTCache = new Map<string, { expiryTime: number, uploadObject: any }>();
  private downloadUrlCache = new Map<string, { expiryTime: number, url: string }>();
  private archiveCache = new Map<string, Archive>();
  private apiServiceBroadcastChannelHandler!: BroadcastChannelHandler;

  clearCaches() {
    this.uploadPOSTCache.clear();
    this.archiveCache.clear();
    this.downloadUrlCache.clear();
    if (this.apiServiceBroadcastChannelHandler) {
      this.apiServiceBroadcastChannelHandler.postMessage({ action: 'CLEAR_CACHE' });
    }
    this.EyesJWK = null;
    this.eyesKeyData = null;
    this.pkeys = null;
  }

  public whipeCrypto() {
    this.uploadPOSTCache.clear();
    this.downloadUrlCache.clear();
    this.archiveCache.clear();
    this.EyesJWK = null;
    this.eyesKeyData = null;
    this.pkeys = null;
    if (this.apiServiceBroadcastChannelHandler) {
      this.apiServiceBroadcastChannelHandler.postMessage({ action: 'WIPE_CRYPTO' });
    }
  }

  constructor(private eyesSvc: EyesEncryptionService, private authzSvc: AuthzService, private networkSvc: NetworkService, private translate: TranslateService) {
    if (navigator.serviceWorker) {
      this.listenForServiceWorkerMessages();
    }
  }

  private listenForServiceWorkerMessages() {

    this.apiServiceBroadcastChannelHandler = new BroadcastChannelHandler("ApiService", async (data) => {
      Console.log('ApiService', data);
      if (!this.authzSvc.isAuthorized()) {
        throw new Error('Ignore');
      }
      if (data == 'SW_RESTARTED') {
        const safeError = new SafeError(ErrorType.CRYPTO_NOT_INITIALIZED, 'Service worker restarted');
        setTimeout(() => { location.reload() }, 5000); //reload the page after 5 second
        ErrorService.instance.process(safeError);
        return;
      } else if (data == 'INITIALIZE_CRYPTO') {
        Console.log('INITIALIZE_CRYPTO');
        try {
          return await this.reInitServiceworkerCrypto();
        } catch (e) {
          //there might be another client that can  initialize the crypto
          Console.error('Error initializing crypto', e);
        }
      } else if (data == 'KEEP_ALIVE') {
        Console.log('Keep alive ping from service worker');
        return this.ping(); //soft ping
      }
      const functionName = data.functionName;
      const args: any[] = data.args;
      return await this[functionName].apply(this, args);

    });

    addEventListener("beforeunload", (event) => {
      if (this.authzSvc.isAuthorized()) {
        this.authzSvc.setAuthorized(false);
        this.apiServiceBroadcastChannelHandler.postMessage({ action: 'BEFORE_UNLOAD' });
      }
      this.clearCaches();
    });
  }

  //called by service worker
  private async swgetState(): Promise<any> {
    Console.log('swgetState');
    if (!this.authzSvc.isAuthorized()) {
      throw new Error('Ignore'); //Do not respond to service worker if not authorized
    }
    await this.authzSvc.ping(true); // force update the state
    return this.authzSvc.state;
  }

  //called by service worker before it wipes the state to test if any clients(tabs) are still authz
  private async swPing(): Promise<any> {
    Console.log('swPing');
    if (!this.authzSvc.isAuthorized()) {
      throw new Error('Ignore'); //Do not respond to service worker if not authorized
    }
  }

  private EyesJWK: { jwk: JsonWebKey, b64CompositeCipherText: string } | null = null;
  //called by service worker to get Cryptokey for eyes attachment decryption
  private async getEyesDecryptionCryptoKey(b64CompositeCipherText: string): Promise<JsonWebKey> {
    if (this.EyesJWK && this.EyesJWK.b64CompositeCipherText == b64CompositeCipherText) {
      return this.EyesJWK.jwk;
    } else {
      this.EyesJWK = null;
    }

    const compositeCipherText = new Uint8Array(base64url.toBuffer(b64CompositeCipherText));
    const keys = await this.getEyesPrivateKeys();
    let cryptoKey: CryptoKey | null = null;;
    for (const entry of keys!.privateKeys) {
      const b64privateKey = entry.key;
      const privateKey = new Uint8Array(base64url.toBuffer(b64privateKey));
      try {
        cryptoKey = await this.eyesSvc.getCryptoKey(compositeCipherText, privateKey);
      } catch (e) {
        Console.error('Error getting crypto key', e);
      }
    }
    if (!cryptoKey) {
      Console.error('Error getting eyes private key');
      throw new Error('Error getting Eyes crypto key');
    }
    const jwk = await crypto.subtle.exportKey('jwk', cryptoKey);
    this.EyesJWK = { jwk, b64CompositeCipherText };
    return jwk;
  }

  private eyesKeyData: { aesKey: CryptoKey; b64CompositeCipherText: string } | null = null;
  /**
   * set by the EyesSendService to provide the CryptoKey for eyes attachment encryption by the serviceworker
   * @param keyData
   */
  setEyesCryptoKey(keyData: { aesKey: CryptoKey; b64CompositeCipherText: string; } | null) {
    this.eyesKeyData = keyData;
    this.EyesJWK = null;
  }

  //called by service worker to get Cryptokey for eyes attachment encryption
  private async getEyesEncryptionCryptoKey(b64CompositeCipherText: string): Promise<JsonWebKey> {
    if (!this.eyesKeyData) {
      Console.error('Eyes key data not set');
      throw new Error('Eyes key data not set');
    }
    if (this.eyesKeyData.b64CompositeCipherText != b64CompositeCipherText) {
      Console.error('Eyes key data does not match');
      throw new Error('Eyes key data does not match');
    }

    if (this.EyesJWK && this.EyesJWK.b64CompositeCipherText == b64CompositeCipherText) {
      Console.log('Returning cached EyesJWK');
      return this.EyesJWK.jwk;
    } else {
      this.EyesJWK = null;
    }
    const cryptoKey = this.eyesKeyData.aesKey;
    const jwk = await crypto.subtle.exportKey('jwk', cryptoKey);
    this.EyesJWK = { jwk, b64CompositeCipherText };
    Console.log('Returning EyesJWK');
    return jwk;
  }

  public isReadOnly(): boolean {
    return (this.authzSvc.appConfig.ro || !this.networkSvc.isOnline)
  }

  ping(force = false) {
    if (this.networkSvc.isOnline) {
      this.authzSvc.ping(force);
    } else {
      Console.log('APiSvc ping: offline');
    }
  }


  private eyesAddresses: string | null = null;
  async putEyesAddresses(addresses: string): Promise<void> {
    this.eyesAddresses = addresses; //cache
    const encrypted = await this.authzSvc.crypto.encryptString(addresses);
    const callback = new CallBack('PutEyesAddresses', new EmptyAction());
    const request = { addresses: encrypted };
    callback.action.request = request;
    await this.authzSvc.apiRequest(callback);
  }

  async getEyesAddresses(): Promise<string | null> {
    if (this.eyesAddresses) {
      return this.eyesAddresses;
    }
    const callback = new CallBack('GetEyesAddresses', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const encrypted = resultCallBack.action.result;
    if (!encrypted) {
      return null;
    }
    const addreses = await this.authzSvc.crypto.decryptString(encrypted);
    this.eyesAddresses = addreses; //cache
    return addreses;
  }

  async getEyesPublicKey(eyesAddr: string): Promise<string | null> {
    const callback = new CallBack('GetEyesPublicKey', new EmptyAction());
    const request = { eyesAddr };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const b64key = resultCallBack.action.result;
    if (!b64key) {
      return null;
    }
    return b64key;
  }

  async getEyesValidationKeys(eyesAddr: string, kid = 0): Promise<{
    id: number;
    validationKey: string;
  }[]> {
    const callback = new CallBack('GetEyesValidationKeys', new EmptyAction());
    const request = { eyesAddr, kid };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  private pkeys: { privateKeys: { key: string, id: number }[], signingKey: { key: string, id: number } | null } | null = null;
  async getEyesPrivateKeys(generate = true): Promise<{ privateKeys: { key: string, id: number }[], signingKey: { key: string, id: number } | null } | null> {
    if (this.pkeys) {
      return this.pkeys;
    }

    const callback = new CallBack('GetEyesPrivateKeys', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const result = resultCallBack.action.result;

    //if we have no keys, generate new ones
    if (!result && generate) {
      //geneate new keys
      await this.refreshAppConfig()
      const { privateKey, publicKey, validationKey, signingKey } = await this.eyesSvc.generateKeys();
      const encryptedPrivateKey = await this.authzSvc.crypto.encryptString(privateKey);
      const encryptedSigningKey = await this.authzSvc.crypto.encryptString(signingKey);
      await this.putEyesKeys(encryptedPrivateKey, publicKey, validationKey, encryptedSigningKey);
      return await this.getEyesPrivateKeys(false); // get keys so we have th right keyid
    } else if (!result) {
      throw new Error('No Eyes keys found');
    }
    if (result.signingKey) {
      result.signingKey.key = await this.authzSvc.crypto.decryptString(result.signingKey.key);
    }
    //decrypt the private keys
    for (const key of result.privateKeys) {
      key.key = await this.authzSvc.crypto.decryptString(key.key);
    }
    this.pkeys = result;
    return result;
  }


  //rotate keys
  async putEyesKeys(privateKey: string, publicKey: string, validationKey?: string, signingKey?: string): Promise<void> {
    const callback = new CallBack('PutEyesKeys', new EmptyAction());
    const request = { privateKey, publicKey, validationKey, signingKey };
    callback.action.request = request;
    await this.authzSvc.apiRequest(callback);
  }

  async publishMessage(recipientAddress: string, messageID: number, header: string, isC2: boolean, attachmentArchives?: string[], pushSub?: string): Promise<{ messageID: string, pushResult: boolean }> {
    const callback = new CallBack('PublishMessage', new EmptyAction());
    const request = { recipientAddress, messageID, header, attachmentArchives, isC2, pushSub };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  async getEyesMessages(): Promise<{
    messages: {
      messageID: number;
      header: string;
      changeTS: number;
      status: 'NEW' | 'PROC';
      attachments?: string[];
    }[];
    outMessages: {
      messageID: number;
      a: string;
      t: 'C2' | 'M';
      attachments?: string[];
    }[];
  }> {
    this.haveNewMessages = false;
    if (!this.authzSvc.appConfig.eyesAddr) { //Todo remove after all safes have been updated
      //generate keys
      await this.getEyesPrivateKeys();
    }

    const callback = new CallBack('GetEyesMessages', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  async acceptEyesMessage(messageIDs: number[], deleteAfter = false): Promise<void> {
    Console.log(`APISvc: acceptEyesMessage: ${deleteAfter} ${messageIDs}`);
    const callback = new CallBack('AcceptEyesMessage', new EmptyAction());
    const request = { messageIDs, deleteAfter };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const safeExp = resultCallBack.action.result.safeExp;
    this.authzSvc.safeExpiryEPOCms = safeExp * 1000;
    this.archiveCache.clear();
    return resultCallBack.action.result;
  }


  async deleteEyesMessage(messageID) {
    const callback = new CallBack('DeleteEyesMessage', new EmptyAction());
    const request = { messageID };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const safeExp = resultCallBack.action.result.safeExp;
    this.authzSvc.safeExpiryEPOCms = safeExp * 1000;
    this.archiveCache.clear();
    return resultCallBack.action.result;
  }

  async rotateEyesKeys() {
    const callback = new CallBack('RotateEyesKeys', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    this.clearCaches();
    return resultCallBack.action.result;
  }

  async rotateEyesAddress() {
    const callback = new CallBack('RotateEyesAddress', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    this.clearCaches();
    return resultCallBack.action.result;
  }

  async getLocationName(locationID: string): Promise<string> {
    const locations = await this.getLocations();
    const location = locations.find(l => l.id == locationID);
    if (!location) {
      throw new Error('Location not found');
    }
    let name = 'Canada';
    const locid = parseInt(locationID);
    if (locid > 16) {
      //multi region
      const regions = location.region.split(',');
      const region1 = this.translate.instant('LOCATIONS.XRegion.' + regions[0]);
      const region2 = this.translate.instant('LOCATIONS.XRegion.' + regions[1]);
      name = region1 + ' & ' + region2;
    } else {
      //single region
      name = this.translate.instant('LOCATIONS.' + location.region);
    }
    return name;
  }

  /**
   * Move all archives from a space to a different location
   * Can not move archives all at once due to 30 second limit on server by API gateway
   * @param spaceID
   * @param locationID
   * @returns
   * @throws SafeError
   *
   */
  async moveSpaceArchives(spaceID: number, locationID: string): Promise<{ recordsResult: any, filesResult: any | undefined }> {

    const archives = await this.getArchives();
    let spaceArchives = archives.filter(a => a.sid == spaceID && a.l != locationID && a.t != 'Local');

    //can not move archives larger than 5GB
    const largeArchives = spaceArchives.filter(a => a.sid == spaceID && a.s >= 5 * 1024 * 1024 * 1024);
    if (largeArchives.length > 0) {
      Console.error('Can not move archives larger than 5GB');
      //remove the large archives from the list
      spaceArchives = spaceArchives.filter(a => a.sid != spaceID || a.s <= 5 * 1024 * 1024 * 1024);
    }
    //do Records first
    const records = spaceArchives.filter(a => a.t == 'Records');
    let recordsResult: any;
    let filesResult: any;
    //then other files
    const files = spaceArchives.filter(a => a.t != 'Records');
    if (records.length > 0) {
      try {
        recordsResult = await this.moveArchive(records, locationID);
      } catch (e) {
        //the records have moved, but the files may have not. They will be retried by the client when it sees that the Arhive location does not match the space location
        Console.log('Move Records error', e);
      }
    }
    if (files.length > 0) {
      filesResult = await this.moveArchive(files, locationID);
    }
    await new Promise(resolve => setTimeout(resolve, 5000)); //wait 5 seconds for server to update the records);
    this.archivesMoved = true;
    return { recordsResult, filesResult };
  }

  private async moveArchive(archives: Archive[], locationID: string): Promise<string> {
    try {
      const archiveIDs = archives.map(a => a.id);
      const callback = new CallBack('MoveArchives', new EmptyAction());
      const request = { archiveIDs, locationID };
      callback.action.request = request;
      const resultCallBack = await this.authzSvc.apiRequest(callback);
      if (resultCallBack.action.result == 'FAILURE') {
        throw new Error(resultCallBack.action.reason);
      }
      return resultCallBack.action.result;
    } finally {
      this.clearCaches();
    }
  }

  async setTimeLock(time: number, type: 'ALL' | 'DEVICE', accessID?: string) {
    const callback = new CallBack('SetTimeLock', new EmptyAction());
    const request = { time, type, accessID }
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  /**
   *
   * Data is locally encrypted then sent to the server for further encryption
   * Data can only be decrypted after webauthn authn user verification
   * @returns doubley encrypted data
   */
  async authnEncryptData(data: string): Promise<string> {
    const localyencrypted = await this.authzSvc.crypto.encryptString(data);
    const callback = new CallBack('EncryptData', new EmptyAction());
    callback.action.request = localyencrypted;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  /**
   * Requires webauthn authn user verification to decrypt the data
   * @param data encrypted by AuthnEncryptData method
   * @returns
   */
  async authnDecryptData(data: string): Promise<string> {
    //get webauthn authn data
    const challengsCallback = new CallBack('DecryptDataGetChallenge', new EmptyAction());
    const challengeResultCallBack = await this.authzSvc.apiRequest(challengsCallback);
    if (challengeResultCallBack.action.result == 'FAILURE') {
      throw new Error(challengeResultCallBack.action.reason);
    }
    const authnData = challengeResultCallBack.action.result;
    // trigger webauthn authn
    const response = await startAuthentication({ optionsJSON: authnData });
    const datacallback = new CallBack('DecryptData', new EmptyAction());
    datacallback.action.request = { authnResult: response, data };
    const dataResultCallBack = await this.authzSvc.apiRequest(datacallback);

    return this.authzSvc.crypto.decryptString(dataResultCallBack.action.result);
  }

  async createAffiliate(): Promise<{ affiliate: any; url: string | undefined; onboarding: boolean }> {
    const callback = new CallBack('CreateAffiliate', new EmptyAction());
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result == 'FAILURE') {
      throw new Error(result.action.reason);
    }
    return result.action.result;
  }

  async createAffiliateLink(suffix: string): Promise<boolean> {
    const callback = new CallBack('CreateAffiliateLink', new EmptyAction());
    callback.action.request = suffix;
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result == 'FAILURE') {
      throw new Error(result.action.reason);
    }
    return result.action.result;
  }

  async getAffiliate(): Promise<{
    affiliate: any;
    url: string | undefined;
    onboarding: boolean | undefined;
  } | null> {
    const callback = new CallBack('GetAffiliate', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  async getRegistrationOptions(mode: 'platform' | 'cross-platform') {
    const callback = new CallBack('GetRegisterDeviceOpts', new EmptyAction());
    callback.action.request = mode;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  async sendRegistrationResponse(response: RegistrationResponseJSON, data: any) {
    const callback = new CallBack('AddDeviceRegistration', new EmptyAction());
    const request = { response, data }
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  private async getWebAuthAuth() {
    const callback = new CallBack('WebAuthnAuth', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  async getArchives(skipCache = false): Promise<Archive[]> {

    if (!skipCache && !this.archivesMoved && this.archiveCache.size > 0) {
      const array = Array.from(this.archiveCache.values());
      return array;
    }

    const callback = new CallBack('GetArchives', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const archives: Archive[] = resultCallBack.action.result;
    //decrypt the archives metadata
    for (const archive of archives) {
      if (!archive.m) continue;
      const decrypted = await this.authzSvc.crypto.decryptString(archive.m);
      archive.m = JSON.parse(decrypted);
    }

    //cache the archives in a map
    for (const archive of archives) {
      this.archiveCache.set(archive.id, archive);
    }
    return archives;
  }

  async getArchive(id: string, skipCache = false): Promise<Archive> {
    const archives = await this.getArchives(skipCache);
    const archive = archives.find(a => a.id == id);
    if (!archive && skipCache && !this.archivesMoved) { //no need to force a server call if moved archives as we are already skipping cache
      return this.getArchive(id, false);
    }
    if (!archive) {
      throw new Error('Archive not found');
    }
    return archive;
  }

  async createArchive(archive: Archive): Promise<Archive> {

    if (archive.t == 'Records') {
      const tier = this.getAppConfig().tier;
      const usePut = tier == 2 || tier == 3;

      const tr = usePut ? 'lput' : 'post'; // if put is supported we can use MRAP
      if (!archive.m) {
        archive.m = {};
      }
      archive.m.tr = tr;
    }
    if (!archive.sid) {
      archive.sid = this.spaceID;
    }

    const archiveRequest = JSON.parse(JSON.stringify(archive)); //clone the object
    const metadata = archiveRequest.m;
    if (metadata) {
      const encryptedMetadata = await this.authzSvc.crypto.encryptString(JSON.stringify(metadata));
      archiveRequest.m = encryptedMetadata;
    }
    const callback = new CallBack('CreateArchive', new EmptyAction());
    callback.action.request = archiveRequest;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    const archiveResult: Archive = resultCallBack.action.result;
    if (archiveResult.m) {
      const decrypted = await this.authzSvc.crypto.decryptString(archiveResult.m);
      archiveResult.m = JSON.parse(decrypted);
    }
    this.archiveCache.set(archiveResult.id, archiveResult);
    return archiveResult;
  }

  async setSensitiveSpaces(safeWordClicks: { x: number; y: number }[], spaces: number[]): Promise<Archive> {
    const callback = new CallBack('SetSensitiveSpaces', new EmptyAction());

    const request = { safeWordClicks, spaces }
    callback.action.request = request;
    this.clearCaches();
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }

    const newarchive = resultCallBack.action.result
    this.archiveCache.set(newarchive.id, newarchive);
    return newarchive;
  }

  async deleteArchive(id: string) {
    const callback = new CallBack('DeleteArchive', new EmptyAction());
    callback.action.request = id;
    this.archiveCache.delete(id);
    const result = await this.authzSvc.apiRequest(callback);
    const safeExp = result.action.result.safeExp;
    this.authzSvc.safeExpiryEPOCms = safeExp * 1000;
    return result;
  }

  async deleteSpaceArchives(sid: number) {
    //get list of archives in the space
    const archives = await this.getArchives(true);
    const inspace = archives.filter(a => a.sid == sid);
    for (const archive of inspace) {
      if (archive.t != 'Records') {
        Console.log('Deleting archive', archive.id);
        await this.deleteArchive(archive.id);
      }
    }
  }

  async updateArchive(archiveRequest: Archive) {
    const archive = JSON.parse(JSON.stringify(archiveRequest));

    const metadata = archive.m;
    if (metadata) {
      const encryptedMetadata = await this.authzSvc.crypto.encryptString(JSON.stringify(metadata));
      archive.m = encryptedMetadata;
    }
    const callback = new CallBack('UpdateArchive', new EmptyAction());
    callback.action.request = archive;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    this.archiveCache.set(archiveRequest.id, archiveRequest);
    return resultCallBack.action.result;
  }

  async getSafeAuthzConfig() {
    const callback = new CallBack('GetAuthConfigTemplate', new EmptyAction());
    return this.authzSvc.apiRequest(callback);
  }

  async setSafeAuthzConfig(data: { pinClicks: { x: number, y: number }[] }) {
    const callback = new CallBack('SetSafeAuthConfig', new EmptyAction());
    callback.action.request = data;
    return await this.authzSvc.apiRequest(callback);
  }

  async deleteSafe(): Promise<{ code: string, credits: number } | undefined> {
    const callback = new CallBack('DeleteSafe', new EmptyAction());
    const ret = await this.authzSvc.apiRequest(callback);
    return ret.action.result
  }

  async getCreditTokenAccessToken(): Promise<string> {
    const callback = new CallBack('GetCreditTokenAccessToken', new EmptyAction());
    const ret = await this.authzSvc.apiRequest(callback);
    return ret.action.result;
  }

  async getLocations(): Promise<Location[]> {
    if (this.locations) {
      return JSON.parse(JSON.stringify(this.locations)); //clone the object because we cache it
    }
    const callback = new CallBack('GetLocations', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    this.locations = resultCallBack.action.result;
    return JSON.parse(JSON.stringify(this.locations));
  }

  async getUploadPostObject(archiveID: string): Promise<any> {
    const cache = this.uploadPOSTCache.get(archiveID);
    if (cache) {
      if (cache.expiryTime > Date.now() + 1000 * 600) { // dont use if less that 10 minutes
        return cache.uploadObject
      } else {
        this.uploadPOSTCache.delete(archiveID);
      }
    }

    const callback = new CallBack('GetUploadPostObject', new EmptyAction());
    callback.action.request = archiveID;
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const uploadObject = resultCallBack.action.result;
    this.cacheUploadPOSTObject(archiveID, uploadObject);
    return uploadObject
  }

  /**
   * current MD5 is used for serverside concurrenrcy control for multiregion archives to detect if the file has changed since the upload was initiated
   * @param archiveID
   * @param newMD5
   * @param currentMD5
   * @returns
   */
  async getUploadPutUrl(archiveID: string, newMD5: string, currentEtag?: string, newEtag?: string): Promise<any> {
    const callback = new CallBack('GetUploadPutUrl', new EmptyAction());
    callback.action.request = { archiveID, md5: newMD5, currentEtag, newEtag };
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    return resultCallBack.action.result;
  }

  private cacheUploadPOSTObject(archiveID: string, uploadObject: any) {
    const policy = uploadObject.fields['Policy'];
    const expiration = this.extractPolicyExpiry(policy);
    this.uploadPOSTCache.set(archiveID, { expiryTime: expiration, uploadObject: uploadObject });
  }

  private extractPolicyExpiry(policy: string) {
    const policyArray = fromBase64(policy);
    const decoder = new TextDecoder();
    const policystring = decoder.decode(policyArray);
    const policyObject = JSON.parse(policystring);
    return Date.parse(policyObject.expiration);
  }


  //ToDo cacah download urls for 3600 seconds
  async getRegionalDownloadUrl(archiveID: string) {
    const cache = this.downloadUrlCache.get(archiveID);
    if (cache) {
      if (cache.expiryTime > Date.now() + 1000 * 60) { // dont use if less that 1 minutes
        return cache.url;
      } else {
        this.downloadUrlCache.delete(archiveID);
      }
    }

    const callback = new CallBack('GetRegionalDownloadUrl', new EmptyAction());
    callback.action.request = { archiveID };
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const url = resultCallBack.action.result;
    this.downloadUrlCache.set(archiveID, { expiryTime: Date.now() + 3600 * 1000, url });
    return url
  }

  async getDownloadUrl(archiveID: string) {
    const cache = this.downloadUrlCache.get(archiveID);
    if (cache) {
      if (cache.expiryTime > Date.now() + 1000 * 60) { // dont use if less that 1 minutes
        return cache.url;
      } else {
        this.downloadUrlCache.delete(archiveID);
      }
    }
    const callback = new CallBack('GetDownloadUrl', new EmptyAction());
    callback.action.request = { archiveID };
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const url = resultCallBack.action.result;
    this.downloadUrlCache.set(archiveID, { expiryTime: Date.now() + 3600 * 1000, url });
    return url
  }

  async setReadOnly(setreadonly: boolean) {
    const cfg = this.authzSvc.appConfig;
    cfg.ro = setreadonly;
    await this.setAppConfig(cfg);
    this.authzSvc.appConfig = cfg;
  }

  async setAppConfig(cfg: AppConfig) {
    const callback = new CallBack('SetAppConfig', new EmptyAction());
    callback.action.request = cfg;
    await this.authzSvc.apiRequest(callback);
    Object.assign(this.authzSvc.appConfig, cfg); //keep the same object
  }

  async getUpgradeInfo() {
    const callback = new CallBack('GetUpgradeInfo', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  getAppConfig(): AppConfig {
    return JSON.parse(JSON.stringify(this.authzSvc.appConfig));
  }

  async refreshAppConfig(): Promise<AppConfig> {
    const callback = new CallBack('GetAppConfig', new EmptyAction());;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const appConfig: AppConfig = resultCallBack.action.result;
    this.authzSvc.appConfig = appConfig;
    return appConfig;
  }

  async getAccesses(types: string): Promise<SafeAccess[]> { // types = share or guard os share,guard or device
    const callback = new CallBack('GetAccessCfg', new EmptyAction());
    callback.action.request = { type: types };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const accesses: SafeAccess[] = resultCallBack.action.result;
    return accesses;
  }

  async createAccess(access: SafeAccess): Promise<void> {
    const callback = new CallBack('CreateAccessCfg', new EmptyAction());
    callback.action.request = { access };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    const safeExp = resultCallBack.action.result.safeExp;
    this.authzSvc.safeExpiryEPOCms = safeExp * 1000;
  }

  async updateAccess(access: SafeAccess): Promise<void> {
    const callback = new CallBack('UpdateAccessCfg', new EmptyAction());
    callback.action.request = { access };
    await this.authzSvc.apiRequest(callback);
  }

  async deleteAccess(accessID: string): Promise<void> {
    const callback = new CallBack('DeleteAccess', new EmptyAction());
    callback.action.request = { accessID };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const safeExp = resultCallBack.action.result.safeExp;
    this.authzSvc.safeExpiryEPOCms = safeExp * 1000;
  }

  async getServerKeys(): Promise<{ server: string, client: string }> {
    const callback = new CallBack('GetServerKeys', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  /**
   *
   * @returns the decrypted credential object containing the SafeAccess Master key and accessID
   */
  async getCredentialObject(): Promise<any> {
    const options = await this.getWebAuthAuth();
    const authenticatorData = await startAuthentication({ optionsJSON: options });
    if (!authenticatorData.response.userHandle) {
      throw new Error('No key returned from webauthn');
    }
    let skey = authenticatorData.response.userHandle;
    if (!skey) {
      throw new Error('No key returned from webauthn');
    }

    const key = UserHandleConverter.processUserHandle(skey);

    authenticatorData.response.userHandle = undefined; //!Important remove the key from the response
    const callback = new CallBack('GetDeviceDataPacket', new EmptyAction());
    callback.action.request = { authenticatorData };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const data = resultCallBack.action.result.data;
    const decrypted = await SafeCrypto.decryptCredentialObject(data, key);
    return decrypted
  }

  /**
   *
   * @param passphrase Get the keys from the server and encrypt them with the passphrase
   * @param accessID
   * @returns
   */
  async reInitServiceworkerCrypto(): Promise<{ ClientDataMasterKey: Uint8Array, RAWSessionKey: ArrayBuffer }> {
    Console.log('reInitServiceworkerCrypto');
    const RAWSessionKey = this.authzSvc.crypto.RAWSessionKey;
    if (!RAWSessionKey) {
      throw new Error('RAWSessionKey not initialized');
    }
    const keys = await this.getServerKeys(); // keys are encrypted with the current safeaccess master key

    const credentialObject = await this.getCredentialObject();

    if (!credentialObject) {
      throw new Error('No data returned from webauthn');
    }
    const crypto = new SafeCrypto(true); //brodcast to serviceworker

    await crypto.initCryptoWithSafeAccessMasterKey(credentialObject.pass);
    const wrappedClientDataKey = keys.client;
    const ClientDataMasterKey = await crypto.decryptClientDataMasterKey(wrappedClientDataKey);

    return { ClientDataMasterKey, RAWSessionKey };
  }
}
