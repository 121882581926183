import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'affiliate-link-modal',
  templateUrl: './affiliate-link-modal.component.html',
  styleUrls: ['./affiliate-link-modal.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgStyle, ReactiveFormsModule, TranslateModule]
})
export class AffiliateLinkModalComponent implements OnInit {


  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>;
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLTextAreaElement>;
  @ViewChild("buttonContainer") buttonContainer!: ElementRef<HTMLInputElement>;
  title = 'Affiliate Link';
  message = 'Create Your Affiliate Link';
  @Output() response = new EventEmitter<string | null>();
  linkForm!: FormGroup;
  baseUrl = 'https://safe.unolock.com/';
  affiliateLink: string | null = null;

  constructor(private fb: FormBuilder) {
    this.baseUrl = ` ${window.location.origin}/`;
  }

  async ngOnInit() {
    this.linkForm = this.fb.group({
      suffix: ['', [
        Validators.required,
        Validators.pattern('^[a-zA-Z0-9_-]+$')
      ]]
    });
  }

  get suffixControl(): AbstractControl {
    return this.linkForm.get('suffix')!;
  }

  async onSubmit() {
    if (this.linkForm.valid) {
      const suffix = this.suffixControl.value;
      this.response.emit(suffix);
    }
  }

  cancel() {
    this.response.emit(null);
  }
}
