import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthzService } from './services/authz.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {
  constructor(public authz: AuthzService, public router: Router) { }
  canActivate(): boolean {
    if (!this.authz.isAuthorized()) {
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
