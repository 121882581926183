import { environment } from "src/environments/environment";

export class Console {
  public static log;
  public static error;
  private static debug = true;
  static {
    if (environment.production && !Console.debug) {
      Console.log = function () { };
      Console.error = Function.prototype.bind.call(console.error, console);
    } else {
      //Preserve console.log and console.error line numbers
      Console.log = Function.prototype.bind.call(console.log, console);
      Console.error = Function.prototype.bind.call(console.error, console);
    }
  }
}
