<div class="space-navigation-container" [style.bottom.px]="safeAreaBottom" [ngClass]="{ 'hidden': hide }" >
  <div class="space-navigation">
    <button (click)="goToPreviousSpace()" [disabled]="isLocked || displayedSpace === 1" class="nav-button prev-button">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M15 18L9 12L15 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <span class="space-info">{{ displayedSpace }} / {{ totalSpaces }}</span>
    <button (click)="goToNextSpace()" [disabled]="isLocked || displayedSpace === totalSpaces" class="nav-button next-button">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 18L15 12L9 6" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <button *ngIf="displayedSpace === totalSpaces && isAdmin && totalSpaces < maxSpaces" (click)="addSpace()" [disabled]="isLocked" class="add-button">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12 5V19M5 12H19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
    <input
      type="text"
      [value]="currentSpaceName"
      (input)="onSpaceNameInput($event)"
      (keyup.enter)="onSpaceNameEnter()"
      [disabled]="isLocked"
      placeholder="Space name"
      class="space-name-input"
      [readonly]="!!this.apiSvc.isReadOnly()"
    >
  </div>
</div>
