import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-license',
  standalone: true,
  imports: [],
  templateUrl: './license.component.html',
  styleUrl: './license.component.scss'
})
export class LicenseComponent implements OnInit {
  licenseText: string = '';

  ngOnInit() {
    fetch('3rdpartylicenses.txt')
      .then(response => response.text())
      .then(text => this.licenseText = text);
  }
}
