import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { State } from '../state';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'message-modal',
  templateUrl: './message-modal.component.html',
  styleUrls: ['./message-modal.component.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class MessageModalComponent {
  displayData = { title: '', message: '' };

  @Output() response = new EventEmitter<State>();

  @Input() set data(value: { title: string, message: string }) {
    const clone = JSON.parse(JSON.stringify(value));
    clone.message = this.sanitizer.bypassSecurityTrustHtml(value.message) as string;
    this.displayData = clone;
  }
  constructor(private sanitizer: DomSanitizer) {
  }

  next() {
    this.response.emit({ value: null });
  }
}
