
  export class SafeAccess {
    type = 'd'; //  g = lockout guard, l = legacy link, d = device
    interval? = 7; // pulse point interval in days. Checks every day when missed
    failures? = 14; // number of failed health checks before enabling lockoutguard access, ie days.
    legacyDelay? = 90; // number of days after lockout guard opens before enabling legacy link access
    legacyId? = ''; // id of legacy access to enable
    pushToken? = ''; // id of push access
    accessID = '';
    wrappedServerKey = '';
    wrappedClientKey = ''; // encrypted client master data key, encrypted with the user's passphase and used by client to initialze its keyring
    enabled = false; // enabled
    TTL = 0; // time to live; // delete after this date
    accesstext?='';
    name? = ''; // name of access
    mode: 'ro'| 'rw'| 'admin'= 'ro'; // r = read only, rw = read/write
    clicks?: { x: number; y: number; }[]
    spaceIds: number[] = [];
}
