import { Pipe, PipeTransform } from '@angular/core';
import { CheckboxI } from '../interfaces/records';

@Pipe({
    name: 'cboxSort',
    standalone: true,
})
export class CboxSortPipe implements PipeTransform {

  transform(object: CheckboxI[]): CheckboxI[] {
    return object.sort((x, y) => Number(x.done) - Number(y.done))
  }
}
