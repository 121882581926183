import { Directive, ElementRef, HostListener, Input } from '@angular/core';

const imgSrc = './assets/images/svg/copy.svg';
@Directive({
  selector: '[appCopyText]',
  standalone: true
})
export class CopyTextDirective {
  @Input() copyText!: string; // The text to copy to the clipboard

  constructor(private el: ElementRef) {}

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const textToCopy = this.copyText || this.el.nativeElement.innerText || this.el.nativeElement.textContent;

    // Copy the text to clipboard
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.createAnimatedIcon(event);
    });
  }

  private createAnimatedIcon(event: MouseEvent) {
    // Get the parent element to append the clone (can be body or modal depending on context)
    const parent = document.body;

    // Create a new image element for the animation
    const animatedIcon = document.createElement('img');
    animatedIcon.src = imgSrc;
    animatedIcon.style.position = 'absolute';
    animatedIcon.style.top = `${event.pageY - 16}px`; // Adjust to center the image on the click
    animatedIcon.style.left = `${event.pageX - 16}px`;
    animatedIcon.style.width = '32px';
    animatedIcon.style.height = '32px';
    animatedIcon.style.pointerEvents = 'none';
    animatedIcon.style.transition = 'transform 2s ease-out, opacity 2s ease-out';
    animatedIcon.style.zIndex = '9999';

    // Append the animated icon to the parent
    parent.appendChild(animatedIcon);

    // Apply the animation using JavaScript after the element is added
    setTimeout(() => {
      animatedIcon.style.transform = 'scale(2)';
      animatedIcon.style.opacity = '0';
    }, 10);

    // Remove the clone after the animation ends
    setTimeout(() => {
      if (animatedIcon && animatedIcon.parentElement) {
        animatedIcon.parentElement.removeChild(animatedIcon);
      }
    }, 2010);
  }
}
