import { Component, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { NgClass, NgIf } from '@angular/common';
import { State } from '../state';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'question-modal',
  templateUrl: './question-modal.component.html',
  styleUrls: ['./question-modal.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf]
})
export class QuestionModalComponent {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<State>();
  @Input() set data(value: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null }) {
    const clone = JSON.parse(JSON.stringify(value));
    clone.message = this.sanitizer.bypassSecurityTrustHtml(value.message) as string;
    this.displayData = clone;
  }
  displayData: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null } = { title: '', message: '', btnCancelLable: '', btnOneLable: '', btnTwoLable: '' };

  constructor(private sanitizer: DomSanitizer) {
  }

  buttonClick(selected: string) {
    this.response.emit({ value: selected });
  }
}
