import { Injectable } from "@angular/core";
import { AbstractState } from "./abstractState";
import { UploadingState } from "./uploadingState";
import { Console } from "src/app/lib/console";

@Injectable()
export class LocalMessagesState extends AbstractState {
  constructor() { super(); }

  async init() {
    Console.log('LocalMessagesState.init()', this.modal.file);
    this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.LOCAL.TITLE')
    this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.LOCAL.MSG1');
    if (this.modal.mobile) {
      this.message += this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.LOCAL.MSG2');
      if (this.modal.ios) {
        this.message += this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.LOCAL.MSG3');
      }
    }
    this.button1Text = this.modal.translate.instant('OK');
  }

  override click1(): void {
    this.modal.errorSvc.supressScreenBlur();
    this.modal.setState(new UploadingState());
  }
}
