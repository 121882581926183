import { Injectable } from "@angular/core";
import { AbstractState } from "./abstractState";
import { LocalMessagesState } from "./localMessagesState";
import { Console } from "src/app/lib/console";

@Injectable()
export class FreeStartState extends AbstractState {
  constructor() { super() }

  async init() {
    Console.log('FreeStartState.init()', this.modal.file);
    if (this.modal.file) {
      this.modal.local = true;
      this.modal.setState(new LocalMessagesState());
    } else {
      this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.START.TITLE');
      this.button1Text = this.modal.translate.instant('CANCEL');
    }
  }

  override click1(): void {
    this.modal.respond(null); //cancel
  }

  override onFileSelected(event: any): void {
    Console.log('onFileSelected', event);
    this.modal.file = event.target.files[0];
    if (!this.modal.file) {
      this.modal.respond(null);
    } else {
      this.modal.local = true;
      this.modal.setState(new LocalMessagesState());
    }
  }
}
