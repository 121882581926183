<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title-container">
          <h2 class="auth-title" [innerHTML]="title"></h2>
          <span class="close" (click)="cancel()">X</span>
        </div>
        <div #authBody class="auth-body">
          <div [innerHTML]="message" class="textHolder notranslate"></div>
          <div *ngIf="supportedProviders.length > 0; else noProviders">
            <form [formGroup]="apiKeyForm" (ngSubmit)="onSubmit()">
              <label for="provider">API Provider:</label>
              <select id="provider" formControlName="provider" (change)="onProviderChange()">
                <option *ngFor="let provider of supportedProviders" [value]="provider">
                  {{ provider }}
                </option>
              </select>
              <button class="btn" type="button" (click)="OpenRegistrationPage()">Open Providers registration page</button>
              <label for="apiKey">API Key:</label>
              <input id="apiKey" type="text" formControlName="apiKey" placeholder="Enter API Key" />
              <div class="button-container">

              <button class="btn-primary" type="submit">{{ apiKeyForm.get('apiKey')?.dirty ? 'Update' : 'Add' }} API Key</button>
              </div>
            </form>
          </div>

          <ng-template #noProviders>
            <p>No API providers available for {{ wallet.coinType }}</p>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
