import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { State } from 'src/app/components/modal/modals/state';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'number-modal',
  templateUrl: './number-modal.component.html',
  styleUrls: ['./number-modal.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgStyle, FormsModule, TranslateModule]
})
export class NumberModalComponent implements OnInit, OnDestroy, OnChanges {
  ngOnChanges(changes: SimpleChanges): void {
    Console.log('number modal changes', changes);
    if (changes['data'] && changes['data'].previousValue) {
      this.data = changes['data'].currentValue;
      this.value = this.data.def;
    }
  }

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>;
  @ViewChild("buttonContainer") buttonContainer!: ElementRef<HTMLInputElement>;
  @ViewChild('number') number!: ElementRef<HTMLInputElement>;

  @Output() response = new EventEmitter<State>();
  @Input() data!: { title: string, message: string, label: string, def: number, min?: number, max?: number, step?: number };

  value = 0; // used for display only

  private resizeListent = () => {
    Console.log('resize');
    this.buttonContainer.nativeElement.scrollIntoView(false); // aligned to the bottom of the visible area for soft keyboards
  };

  ngOnInit(): void {
    this.value = this.data.def;
    visualViewport?.addEventListener("resize", this.resizeListent);
  }

  ngOnDestroy(): void {
    //Small screen soft keyboards are a pain
    visualViewport?.removeEventListener("resize", this.resizeListent);
  }

  async send() {
    this.response.emit({ value: Number.parseInt(this.number.nativeElement.value) });
  }

  async cancel() {
    this.response.emit({ value: null });
  }
}
