import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { AuthzService } from 'src/app/services/authz.service';

@Component({
  selector: 'app-spacecontrol',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './spacecontrol.component.html',
  styleUrl: './spacecontrol.component.scss'
})
export class SpacecontrolComponent {
  maxSpaces = 50;

  constructor(public authzSvc: AuthzService, public apiSvc:ApiService, private route: Router) {
  }

  isAdmin = false;
  routeSub: any;
  hide = false;
  @Input() totalSpaces: number = 1;
  @Input() set currentSpaceIndex(value: number) {
    Console.log('SpacecontrolComponent currentSpaceIndex', value);
    this.currentSpace = value+1;
  }
  @Input() set isLocked(locked: boolean) {
    this._isLocked = locked;
    if (!locked && this.pendingSpace !== null) {
      this.currentSpace = this.pendingSpace;
      this.pendingSpace = null;
    }
  }
  get isLocked(): boolean {
    return this._isLocked;
  }

  @Input() set currentSpaceName(value: string) {
    this._currentSpaceName = value;
  }
  get currentSpaceName(): string {
    return this._currentSpaceName;
  }

  @Output() spaceChange = new EventEmitter<number>();
  @Output() newSpace = new EventEmitter<void>();
  @Output() spaceNameChange = new EventEmitter<{ space: number, name: string }>();

  currentSpace: number = 1;
  displayedSpace: number = 1;
  safeAreaBottom = 0;
  private _isLocked: boolean = false;
  private pendingSpace: number | null = null;
  private _currentSpaceName: string = '';

  private spaceChangeSubject = new Subject<number>();
  private subscription!: Subscription;

  @HostListener('window:resize')
  onResize() {
    this.updateSafeArea();
  }

  ngOnInit() {
    this.updateSafeArea();
    this.subscription = this.spaceChangeSubject.pipe(
      debounceTime(500)
    ).subscribe((space) => {
      this.changeSpace(space);
    });
    this.routeSub = this.route.events.subscribe((event: any) => {
      if (event.type != 1) return;
      //NavigationEnd event
      const url: string = event.urlAfterRedirects;
      if (url && (url.startsWith('/config') || url.startsWith('/archives/'))) {
        this.hide = true;
      } else {
        this.hide = false;
      }
    });

    this.isAdmin = this.authzSvc.appConfig.isAdmin;
    const tier = this.authzSvc.appConfig.tier;
    if (tier && tier == 1) {
      this.isAdmin = false;
      this.maxSpaces = 2;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.routeSub.unsubscribe();
  }

  updateSafeArea() {
    const safeAreaInset = getComputedStyle(document.documentElement).getPropertyValue('--sat-safe-area-bottom');
    Console.log('safeAreaInset', safeAreaInset);
    //need to remove 'px' from the end of the safeAreaInset string
    const safeAreavalue = parseInt(safeAreaInset.substring(0, safeAreaInset.length - 2));
    this.safeAreaBottom = safeAreavalue ? safeAreavalue : 10;
    Console.log('safeAreaBottom', this.safeAreaBottom);
  }

  goToPreviousSpace() {
    if (this.displayedSpace > 1 && !this.isLocked) {
      this.displayedSpace--;
      this.spaceChangeSubject.next(this.displayedSpace);
    }
  }

  goToNextSpace() {
    if (this.displayedSpace < this.totalSpaces && !this.isLocked) {
      this.displayedSpace++;
      this.spaceChangeSubject.next(this.displayedSpace);
    }
  }

  addSpace() {
    if (!this.isLocked) {
      this.isLocked = true;
      this.displayedSpace++;
      this.pendingSpace = this.displayedSpace;
      this.newSpace.emit();
    }
  }

  onSpaceNameInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this._currentSpaceName = input.value;
  }

  onSpaceNameEnter() {
    this.spaceNameChange.emit({ space: this.currentSpace, name: this.currentSpaceName });
  }

  private changeSpace(space: number) {
    if (space !== this.currentSpace && !this.isLocked) {
      this.spaceChange.emit(space);
      this._isLocked = true;
      this.pendingSpace = space;
    }
  }
}
