import { Component, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { SharedService } from 'src/app/services/shared.service';
import { AuthzService } from 'src/app/services/authz.service';
import { DATE_PIPE_DEFAULT_OPTIONS, NgClass, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { RecordsService } from 'src/app/services/records.service';
import { ModalComponent } from '../modal/modal.component';
import { Router } from '@angular/router';
import { NetworkService } from 'src/app/services/network.service';
import { C2Service } from 'src/app/services/c2.service';
const SECONDS_IN_A_DAY = 86400;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule, DatePipe, ModalComponent, NgClass],
  providers: [{
    provide: DATE_PIPE_DEFAULT_OPTIONS,
    useValue: { dateFormat: "longDate" }
  }],
})
export class NavbarComponent {
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  constructor(public Shared: SharedService, public api: ApiService, public authSvc: AuthzService, public translate: TranslateService, private recordsSvc: RecordsService, private router: Router, public networkSvc: NetworkService, private  C2Svc: C2Service) { 
  }

  getTierName(): string {
    const tier = this.authSvc.appConfig.tier;
    switch (tier) {
      case 0:
        return 'FreeSafe';
      case 1:
        return 'PrimeSafe';
      case 2:
        return 'LockSafe';
      case 3:
        return 'LifeSafe';
      case 4:
        return 'LegacyGuard';
      default:
        return 'Unknown';
    }
  }

  toggleSideBar() { this.Shared.setSideBar.next('toggle'); }

  async refresh() {
    this.api.clearCaches();
    this.C2Svc.flushCache();
    await this.api.refreshAppConfig(); //admin status migh have changed btw wait for it so running in local mode works reliably
    this.recordsSvc.refresh();
  }

  async close() {
    const timer = setTimeout(() => {
      location.reload();
    }, 10000);
    const response = await this.modalController.displayQuestion(this.translate.instant('NAV_BAR.TIMELOCK.TITLE'), this.translate.instant('NAV_BAR.TIMELOCK.MSG1'), this.translate.instant('CANCEL'), this.translate.instant('NAV_BAR.TIMELOCK.BTN2'), this.translate.instant('NAV_BAR.TIMELOCK.BTN1'));
    if (response === 'ONE') {
      clearTimeout(timer);
      const safeExpiresInDays = Math.floor(((this.authSvc.safeExpiryEPOCms - Date.now()) / 1000) / SECONDS_IN_A_DAY) - 7;
      const days = await this.modalController.displayNumber(this.translate.instant('NAV_BAR.TIMELOCK.TITLE'), this.translate.instant('NAV_BAR.TIMELOCK.MSG2'), 'Days', 1, 1, safeExpiresInDays, 1);
      if (!days) return;
      const time = Date.now() / 1000 + (days * SECONDS_IN_A_DAY);
      const date = new Date(time * 1000).toDateString();
      const sure = await this.modalController.displayQuestion(this.translate.instant('NAV_BAR.TIMELOCK.TITLE'), this.translate.instant('NAV_BAR.TIMELOCK.MSG3', { days: days, date: date }), this.translate.instant('CANCEL'), null, this.translate.instant('NAV_BAR.TIMELOCK.BTN2'));
      if (sure != 'TWO') return;
      await this.api.setTimeLock(time, 'DEVICE');
    } else if (response === 'ZERO') {
      clearTimeout(timer);
      return;
    }
    location.reload();
  }

  toggleView() {
    this.Shared.recordViewType.value === 'grid' ? this.Shared.recordViewType.next('list') : this.Shared.recordViewType.next('grid')
  }

  openTooltip(button: HTMLDivElement, tooltipEl: HTMLDivElement) {
    this.Shared.createTooltip(button, tooltipEl);
  }

  getCredits() {
    this.router.navigate(['/config', { action: "CREDITS" }]);
  }

  unlock() {
    if (this.authSvc.appConfig.isAdmin) { // must be admin to disabel datashield
      this.router.navigate(['/config', { action: "LOCK" }]);
    }
  }
}
