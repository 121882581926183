export const bgColorsConst = {
    _ : {bg:'',color: '' },
    red : {bg:'#5c2b29',color: 'white' },
    orange : {bg:'#614a19',color: 'white' },
    yellow :{bg: '#635d19',color: 'white' },
    green : {bg:'#345920',color: 'white' },
    teal : {bg:'#16504b',color: 'white' },
    blue : {bg:'#2d555e',color: 'white' },
    darkblue : {bg:'#1e3a5f',color: 'white' },
    purple : {bg:'#42275e',color: 'white' },
    pink :{bg: '#5b2245',color: 'white' },
    brown : {bg:'#442f19',color: 'white' },
    gray : {bg:'#3c3f43',color: 'white' },
} as const;
export type bgColors = typeof bgColorsConst[keyof typeof bgColorsConst];


export const bgImagesConst = {
    i0 : {img:'', color:''},
    i1 :  {img:'/assets/images/backgrounds/image-1.svg',color: 'white' },
    i2 :  {img:'/assets/images/backgrounds/image-2.svg',color: 'white' },
    i3 :  {img:'/assets/images/backgrounds/image-3.svg',color: 'white' },
    i4 :  {img:'/assets/images/backgrounds/image-4.svg',color: 'white' },
    i5 :  {img:'/assets/images/backgrounds/image-5.svg',color: 'white' },
    i6 : {img: '/assets/images/backgrounds/image-6.svg',color: 'white' },
    i7 : {img: '/assets/images/backgrounds/image-7.svg',color: 'white' },
} as const;
export type bgImages = typeof bgImagesConst[keyof typeof bgImagesConst];

