import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { AuthzService } from 'src/app/services/authz.service';
import { db } from 'src/app/db/json';

@Component({
  selector: 'app-spacecontrol',
  standalone: true,
  imports: [NgIf, NgClass],
  templateUrl: './spacecontrol.component.html',
  styleUrl: './spacecontrol.component.scss'
})
export class SpacecontrolComponent {
  maxSpaces = 50;

  constructor(public authzSvc: AuthzService, public apiSvc: ApiService, private route: Router, public db: db) {
  }

  isAdmin = false;
  routeSub: any;
  hide = false;

  @Input() isLocked: boolean = false;

  @Input() set currentSpaceName(value: string) {
    this._currentSpaceName = value;
  }
  get currentSpaceName(): string {
    return this._currentSpaceName;
  }

  @Output() spaceChange = new EventEmitter<number>();
  @Output() newSpace = new EventEmitter<void>();
  @Output() spaceNameChange = new EventEmitter<{ space: number, name: string }>();

  changespaceNumberDebounce = -1

  safeAreaBottom = 0;

  private _currentSpaceName: string = '';

  private spaceChangeSubject = new Subject<number>();
  private subscription!: Subscription;

  @HostListener('window:resize')
  onResize() {
    this.updateSafeArea();
  }

  ngOnInit() {
    this.updateSafeArea();
    this.subscription = this.spaceChangeSubject.pipe(
      debounceTime(500)
    ).subscribe((space) => {
      this.changeSpace(space);
    });

    this.routeSub = this.route.events.subscribe((event: any) => {
      if (event.type != 1) return;
      //NavigationEnd event
      const url: string = event.urlAfterRedirects;
      if (url && (url.startsWith('/config') || url.startsWith('/archives/'))) {
        this.hide = true;
      } else {
        this.hide = false;
      }
    });

    this.isAdmin = this.authzSvc.appConfig.isAdmin;
    const tier = this.authzSvc.appConfig.tier;
    if (tier && tier == 1) {
      this.isAdmin = false;
      this.maxSpaces = 2;
    }
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.routeSub.unsubscribe();
  }

  updateSafeArea() {
    const safeAreaInset = getComputedStyle(document.documentElement).getPropertyValue('--sat-safe-area-bottom');
    //need to remove 'px' from the end of the safeAreaInset string
    const safeAreavalue = parseInt(safeAreaInset.substring(0, safeAreaInset.length - 2));
    this.safeAreaBottom = safeAreavalue ? safeAreavalue : 10;
  }

  goToPreviousSpace() {
    if (this.isLocked) {
      return;
    }
    if (this.changespaceNumberDebounce == -1) {
      this.changespaceNumberDebounce = this.db.currentSpaceNumber + 1;
    }
    if (this.changespaceNumberDebounce > 1) {
      this.changespaceNumberDebounce--;
      this.spaceChangeSubject.next(this.changespaceNumberDebounce);
    }
  }

  goToNextSpace() {
    if (this.isLocked) {
      return;
    }
    if (this.changespaceNumberDebounce == -1) {
      this.changespaceNumberDebounce = this.db.currentSpaceNumber + 1;
    }
    if (this.changespaceNumberDebounce < this.db.spaces.length) {
      this.changespaceNumberDebounce++;
      this.spaceChangeSubject.next(this.changespaceNumberDebounce);
    }
  }

  addSpace() {
    if (!this.isLocked) {
      this.isLocked = true;
      this.newSpace.emit();
    }
  }

  onSpaceNameInput(event: Event) {
    const input = event.target as HTMLInputElement;
    this._currentSpaceName = input.value;
  }

  onSpaceNameEnter() {
    this.spaceNameChange.emit({ space: this.db.currentSpaceNumber + 1, name: this.currentSpaceName });
  }

  private changeSpace(space: number) {
    this.changespaceNumberDebounce = -1
    if (space !== this.db.currentSpaceNumber + 1) {
      this.spaceChange.emit(space);
    }
  }
}
