import { Injectable } from '@angular/core';
import { C2CMD } from '../interfaces/C2CMD';
import { Console } from '../lib/console';
import { SafeContact } from '../interfaces/safeContact';
import { ApiService } from './api.service';
import { EyesEncryptionService } from './eyesEncryption.service';
import { SUB_UPDATE } from '../lib/C2/SUB_UPDATE';
import { EyesAddressService } from './eyesAddress.service';
import base64url from 'base64url';
import { EyesHeader, Message } from '../interfaces/eyesHeader';
import { Archive } from '../interfaces/archive';
import { SUB_REQUEST } from '../lib/C2/SUB_REQUEST';

@Injectable({
  providedIn: 'root'
})
export class C2Service {

  public static instance: C2Service;
  constructor(public apiSvc: ApiService, public eyesAddrSvc: EyesAddressService, public eyesEncrySvc: EyesEncryptionService) {
    C2Service.instance = this;
  }

  async executeCommand(command: C2CMD, contact: SafeContact): Promise<void> {
    Console.log('Executing command:', command);
    switch (command.type) {
      case 'SUB_UPDATE':
        await new SUB_UPDATE(this).executeCommand(command, contact);
        break;
      case 'SUB_REQUEST':
        await new SUB_REQUEST(this).executeCommand(command, contact);
        break;
      default:
        Console.log('Unknown command:', command);
    }
  }

  async sendCommand(command: C2CMD, contact: SafeContact): Promise<void> {
    Console.log(`Sending command:${command} to ${contact}`);
    const publickey = await this.eyesAddrSvc.getPublicKey(contact.Id);
    if (!publickey) {
      throw new Error('Public key not found');
    }

    const clearheader: EyesHeader = {
      sender: this.apiSvc.getAppConfig().eyesAddr,
      subject: '',
      message: '',
      timestamp: Date.now(),
      attachments: [],
      cmd: JSON.stringify(command)
    }

    let signingKey: Uint8Array | undefined;
    let signingkeyid: number | undefined;
    const keys = await this.apiSvc.getEyesPrivateKeys();

    Console.log('got private Keys:', keys);
    if (keys?.signingKey) {
      signingKey = new Uint8Array(base64url.toBuffer(keys.signingKey.key));
      signingkeyid = keys.signingKey.id;

    }

    // sign and seal (encrypt) the headder
    const { header } = await this.eyesEncrySvc.sealSeparate(clearheader, [], publickey, signingKey, signingkeyid);
    await this.apiSvc.publishMessage(contact.Id, Date.now(), base64url.encode(Buffer.from(header)), true, []);
  }

  private _cache: {
    data: {
      newMessages: Message[],
      procMessages: Message[],
      outMessages: {
        messageID: number;
        a: string;
        t: 'C2' | 'M';
        attachments?: string[];
      }[]
    },
    timestamp: number
  } | null = null;
  private readonly CACHE_TTL = 120000; // Cache valid for 120 seconds
  public flushCache() {
    this._cache = null;
  }

  public async getEyesMessages(skipCache = false): Promise<{
    newMessages: Message[], procMessages: Message[], outMessages: {
      messageID: number;
      a: string;
      t: 'C2' | 'M';
      attachments?: string[];
    }[]
  }> {

    // Return cached data if available and not expired
    if (!skipCache && this._cache && (Date.now() - this._cache.timestamp < this.CACHE_TTL)) {
      return this._cache.data;
    }

    const { messages, outMessages } = await this.apiSvc.getEyesMessages();
    const { newMessages, procMessages } = await this.decodeMessages(messages);
    if (newMessages.length > 0) {
      Console.log('Processing New messages:', newMessages);
      await this.removedBlockedMessages(newMessages);
      await this.processNewMessages(newMessages);
      if (newMessages.length > 0) {
        this.apiSvc.haveNewMessages = true;
      } else {
        this.apiSvc.haveNewMessages = false;
      }
    }

    //filter out outbound C2 command messages
    const filterdOutMessages = outMessages.filter(m => m.t === 'M');


    filterdOutMessages.forEach(async m => { m.a = await this.eyesAddrSvc.getName(m.a) || m.a });
    // Cache the result along with the current timestamp
    const result = { newMessages, procMessages, outMessages: filterdOutMessages };
    this._cache = { data: result, timestamp: Date.now() };
    return result;
  }

  async removedBlockedMessages(newMessages: Message[]) {
    const toremove: Message[] = [];
    for (const message of newMessages) {
      const sender = message.header.sender;
      const blocked = !sender || await this.eyesAddrSvc.getTrustLevel(sender) === 'Blocked';
      if (blocked) {
        Console.log('Blocked contact:deleting message from:', sender);
        await this.apiSvc.acceptEyesMessage([message.id], true);
        toremove.push(message);
      }
    }
    for (const message of toremove) {
      newMessages.splice(newMessages.indexOf(message), 1);
    }
  }

  /**
   * Check if there are C2 commands in the new messages and executes them
   * @param newMessages 
   */
  private async processNewMessages(newMessages: Message[]) {
    const acceptedMessages: number[] = [];
    for (const message of newMessages) {
      const sender = message.header.sender!;
      if (message.header.cmd) {
        acceptedMessages.push(message.id);
        const cmd = JSON.parse(message.header.cmd);
        const contact = await this.eyesAddrSvc.getContact(sender);
        if (!contact) {
          Console.error('Contact not found: not executing command');
          continue;
        }
        await this.executeCommand(cmd, contact);
      }
    }
    if (acceptedMessages.length > 0) {
      await this.apiSvc.acceptEyesMessage(acceptedMessages, true);
      //remove the messages that have been processed from the new messages array
      for (const message of newMessages) {
        if (acceptedMessages.includes(message.id)) {
          newMessages.splice(newMessages.indexOf(message), 1);
        }
      }
    }
  }

  private async decodeMessages(eyesMessages: { messageID: number; header: string; changeTS: number; status: "NEW" | "PROC"; attachments?: string[]; }[]) {
    const keys = await this.apiSvc.getEyesPrivateKeys();
    if (!keys) {
      throw new Error('No keys found');
    }
    const newMessages: Message[] = [];
    const procMessages: Message[] = [];
    const privatekey = base64url.toBuffer(keys.privateKeys[0].key);
    for (const eyesMessge of eyesMessages) {

      const encryptedH3eaderBuffer = base64url.toBuffer(eyesMessge.header);
      const { header } = await this.eyesEncrySvc.unsealSeparate(encryptedH3eaderBuffer, privatekey);
      const size = header.attachments.reduce((acc, curr) => acc + curr.size, 0);

      let name = header.sender;
      if (name) {
        const savedName = await this.eyesAddrSvc.getName(name);
        if (savedName) {
          name = savedName;
        }
      }

      const message: Message = {
        id: eyesMessge.messageID,
        sender: name || 'Unknown',
        subject: header.subject || '',
        date: new Date(eyesMessge.messageID).toISOString(),
        header: header,
        status: eyesMessge.status,
        size: size,
      }
      if (eyesMessge.attachments) {

        message.attachments = [];
        for (const attachment of eyesMessge.attachments) {
          const archive: Archive = JSON.parse(attachment);
          message.attachments.push(archive);
        }
      }

      if (message.status === 'NEW') {
        newMessages.push(message);
      } else if (message.status === 'PROC') {
        procMessages.push(message);
      }

    }
    return { newMessages, procMessages };
  }

  async acceptEyesMessage(messageIds: number[]) {
    const result = await this.apiSvc.acceptEyesMessage(messageIds);
    this.flushCache();
    return result;
  }

  async deleteEyesMessage(messageId: number) {
    const result = await this.apiSvc.deleteEyesMessage(messageId);
    this.flushCache();
    return result;
  }

  async publishMessage(recipientAddress: string, messageID: number, header: string, attachmentArchives: string[], subscription: string | undefined) {
    if(this._cache){
      const name = await this.eyesAddrSvc.getName(recipientAddress);
      this._cache.data.outMessages.push({messageID, a: name ? name: recipientAddress, t: 'M', attachments: attachmentArchives});
    }
    const result = await this.apiSvc.publishMessage(recipientAddress, messageID, header, false, attachmentArchives, subscription);
    if (!result.pushResult) {
      Console.log('Removing old push subscription');
      await this.eyesAddrSvc.setPushSubscription(recipientAddress, '');
      //sent a SUB_REQUEST command to the recipient

      const contact = await this.eyesAddrSvc.getContact(recipientAddress);
      if (contact) {
        const cmd = SUB_REQUEST.getCmd();
        this.sendCommand(cmd, contact);
      }
    }
  }

}
