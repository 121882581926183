import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import base64url from 'base64url';
import { Console } from '../lib/console';
import { SafeContact } from '../interfaces/safeContact';

export interface SafeAddressBook {
  contacts: SafeContact[];
  pushSub?: string;
}

@Injectable({
  providedIn: 'root'
})
export class EyesAddressService {

  private addressBook: SafeAddressBook | null = null;

  constructor(private apiService: ApiService) { }

  /**
   * Loads the address book from storage or initializes a new one.
   */
  private async getAddressBook(): Promise<SafeAddressBook> {
    if (!this.addressBook) {
      try {
        const dataString = await this.apiService.getEyesAddresses();
        if (dataString) {
          this.addressBook = JSON.parse(dataString) as SafeAddressBook;
          if (!this.addressBook.contacts) {
            this.addressBook.contacts = [];
          }
        } else {
          this.addressBook = { contacts: [], pushSub: undefined };
        }
      } catch (error) {
        Console.error('Error loading address book:', error);
        this.addressBook = { contacts: [], pushSub: undefined };
      }
    }
    return this.addressBook;
  }

  /**
   * Persists the address book to storage with error handling.
   */
  private async saveAddressBook(): Promise<void> {
    if (!this.addressBook) {
      Console.error('No address book available to save.');
      return;
    }
    try {

      //clone contacts with the publickeys striped out
      const contacts = this.addressBook.contacts.map(contact => {
        return {
          Id: contact.Id,
          name: contact.name,
          trustLevel: contact.trustLevel,
          pushSubscription: contact.pushSubscription
        }
      });

      const book = { contacts: contacts, pushSub: this.addressBook.pushSub };

      const jsonString = JSON.stringify(book);
      await this.apiService.putEyesAddresses(jsonString);
    } catch (error) {
      Console.error('Error saving address book:', error);
    }
  }

  /**
   * Validates an address.
   */
  private isValidAddress(address: string): boolean {
    return address.length === 21 && !address.includes(' ');
  }

  /**
   * Ensures a contact entry exists; creates one if missing.
   */
  private async ensureContactEntry(address: string): Promise<SafeContact> {
    if(!this.isValidAddress(address)) {
      throw new Error(`Invalid address: ${address}`);
    }
    const addressBook = await this.getAddressBook();
    let contact = addressBook.contacts.find(c => c.Id === address);
    if (!contact) {
      contact = { Id: address, name: '', trustLevel: 'Limited' };
      addressBook.contacts.push(contact);
      await this.saveAddressBook();
    }
    return contact;
  }

  /**
   * Loads and caches a contact's public key.
   */
  private async loadPublicKey(address: string): Promise<Uint8Array | null> {
    Console.log(`loadPublicKey Address: ${address}`);
    const contact = await this.ensureContactEntry(address);

    if (contact.publicKey) {
      return contact.publicKey;
    }

    if (!this.isValidAddress(address)) {
      Console.error(`loadPublicKey: Address ${address} is not valid`);
      return null;
    }

    try {
      const b64key = await this.apiService.getEyesPublicKey(address);
      if (b64key) {
        const buffer = new Uint8Array(base64url.toBuffer(b64key));
        contact.publicKey = buffer;
        return buffer;
      }
    } catch (error) {
      Console.error(`Error loading public key for address ${address}:`, error);
    }
    return null;
  }

  /**
   * Retrieves a contact entry by address or name.
   */
  async getContact(addressOrName: string): Promise<SafeContact | null> {
    const address = await this.getAddress(addressOrName) || addressOrName;
    const addressBook = await this.getAddressBook();
    return addressBook.contacts.find(c => c.Id === address) || null;
  }

  /**
   * Returns the name for the given address or name.
   */
  async getName(addressOrName: string): Promise<string | null> {
    if (!addressOrName) return null;
    const contact = await this.getContact(addressOrName);
    return contact?.name || null;
  }

  /**
   * Finds and returns the address for the given input (address or name).
   */
  async getAddress(addressOrName: string): Promise<string | null> {
    const addressBook = await this.getAddressBook();
    const contact = addressBook.contacts.find(c => c.Id === addressOrName || c.name === addressOrName);
    return contact ? contact.Id : null;
  }

  /**
   * Sets the name for a contact while preventing duplicate names.
   */
  async setName(addressOrName: string, name: string): Promise<SafeContact | null> {
    if (!name || name.trim() === '') {
      Console.error('Name cannot be empty.');
      return null;
    }
    name = name.trim();
    const address = await this.getAddress(addressOrName) || addressOrName;
    if (address) {
      await this.ensureContactEntry(address);
    }
    const addressBook = await this.getAddressBook();

    // Prevent duplicate names.
    const duplicate = addressBook.contacts.find(c => c.name === name && c.Id !== address);
    if (duplicate) {
      Console.error('A contact with this name already exists.');
      return null;
    }

    const contact = addressBook.contacts.find(c => c.Id === address);
    if (!contact) {
      Console.error('Contact not found for address:', address);
      return null;
    }
    if (contact.name === name) {
      // No update needed.
      return contact;
    }
    contact.name = name;
    await this.saveAddressBook();
    return contact;
  }

  /**
   * Deletes a contact based on address or name.
   */
  async deleteContact(addressOrName: string): Promise<void> {
    const address = await this.getAddress(addressOrName) || addressOrName;
    const addressBook = await this.getAddressBook();
    const index = addressBook.contacts.findIndex(c => c.Id === address);
    if (index !== -1) {
      addressBook.contacts.splice(index, 1);
      await this.saveAddressBook();
    } else {
      Console.log(`deleteAddress: Contact with address ${address} not found.`);
    }
  }

  /**
   * Returns an array of all contact names.
   */
  async getContactNames(): Promise<string[]> {
    const addressBook = await this.getAddressBook();
    return addressBook.contacts.map(contact => contact.name);
  }

  async getContacts(): Promise<SafeContact[]> {
    const addressBook = await this.getAddressBook();
    return addressBook.contacts;
  }

  /**
   * Retrieves the public key for a given address or name.
   */
  async getPublicKey(addressOrName: string): Promise<Uint8Array | null> {
    const address = await this.getAddress(addressOrName) || addressOrName;
    return this.loadPublicKey(address);
  }

  /**
   * Retrieves the trust level for a specific address.
   */
  async getTrustLevel(address: string): Promise<'Blocked' | 'Limited' | 'Standard' | 'Admin'> {
    const contact = await this.ensureContactEntry(address);
    return contact.trustLevel;
  }

  /**
   * Sets the trust level for a specific address.
   */
  async setTrustLevel(address: string, trustLevel: 'Blocked' | 'Limited' | 'Standard' | 'Admin'): Promise<void> {
    const contact = await this.ensureContactEntry(address);
    if (contact.trustLevel === trustLevel) return;
    contact.trustLevel = trustLevel;
    await this.saveAddressBook();
  }

  /**
   * Sets the push subscription for a contact.
   */
  async setPushSubscription(address: string, subscription: string): Promise<void> {
    const contact = await this.ensureContactEntry(address);
    if (contact.pushSubscription === subscription) return;
    contact.pushSubscription = subscription || undefined;
    await this.saveAddressBook();
  }

  async setLocalPushSubscription(subscription: string) {
    const addressbook = await this.getAddressBook();
    addressbook.pushSub = subscription;
    await this.saveAddressBook();
  }

  /**
   * Returns the push subscription for a given address or the local subscription if no address is provided.
   */
  async getPushSubscription(address?: string): Promise<string | null> {
    if (!address) {
      const addressbook = await this.getAddressBook();
      const sub = addressbook.pushSub;
      return sub || null;
    }
    const contact = await this.ensureContactEntry(address);
    return contact.pushSubscription || null;
  }
}
