import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-filedrop',
  standalone: true,
  imports: [],
  templateUrl: './filedrop.component.html',
  styleUrl: './filedrop.component.scss'
})
export class FiledropComponent {
  @Output() fileDropped: EventEmitter<File[]> = new EventEmitter();
  @ViewChild('dropZone') dropZone!: ElementRef;
  onDragOver(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropZone.nativeElement.classList.add('highlight');
  }

  onDragLeave(event: Event): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropZone.nativeElement.classList.remove('highlight');
  }

  onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
    this.dropZone.nativeElement.classList.remove('highlight');

    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.fileDropped.emit(files);
    }
  }
}
