
<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
          <div class="auth-title-container">
            <h2 class="auth-title" [innerHTML]="title"></h2>
            <span  class="close" (click)="cancel()">X</span>
          </div>
        <div #authBody class="auth-body">
          <div [innerHTML]="message" class="textHolder notranslate"></div>
        </div>
        <div class="coin-buttons">
          <button *ngFor="let coin of availableCoins" (click)="selectCoin(coin)" class="coin-button">
            <img [src]="getCoinIconPath(coin)" [alt]="coin + ' icon'" class="coin-icon">
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
