export class Archive {
  id!: string;
  t: 'Records' | 'Cloud' | 'Local' | 'Msg' = 'Cloud'; //type
  arr?: { key: string, iv: string }; // Eyes only
  archiveListID?: string;
  s!: number; // size KB
  m: any; // client encrypted metadata
  l!: string; // locationID
  p?: number[]; // parts
  fs? = 0; // file size
  recordId?: number | undefined;
  sid = 0; // space id in seconds epoch to ensure unique id and ordering
  moved = false; // moved locations, used to inhibit multiple moves quickly
}

export class Location {
  id!: string;
  region!: string;
  class!: string; //redundancy?
  name?: string
}

export class RecordArchive {
  archiveID!: string;
  name!: string;
}
