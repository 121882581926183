<div #appConfigModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title">
          <p>{{title}}</p>
        </div>
        <div class="info">
          <div> {{'NAV_BAR.VERSION' | translate}}{{version}}</div>
          <div class="view-licenses-btn" (click)="openLicenses()" > {{'NAV_BAR.LICENSES' | translate}} </div>
        </div>
        <br>
        <div class="checkbox-container">
          <dir class="item">
            <dir class="checkboxes" appHelp="FEATURES.HIDE">
              <input type="checkbox" id="hide" name="hide" [(ngModel)]="config.hide" (change)="cbChange()">
              <label for="hide">{{ 'FEATURES.HIDE' | translate}}</label><br>
            </dir>
          </dir>
          <dir class="item" *ngIf="!config.guardSet">
            <dir class="checkboxes" appHelp="FEATURES.GUARD">
              <input type="checkbox" id="guard" name="guard" [(ngModel)]="config.hg" (change)="cbChange()">
              <label for="guard">{{ 'FEATURES.GUARD' | translate}}</label><br>
            </dir>
          </dir>

          <dir class="item">
            <dir class="checkboxes" appHelp="FEATURES.FILTER">
              <input type="checkbox" id="filter" name="LABEL" [(ngModel)]="config.filter" (change)="cbChange()">
              <label for="filter">{{ 'FEATURES.FILTER' | translate}}</label><br>
            </dir>
          </dir>

          <dir class="item">
            <dir class="checkboxes" appHelp="FEATURES.AFFILIATE">
              <input [disabled]="config.tier!=2 && config.tier!=3 || config.aff || !isAdmin" type="checkbox"
                id="affiliate" name="affiliate" [(ngModel)]="config.aff" (change)="cbChange()">
              <label for="affiliate">{{ 'FEATURES.AFFILIATE' | translate}}</label><br>
            </dir>
          </dir>

          <div class="item">
            <div class="storage" appHelp="FEATURES.STORAGE">
              <label for="storage">{{ 'FEATURES.STORAGE' | translate}}</label>
              <button id="storage" [disabled]="!isAdmin || config.tier==0 || config.tier==1 || config.tier==4" class="btn" (click)="changeDefaultLocation()">{{locationName}}</button>
            </div>
          </div>

        </div>
        <div class="button-container">
          <button [disabled]="ro" class="btn delete-btn" (click)="deleteSafe()">{{ 'NAV_BAR.DELETE_SAFE' | translate
            }}</button>
        </div>
        <div class="button-container">
          <button [ngClass]="!changed ? 'btn-primary': 'btn'" (click)="cancel()"> {{cancelBtnTxt}} </button>
          <button [disabled]="!changed" class="btn-primary" (click)="save()"> {{saveBtnTxt}} </button>
        </div>
      </div>
    </div>
  </div>
</div>
<app-modals></app-modals>
<auth-region-modal *ngIf='locations' [locations]="locations" [locationID]="locationID"
[multiRegion]="true"
  (reply)="newLocation($event)"></auth-region-modal>
