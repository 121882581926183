import { NgFor } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pin-warning-modal',
  standalone: true,
  imports: [TranslateModule, NgFor, FormsModule],
  templateUrl: './pin-warning.component.html',
  styleUrl: './pin-warning.component.scss'
})
export class PinWarningComponent {
  constructor(public translate: TranslateService) { }
  @Output() response = new EventEmitter<void>();
  warnings = [
    { message: this.translate.instant('CONFIG.PIN.NEW.WARN1'), checked: false },
    { message: this.translate.instant('CONFIG.PIN.NEW.WARN2'), checked: false },
    { message: this.translate.instant('CONFIG.PIN.NEW.WARN3'), checked: false }
  ];

  allChecked(): boolean {
    return this.warnings.every(warning => warning.checked);
  }

  next() {
    this.response.emit();
  }
}
