<div class="message-composer">
  <header class="composer-header">
    <h1>Eyes-Only</h1>
  </header>
  <form [formGroup]="messageForm" (ngSubmit)="sendMessage()">
    <!-- Recipient Address -->
    <div class="form-group">
      <label for="recipient">Recipient Address</label>
      <input
        type="text"
        id="recipient"
        formControlName="recipient"
        placeholder="Enter recipient address"
        (blur)="onRecipientBlur()"
        (keydown.enter)="onRecipientEnter($event)" />
      <!-- Show error if the recipient address is not valid -->
      <div class="field-error" *ngIf="recipientError">
        {{ recipientError }}
      </div>
    </div>

    <!-- Subject -->
    <div class="form-group">
      <label for="subject">Subject</label>
      <input
        type="text"
        id="subject"
        formControlName="subject"
        placeholder="Enter subject" />
    </div>

    <!-- Rich Text Message Body -->
    <div class="form-group">
      <label for="message">Message</label>
      <quill-editor
        formControlName="message"
        [modules]="quillModules"
        theme="snow"
        placeholder="Compose your message...">
      </quill-editor>
    </div>

    <!-- File Attachments -->
    <div class="form-group">
      <label for="attachments">Attachments</label>
      <input type="file" id="attachments" multiple (change)="onFileChange($event)" />

      <!-- List each attachment with a delete option -->
      <div class="attachment-preview" *ngIf="attachments.length">
        <ul>
          <li *ngFor="let file of attachments; let i = index">
            <span>{{ file.name }} ({{ file.size }} bytes)</span>
            <button type="button" (click)="removeAttachment(i)">Delete</button>
          </li>
        </ul>
      </div>
    </div>

    <!-- Error Message for Encryption / Other Errors -->
    <div class="error-message" *ngIf="errorMessage">
      <p>{{ errorMessage }}</p>
    </div>

    <!-- Send Button -->
    <div class="form-group">
      <button type="submit" [disabled]="messageForm.invalid || loading || recipientError">
        {{ loading ? 'Encrypting…' : 'Send Message' }}
      </button>
    </div>
  </form>
</div>
