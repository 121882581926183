import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { QuillModule } from 'ngx-quill';
import { RecordI } from 'src/app/interfaces/records';

@Component({
  selector: 'app-record-view',
  standalone: true,
  imports: [QuillModule],
  templateUrl: './record-view.component.html',
  styleUrl: './record-view.component.scss',
})
export class RecordViewComponent implements OnChanges {
  @Input() record!: RecordI;
  data: string | undefined = '{"ops":[]}';

  ngOnChanges(changes: SimpleChanges): void {
    // Check if the recordTitle or recordBody has changed
    if (changes['record']) {
      this.data = this.getTrimmedRecordBody();
    }
  }

  private getTrimmedRecordBody(): string {
    //quill editor data
    if (!this.record.recordBody) {
      return '{"ops":[]}';
    }
    try {
      const data = JSON.parse(this.record.recordBody);
      // Trim the ops array to the desired maximum length
      const trimmedOps = data.ops.slice(0, 8);
      return JSON.stringify({ ops: trimmedOps });
    } catch (e) {
      console.log('getTrimmedRecordBody error');
      return '{"ops":[]}';
    }
  }
}
