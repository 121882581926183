import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfig } from 'src/app/interfaces/appConfig';
import { ApiService } from 'src/app/services/api.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../../modal/modal.component';
import { version } from '../../../../environments/version'
import { HelpDirective } from '../../help/help-directive';
import { NgClass, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { Console } from 'src/app/lib/console';
import { Location } from 'src/app/interfaces/archive';
import { AuthRegionModalComponent } from '../../auth/region-modal/region-modal.component';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  standalone: true,
  imports: [ModalComponent, HelpDirective, NgClass, TranslateModule, FormsModule, RouterModule, NgIf, AuthRegionModalComponent]
})
export class FeaturesComponent implements OnInit  {
  config!: AppConfig;
  title = this.translate.instant('FEATURES.TITLE');
  message = this.translate.instant('FEATURES.MSG');
  cancelBtnTxt = this.translate.instant('FEATURES.CANCEL_BTN');
  @Output() done = new EventEmitter<string>();
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  version = version;
  changed = false;
  ro = false;
  isAdmin = false;

  locations: Location[] | null = null;
  locationID = '1';
  multiRegion = true;
  locationName= 'Canada';

  constructor(public apiSvc: ApiService, private translate: TranslateService) {
    const cfg = this.apiSvc.getAppConfig();
    Console.log('FeaturesComponent config', cfg);
    this.ro = cfg.ro || !cfg.isAdmin;
    this.isAdmin = cfg.isAdmin;
    this.config = cfg;
    this.locationID = this.config.locID;
  }

  async ngOnInit(): Promise<void> {
      this.locationName = await this.apiSvc.getLocationName(this.locationID);
  }

  cancel() {
    this.done.emit('Done');
  }

  saveBtnTxt = this.translate.instant('FEATURES.SAVE_BTN');
  async save() {
    this.modalController.displaySpinner(true);
    try {
      await this.apiSvc.setAppConfig(this.config);
    } finally {
      this.modalController.displaySpinner(false);
    }
    this.done.emit('Done');
  }

  async deleteSafe() {
    if (this.config.isAff) {
      await this.modalController.displayMessage('Affiliat Progam', '<p>You have an Affiliate account. Deletting this Safe will also cause your linked Affiliate account to be closed and you will stop receiving payments.</p><p> You will no longer be able to access your Affliate account. Are you really sure that you want to delete this Safe and your stripe Affiliate account and loose any money in that account??</p>');
    }
    const response = await this.modalController.displayQuestion(this.translate.instant('NAV_BAR.DELETE.TITLE'), this.translate.instant('NAV_BAR.DELETE.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('DELETE'));
    if (response === 'TWO') {
      const regret = this.translate.instant('NAV_BAR.DELETE.REGRET');
      const response = await this.modalController.displayInput(this.translate.instant('NAV_BAR.DELETE.TITLE'), this.translate.instant('NAV_BAR.DELETE.REGRET_MSG'), false);
      if (response === regret) {
        this.modalController.displaySpinner(true);
        const promoCredit = await this.apiSvc.deleteSafe();
        if (promoCredit) {
          await this.modalController.displayMessage(this.translate.instant('NAV_BAR.DELETE.PROMO.TITLE'), this.translate.instant('NAV_BAR.DELETE.PROMO.MSG', { code: promoCredit.code, credits: promoCredit.credits })), this.translate.instant('OK');
        }
        location.reload();
      }
    }
  }

  cbChange() {
    this.changed = true;
  }

  async changeLocation() {
    this.modalController.displaySpinner(true);
    try {
      this.locations = await this.apiSvc.getLocations();
    } finally {
      this.modalController.displaySpinner(false);
    }
  }

  async newLocation(location: Location) {
    Console.log('newLocation', location);
    if (location && location.id != this.locationID) {
      this.config.locID = location.id;
      await this.save();
    }
    this.locations = null;
  }

}
