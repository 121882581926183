import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { RecordsComponent } from './records/records.component';
import { InputComponent } from './input/input.component';
import { ModalComponent } from '../modal/modal.component';
import { SpacetabsComponent } from '../spaces/spacetabs/spacetabs.component';
import { db } from 'src/app/db/json';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-main-records',
  templateUrl: './main-records.component.html',
  styleUrls: ['./main-records.component.scss'],
  standalone: true,
  imports: [InputComponent, RecordsComponent, ModalComponent, SpacetabsComponent, NgIf]
})
export class MainRecordsComponent implements AfterViewInit {
  @ViewChild('component') modalComponent!: ModalComponent;

  constructor(private cdr: ChangeDetectorRef, public db:db) { }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
