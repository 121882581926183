<app-modals></app-modals>

<div *ngIf="legacyAccess"  class="value-entry">
  <label for="failures">{{'GUARD.LEGACY.DELAY' | translate:{months: guardAccess.legacyDelay} }}
  </label>
  <input id="failures" [(ngModel)]="guardAccess.legacyDelay" (ngModelChange)="onChanged()" type="range" min="1" [max]="legacyDelayMax">
  <div class="button-container">
    <button class="btn" (click)="delete()">{{'GUARD.LEGACY.DELETE.BTN'| translate }}</button>
  </div>
</div>

