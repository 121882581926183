
import * as bitcoin from 'bitcoinjs-lib';
import { AddressType, CoinType, networks, NetworkType } from './paperWallet';
import { Console } from './console';
import bs58 from 'bs58';
// Token Decimals (ERC-20 tokens)
const tokenDecimals: { [key: string]: number } = {
  Tether: 6,
  'USD Coin': 6,
  Dai: 18,
  Chainlink: 18,
};
export class Wallet {

  static fromJSON(json: any): Wallet {
    if (!json.savedAddresses) {
      json.savedAddresses = [];
    }
    const wallet = new Wallet(json.address, json.type, json.network, json.coinType, json.mnemonic, json.savedAddresses);
    wallet.privateKey = json.privateKey;
    return wallet;
  }

  static toJSON(wallet: Wallet): any {
    return {
      address: wallet.address,
      privateKey: wallet.privateKey,
      type: wallet.type,
      network: wallet.network,
      coinType: wallet.coinType,
      mnemonic: wallet.mnemonic,
      savedAddresses: wallet.savedAddresses
    };
  }

   privateKey = '';
  constructor(
    public address: string,
    public type: AddressType,
    public network: NetworkType,
    public coinType: CoinType,
    public mnemonic: string,
    public savedAddresses: string[] = []
  ) {
    if (type === AddressType.BitcoinORIG) {
      this.type = AddressType.Bitcoin; // backwards compatibility
    }
    if (this.coinType === CoinType.ETH) {
      this.getEtherLib();
    }
  }

  //Encrypts the private key client side and then serverside to force the requierment of webauthn for private key access
  setPrivateKey(privateKey: string) {
    Console.log('Setting private key:', privateKey);
    this.privateKey = privateKey;
  }

  public getCoinDecimals(): number {
    switch (this.coinType) {
      case CoinType.BTC:
        return 8;
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
        return 18;
      case CoinType.LTC:
        return 8;
      case CoinType.DOGE:
        return 8;
      // case CoinType.ADA:
      //   return 6;
      case CoinType.SOL:
        return 9;
      // case CoinType.XRP:
      //   return 6;
      default:
        return 0;
    }
  }

  public getsmallUnitName() {

    switch (this.coinType) {
      case CoinType.BTC:
        return 'satoshi';
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
        return 'wei';
      case CoinType.LTC:
        return 'litoshi';
      case CoinType.DOGE:
        return 'shibes';
      // case CoinType.ADA:
      //   return 'lovelace';
      case CoinType.SOL:
        return 'lamport';
      // case CoinType.XRP:
      //   return 'drop';
      default:
        return 'unit';
    }
  }

  getIcon() {
    return CoinType.getSvgPath(this.coinType);
  }

  getSmallIcon(): string {
    return CoinType.getSmallSvgPath(this.coinType);
  }

  public validateAddress(addr: string = this.address): boolean {
    Console.log('Validating address:', this);
    switch (this.coinType) {
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.BNB:
      case CoinType.LINK:
        return this.validateEthereumAddress(addr);
      case CoinType.LTC:
      case CoinType.DOGE:
      case CoinType.BTC:
        return this.validateBitcoinAddress(addr);
      // case CoinType.ADA:
      //   return this.validateCardanoAddress(addr);
      case CoinType.SOL:
        return this.validateSolanaAddress(addr);
      // case CoinType.XRP:
      //   return this.validateRippleAddress(addr);
      default:
        return false;
    }
  }

  private validateBitcoinAddress(addr: string): boolean {
    const network = this.network == NetworkType.Testnet ? networks[this.coinType].testnet : networks[this.coinType].mainnet;
    try {
      bitcoin.address.toOutputScript(addr, network);
    } catch (e) {
      Console.error(e);
      return false;
    }
    return true;
  }

  private validateEthereumAddress(addr: string): boolean {
    const ethers = (window as any).ethers;
    return ethers.isAddress(addr);
  }

  private validateSolanaAddress(addr: string): boolean {
    try {
      // Decode the address using Base58
      const decoded = bs58.decode(addr);
      // Check if the decoded address is 32 bytes if it is this Might be a valid address.
      return decoded.length === 32;
    } catch (error) {
      // If decoding fails, it's not a valid address
      return false;
    }
  }

  public async getEtherLib(): Promise<any> {
    if (!(window as any).ethers) {
      (window as any).ethers = await import('ethers');
    }
    return (window as any).ethers;
  }

  // Function to convert Wei to Ether (Synchronous)
  private weiToEth(wei: string): string {
    const weiBigInt = BigInt(wei);
    const ethBigInt = weiBigInt / BigInt(1e18); // Divide by 10^18 to convert to Ether
    const remainder = weiBigInt % BigInt(1e18);
    // Combine the integer part and the remainder to get the human-readable result
    return `${ethBigInt}.${remainder.toString().padStart(18, '0')}`;
  }

  // Function to convert token amount (based on decimals) to human-readable format (Synchronous)
  private weiToToken(wei: string, decimals: number): string {
    const weiBigInt = BigInt(wei);
    const divisor = BigInt(10) ** BigInt(decimals);
    const tokenBigInt = weiBigInt / divisor; // Divide by 10^decimals
    const remainder = weiBigInt % divisor;
    // Combine the integer part and the remainder to get the human-readable result
    return `${tokenBigInt}.${remainder.toString().padStart(decimals, '0')}`;
  }

  // Function to convert Wei to Ether or ERC-20 tokens (Synchronous)
  private convertWeiToCurrency(wei: string, coinType: CoinType): string {
    if (coinType === CoinType.ETH) {
      return this.weiToEth(wei); // Convert Wei to Eth
    } else {
      const decimals = tokenDecimals[coinType]; // Assume tokenDecimals is a map that has the decimals for each CoinType
      return this.weiToToken(wei, decimals); // Convert Wei to token value
    }
  }

  /**
   *
   * @param amount smallest unit or formatted amount of the coin
   * @returns amount in the largest unit of the coin keeping decimals
   */
  public convertToLargestAmount(amount: string, coinType?: CoinType): string {

    // Validate that the amount is either an integer string or a decimal number in string format
    if (!/^\d+$/.test(amount) && !/^\d+\.\d+$/.test(amount)) {
      throw new Error(`Invalid amount provided: ${amount}. Amount must be a non-negative integer or decimal represented as a string.`);
    }

    if (!coinType) {
      coinType = this.coinType;
    }


    // Convert decimal amount to smallest unit equivalent if necessary
    const decimals = this.getCoinDecimals();

    if (amount.includes('.')) {
      // Handle case when amount has a decimal point, convert it to smallest unit format
      const [integerPart, fractionalPart] = amount.split('.');

      // Ensure the fractional part has enough precision
      if (fractionalPart.length > decimals) {
        throw new Error(`Invalid fractional part: ${fractionalPart}. It exceeds the maximum allowed decimals (${decimals}) for this coin type.`);
      }

      // Pad the fractional part to match the decimals required
      const paddedFractionalPart = fractionalPart.padEnd(decimals, '0');

      // Combine integer and fractional parts
      amount = integerPart + paddedFractionalPart;

      // Remove leading zeros, if any, for consistency
      amount = amount.replace(/^0+/, '') || '0';
    }

    // Validate the final amount is an integer string after any conversion
    if (!/^\d+$/.test(amount)) {
      throw new Error(`Invalid conversion resulted in non-integer amount: ${amount}.`);
    }

    if (amount === '0') {
      return '0';
    }

    if (this.type === AddressType.Ethereum) {
      return this.convertWeiToCurrency(amount, coinType);
    }

    // Handle the case where the amount is smaller than the smallest unit
    if (amount.length <= decimals) {
      return '0.' + amount.padStart(decimals, '0');
    }

    // Separate the integer and fractional parts correctly
    let integerPart = amount.slice(0, amount.length - decimals);
    let fractionalPart = amount.slice(amount.length - decimals);

    // Drop leading zeros from the integer part
    integerPart = integerPart.replace(/^0+/, '');

    // If integer part becomes empty, set it to '0'
    if (integerPart === '') {
      integerPart = '0';
    }

    // Trim trailing zeros from the fractional part to avoid unnecessary decimals
    fractionalPart = fractionalPart.replace(/0+$/, '');

    // If the fractional part ends up being empty, do not append the decimal
    if (fractionalPart === '') {
      return integerPart;
    }

    // Return the correctly formatted value
    return `${integerPart}.${fractionalPart}`;
  }

  /**
  * Converts an amount from the largest unit of the coin to the smallest unit.
  * @param amount amount in the largest unit of the coin
  * @param coinType optional parameter for specifying the coin type
  * @returns amount in the smallest unit of the coin as a string
  */
  public convertToSmallestAmount(amount: string, coinType?: CoinType): bigint {
    if (amount === '0' || amount === '0.0') {
      return 0n;
    }
    if (!coinType) {
      coinType = this.coinType;
    }

    if (this.type === AddressType.Ethereum) {
      return BigInt(this.weiToEth(amount));
    }

    const decimals = this.getCoinDecimals();

    // Split the amount into integer and fractional parts
    const [integerPart, fractionalPart = ''] = amount.split('.');

    // Convert integer part to string representation
    const integerInSmallest = integerPart !== '' ? integerPart : '0';

    // Pad the fractional part with trailing zeros to reach the required decimals
    const paddedFractionalPart = fractionalPart.padEnd(decimals, '0');

    // Concatenate the integer and fractional parts
    const smallestAmount = `${integerInSmallest}${paddedFractionalPart}`;

    // Remove leading zeros, unless the result is '0'
    return BigInt(smallestAmount.replace(/^0+(?!$)/, ''));
  }


}
