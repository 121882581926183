<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title-container">
          <h2 class="auth-title" [innerHTML]="displayData.title"></h2>
          <span *ngIf="displayData.btnCancelLable" class="close" (click)="buttonClick('ZERO')">
            <img src="assets/images/svg/x-btn.svg" alt="close">
          </span>
        </div>
        <div #authBody class="auth-body">
          <div [innerHTML]="displayData.message"></div>
        </div>
        <div class="button-container" [ngClass]="{'button-right': !(displayData.btnOneLable && displayData.btnTwoLable)}">
          <button *ngIf="displayData.btnCancelLable" class="btn" (click)="buttonClick('ZERO')">
            {{displayData.btnCancelLable}}</button>
          <button *ngIf="displayData.btnOneLable" class="btn" (click)="buttonClick('ONE')"> {{displayData.btnOneLable}} </button>
          <button *ngIf="displayData.btnTwoLable" class="btn-primary" (click)="buttonClick('TWO')"> {{displayData.btnTwoLable}} </button>
        </div>
      </div>
    </div>
  </div>
</div>
