import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from '../../modal/modal.component';
import { KeyComponent } from "./key/key.component";
import { NgFor, NgIf } from '@angular/common';
import { RegisterKeyComponent } from "../register-key/register-key.component";
import { AuthzService } from 'src/app/services/authz.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Console } from 'src/app/lib/console';
import { Utilities } from 'src/app/lib/utilities';

@Component({
  selector: 'app-manage-keys',
  templateUrl: './manage-keys.component.html',
  styleUrls: ['./manage-keys.component.scss'],
  standalone: true,
  imports: [ModalComponent, KeyComponent, NgFor, NgIf, RegisterKeyComponent, TranslateModule]
})
export class ManageKeysComponent implements OnInit {
  @Input() accessID: string | null = null;
  @Output() done = new EventEmitter<boolean>();
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  showRegisterKey = false;
  keys: SafeAccess[] = [];
  currentAccessID: string;
  constructor(private apiSvc: ApiService, private authzSvc: AuthzService, private translate: TranslateService) {
    this.currentAccessID = authzSvc.accessID;
  }

  async ngOnInit(): Promise<void> {
    const keys = await this.apiSvc.getAccesses('device');
    for (const key of keys) {
      if (key.accessID == this.currentAccessID) {
        this.keys.unshift(key); //current key is always first
        if (!key.name || this.isValidUuid(key.name)) { //Do not substitute key name if owner changed it
          key.name = this.translate.instant(this.translate.instant('KEY.CURRENT'));
        }
      } else {
        this.keys.push(key);
      }
    }
  }

  private isValidUuid(uuid: string): boolean {
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[1-5][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(uuid);
  }

  //actions from key components
  action(event: { key: SafeAccess, action: string }) {
    Console.log(event);
    switch (event.action) {
      case 'delete':
        this.deleteKey(event.key);
        break;
      case 'toggleEnable':
        this.toggleEnable(event.key);
        break;
      case 'rename':
        this.rename(event.key);
        break;
      case 'setAdmin':
        this.setAdmin(event.key);
        break;
      case 'setReadWrite':
        this.setReadWrite(event.key);
        break;
      case 'setReadOnly':
        this.setReadOnly(event.key);
        break;
    }
  }

  async toggleEnable(key: SafeAccess) {
    if (this.check(key)) {
      return;
    }
    key.enabled = !key.enabled;
    await this.apiSvc.updateAccess(key);
  }

  async setAdmin(key: SafeAccess) {
    if (this.check(key)) {
      return;
    }
    key.mode = 'admin';
    await this.apiSvc.updateAccess(key);
  }

  async setReadWrite(key: SafeAccess) {
    if (this.check(key)) {
      return;
    }
    key.mode = 'rw';
    await this.apiSvc.updateAccess(key);
  }

  async setReadOnly(key: SafeAccess) {
    if (this.check(key)) {
      return;
    }
    key.mode = 'ro';
    await this.apiSvc.updateAccess(key);
  }

  async deleteKey(key: SafeAccess) {
    if (this.check(key)) {
      return;
    }
    const answer = await this.modalController.displayQuestion(this.translate.instant('KEY.DELETE.TITLE'), this.translate.instant('KEY.DELETE.MSG'), this.translate.instant('CANCEL'), this.translate.instant('YES'), this.translate.instant('NO'));
    if (answer == "ONE") {
      await this.apiSvc.deleteAccess(key.accessID);
      this.keys.splice(this.keys.indexOf(key), 1);
    }
  }

  async rename(key: SafeAccess) {
    const name = await this.modalController.displayInput(this.translate.instant('KEY.RENAME.TITLE'), this.translate.instant('KEY.RENAME.MSG'), false);
    if (name) {
      key.name = name;
      await this.apiSvc.updateAccess(key);
    }
  }

  async accessCreated(access: SafeAccess) {
    this.showRegisterKey = false;
    if (access) {
      // the keys will be changed when the key is registered
      access.wrappedClientKey = '';
      access.wrappedServerKey = '';
      this.keys.push(access);
      if (this.keys.length > 2) {
        await this.modalController.displayMessage(this.translate.instant('KEY.WARN_FEE.TITLE'), this.translate.instant('KEY.WARN_FEE.MSG',{fee: Utilities.CREDIT_PER_KEY}));
      }
    }
  }

  private check(key: SafeAccess): boolean {
    if (key.accessID == this.authzSvc.accessID) {
      this.modalController.displayMessage(this.translate.instant('KEY.DELETE.ERROR.TITLE'), this.translate.instant('KEY.DELETE.ERROR.MSG'));
      return true;
    }
    return false;
  }
  close() {
    this.done.emit(true);
  }
}

