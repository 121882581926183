<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
          <h2 class="auth-title" [innerHTML]="displayData.title"></h2>
        <div #authBody class="auth-body">
          <div [innerHTML]="displayData.message" class="textHolder notranslate"></div>
        </div>
        <div class="button-container">
          <button class="btn-primary" (click)="next()"> {{ 'NEXT' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
