import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AppConfig } from 'src/app/interfaces/appConfig';
import { ApiService } from 'src/app/services/api.service';
import {  TranslateModule, TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../../modal/modal.component';
import { version } from '../../../../environments/version'
import { HelpDirective } from '../../help/help-directive';
import { NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  standalone: true,
  imports: [ ModalComponent, HelpDirective, NgClass, TranslateModule, FormsModule, RouterModule]
})
export class FeaturesComponent implements OnInit {
  config!: AppConfig;
  title = this.translate.instant('FEATURES.TITLE');
  message = this.translate.instant('FEATURES.MSG');
  cancelBtnTxt = this.translate.instant('FEATURES.CANCEL_BTN');
  @Output() done = new EventEmitter<string>();
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  version = version;
  changed = false;
  ro = false;
  isAdmin = false;

  constructor(public apiSvc: ApiService, private translate: TranslateService) {
    const cfg = this.apiSvc.getAppConfig();
    this.ro = cfg.ro || !cfg.isAdmin;
    this.isAdmin = cfg.isAdmin;
  }

  ngOnInit(): void {
    this.config = this.apiSvc.getAppConfig();
  }

  cancel() {
    this.done.emit('Done');
  }

  saveBtnTxt = this.translate.instant('FEATURES.SAVE_BTN');
  async save() {
    this.apiSvc.setAppConfig(this.config);
    this.done.emit('Done');
  }

  async deleteSafe() {
    if (this.config.isAff) {
      await this.modalController.displayMessage('Affiliat Progam', '<p>You have an Affiliate account. Deletting this Safe will also cause your linked Affiliate account to be closed and you will stop receiving payments.</p><p> You will no longer be able to access your Affliate account. Are you really sure that you want to delete this Safe and your stripe Affiliate account and loose any money in that account??</p>');
    }
    const response = await this.modalController.displayQuestion(this.translate.instant('NAV_BAR.DELETE.TITLE'), this.translate.instant('NAV_BAR.DELETE.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('DELETE'));
    if (response === 'TWO') {
      const regret = this.translate.instant('NAV_BAR.DELETE.REGRET');
      const response = await this.modalController.displayInput(this.translate.instant('NAV_BAR.DELETE.TITLE'), this.translate.instant('NAV_BAR.DELETE.REGRET_MSG'), false);
      if (response === regret) {
        this.modalController.displaySpinner(true);
        const promoCredit = await this.apiSvc.deleteSafe();
        if (promoCredit) {
          await this.modalController.displayMessage(this.translate.instant('NAV_BAR.DELETE.PROMO.TITLE'), this.translate.instant('NAV_BAR.DELETE.PROMO.MSG', { code: promoCredit.code, credits: promoCredit.credits })), this.translate.instant('OK');
        }
        location.reload();
      }
    }
  }

  cbChange() {
    this.changed = true;
  }

  viewLicenses() {
    // TODO: Implement license viewing logic
    console.log('Viewing licenses');
    // You might want to open a new modal or navigate to a licenses page
  }
}
