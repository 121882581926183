<div #main [class.main-container]="!isEditing" [style.box-shadow]="recordMain.hidden ? 'unset' : ''">
  <!--? placeholder -->
  <div #recordPlaceholder>
    <div *ngIf="!authSvc.appConfig.ro" class="button-container min-width">
      <button *ngIf="numberOfWallets<=numnerOfCoinTypes" class="btn-primary" (click)="recordPhClick()">{{this.currentLabel == 'Wallet'
        && walletSupport ?
        ('RECORDS.INPUT.BITCOIN'|
        translate) : ('RECORDS.INPUT.CREATE'| translate) }}</button>
      <button *ngIf="this.currentLabel!= 'Wallet' ||  ! walletSupport" class="btn-primary"
        (click)="recordPhClick();isCbox.next(true)">{{'RECORDS.INPUT.NEWL'| translate}}</button>
    </div>
  </div>

  <!--? new record -->
  <div #recordMain class="record-main" hidden>
    <div class="input-modal-container" [ngClass]="!isEditing? 'modal-container':''">
      <div [ngClass]="!isEditing? 'modal':''">
        <div #recordContainer class="record-container input-container">

          <!-- header -->
          <div class="title-container">
            <div #recordTitle (input)="updateInputLength({title:recordTitle.innerHTML.length})"
              (paste)="pasteEvent($event)" (click)="showTitlePH ? recordTitle.innerHTML='':''"
              class="record-title notranslate" [ngClass]="showTitlePH? 'ph':''"
              [attr.contenteditable]="!authSvc.appConfig.ro && !recordToEdit.ro" autocorrect="off" autocapitalize="off"
              spellcheck="false" autocomplete="off">
              "" </div>
          </div>

          <!-- body record OR checkboxes -->
          <ng-container [ngTemplateOutlet]="isCbox.value ? checkboxesTemplate : recordTemplate">
          </ng-container>

          <!-- footer -->
          <div class="record-footer">
            <!--? labels -->
            <div class="labels-container">
              <ng-container *ngFor="let label of labels">
                <div (click)="labelClicked(label)" class="label-container" *ngIf="label.added">
                  <div class="label"><span>{{ label.name === 'Wallet' ? ('VIEW' | translate) : label.name }}</span>
                    <img *ngIf="label.name === 'Wallet'" [src]="getCoinIcon(recordToEdit.wallet)" />
                    <div *ngIf="!authSvc.appConfig.ro && (label.name!='Wallet' || !walletSupport)" class="cancel-icon"
                      (click)="label.added = !label.added; labelsChanged=true; setDirty()"></div>
                  </div>
                </div>
              </ng-container>
              <div *ngIf="recordToEdit.ro" class="locked"><img src="./assets/images/svg/lock-locked.svg"></div>
            </div>

            <!--? attachments -->
            <div class="archives-container">
              <ng-container>
                <div class="archive-container" *ngIf="recordToEdit.archive">
                  <div class="archive">
                    <span (click)="openFile(recordToEdit.archive)">{{recordToEdit.archive.name}}</span>
                    <div *ngIf="!authSvc.appConfig.ro" class="cancel-icon" (click)="removeFile()"></div>
                  </div>
                </div>
              </ng-container>
            </div>

            <div *ngIf="!authSvc.appConfig.ro" #recordPin data-pinned="false"
              [class.pinned]="recordPin.dataset['pinned'] === 'true'" class="pin-icon H pop"
              (click)="setDirty(); recordPin.dataset['pinned'] === 'false' ? recordPin.dataset['pinned']='true': recordPin.dataset['pinned']='false'"
              [attr.data-pop]="recordPin.dataset['pinned'] === 'false' ? 'Pin record':'Unpin record' ">
            </div>

            <!--? footer bar-->
            <div #iconsContainer class="icons-container"
              [style.flex-direction]="authSvc.appConfig.ro? 'row-reverse': 'row'">
              <div *ngIf="!authSvc.appConfig.ro" class="icons">
                <div *ngIf="!recordToEdit.ro" #colorMenuTtBtn class="color H"
                  (click)="openTooltip(colorMenuTtBtn,colorMenuTt)">
                </div>

                <div #moreMenuTtBtn class="more H" (click)="openTooltip(moreMenuTtBtn,moreMenuTt)">
                </div>
              </div>
              <div class="button-container save-close-button-container">
                <button
                  [ngClass]=" (showTitlePH && !editorContent && !checkBoxes.length || !pageDirty) ? 'btn-primary':'btn'"
                  (click)="closeRecord()"> {{pageDirty? ('CANCEL' | translate) : ('CLOSE' | translate)
                  }}</button>
                <button *ngIf="!authSvc.appConfig.ro && ! recordToEdit.ro"
                  [disabled]="showTitlePH && !editorContent && !checkBoxes.length || !pageDirty" class="btn-primary"
                  (click)="saveRecord(true)">
                  {{'SAVE'| translate }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--* templates -->
<!--* record template -->
<ng-template #recordTemplate>

  <div #recordBody>
    <quill-view *ngIf="(!!recordToEdit.ro || authSvc.appConfig.ro); else readWrite"
      (onEditorCreated)="editorCreated($event)" [content]="editorContent" format="json" theme="snow"></quill-view>
    <ng-template #readWrite>
      <quill-editor class="rtcEditor" (onEditorCreated)="editorCreated($event)" format="json" [sanitize]="true"
        [modules]="editorModules" [placeholder]="'RECORDS.INPUT.CREATE'| translate" [(ngModel)]="editorContent"
        (onContentChanged)="textChanged($event)" autocorrect="off" autocapitalize="off" spellcheck="false"
        autocomplete="off"></quill-editor>
    </ng-template>
  </div>
</ng-template>

<!--* cboxes template -->
<ng-template #checkboxesTemplate>
  <!--? isDone: false -->
  <div class="cbox-list">
    <ng-container [ngTemplateOutlet]="cboxTemplate" [ngTemplateOutletContext]="{ isDone: false }">
    </ng-container>
    <div *ngIf="!authSvc.appConfig.ro && !recordToEdit.ro" class="cbox-ph-container">
      <div class="cbox-plus-icon"></div>
      <div class="cbox-record-container">
        <div class="cbox-record ph">{{'RECORDS.INPUT.LIST_ITEM'| translate}}</div>
        <div #cboxPh (keydown)="cboxPhKeyDown($event)" class="cbox-record notranslate" contenteditable="true"
          spellcheck="false" autocorrect="off" autocapitalize="off" autocomplete="off">
        </div>
      </div>
    </div>
    <!--? isDone: true -->
    <ng-container *ngIf="(checkBoxes | cboxDone:true).length">
      <div class="hr"></div>
      <div class="cbox-done-container" (click)="isCboxCompletedListCollapsed = !isCboxCompletedListCollapsed;  ">
        <div [class.rotate]="!isCboxCompletedListCollapsed" class="cbox-arrow"></div>
        <div>
          <span>({{(checkBoxes | cboxDone:true).length}}) {{'RECORDS.INPUT.COMPLETED_ITEM'| translate}}</span>
        </div>
      </div>
    </ng-container>
    <ng-container class="cbox-completed" *ngIf="!isCboxCompletedListCollapsed" [ngTemplateOutlet]="cboxTemplate"
      [ngTemplateOutletContext]="{ isDone: true }">
    </ng-container>

    <!--* cbox template -->
    <ng-template let-isDone="isDone" #cboxTemplate>
      <div cdkDropList (cdkDropListDropped)="dropCBox($event)" class="droplist "
        [cdkDropListDisabled]="authSvc.appConfig.ro && ! recordToEdit.ro">
        <ng-container>
          <div *ngFor="let cb of checkBoxes | cboxDone:isDone;let last = last" cdkDrag cdkDragLockAxis="y"
            cdkDragBoundary=".droplist">
            <div class="cbox-container">
              <div *ngIf="!authSvc.appConfig.ro && ! recordToEdit.ro" class="cbox-move-icon" cdkDragHandle></div>
              <div *ngIf="!authSvc.appConfig.ro && ! recordToEdit.ro" [class.done]="cb.done" class="cbox-icon"
                (click)="cboxTools(cb.id).check()"></div>
              <div class="cbox-record-container">
                <div #cboxInput [attr.data-cbox-last]="last" [class.cbox-done]="cb.done"
                  (blur)="cboxTools(cb.id).update(cboxInput)" (keydown)="cBoxKeyDown($event,cb.id)" class="cbox-record"
                  [attr.contenteditable]="!authSvc.appConfig.ro && ! recordToEdit.ro" [innerHTML]="cb.data"
                  autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off">
                </div>
              </div>
              <div *ngIf="!authSvc.appConfig.ro && ! recordToEdit.ro" class="cbox-cancel-icon H"
                (click)="cboxTools(cb.id!).remove()"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</ng-template>

<!--? --------tooltips-------- -->

<!--? more MENU -->
<div #moreMenuTt data-tooltip="true" data-is-tooltip-open="false" class="moreMenu">
  <div #labelMenuBtn [hidden]="moreMenuEls.delete.disabled || recordToEdit.ro"
    (click)="this.Shared.closeTooltip(moreMenuTt);openTooltip(moreMenuTtBtn!.nativeElement,labelMenuTt)">
    {{ 'RECORDS.LABEL' | translate }}
  </div>
  <div *ngIf="recordToEdit.id && walletSupport" (click)="moreMenu(moreMenuTt).wallet()">
    Wallet
  </div>

  <div [hidden]="!isEditing" (click)="moreMenu(moreMenuTt).toggleReadOnly()">
    {{ recordToEdit.ro ? ('RECORDS.UNLOCK' | translate) : ('RECORDS.LOCK' | translate) }}
  </div>
  <div *ngIf="!recordToEdit.archive && ! recordToEdit.ro" (click)="attachFile()">
    {{ 'RECORDS.ATTACH_FILE' | translate }}
  </div>
  <div [hidden]="moreMenuEls.delete.disabled || recordToEdit.ro || !isEditing" (click)="moreMenu(moreMenuTt).trash()">
    {{'RECORDS.INPUT.DELETE_RECORD'| translate}}
  </div>
</div>

<!--? color MENU -->
<div #colorMenuTt data-tooltip="true" data-is-tooltip-open="false" class="colorMenu">
  <div class="first-row">
    <ng-container *ngFor="let color of bgColorsConst | keyvalue">
      <div [attr.data-bg-color]="color.key" [style.background-color]="color.value.bg"
        (click)="colorMenu.bgColor(color.value)" [class.nocolor]="color.value.bg === ''">
      </div>
    </ng-container>
  </div>
  <div class="second-row">
    <ng-container *ngFor="let image of bgImagesConst | keyvalue">
      <div [attr.data-bg-image]="image.key" [style.background-image]="image.value.img | ph"
        (click)="colorMenu.bgImage(image.value)" [class.noimage]="image.value.img === ''">
      </div>
    </ng-container>
  </div>
</div>

<!--? label MENU -->
<div #labelMenuTt data-tooltip="true" data-is-tooltip-open="false" class="labelMenu">
  <div class="title">{{'RECORDS.LABEL' | translate}}</div>
  <div class="header">
    <div class="input-container notranslate">
      <input #labelInput autofocus (keydown.enter)="addLabel(labelInput);this.Shared.closeTooltip(labelMenuTt)" type="text"
        placeholder="Create new label" maxlength="50" (input)="''" autocorrect="off" autocapitalize="off"
        spellcheck="false" autocomplete="off">
      <div [style.display]="labelInput.value.length ? 'block' : 'none'" class="check-icon H"
        (click)="addLabel(labelInput);this.Shared.closeTooltip(labelMenuTt)"></div>
    </div>
    <div #labelError class="error" hidden>{{''}}</div>
  </div>
  <div class="label-list-container">
    <div class="label-list" [class.disabled]="label.name === 'Wallet'" *ngFor="let label of labels"
      (click)="label.name !== 'Wallet' && label.added = !label.added; setDirty()">
      <div [class.done]='label.added' class="check-icon"></div>
      <div class="label-name">{{label.name}}</div>
    </div>
  </div>
</div>
