<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div id='print-container'>
          <h2 class="auth-title" [innerHTML]="title"></h2>
          <div class="token-list">
            <div class="token-item" *ngFor="let token of tokens">
              <div class="icon" [innerHTML]="token.svgIcon ? (token.svgIcon | safeHtml) : '' "></div>
              <div class="details">
                <h3>{{ token.name }}</h3>
                <p>{{ token.symbol }}</p>
                <p>{{ token.balance }}</p>
              </div>
            </div>
          </div>
        <div class="button-container">
          <button class="btn-primary" (click)="next()"> {{ 'DONE' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
