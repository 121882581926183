<div class="container">
  <h1>{{'UPGRADE.UPGRADE_TITLE' | translate}}</h1>

  <div *ngIf="currentTier">
    <p>{{'UPGRADE.CURRENT_TIER' | translate }} <strong>{{ currentTier }}</strong></p>
  </div>

  <div *ngIf="currentTier!='FreeSafe'" class="credit-info">
    <p>{{'UPGRADE.CURRENT_CREDITS'| translate}} {{ currentCredits | number : '1.0-2' }}</p>
    <p>{{ 'UPGRADE.EXPIRY'| translate }} {{ expiryDate * 1000 | date : 'mediumDate' : '' : translate.currentLang }}</p>
  </div>

  <h2>{{'UPGRADE.SELECT' | translate }}</h2>
  <p>{{'UPGRADE.ALL_MSG'|translate}}</p>
  <div class="upgrade-options">

    <!-- LegacyGuard Upgrade -->
    <div *ngIf="currentTier == 'FreeSafe'" class="upgrade-card">
      <div class="title">
        <h3 appHelp="UPGRADE.HELP.LEGACY_GUARD">LegacyGuard</h3>
      </div>
      <p>{{'UPGRADE.LEGACY_GUARD'| translate }}</p>
      <p>{{legacyPrice | currency:'USD' }} {{'UPGRADE.PER_MONTH' | translate}}</p>
      <div class="button-container">
        <button class='btn' (click)="upgrade(4)">{{'UPGRADE.TITLE'| translate}}</button>
      </div>
    </div>

    <!-- PrimeSafe Upgrade -->
    <div *ngIf="currentTier !== 'PrimeSafe' && currentTier !== 'LockSafe' && currentTier !== 'LifeSafe'"
      class="upgrade-card">
      <div class="title">
        <h3 appHelp="UPGRADE.HELP.PRIME_SAFE">PrimeSafe</h3>
      </div>
      <p>{{'UPGRADE.PRIME_SAFE'| translate }}</p>
      <p>{{seedPrice | currency:'USD' }} {{'UPGRADE.PER_MONTH' | translate}}</p>
      <div class="button-container">
        <button class='btn' (click)="upgrade(1)">{{'UPGRADE.TITLE'| translate}}</button>
      </div>
    </div>

    <!-- LockSafe Upgrade -->
    <div *ngIf="currentTier !== 'LockSafe' && currentTier !== 'LifeSafe'" class="upgrade-card">
      <div class="title">
        <h3 appHelp="UPGRADE.HELP.LOCK_SAFE">LockSafe</h3>
      </div>
      <p>{{'UPGRADE.LOCK_SAFE'| translate}}</p>
      <p>{{lockPrice | currency:'USD' }} {{'UPGRADE.PER_MONTH' | translate}}</p>
      <div class="button-container">
        <button class='btn' (click)="upgrade(2)">{{'UPGRADE.TITLE'| translate}}</button>
      </div>
    </div>

    <!-- LifeSafe Upgrade -->
    <div *ngIf="currentTier !== 'LifeSafe'" class="upgrade-card">
      <div class="title">
        <h3 appHelp="UPGRADE.HELP.LIFE_SAFE">LifeSafe</h3>
      </div>
      <p>{{'UPGRADE.LIFE_SAFE'|translate}}</p>
      <p>{{lifePrice | currency:'USD' }} {{'UPGRADE.PER_MONTH' | translate}}</p>
      <div class="button-container" >
        <button class='btn' (click)="upgrade(3)">{{'UPGRADE.TITLE'| translate}}</button>
      </div>
    </div>
  </div>
  <p class="fine-print" [translate]="'UPGRADE.COST_PER_GIG'" [translateParams]="{storageFee: storageFee }"> </p>
  <p class="fine-print" [translate]="'UPGRADE.MONTHDAYS'"> </p>
  <div class="button-container">
    <button class="btn-primary" (click)="close()">{{'CANCEL' | translate}}</button>
  </div>
</div>

<app-modals></app-modals>
