import { Injectable } from '@angular/core';
import { db } from '../db/json';
import { LabelI } from '../interfaces/labels';
import { Console } from '../lib/console';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  constructor(private db: db) { }

  labelsList$ = this.db.records.getLabelObservable().pipe(
    map(labels => this.sortLabels(labels)));

  async add(labelObj: LabelI) {
    return await this.db.records.addLabel(labelObj)
  }

  async delete(id: number) {
    try {
      return await this.db.records.deleteLabel(id)
    } catch (error) {
      Console.log(error)
    }
  }

  async update(object: LabelI, id: number) {
    Console.log('update', object, id)
    if (id !== -1) {
      try {
        return await this.db.records.updateLabel(id, object)
      } catch (error) {
        Console.log(error)
      }
    }
  }

  private sortLabels(labels: LabelI[]): LabelI[] {
    return labels.sort((a, b) => {
      if (a.name === 'Wallet') return 1;
      if (b.name === 'Wallet') return -1;
      return a.name.localeCompare(b.name);
    });
  }
}
