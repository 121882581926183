
import { AddressType, CoinType, NetworkType } from './paperWallet';

export class Wallet {

  static fromJSON(json: any): Wallet {
    if (!json.savedAddresses) {
      json.savedAddresses = [];
    }
    const wallet = new Wallet(json.address, json.type, json.network, json.coinType);
    wallet.privateKey = json.privateKey;
    if(json.mnemonic) {
      wallet.mnemonic = json.mnemonic;
    }
    if (json.mnemonicFirstHalf) {
      wallet.mnemonicFirstHalf = json.mnemonicFirstHalf;
    }
    if (json.mnemonicSecondHalf) {
      wallet.mnemonicSecondHalf = json.mnemonicSecondHalf;
    }
    return wallet;
  }

  static toJSON(wallet: Wallet): any {
    return {
      address: wallet.address,
      privateKey: wallet.privateKey,
      type: wallet.type,
      network: wallet.network,
      coinType: wallet.coinType,
      mnemonic: wallet.mnemonic,
      mnemonicFirstHalf: wallet.mnemonicFirstHalf, // Only save the first half of the mnemonic
      mnemonicSecondHalf: wallet.mnemonicSecondHalf, // Only save the second half of the mnemonic
    };
  }

  privateKey = '';
  mnemonic = '';
  mnemonicFirstHalf = '';
  mnemonicSecondHalf = '';
  constructor(
    public address: string,
    public type: AddressType,
    public network: NetworkType,
    public coinType: CoinType,
  ) {
  }

  public getsmallUnitName() {

    switch (this.coinType) {
      case CoinType.BTC:
        return 'satoshi';
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.LINK:
        return 'wei';
      case CoinType.LTC:
        return 'litoshi';
      case CoinType.DOGE:
        return 'shibes';
      case CoinType.ADA:
        return 'lovelace';
      case CoinType.SOL:
        return 'lamport';
      case CoinType.XRP:
        return 'drop';
      default:
        return 'unit';
    }
  }

  getIcon() {
    return CoinType.getSvgPath(this.coinType);
  }

  getSmallIcon(): string {
    return CoinType.getSmallSvgPath(this.coinType);
  }
}
