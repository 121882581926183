import { FileUploadComponent } from "./file-upload.component";
import { State } from "./state";

export abstract class AbstractState implements State {
  title = ''
  message = ''
  percent = 0;
  button1Text = ''
  button2Text = ''
  fileName = ''
  showFileName = false;
  url = ''
  modal!: FileUploadComponent;

  abstract init(): Promise<void>;
  async setModal(modal: FileUploadComponent): Promise<void> {
    this.modal = modal;
    await this.init();
  };
  click1(): void { };
  click2(): void { };
  onFileSelected(event: any): void { };
  localDownloadTriggered(asBlob: boolean): void { };
}
