import { NgFor } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CryptoTokenData } from 'src/app/interfaces/cryptoTokenData';
import { SafeHtmlPipe } from 'src/app/pipes/safe-html.pipe';

@Component({
  selector: 'token-balance-list',
  standalone: true,
  imports: [TranslateModule, SafeHtmlPipe, NgFor],
  templateUrl: './token-balance-list.component.html',
  styleUrl: './token-balance-list.component.scss'
})
export class TokenBalanceListComponent {
  title = 'Address Balance';
  @Output() response = new EventEmitter<boolean>();
  @Input() tokens: CryptoTokenData[] = [];
  @Input() balance: string = '0';
  constructor() { }

  next() {
    this.response.emit(true);
  }
}
