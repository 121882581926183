import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { ApiService } from 'src/app/services/api.service';
import { AppConfig } from 'src/app/interfaces/appConfig';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CurrencyPipe, DatePipe, DecimalPipe, NgIf } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthzService } from 'src/app/services/authz.service';
import { Console } from 'src/app/lib/console';
import { Utilities } from 'src/app/lib/utilities';
import { HelpDirective } from '../../help/help-directive';

@Component({
  selector: 'app-upgrade',
  templateUrl: './upgrade.component.html',
  styleUrls: ['./upgrade.component.scss'],
  standalone: true,
  imports: [ModalComponent, TranslateModule, NgIf, DatePipe, DecimalPipe, CurrencyPipe, HelpDirective],
  providers: [CurrencyPipe]
})
export class UpgradeComponent implements AfterViewInit {
  @ViewChild(ModalComponent, { static: false })
  private modalController!: ModalComponent;
  @Output() done = new EventEmitter<string>();
  config!: AppConfig;
  currentTier = 'FreeSafe'; // Could be 'FreeSafe' 'PrimeSafe', 'LockSafe', or 'LifeSafe'
  currentCredits = 0
  expiryDate = 0; // date in seconds epoch
  paymentURL = environment.paymentURL ? environment.paymentURL : 'https://payments.safe.' + environment.envVar.DOMAIN_NAME;
  seedPrice = Utilities.calculatePrice(Utilities.getBaseFeeForTier(1));
  lockPrice = Utilities.calculatePrice(Utilities.getBaseFeeForTier(2));
  lifePrice = Utilities.calculatePrice(Utilities.getBaseFeeForTier(3)); // Who says you can't put a price on life?
  legacyPrice = Utilities.calculatePrice(Utilities.getBaseFeeForTier(4));
  storageFee = this.currencyPipe.transform(Utilities.calculatePrice(Utilities.CREDIT_TO_STORAGE_GB), 'USD', 'symbol'); // Price per Gig per Month formatted as USD
  constructor(private apiSvc: ApiService, private authzSvc: AuthzService, public translate: TranslateService, private currencyPipe: CurrencyPipe) {
  }

  async ngAfterViewInit(): Promise<void> {
    setTimeout(() => {
      this.start();
    }, 10);
  }

  private async start() {
    await this.getConfig();
    let message = '';
    if (this.config.tier == 0) {
      this.currentTier = 'FreeSafe'
      message = this.translate.instant('UPGRADE.TIER0_MSG');
    } else if (this.config.tier == 1) {
      this.currentTier = 'PrimeSafe'
      message = this.translate.instant('UPGRADE.TIER1_MSG');
    } else if (this.config.tier == 2) {
      this.currentTier = 'LockSafe'
      message = this.translate.instant('UPGRADE.TIER2_MSG');
    } else if (this.config.tier == 3) {
      this.currentTier = 'LifeSafe' // no upgrade path from here
    } else {
      this.currentTier = 'LegacyGuard'
      message = this.translate.instant('UPGRADE.TIER0_MSG'); //same message as tier 0
    }
    if (this.currentTier != 'LifeSafe') {
      let answer = await this.modalController.displayQuestion(this.translate.instant('UPGRADE.TITLE'), message, this.translate.instant('CANCEL'), null, this.translate.instant('NEXT'));
      if (answer == 'ZERO') {
        this.close();
        return;
      }
      if (this.currentTier != 'FreeSafe') {
        answer = await this.modalController.displayQuestion(this.translate.instant('UPGRADE.TITLE'), this.translate.instant('UPGRADE.COST'), this.translate.instant('CANCEL'), null, this.translate.instant('NEXT'));
        if (answer == 'ZERO') {
          this.close();
          return;
        }
      }
    }
  }

  private async getConfig() {
    this.config = this.apiSvc.getAppConfig();
    const upgradeInfo = await this.apiSvc.getUpgradeInfo();
    this.currentCredits = upgradeInfo.credits;
    this.expiryDate = upgradeInfo.expiryDate;
    Console.log('upgradeInfo', upgradeInfo);
  }

  close() {
    this.done.emit('Done');
  }

  async upgrade(tier: number) {

    const btn = await this.modalController.displayQuestion(this.translate.instant('UPGRADE.WARNING_TITLE'), this.translate.instant('UPGRADE.WARNING'), this.translate.instant('CANCEL'), null, this.translate.instant('OK'));
    if (btn == 'ZERO') {
      return;
    }

    if (this.config.tier == 0 || this.config.tier == 4) {
      // go to payment
      await this.modalController.displayMessage(this.translate.instant('UPGRADE.TITLE'), this.translate.instant('UPGRADE.TOPAYMENT'));
      await this.updateConfig(tier);
      await this.payemnt();
    } else {
      await this.updateConfig(tier);
      await this.getConfig();
      this.authzSvc.setSafeExpiry(this.expiryDate);
      const date = new Date(this.expiryDate * 1000);
      const btn = await this.modalController.displayQuestion(this.translate.instant('UPGRADE.TITLE_COMPLETE'), this.translate.instant('UPGRADE.COMPLETED_MSG', { date }), null, this.translate.instant('UPGRADE.EXTEND_SAFE'), this.translate.instant('OK'));
      if (btn == 'ONE') {
        await this.payemnt();
      } else {
        window.location.reload();
      }
    }
   // this.done.emit('Done');
  }

  private async updateConfig(tier: number) {
    this.modalController.displaySpinner(true);
    this.config.tier = tier;
    await this.apiSvc.setAppConfig(this.config);
  }

  private async payemnt() {
    this.modalController.displaySpinner(true);
    const at = await this.apiSvc.getCreditTokenAccessToken();
    window.open(`${this.paymentURL}/#/?code=${at}`, '_self');
  }

}
