import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { db } from 'src/app/db/json';
import { ApiService } from 'src/app/services/api.service';
import { FeaturesComponent } from './features/features.component';
import { NgClass, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { AuthzService } from 'src/app/services/authz.service';
import { ModalComponent } from "../modal/modal.component";
import { SwUpdate } from '@angular/service-worker';
import { GuardEditorComponent } from './guard/guard-editor.component';
import { ManageKeysComponent } from "./manage-keys/manage-keys.component";
import { UpgradeComponent } from './upgrade/upgrade.component';
import { SharedService } from 'src/app/services/shared.service';
import { Console } from 'src/app/lib/console';
import { AffiliateComponent } from "./affiliate/affiliate.component";
import { HelpDirective } from '../help/help-directive';
import { KeySpaceManagerComponent } from "./key-space-manager/key-space-manager.component";
import { Subscription } from 'rxjs';
import { NetworkService } from 'src/app/services/network.service';
const AB_COLOUR = 'rgb(95, 194, 217)'; //freze blue
@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss'],
  standalone: true,
  imports: [NgIf,
    TranslateModule,
    FeaturesComponent,
    GuardEditorComponent,
    ModalComponent,
    ManageKeysComponent,
    HelpDirective,
    UpgradeComponent,
    NgClass, AffiliateComponent, KeySpaceManagerComponent]
})
export class ConfigComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(public authzSvc: AuthzService,
    private apiSvc: ApiService,
    public networkSvc: NetworkService,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private swUpdate: SwUpdate,
    private shared: SharedService,
    private router: Router, private db: db) {
    const appConfig = this.apiSvc.getAppConfig();

    if (appConfig.tier == 3) {
      this.abText = this.translate.instant('APP_CONFIG.AB.PLAUSIBLEMODE');
    } else {
      this.abText = this.translate.instant('APP_CONFIG.AB.DECOYMODE');
    }
    this.isAdmin = appConfig.isAdmin;
    this.affiliatesCfg = appConfig.aff;
  }

  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  abText = 'Duress Decoy';
  paymentURL = environment.paymentURL ? environment.paymentURL : 'https://payments.safe.' + environment.envVar.DOMAIN_NAME;
  safeLocked = false;
  showAppConfig = false;
  showGuard = false;
  showManageKeys = false;
  showManageSpaces = false;
  showUpgrade = false;
  upgradeable = true;
  addCreditsOption = true;
  isAdmin = false;
  affiliatesCfg = false;
  showManageAffiliate = false;
  tier = 0;
  updateAvailable: any;
  deferredInstallPrompt: any | undefined; // set in main.component.ts on apiserver
  lockedText = 'Locked';
  unlockedText = 'Unlocked';
  disabledAB = false;

  routerSubscription!: Subscription;

  ADD_CREDIT_TEXT_KEY = 'APP_CONFIG.EXTEND';

  ngOnInit() {
    this.lockedText = this.translate.instant('APP_CONFIG.LOCK.LOCKED.TITLE');
    this.unlockedText = this.translate.instant('APP_CONFIG.LOCK.UNLOCKED.TITLE');
    const config = this.apiSvc.getAppConfig();
    if (this.authzSvc.newSafe) {
      this.ADD_CREDIT_TEXT_KEY = 'APP_CONFIG.NEW_SAFE';
    } else if (config.sub) {
      this.ADD_CREDIT_TEXT_KEY = 'APP_CONFIG.SUBSCRIPTION.TITLE';
    }
    this.safeLocked = this.apiSvc.isReadOnly();
    this.tier = config.tier;
    this.updateAvailable = this.apiSvc.updateAvailable;
    this.deferredInstallPrompt = this.apiSvc.deferredInstallPrompt;
    this.upgradeable = config.tier != 3;
    this.addCreditsOption = config.tier != 0 && this.authzSvc.FIDO2;
    if (document.body.style.background == AB_COLOUR) {
      //We are in AB mode data entry
      this.disabledAB = true;
    }

    this.routerSubscription = this.route.params.subscribe(params => {
      if (params['action']) {
        this.ngAfterViewInit();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  async ngAfterViewInit(): Promise<void> {
    const action = this.route.snapshot.paramMap.get('action');
    switch (action) {
      case 'PIN':
        await this.modalController.displayPinWarning();
        this.setPin();
        break;
      case 'AB':
        this.setAB();
        break;
      case 'CREDITS':
        if (this.tier == 0) {
          this.upgrade(true);
        } else {
          this.addCredits();
        }
        break;
      case 'FEATURES':
        this.appConfig();
        break;
      case 'LOCK':
        this.lockSafe();
        break;
      case 'WEBAUTHN':
        this.registerWebAuthn();
        break;
      case 'UPDATE':
        this.updateApp();
        break;
      case 'UPGRADE':
        this.upgrade(true);
        break
      case 'GUARD':
        this.guard(true);
        break;
      case 'KEYS':
        const keyID = this.route.snapshot.paramMap.get('accessID');
        this.keys(true, keyID);
        break;
      case 'CLOSE':
        this.upgrade(false);
        this.guard(false);
        this.keys(false);
        this.spaces(false);
        break;
    }
  }

  async setPin(): Promise<string> {
    const clicks = await this.modalController.displayPad(this.translate.instant('APP_CONFIG.PAD.SET_PIN'));
    if (clicks === null) {
      await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.PIN.CANCELLED.TITLE'), this.translate.instant('APP_CONFIG.PIN.CANCELLED.MSG'));
      return 'CANCELLED';
    }

    this.modalController.displaySpinner(true);
    try {
      const result = await this.apiSvc.setSafeAuthzConfig({ pinClicks: clicks });
      this.modalController.displaySpinner(false);
      if (result.action.result == 'SUCCESS') {
        await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.PIN.SUCCESS.TITLE'), this.translate.instant('APP_CONFIG.PIN.SUCCESS.MSG'));
        location.reload(); // need to test the new pin

      } else {
        const reason = result.action.reason;
        Console.log(reason);
        let message = '';
        switch (reason) {
          case 'NO_PIN': //should not get this, but..
            message = this.translate.instant('APP_CONFIG.NO_PIN.MSG');
            break;
          case 'PIN_SAFEWORD_SAME':
            message = this.translate.instant('APP_CONFIG.PIN_SAME.MSG');
            break;
        }
        await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.ERROR.TITLE'), this.translate.instant('APP_CONFIG.ERROR.PIN') + message);
        return this.setPin() //loop back to get a new pin
      }
    } catch (err) {
      this.modalController.displaySpinner(false);
      await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.ERROR.TITLE'), this.translate.instant('APP_CONFIG.ERROR.UNKNOWN'));
      return 'CANCELLED';
    }
    return 'SUCCESS';
  }

  async setAB() {
    if (this.tier == 0 || this.tier == 4) {
      return; // Not for FreeSafe or PrimeSafe
    }

    let answer = await this.modalController.displayQuestion(this.translate.instant('APP_CONFIG.AB.SET_SAFE_MODE_TITLE'), this.translate.instant('APP_CONFIG.AB.SET_SAFE_MODE_MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('NEXT'));
    if (answer == 'ZERO') {
      return;
    }

    //get new safeword
    const clicks = await this.modalController.displayPad(this.translate.instant('APP_CONFIG.PAD.SET_SAFE_CODE'));
    if (!clicks || clicks.length == 0) {
      await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.PIN.CANCELLED.TITLE'), this.translate.instant('APP_CONFIG.PIN.CANCELLED.MSG'));
      Console.log('PIN CANCELLED');
      return
    }
    const tier = this.apiSvc.getAppConfig().tier;
    const title = tier == 3 ? this.translate.instant('APP_CONFIG.AB.QUESTION.P_TITLE') : this.translate.instant('APP_CONFIG.AB.QUESTION.D_TITLE');
    const message = tier == 3 ? this.translate.instant('APP_CONFIG.AB.QUESTION.P_MSG') : this.translate.instant('APP_CONFIG.AB.QUESTION.D_MSG');
    const button = await this.modalController.displayQuestion(title, message, this.translate.instant('CANCEL'), null, this.translate.instant('YES'));
    if (button == "ZERO") return;

    //If not in lifesafe tier, then ask for confirmation because data can be deleted
    if (this.tier != 3) {
      //Are you really sure
      const iamsure = this.translate.instant('APP_CONFIG.AB.SURE.IAMSURE');
      const response = await this.modalController.displayInput(this.translate.instant('APP_CONFIG.AB.SURE.TITLE'), this.translate.instant('APP_CONFIG.AB.SURE.MSG'), false);
      if (response != iamsure) {
        return;
      }
    }

    if (this.db.spaces.length == 1) {
      await this.db.addSpace('', false);
    }

    try {
      //get spaces to show
      const spaces = await this.modalController.displaySpaceSelector(undefined, { title: this.translate.instant('APP_CONFIG.AB.SPACES.SELECT.TITLE'), message: this.translate.instant('APP_CONFIG.AB.SPACES.SELECT.MSG') }, true); //require at least one non censitive space
      Console.log('Spaces selected', spaces);
      if (spaces && spaces.length >= 0) {
        const spaceNames = this.db.spaces.filter(s => spaces.includes(s.sid)).map(s => s.m?.spaceName || `Space ${spaces.indexOf(s.sid) + 1}`);
        await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.AB.SPACES.TITLE'), this.translate.instant('APP_CONFIG.AB.SPACES.MSG', { spaces: spaceNames }));
        await this.apiSvc.setSensitiveSpaces(clicks, spaces);
      }
      this.disabledAB = true;
      this.router.navigate(['/']);
    } catch (err: any) {
      Console.error(err);
      let message = '';
      let nopin = false;
      if (err.message == 'PIN_SAFEWORD_SAME') {
        message = this.translate.instant('APP_CONFIG.PIN_SAME.MSG');
      } else if (err.message == 'NO_PIN') { // should not happen
        nopin = true;
        message = this.translate.instant('APP_CONFIG.AB.NO_PIN.MSG');
      }
      await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.ERROR.TITLE'), this.translate.instant('APP_CONFIG.ERROR.SAFEWORD') + message);
      Console.log(err);
      if (nopin) {
        await this.setPin();
      } else {
        await this.setAB();
      }
    }
  }

  async lockSafe(): Promise<boolean> {
    let message: string;
    let title: string;
    if (this.safeLocked) {
      message = this.translate.instant('APP_CONFIG.LOCK.LOCKED.MSG');
      title = this.translate.instant('APP_CONFIG.LOCK.LOCKED.TITLE');
    } else {
      message = this.translate.instant('APP_CONFIG.LOCK.UNLOCKED.MSG');
      title = this.translate.instant('APP_CONFIG.LOCK.UNLOCKED.TITLE');
    }
    const button = await this.modalController.displayQuestion(title, message, this.translate.instant('CANCEL'), null, this.translate.instant('YES'));
    const change = (button == "TWO");
    if (change) {
      this.safeLocked = !this.safeLocked;
      this.apiSvc.setReadOnly(this.safeLocked);
    }
    return this.safeLocked
  }

  async appConfig() {
    this.showAppConfig = true;
  }

  async addCredits() {
    // Current or expired safe
    const config = this.apiSvc.getAppConfig();
    if (config.tier != 0) {
      let title = this.translate.instant('APP_CONFIG.CREDITS.TITLE');
      let message = this.translate.instant('APP_CONFIG.CREDITS.MSG');
      if (config.sub) {
        title = this.translate.instant('APP_CONFIG.SUBSCRIPTION.TITLE');
        message = this.translate.instant('APP_CONFIG.SUBSCRIPTION.MSG');
      }
      let answer = await this.modalController.displayQuestion(title, message, this.translate.instant('CANCEL'), null, this.translate.instant('NEXT'));
      if (answer == 'ZERO') return;
      this.modalController.displaySpinner(true);
      const at = await this.apiSvc.getCreditTokenAccessToken();
      window.open(`${this.paymentURL}/#/?code=${at}`, '_self');
    }
  }

  //Only available if access was with clientID and passphrase
  async registerWebAuthn() {
    const button = await this.modalController.displayQuestion(this.translate.instant('APP_CONFIG.WEBAUTHN.REGISTER.TITLE'), this.translate.instant('APP_CONFIG.WEBAUTHN.REGISTER.MSG'), this.translate.instant('CANCEL'), null, "Register");
    if (button == "ZERO") return;

    //display message and redirect after 1 second
    setTimeout(() => {
      const url = window.location.origin + '/#/register/';
      window.location.href = url;
      location.reload();
    }, 1000);
    await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.WEBAUTHN.REDIRECT.TITLE'), this.translate.instant('APP_CONFIG.WEBAUTHN.REDIRECT.MSG'));

  }

  async updateApp() {
    const result = await this.modalController.displayQuestion(this.translate.instant('APP_CONFIG.UPDATE.KEY'), this.translate.instant('APP_CONFIG.UPDATE.MSG', { hash: this.updateAvailable }), this.translate.instant('CANCEL'), null, this.translate.instant('APP_CONFIG.UPDATE.BTN'));
    if (result == 'TWO') {
      this.modalController.displaySpinner(true);
      this.swUpdate.versionUpdates.subscribe(async (event) => {
        Console.log('version ready', event);
        if (event.type == 'VERSION_READY') {
          await this.modalController.displayMessage(this.translate.instant('APP_CONFIG.UPDATE.TITLE'), this.translate.instant('APP_CONFIG.UPDATE.MSG2'));
          location.reload();
        } else if (event.type == 'VERSION_INSTALLATION_FAILED') {
          Console.error('Error while updating', event.error);
          this.modalController.displayMessage(this.translate.instant('MAIN.UP_ERROR.TITLE'), this.translate.instant('MAIN.UP_ERROR.MSG') + "\n" + event.error);
        } else {
          Console.log('version updates event', event);
        }
        this.modalController.displaySpinner(false);
      });
      await this.swUpdate.activateUpdate();
    }
  }

  async upgrade(show: boolean) {
    this.setNavBar(show);
    this.showUpgrade = show;
  }

  async installApp() {
    const response = await this.modalController.displayQuestion(this.translate.instant('APP_CONFIG.INSTALL.KEY'), this.translate.instant('APP_CONFIG.INSTALL.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('YES'));
    if (response == 'TWO') {

      const result = await this.deferredInstallPrompt.prompt();
      Console.log(`Install prompt result was: ${result.outcome}`);
      if (result.outcome === 'accepted') {
        this.modalController.displayMessage(this.translate.instant('APP_CONFIG.INSTALL.TITLE2'), this.translate.instant('APP_CONFIG.INSTALL.MSG2'));
      }
    }
  }

  accessID: string | null = null;
  async keys(show: boolean, accessID: string | null = null) {
    this.accessID = accessID;
    if (this.tier == 0 || this.tier == 4) {
      return;
    }
    this.setNavBar(show);
    this.showManageKeys = show;
  }

  async spaces(show: boolean, accessID: string | null = null) {
    if (this.tier == 0 || this.tier == 4) {
      return;
    }
    this.setNavBar(show);
    this.showManageSpaces = show;
    if (accessID) {
      this.keys(true, accessID.toString());
    }
  }

  async manageAffiliate(show: boolean) {
    this.showManageAffiliate = show;
  }

  async guard(show: boolean) {
    this.setNavBar(show);
    this.showGuard = show;
  }

  private setNavBar(show: boolean) {
    if (show) {
      if (window.innerWidth < 700) {
        this.shared.setSideBar.next('hidden');
      }
    } else {
      if (window.innerWidth < 700) {
        this.shared.setSideBar.next('default');
      }
    }
  }
}
