<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div id='print-container'>
          <h2 class="auth-title" [innerHTML]="title"></h2>
          <div class="qrcode">
            <qrcode *ngIf="url" (click)="copyURL()" [level]="'H'" [size]='300' [value]="url"></qrcode>
          </div>
          <div *ngIf="code && data.action!='register'" #authBody class="auth-body">
            <div class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false"
              autocomplete="off">
              <label for="code">Recovery Code: </label>
              <p appCopyText [copyText]="code" id="code">{{code}}</p>
            </div>
          </div>
          <div *ngIf="url && data.action=='register'" #authBody class="auth-body">
            <div class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false"
              autocomplete="off">
              <p appCopyText  [showIcon]="false" [copyText]="url" id="url">{{url}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="url" class="button-container">
          <button *ngIf="code && data.action!='register'" class="btn" (click)="copyCode()">{{'MODALS.ACCESS_ID.COPY_CODE' | translate }}</button>
          <button  *ngIf="url && data.action=='register'" class="btn" (click)="copyURL()">{{'MODALS.ACCESS_ID.COPY_URL' | translate }}</button>
          <button class="btn" (click)="print()">{{'MODALS.ACCESS_ID.PRINT' | translate }}</button>
          <button class="btn-primary" (click)="next()"> {{ 'DONE' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
<message-modal *ngIf="showMessage" [data]="messageData" (response)="messageReturn()"></message-modal>
<question-modal *ngIf="showQuestion" [data]="questionData" (response)="questionReturn($event)"></question-modal>
