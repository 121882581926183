// Import necessary functions from @aws-sdk/util-base64-browser
import { fromBase64 } from '@aws-sdk/util-base64-browser';
import { base64URLStringToBuffer } from '@simplewebauthn/browser';

/**
 * UserHandleConverter
 *
 * A utility class to handle conversion between Base64 and Base64URL encoded userHandle strings.
 * It detects the format of a given userHandle and provides methods to convert between formats.
 */
export class UserHandleConverter {

  /**
    * Processes a userHandle by detecting its format and converting it to the original Base64 format if necessary.
    *
    * @param userHandle - The userHandle string to process.
    * @returns The original Base64-encoded userHandle.
    * @throws Error if the userHandle format is unknown.
    */
  public static processUserHandle(userHandle: string): ArrayBuffer {

    let result: ArrayBuffer;
    const format = this.detectFormat(userHandle);

    if (format === 'current') {
      // Already in original Base64 format
      result = base64URLStringToBuffer(userHandle);
    } else {
      // Convert Base64URL to original Base64
      result = this.recoverOriginalUserHandle(userHandle);
    }
    return result;
  }

  /**
   * Detects the encoding format of the provided userHandle.
   *
   * @param userHandle - The userHandle string to check.
   * @returns 'base64' | 'base64url' | 'unknown' indicating the detected format.
   */
  private static detectFormat(userHandle: string): 'current' | 'legacy' {
    return userHandle.length === 43 ? 'current' : 'legacy';
  }

  /**
   * Converts a Base64URL-encoded string to a standard Base64-encoded string.
   *
   * @param base64url - The Base64URL-encoded string.
   * @returns The standard Base64-encoded string with proper padding.
   */
  private static base64URLToBase64(base64url: string): string {
    let base64 = base64url.replace(/-/g, '+').replace(/_/g, '/');
    const paddingNeeded = (4 - (base64.length % 4)) % 4;
    base64 += '='.repeat(paddingNeeded);
    return base64;
  }

  /**
   * Recovers the original Base64-encoded userHandle from a Base64URL-encoded string.
   *
   * @param base64urlHandle - The Base64URL-encoded userHandle returned by version 11.
   * @returns The original Base64-encoded userHandle as stored in version 8.
   */
  private static recoverOriginalUserHandle(base64urlHandle: string): ArrayBuffer {
    const base64 = this.base64URLToBase64(base64urlHandle);
    const bytes = fromBase64(base64);
    const decoder = new TextDecoder('utf-8');
    const originalBase64 = decoder.decode(bytes);
    return fromBase64(originalBase64);
  }
}
