
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Archive, Location } from '../interfaces/archive';
import { RecordI } from '../interfaces/records';
import { ApiService } from '../services/api.service';
import { AuthzService } from '../services/authz.service';
import { ErrorService, ErrorType, SafeError } from '../services/error.service';
import { FileService } from '../services/file.service';
import { SpinnerService } from '../services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { LabelI } from '../interfaces/labels';
import { Console } from '../lib/console';

function clone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export class Records {

  recordsArchive: Archive | undefined;
  spaces: Archive[] = [];

  constructor(private authzSvc: AuthzService, private apiSvc: ApiService, private fileSvc: FileService, private errorSvc: ErrorService, private spinnerSvc: SpinnerService, private translate: TranslateService) { }
  private initializedProperly = false; // don't allow any operations until records are loaded safely to avoid overwriting good data with bad data
  private dirty = false;
  private nextRecordID = 0;
  private records: RecordI[] = [];
  private labels: LabelI[] = [];
  private nextLabelID = 0;

  async initializeNew(recordsArchive: Archive) {
    this.recordsArchive = recordsArchive;
    try {
      await this.fileSvc.postData(this.recordsArchive, { title: 'Records', data: { nextRecordID: this.nextRecordID, records: this.records, nextLabelID: this.nextLabelID, labels: this.labels } });
    } catch (error) {
      this.processError(error);
      throw error;
    }
    this.initializedProperly = true
    this.eventLabelEmiter.emit(clone(this.labels));
  }

  /**
   *
   * @param archive called when creating Safe data
   * @param reset if true, clear all current records and labels
   */
  async setArchive(archive: Archive, reset: boolean) {
    this.initializedProperly = false;
    if (reset) {
      this.records.length = 0;
      this.eventRecordEmiter.emit(clone(this.records));
      this.labels.length = 0;
      this.eventLabelEmiter.emit(clone(this.labels));
      await this.load({ title: 'Records', data: {} }, archive);
    }
    this.recordsArchive = archive;
    this.initializedProperly = true
    try {
      await this.save(true); //no debounce
    } catch (err) {
      Console.error(err);
      this.initializedProperly = false;
    }

  }

  private getNextRecordID(): number {
    this.nextRecordID += 1;
    return this.nextRecordID;
  }

  private getRecordIndexByID(id: number): number {
    return this.records.findIndex(record => record.id == id);
  }

  private getNextLabelID(): number {
    this.nextLabelID += 1;
    return this.nextLabelID;
  }

  private getLabelIndexByID(id: number): number {
    return this.labels.findIndex(label => label.id == id);
  }

  saveTimeout: any;
  /**
   * Debounce save by default
   * @param now if true, save immediately- no debounce
   * @returns Queue the save and send it in a delayed batch
   */
  private async save(now = false) {
    Console.log('save', now);
    if (!this.initializedProperly) {
      throw new SafeError(ErrorType.RECORD_LOAD, 'Records not initialized properly 2');
    }
    this.apiSvc.ping();
    if (this.apiSvc.getAppConfig().ro) {
      return;
    }
    if (this.recordsArchive) {
      if (now) {
        await this.fireAndForgetSave();
        return;
      } else {
        if (this.saveTimeout) { //cancel pending saves
          clearTimeout(this.saveTimeout);
          this.saveTimeout = undefined;
        }
        this.spinnerSvc.showSpinner(true);

        //sort records by id descending
        this.records.sort((a, b) => b.id! - a.id!);

        this.eventRecordEmiter.emit(clone(this.records));
        this.eventLabelEmiter.emit(clone(this.labels));
        this.saveTimeout = setTimeout(() => {
          this.fireAndForgetSave();
        }, 1000);
      }
    } else {
      Console.log('No records archive');
      Snackbar.show({
        pos: 'top-center',
        text: 'There is a problem, data NOT saved',
        duration: 10000,
      });
    }
  }
  private async fireAndForgetSave() {
    Console.log('fireAndForgetSave', this.recordsArchive, this.records);
    if (!this.initializedProperly || !this.recordsArchive) {
      throw new SafeError(ErrorType.RECORD_LOAD, 'Records not initialized properly 1');
    }
    this.spinnerSvc.showSpinner(true);
    try {
      const start = Date.now();
      this.dirty = true;
      await this.fileSvc.postData(this.recordsArchive, { title: 'Records', data: { nextRecordID: this.nextRecordID, records: this.records, nextLabelID: this.nextLabelID, labels: this.labels } });
      const timer = Date.now() - start;
      if (timer > 15000 && navigator.userAgent.includes('Chrome')) {
        const serro = new SafeError(ErrorType.UNKNOWN, { status: 504, url: 'mrap.accesspoint' });
        throw serro;
      }
      this.dirty = false;
      Console.log('Save time', timer);
      Snackbar.show({
        pos: 'top-center',
        text: 'Changes Saved',
        duration: 5000,
      });
    } catch (error: any) {
      this.dirty = true
      this.processError(new SafeError(ErrorType.RECORD_SAVE, error));
    } finally {
      this.spinnerSvc.showSpinner(false);
    }
  }

  private processError(error: any) {
    Console.error(error);
    this.errorSvc.process(error);
  }

  /**
   * Loads record data
   * @param archiveData
   * @param archive
   */
  async load(archiveData: any, archive: Archive) {
    this.recordsArchive = archive;
    this.initializedProperly = false;
    this.spinnerSvc.showSpinner(true, this.translate.instant('RECORDS.LOADING'));
    try {
      const nextRecordID = archiveData.data.nextRecordID;
      if (nextRecordID) {
        this.nextRecordID = nextRecordID;
      } else {
        this.nextRecordID = 0;
        archiveData.data.nextLabelID = 0;
      }
      const records = archiveData.data.records;
      if (records) {
        this.records = records;
      }

      const nextLabelID = archiveData.data.nextLabelID;
      if (nextLabelID) {
        this.nextLabelID = nextLabelID;
      } else {
        this.nextLabelID = 0;
        archiveData.data.nextLabelID = 0;
      }

      const labels = archiveData.data.labels;
      this.labels = labels ? labels : [];

      // if tier is not zero or 4 make sure wallet label is present
      if (this.apiSvc.getAppConfig().tier !== 0 && this.apiSvc.getAppConfig().tier !== 4) {
        const walletLabel = this.labels.find(x => x.name === 'Wallet');
        if (!walletLabel) {
          const label: any = { name: 'Wallet' };
          const id = this.getNextLabelID();
          label.id = id;
          this.labels.push(clone(label));
        }
      }

      this.eventRecordEmiter.emit(clone(this.records));
      this.eventLabelEmiter.emit(clone(this.labels));
      this.initializedProperly = true;
    } catch (error) {
      const safeError = new SafeError(ErrorType.RECORD_LOAD, error);
      this.processError(safeError);
    } finally {
      this.spinnerSvc.showSpinner(false);
    }
  }


  async deleteRecord(id: number) {
    if (this.apiSvc.getAppConfig().ro) {
      return;
    }
    const index = this.getRecordIndexByID(id);
    if (id == -1) {
      throw new Error('Invalid record id');
    }
    const record = this.records[index];
    this.records.splice(index, 1);

    await this.DeleteLabelIfNotUsed(record.labels);
    await this.save();
  }

  async deleteLabel(id: number) {
    if (this.apiSvc.getAppConfig().ro) {
      return;
    }
    const index = this.getLabelIndexByID(id);
    if (id == -1) {
      Console.log('Invalid label id', id);
      throw new Error('Invaid label id');
    }
    if (this.labels[index].name === 'Wallet' && this.authzSvc.appConfig.tier !== 0 && this.authzSvc.appConfig.tier !== 4) {
      Console.log('Not deleting Wallet label');
      return;
    }
    this.labels.splice(index, 1);
    await this.updateAllLabels(id, '');
  }

  async getRecord(id: number): Promise<RecordI> {
    const record = this.records.find((record) => record.id === id);
    if (!record) {
      Console.log('Invalid id', id, this.records);
      throw new Error('Invalid id');
    }
    return clone(record);
  }

  removeArchive(id: string) {
    let changed = false;
    for (const record of this.records) {
      if (record.archive?.archiveID === id) {
        record.archive = null;
        changed = true;
      }
    }
    if (changed) {
      this.save();
    }
  }

  getRecordByArchiveID(archiveId: string): RecordI | undefined {
    return this.records.find((record) => record.archive?.archiveID === archiveId);
  }

  async updateRecord(id: number, record: RecordI) {
    Console.log('updateRecord', id, record);
    if (this.apiSvc.getAppConfig().ro) {
      return;
    }

    record.id = id;
    const index = this.getRecordIndexByID(id);
    if (index == -1) throw new Error('Invalid record id');
    // don't bother if no change
    const current = this.records[index]
    if (!this.dirty && JSON.stringify(record) == JSON.stringify(current)) {
      Console.log('no change');
      return;
    }
    //remove record
    this.records.splice(index, 1, clone(record));

    //check if labes were removed
    const removedLabels: LabelI[] = [];
    current.labels.forEach(label => {
      if (!record.labels.find(x => x.id === label.id)) {
        removedLabels.push(label);
      }
    });
    if (removedLabels.length > 0) {
      await this.DeleteLabelIfNotUsed(removedLabels);
    }
    await this.save();
  }

  /**
   *
   * @param removedLabels search for the removed labels in all records and delete them if not used
   */
  private async DeleteLabelIfNotUsed(removedLabels: LabelI[]) {
    Console.log('DeleteLabelIfNotUsed', removedLabels);
    for (const label of removedLabels) {
      if (label.name === 'Wallet' && this.authzSvc.appConfig.tier !== 0 && this.authzSvc.appConfig.tier !== 4) {
        continue;
      }
      let index = this.records.findIndex(record => record.labels.findIndex(x => x.id === label.id) !== -1);
      if (index === -1) {
        Console.log('label not used', label);
        await this.deleteLabel(label.id!);
        //if removed label was the current label, go home
        const name = label.name;
        const path = window.location.hash;
        if (path === '#/label/' + name) {
          Console.log('going home');
          setTimeout(() => window.location.hash = '#/', 500);
        }
      }
    }
  }

  async updateLabel(id: number, label: LabelI) {
    if (this.apiSvc.getAppConfig().ro) {
      return;
    }
    label.id = id;
    const index = this.getLabelIndexByID(id);
    if (index == -1) {
      Console.log('invalid label id', label);
      throw new Error('Invalid label id');

    }
    //don't update if there is already a lable with that name.
    const labelIndex = this.labels.findIndex((label) => label.name === label.name);
    if (labelIndex !== -1) {
      Console.log('label name already exists', label);
      throw new Error('Label name already exists');
    }
    // don't bother if no change
    if (JSON.stringify(label) == JSON.stringify(this.labels[index])) {
      return;
    }

    this.labels.splice(index, 1, clone(label));
    await this.updateAllLabels(id, label.name);
  }

  async updateRecordKey(id: number, updates: RecordI) {
    if (this.apiSvc.getAppConfig().ro) {
      return;
    }

    const current = await this.getRecord(id);
    if (!current) throw new Error('Invalid id ' + id);
    const updated = { ...current, ...updates };
    const index = this.getRecordIndexByID(id);

    if (updates.labels) {
      const removeLabels = updates.labels.filter(x => !x.added);
      updated.labels = updates.labels.filter(x => x.added);
      updated.labels.forEach(x => { if (!x.added) { delete x.added } });
      this.records.splice(index, 1, clone(updated));
      if (removeLabels.length > 0) {
        await this.DeleteLabelIfNotUsed(removeLabels);
      }
    } else {
      this.records.splice(index, 1, clone(updated));
    }
    await this.save();
  }

  async addRecord(recordObj: RecordI): Promise<number> {
    if (this.apiSvc.getAppConfig().ro) {
      return 0;
    }
    const id = this.getNextRecordID();
    recordObj.id = id;
    this.records.push(clone(recordObj));
    await this.save();
    return id;
  }
  async addLabel(label: LabelI): Promise<number> {
    if (this.apiSvc.getAppConfig().ro) {
      return 0;
    }
    //don't update if there is already a lable with that name.
    const labelIndex = this.labels.findIndex((x) => x.name === label.name);
    if (labelIndex !== -1) {
      throw new Error('Name Exists');
    }
    const id = this.getNextLabelID();
    label.id = id;
    this.labels.push(clone(label));
    await this.save();
    return id;
  }

  private async updateAllLabels(labelId: number, labelValue: string) {

    if (this.apiSvc.getAppConfig().ro) {
      return;
    }
    this.records.forEach(record => {
      let index = record.labels.findIndex(label => label.id === labelId);
      if (index !== -1) {
        if (labelValue === '') {
          record.labels.splice(index, 1);
        } else {
          record.labels[index].name = labelValue;
        }
      }
    });
    await this.save();
  }

  private eventRecordEmiter = new EventEmitter<RecordI[]>();
  getRecordObservable(): Observable<RecordI[]> {
    return this.eventRecordEmiter;
  }

  private eventLabelEmiter = new EventEmitter<LabelI[]>();
  getLabelObservable(): Observable<LabelI[]> {
    return this.eventLabelEmiter;
  }
}

@Injectable({
  providedIn: 'root'
})

export class db {

  public records: Records;
  spaces: Archive[] = [];
  public currentSpaceNumber = 0;
  public spaceName = '';

  constructor(private apiSvc: ApiService, private authzSvc: AuthzService, private fileSvc: FileService, private errorSvc: ErrorService, private spinnerSvc: SpinnerService, private translate: TranslateService) {
    this.records = new Records(authzSvc, apiSvc, fileSvc, errorSvc, spinnerSvc, translate);
    this.authzSvc.authorized.subscribe(async (authorized: boolean) => { if (authorized) { await this.load(); this.checkMissingSpaces() } });
  }

  private checkMissingSpaces() {
    const tier = this.authzSvc.appConfig.tier;
    if (tier === 0 || tier === 4) {
      return;
    }
    if (this.spaces.length < 2 && this.authzSvc.appConfig.isAdmin) {
      this.addSpace('', this.spaces.length === 0); // switch to new space if it is the only one
    }
  }

  /**
 *
 * @param spaceNumber starting at 0
 */
  public async setSpace(spaceNumber: number) {
    spaceNumber--;
    if (spaceNumber >= this.spaces.length) {
      throw new SafeError(ErrorType.UNKNOWN, 'Invalid space number');
    }
    await this.load(spaceNumber);
    this.currentSpaceNumber = spaceNumber;
  }

  public async addSpace(name?: string, setCurrent = true) {
    const locationID = this.authzSvc.appConfig.locID;
    const archiveRequest = new Archive();
    archiveRequest.t = 'Records';
    archiveRequest.m = { name: "records", description: "Records", spaceName: name ? name : '' };
    archiveRequest.l = locationID;
    archiveRequest.sid = Math.round(Date.now() / 1000);
    const recordsArchive = await this.apiSvc.createArchive(archiveRequest);
    this.spaces.push(recordsArchive);
    if (setCurrent) {
      await this.records.setArchive(recordsArchive, true);
      this.apiSvc.spaceID = recordsArchive.sid;
      this.currentSpaceNumber = this.spaces.length - 1;
      this.spaceName = name ? name : '';
    } else {
      await this.fileSvc.postData(recordsArchive, { title: 'Records', data: { nextRecordID: 0, records: [], nextLabelID: 0, labels: [] } });
    }
  }

  async deleteSpace(id: number) {
    const index = this.spaces.findIndex(space => space.sid === id);
    if (index === -1) {
      throw new SafeError(ErrorType.UNKNOWN, 'Invalid space id');
    }
    const iscurrent = this.currentSpaceNumber === index;
    await this.apiSvc.deleteArchive(this.spaces[index].id);
    this.spaces.splice(index, 1);
    if (this.spaces.length = 0) {
      await this.addSpace();
    } else {
      if (iscurrent) {
        this.load(0);
      }
    }
  }

  public async setSpaceName(space: number, name: string) {
    const archive = this.spaces[--space];
    archive.m.spaceName = name;
    await this.apiSvc.updateArchive(archive);
    if (space === this.currentSpaceNumber) {
      this.spaceName = name;
    }
  }

  public refresh() {
    this.load(this.currentSpaceNumber);
  }

  /**
   * Called when creating a new safe
   * @param location
   */
  public async initialize(location: Location) {
    Console.log('initialize');
    const archiveRequest = new Archive();
    archiveRequest.t = 'Records';
    archiveRequest.m = { name: "records", description: "Records", spaceName: '' };
    archiveRequest.l = location.id;
    archiveRequest.sid = Math.round(Date.now() / 1000);
    this.apiSvc.spaceID = archiveRequest.sid;
    const recordsArchive = await this.apiSvc.createArchive(archiveRequest);
    await this.records.initializeNew(recordsArchive);
    this.spaces.push(recordsArchive);
    this.authzSvc.appConfig.locID = location.id;
  }

  public async load(spaceNumber = 0) {
    Console.log('load', spaceNumber);
    if (this.authzSvc.expired) {
      this.errorSvc.process(new SafeError(ErrorType.EXPIRED, 'Session expired'));
      return;
    }
    this.spinnerSvc.showSpinner(true, this.translate.instant('RECORDS.LOADING'));
    try {
      if (this.spaces.length === 0) {
        const archives = await this.apiSvc.getArchives();
        if (<any>archives === 'UNWILLING_TO_PERFORM') {
          Console.log('UNWILLING_TO_PERFORM');
          return;
        }

        this.spaces.push(...archives.filter(archive => archive.t == 'Records'));

        //ToDo: remove this after all pages have a page number
        for (const space of this.spaces) {
          if (!space.sid) {
            Console.log('no space id', space);
            if (!this.apiSvc.spaceID) {
              this.apiSvc.spaceID = Math.round(Date.now() / 1000);
            }
            space.sid = this.apiSvc.spaceID;
            await this.apiSvc.updateArchive(space);
          }
        }
        this.spaces.sort((a, b) => a.sid - b.sid);
      }
      const recordsArchive = this.spaces[spaceNumber];

      if (!recordsArchive) {
        this.errorSvc.process(new SafeError(ErrorType.NO_ARCHIVES, 'NO_NOTES_ARCHIVE'));
        return;
      }

      this.authzSvc.appConfig.locID = recordsArchive.l;

      const archiveData: any = await this.fileSvc.getData(recordsArchive);
      await this.records.load(archiveData, recordsArchive);
      this.currentSpaceNumber = spaceNumber;
      this.spaceName = recordsArchive.m.spaceName;
      if (!this.spaceName) {
        this.spaceName = '';
      }
      this.apiSvc.spaceID = recordsArchive.sid;
      Console.log('spaceName', this.spaceName);
      Console.log('spaceNumber', this.currentSpaceNumber);
      Console.log('spaceID', this.apiSvc.spaceID);

    } catch (error) {
      const safeError = new SafeError(ErrorType.RECORD_LOAD, error);
      this.errorSvc.process(safeError);
    } finally {
      this.spinnerSvc.showSpinner(false);
    }
  }
}
