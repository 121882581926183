import { Console } from "./console";
import { Tiers } from "./Tiers";

const GIGIBYTE = 1024 * 1024 * 1024; // 1 GB in bytes
const SECONDS_IN_A_MONTH = 2630233; //~seconds/month
export class Utilities {


  static setTiers(tierData: Tiers) {
    Console.log('Setting tiers', tierData);
    Utilities.TIER_1_FEE = tierData.TIER_1_FEE; //credits per month
    Utilities.TIER_2_FEE = tierData.TIER_2_FEE; //credits per month
    Utilities.TIER_3_FEE = tierData.TIER_3_FEE; //credits per month
    Utilities.TIER_4_FEE = tierData.TIER_4_FEE;
    Utilities.TIER_1_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_1_TTL_MONTHS;
    Utilities.TIER_2_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_2_TTL_MONTHS;
    Utilities.TIER_3_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_3_TTL_MONTHS;
    Utilities.TIER_4_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_4_TTL_MONTHS;
    Utilities.CREDIT_TO_STORAGE_GB = tierData.CREDIT_TO_STORAGE_GB; // credit per GB per month
    Utilities.BASE_CREDIT_VALUE = tierData.BASE_CREDIT_VALUE; //  USD per credit
    Utilities.TIER_1_BASE_STORAGE_GB = tierData.TIER_1_BASE_STORAGE_GB;
    Utilities.TIER_2_BASE_STORAGE_GB = tierData.TIER_2_BASE_STORAGE_GB;
    Utilities.TIER_3_BASE_STORAGE_GB = tierData.TIER_3_BASE_STORAGE_GB;
    Utilities.TIER_4_BASE_STORAGE_GB = tierData.TIER_4_BASE_STORAGE_GB;
  }

  static TIER_1_FEE: number;//credits per month
  static TIER_2_FEE: number; //credits per month
  static TIER_3_FEE: number; //credits per month
  static TIER_4_FEE: number; //credits per month
  static TIER_1_TTL_SECONDS: number;
  static TIER_2_TTL_SECONDS: number;
  static TIER_3_TTL_SECONDS: number;
  static TIER_4_TTL_SECONDS: number;
  static CREDIT_TO_STORAGE_GB: number; // credit per GB per month
  static BASE_CREDIT_VALUE: number; //  USD per credit

  static TIER_1_BASE_STORAGE_GB: any;
  static TIER_2_BASE_STORAGE_GB: any;
  static TIER_3_BASE_STORAGE_GB: any;
  static TIER_4_BASE_STORAGE_GB: any;

  static getCreditsFromAmountPaid(amountPaid: number): number {
    const credits = amountPaid / Utilities.BASE_CREDIT_VALUE; // Calculate initial credits from the amount paid
    return Math.round(credits * 100) / 100;
  }

  /*
   * Calculates the late fee for a given epoc date in seconds that the safe expired
   * @param expired - the epoc date in seconds that the safe expired
   * @returns the late months
   */
  static calculateLateFeeCredits(expiryDateInSeconds: number, tier: number, bytesUsed: number): number {
    const now = new Date().getTime() / 1000;
    const lateSeconds = Math.ceil(now - expiryDateInSeconds);
    const lateFeeIncredits = Utilities.getCreditsFromExpiry(lateSeconds, tier, bytesUsed);
    return lateFeeIncredits;
  }

  /*
   * Calculate the price of the credits

   */
  static calculatePrice(credits: number): number {
    if (credits < 0) {
      // negative credits are not allowed
      throw new Error('Credits cannot be negative');
    }

    const price = credits * Utilities.BASE_CREDIT_VALUE;
    Console.log(`Calculated final price ${price}`);
    return Math.round(price * 100) / 100;
  }

  public static calculateNewSafeExpiry(
    currentUsage: number,
    currentExpiryEpochSeconds: number,
    tier: number,
    addedUsage: number,
  ): number {
    if (tier == 0) {
      // do not recalculate expiry for trial safe
      return currentExpiryEpochSeconds;
    }
    const currentTimeEpochSeconds = Math.floor(Date.now() / 1000);
    // Calculate the total credits based on remaining time and current usage
    const currentCredits = Utilities.getCreditsFromExpiry(currentExpiryEpochSeconds, tier, currentUsage);
    Console.log(
      `totalCredits: ${currentCredits}, currentUsage: ${currentUsage}, currentExpiryEpoch: ${currentExpiryEpochSeconds}, currentTimeEpoch: ${currentTimeEpochSeconds}`,
    );
    // Calculate the new  total usage
    const totalUsage = currentUsage + addedUsage;
    // Calculate the new expiry date
    const newExpiryEpochSeconds = Utilities.getExpiryInSeconds(totalUsage, tier, currentCredits);
    Console.log(`newExpiryEpoch: ${newExpiryEpochSeconds}, totalUsage:${totalUsage}, addedUsage:${addedUsage}`);
    return newExpiryEpochSeconds;
  }

  static getBaseFeeForTier(tier: number): number {
    switch (tier) {
      case 0:
        return 0;
      case 1:
        return this.TIER_1_FEE;
      case 2:
        return this.TIER_2_FEE;
      case 3:
        return this.TIER_3_FEE;
        case 4:
          return this.TIER_4_FEE;
      default:
        throw new Error('Invalid tier');
    }
  }
  static getExpiryInSeconds(storageUsed: number, tier: number, credits: number): number {
    if (tier == 0) {
      return SECONDS_IN_A_MONTH * 24;
    }
    const storageUsedGB = Utilities.adjustUsedStorage(storageUsed, tier);

    const totalCreditsNeeded = this.getBaseFeeForTier(tier) + storageUsedGB / this.CREDIT_TO_STORAGE_GB;
    return Math.round((credits / totalCreditsNeeded) * SECONDS_IN_A_MONTH);
  }
  /**
   *
   * @param currentExpiryInSeconds , NotEpoch
   * @param tier
   * @param storageUsed
   * @returns
   */
  static getCreditsFromExpiry(currentExpiryInSeconds: number, tier: number, storageUsed: number): number {
    if (tier == 0) {
      return SECONDS_IN_A_MONTH * 24;
    }
    const storageUsedGB = Utilities.adjustUsedStorage(storageUsed, tier);
    Console.log(`storageUsedGB: ${storageUsedGB}`);
    const monthlyCreditsNeeded = this.getBaseFeeForTier(tier) + storageUsedGB / this.CREDIT_TO_STORAGE_GB;
    Console.log(`monthlyCreditsNeeded: ${monthlyCreditsNeeded}`);
    const factor = currentExpiryInSeconds / SECONDS_IN_A_MONTH;
    Console.log(`factor: ${factor}`);
    return monthlyCreditsNeeded * factor;
  }

  static generateSecurePassword(length = 12) {
    const charset = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    const values = new Uint8Array(length);
    window.crypto.getRandomValues(values);

    for (let i = 0; i < length; i++) {
      password += charset[values[i] % charset.length];
    }

    return password;
  }

  private static adjustUsedStorage(storageUsed: number, tier: number): number {
    let storageUsedGB = storageUsed / GIGIBYTE;
    // adjust for included storage
    if (tier == 1) {
      storageUsedGB -= this.TIER_1_BASE_STORAGE_GB;
    } else if (tier == 2) {
      storageUsedGB -= this.TIER_2_BASE_STORAGE_GB;
    } else if (tier == 3) {
      storageUsedGB -= this.TIER_3_BASE_STORAGE_GB;
    } else if (tier == 4) {
      storageUsedGB -= this.TIER_4_BASE_STORAGE_GB;
    }
    //can not be negative
    return Math.max(0, storageUsedGB);
  }

  static getBaseStorageByTier(tier: number) {
    switch (tier) {
      case 0:
        return 0;
      case 1:
        return this.TIER_1_BASE_STORAGE_GB * GIGIBYTE;
      case 2:
        return this.TIER_2_BASE_STORAGE_GB * GIGIBYTE;
      case 3:
        return this.TIER_3_BASE_STORAGE_GB * GIGIBYTE;
        case 4:
          return this.TIER_4_BASE_STORAGE_GB * GIGIBYTE;
      default:
        throw new Error('Invalid tier');
    }
  }
}
