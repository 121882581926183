<div class="message-viewer">
  <div *ngIf="loading" class="loading">Loading message...</div>
  <div *ngIf="error" class="error">{{ error }}</div>

  <div *ngIf="header" class="message-content">
    <div class="message-detail">
      <label>Sender Address:</label>
      <div class="name-container">
        <span>{{ header.sender }}</span>
        <button [disabled]="header.sender=='Unknown'"  type="button" (click)="editRecipientName()" class="edit-btn"> <img
            src="../../../../assets/images/svg/pen-filled.svg" alt="edit name"></button>
      </div>
    </div>

    <div class="message-detail">
      <label>Subject:</label>
      <span>{{ header.subject }}</span>
    </div>

    <div class="message-detail">
      <label>Message:</label>
      <div class="message-body">
        <quill-view [content]="header.message" format="json" theme="snow">
        </quill-view>
      </div>
    </div>

    <div class="message-detail" *ngIf="header?.attachments?.length">
      <label>Attachments:</label>
      <div class="attachment-list">
        <div class="attachment" *ngFor="let file of header.attachments">
          <div class="attachment-info">
            <div class="attachment-details">
              <div class="attachment-name">{{ file.name }}</div>
              <span class="attachment-type">{{ file.type }}</span>
              <span class="attachment-size">{{ file.size | fileSize }}</span>
            </div>
          </div>
          <div class="button-container archive-buttons">
            <button type="button" (click)="saveAttachment(file)" class="btn">
              Save
            </button>
            <button type="button" (click)="openAttachment(file)" class="btn">
              Open
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="button-container">
    <button type="button" (click)="saveAsNote()" class="btn-primary">
      Save as Note
    </button>
    <button type="button" (click)="exit()" class="btn-primary">
      Close
    </button>
  </div>
  <div id="viewer">
    <div *ngIf="url && type!='unknown'">
      <div class="button-container center">
        <button class="btn" (click)="close()">
          <span class="text">{{'CLOSE' | translate}}</span>
        </button>
      </div>
      <div class="view-container">
        <img class="image" *ngIf="type=='image'" [src]="url" (click)="close()">
        <audio class="audio" *ngIf="type=='audio'" preload="none" controls>
          <source [src]="url" attr.type="{{type}}" />
        </audio>
        <video class="video" *ngIf="type=='video'" preload="none" attr.type="{{type}}" [src]="url" controls muted
          autoplay height="400"></video>
        <pre class="text notranslate" autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off"
          *ngIf="type=='text'">{{textData}}</pre>
        <ngx-extended-pdf-viewer *ngIf="type=='pdf'" [showDownloadButton]="false" [showHighlightEditor]="false"
          [showPrintButton]="false" [showOpenFileButton]="false" [showFindButton]="false" [showDrawEditor]="false"
          [showStampEditor]="false" [showTextEditor]="false" [useInlineScripts]="false" [language]="language"
          [zoom]="zoom" [src]="url"></ngx-extended-pdf-viewer>
      </div>
    </div>
  </div>
</div>
<app-modals></app-modals>