import { EventEmitter, Injectable, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Action, CallBack, CallBackEncryptedDTO, EmptyAction } from 'src/app/interfaces/callback';
import * as Snackbar from 'node-snackbar';
import { version } from 'src/environments/version';
import { AppConfig } from '../interfaces/appConfig';
import { TranslateService } from '@ngx-translate/core';
import { SafeCrypto } from '../lib/safeCrypto';
import * as LZString from 'lz-string';
import { ErrorService, ErrorType, SafeError } from './error.service';
import { Console } from '../lib/console';
import { NetworkService } from './network.service';
const inactivityDelay = 30000; // 30 seconds

@Injectable({
  providedIn: 'root',
})
export class AuthzService implements OnInit{
  crypto = new SafeCrypto();
  safeExpiryEPOCms = 0 //epoch date
  public expired = false
  public FIDO2 = false;
  public accessID = '';
  private auth = false;
  private apiURL = 'https://api.' + location.host;
  state: any;
  private sessionTimeout = 0;
  public appConfig: AppConfig = {
    ro: false, cbox: false, hide: true, adv: false, safeName: '', locID: '', tier: 0, isAdmin: false, pid: '', sub: false, filter: false, aff: false, isAff: false, guardSet: false, hg: false
  };
  public newSafe = false;
  private markForPing = false; // used to prevent multiple unneeded pings to server
  private inactivityTimer;




  constructor(private http: HttpClient, private translate: TranslateService, private errorSvc: ErrorService, private networkService: NetworkService) {
    if (environment.apiURL) {
      this.apiURL = environment.apiURL;
    } else {
      this.apiURL = 'https://api.safe.' + environment.envVar.DOMAIN_NAME;
    }
    // When backgrounded timers might not run so detect wakeup and reload if session expired.
    // Chech every 2 seconds for session expiry
    setInterval(this.donthaveagoodname, 5000); // after wakeup we might need this to kill timed out session
    document.addEventListener('visibilitychange', () => {
      Console.log('visibilitychange');
      this.donthaveagoodname();
    });
    document.addEventListener('freeze', () => { // chrome
      Console.log('freeze');
      this.donthaveagoodname();
    });

    Console.log('Version: ' + version);

    // this.setSessionEpiery(Date.now() / 1000 + 300); // close in 5 minute seconds if authz not started;
    Console.log('AuthzService: init');
  }
  ngOnInit(): void {
    this.networkService.networkStatus$.subscribe((status) => {
      Console.log('AuthzSvc networkStatus', status);
    });
  }

  private donthaveagoodname() {
    if (this.networkService && !this.networkService.isOnline) {
      Console.log('donthaveagoodname: offline');
      return;
    }
    if (this.sessionTimeout) {
      if (Date.now() > this.sessionTimeout) {
        // window.location.href = 'about:blank';
        location.reload();
      }
    }
    // Hide data when not in use because we do not want an image of the data persisted during freezing tabs if avoidable
    if (document.hidden && this.errorSvc && this.auth) {
      this.errorSvc.process(new SafeError(ErrorType.HIDE_SCREEN, 'hide screen'))
    }
  }

  async start(type: string, args: string): Promise<CallBack> {
    try {
      const configUrl = this.apiURL + '/start';
      const params = { 'type': type, args };
      const options = {
        responseType: 'json' as const, params,
        headers: { 'x-app-version': version, 'ngsw-bypass': 'true' }
      };
      const ret: CallBack = await lastValueFrom(this.http.get<CallBack>(configUrl, options));
      this.setSessionEpiery(ret.exp)
      return ret;
    } catch (err) {
      throw err;
    }
  }

  /**
   *
   * @param callback called from authz.component duting authorization
   * @returns
   */
  async reply(callback: CallBack): Promise<CallBack> {
    try {
      //No need to return the request data
      callback.action.request = {};

      let body: any = callback;

      if (this.crypto.ECDHEDone && callback.type != 'Captcha' && callback.type != 'ECDHE') {
        body = new CallBackEncryptedDTO(callback);
        body.action = await this.crypto.encryptAction(callback.action);
      }

      const configUrl = this.apiURL + '/start';
      const options = {
        responseType: 'json' as const,
        headers: { 'x-app-version': version, 'ngsw-bypass': 'true' }
      };
      const ret: any = await lastValueFrom(this.http.post<CallBack>(configUrl, body, options));

      //decrypt
      if (this.crypto.ECDHEDone && ret.type != 'Captcha' && ret.type != 'ECDHE' && ret.type != 'FAILED') {
        if (ret.action) {
          ret.action = await this.crypto.decryptAction(ret.action);
        }
      }

      // the state will be used as the authz token by the ApiService
      if (ret.type == 'SUCCESS') {
        this.state = ret.state;
        this.appConfig = Object.assign(this.appConfig, ret.action.result); //AppConfig is returned in the SUCCESS action
        Console.log('AppConfig', this.appConfig);
        const action: Action = ret.action;
        if (action.message.includes('NEW_SAFE')) {
          this.newSafe = true;
        }
        this.setSafeExpiry(ret.safeExp);
      } else {
        this.setSessionEpiery(ret.exp)
      }

      return ret;
    } catch (err) {
      Console.error(err);
      throw err;
    }
  }

  /*
   * Perform an ECDSA key exchange to establish an AES256 GCM session key.
   * This is used to encrypt callback data between the client and the API Server, providing an extra layer of security over SSL.
   * The API Server's public validation key, which is hardcoded in the client, is used to authenticate the API Server.
   * ---
   * Client application files, including the API Server's validation key, are signed during the build process and uploaded to the server.
   * A service worker validates the signatures of any new application versions' files before using them to ensure their integrity.
   * As long as the application is installed from a trusted host the first time...
   * We're doing the best we can. This approach is a significant step ahead of most other SPAs/PWAs and aims to provide an additional layer of security beyond SSL.
   * Data is encrypted client-side and transmitted to the storage systems independently of the API Server.
   * If you begin with a compromised system, just like with any other applications, you're in trouble...
   */
  async doKeyExchange(ECPublicKeyJWK: any, signatureb64: string) {
    const keys = await this.crypto.doKeyExchange(ECPublicKeyJWK, signatureb64, environment.envVar.serverSigningValidationKey);

    Snackbar.show({
      pos: 'top-center',
      text: this.translate.instant('AUTHZSVC.VALIDATED'),
      duration: 3000,
    });
    // Return clients ECDH public key to API Server
    return keys;
  }

  setSafeExpiry(safeExp: number) {
    this.safeExpiryEPOCms = safeExp * 1000;
    const currentDate = new Date();
    const safeExpDate = new Date(this.safeExpiryEPOCms);

    // Check if the futureDate is not in the future and return 'Expired' if true
    if (currentDate >= safeExpDate) {
      this.expired = true;
    }
  }

  authorized = new EventEmitter<boolean>();
  /*
  * Notify listeners when we are finally authorized
  * or trigger a reload if becoming unauthorized
  */
  setAuthorized(authorized: boolean) {
    this.auth = authorized;
    if (!authorized) {
      this.state = undefined;
      this.crypto = new SafeCrypto();
      clearTimeout(this.inactivityTimer);
    } else {
      this.ping();
    }
    this.authorized.emit(authorized);
  }

  /*
  * Used by the API service to send callbacks the the API Server
  * Action data will be encrypted before posting except for ECDHE and Captcha callbacks.
  */
  async apiRequest(callback: CallBack): Promise<CallBack> {
    if (!this.networkService.isOnline) {
      Console.log('apiRequest: offline');
      throw new SafeError(ErrorType.OFFLINE, 'Offline');
    }
    Console.log('apiRequest', callback);
    try {
      if (!this.state) {
        Console.error('Not Authorized yet');
        throw new Error('Not Authorized yet');
      }
      callback.state = this.state;


      let body: any;
      if (callback.type != 'Captcha' && callback.type != 'ECDHE') {
        if (!this.crypto.ECDHEDone) {
          throw new Error('ECDHE exchange not completed');
        }
        body = new CallBackEncryptedDTO(callback);
        body.action = await this.crypto.encryptAction(callback.action);
      } else {
        body = callback;
      }

      // Post callback to server
      const configUrl = this.apiURL + '/api';
      const options: any = {
        method: 'POST',
        body: JSON.stringify(body),
        responseType: 'json' as const,
        headers: {
          'x-app-version': version, 'ngsw-bypass': 'true',
          'Cache-Control': 'no-cache'
        },
      };
      const resp = await fetch(configUrl, options);
      if (!resp.ok) {
        const safeError = await this.errorSvc.process(resp);
        if (safeError) throw safeError;
      }
      let ret = await resp.json();

      this.updateConnectionStatus(ret);

      // Decrypt answer unless it is Captcha or ECDHE key exchange
      if (this.crypto.ECDHEDone && ret.type != 'Captcha' && ret.type != 'ECDHE') {
        if (ret.action) {
          ret.action = await this.crypto.decryptAction(ret.action);
        }
      }
      Console.log('apiRequest response', ret);
      return ret;
    } catch (err) {
      if (err instanceof SafeError) {
        throw err;
      }
      Console.error('AuthzService: Error prossesing apiRequest');
      const newError = await this.errorSvc.process(err);
      throw newError;
    }
  }

  private updateConnectionStatus(ret: any) {
    Console.log('updateConnectionStatus', ret);
    this.state = ret.state; // Update state for later calls
    this.setSessionEpiery(ret.exp);
  }

  private timer?: NodeJS.Timeout;
  private setSessionEpiery(exp: number | undefined) {
    clearTimeout(this.timer);
    if (!exp) return;
    //exp is date in seconds when connection expires
    this.sessionTimeout = (exp - 60) * 1000; // 60 seconds before expiry
    const future = this.sessionTimeout - Date.now();
    this.timer = setTimeout(() => this.handleSessionTimeout(), future);
  }

  private handleSessionTimeout(): void {
    const timer = this.timer = setTimeout(() => location.reload(), 60000);
    if (this.markForPing) { // There has been a ping request since the last call to handleSessionTimeout
      this.ping(true);
    } else {
      const that = this;
      Snackbar.show({
        pos: 'top-center',
        actionText: this.translate.instant('YES'),
        text: this.translate.instant('AUTHZSVC.STILLTHERE'),
        duration: 999999,
        onClose: function (element) {
          clearTimeout(timer);
          that.ping(true);
        }
      });
    }
  }

  // renew token
  public async ping(force: boolean = false) {
    if (!this.networkService.isOnline) {
      Console.log('ping: offline');
      return;
    }
    Console.log('ping', force);
    if (force) {
      this.markForPing = false;
      const ping = new CallBack('Ping', new EmptyAction());
      await this.apiRequest(ping);
    } else {
      this.markForPing = true; // defer ping until handleSessionTimeout is called
    }
    clearTimeout(this.inactivityTimer);
    if (this.auth) this.inactivityTimer = setTimeout(() => { this.errorSvc.process(new SafeError(ErrorType.HIDE_SCREEN, 'hide screen')) }, inactivityDelay);
  }

  /*
   * Reinitializes Crypto with the client data key from the server

   * The client day key is stored double encrypted in the SafeAccessID.
   * It also adds support for passkeys.
    */
  async unwrapAndSetClientDataKey(wrappedClientDataKey: string) {
    if (!this.crypto.initialized) {
      //Crypto must first be initialized with a master key to decrypt the client data key
      throw new Error('Crypto not initialized');
    }
    await this.crypto.unwrapAndSetClientDataMasterKey(wrappedClientDataKey);
  }

  /*
  * Initializes AWSCrypto keyring and WebCrypto
  * SafeAccess Master key = first 128 bits of AES256(passphrase + accessID).
  * Used to encrypt and decrypt the Client Data Master key that is then used to reinitialize the keyring with a key not related to the passphrase.
  * Storing the client data key double encrypted in the safe metadata allows for future support for passphrase changees without re-encrypting all the data.
  * It also adds support for passkeys.
   */
  async initCryptoWithPassphrase(passphrase: string, accessID: string) { //called from authz.component
    return this.crypto.initCryptoWithPassphrase(passphrase, accessID)
  }

  async initCryptoWithWebAuthn(b64masterKey: string) { //called from authz.component
    return this.crypto.initCryptoWithSafeAccessMasterKey(b64masterKey);
  }

  /*
    * Generates a new client data master key and encrypts it with the passphrase and the accessID.
    * The wrapped key is sent to the server and stored in the safe metadata.
    * The passphrase is not sent to the server or persisted anywhere.
    * The client data key is used to initialize the clients keyring.
    */
  async generateNewClientDataMasterKey(): Promise<string> {
    if (!this.crypto.initialized) {
      throw new Error('keyring must be initialized first');
    }
    const key = await this.crypto.generateNewClientDataKey();
    return key;
  }

  public async unwrapServerMetadataKey(wrappedSafeKey: string): Promise<string> {
    const key = await this.crypto.decryptServerMetadataKey(wrappedSafeKey);
    return key;
  }

  public wrapServerMetadataKey(serverKey: string): Promise<string> {
    return this.crypto.encryptServerMetadataKey(serverKey);
  }

  public async encryptString(text: string): Promise<string> {
    // Compress
    return this.crypto.encryptString(LZString.compressToBase64(text));
  }

  public async decryptString(str: string): Promise<string> {
    // Decompress
    return LZString.decompressFromBase64(await this.crypto.decryptString(str));
  }

  public isAuthorized(): boolean {
    return this.auth;
  }
}
