import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { Router, RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgFor, NgIf } from '@angular/common';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  standalone: true,
  imports: [RouterLink, TranslateModule, NgFor, NgIf]
})
export class NavComponent implements OnInit, OnDestroy {
  @ViewChild("modalContainer ") modalContainer !: ElementRef<HTMLInputElement>
  @ViewChild("modal") modal !: ElementRef<HTMLInputElement>
  @ViewChild("labelInput") labelInput !: ElementRef<HTMLInputElement>
  @ViewChild("labelError") labelError !: ElementRef<HTMLInputElement>
  routeSub: any;
  selectedLabelName = 'NONE';

  constructor(public Shared: SharedService, public route: Router, public apiSvc: ApiService) {
  }

  // ? modal ----------------------------------------------------------
  openModal() {
    this.modalContainer.nativeElement.style.display = 'block';
    document.addEventListener('mousedown', this.mouseDownEvent)
  }

  hideModal() {
    this.modalContainer.nativeElement.style.display = 'none'
    document.removeEventListener('mousedown', this.mouseDownEvent)
  }

  mouseDownEvent = (event: Event) => {
    let modalEl = this.modal.nativeElement
    if (!(modalEl as any).contains(event.target)) {
      this.hideModal()
    }
  }

  navClicked() {
    Console.log('navClicked');
    if (this.navMode == 2) {
      this.collapseSideBar('default');// make nice on small devices
    }
  }

  /*Three modes for the
  * 0 = full -- open
  * 1 = short -- closed
  * 2 = hidden
  */
   navMode = -1;
  collapseSideBar(mode: 'full' | 'short' | 'default' | 'hidden' | 'toggle') {
    switch (mode) {
      case 'toggle':
        if (++this.navMode == 3) {
          this.navMode = 0;
        }
        break;
      case 'short':
        this.navMode = 0;
        break;
      case 'hidden':
        this.navMode = 1;
        break;
      case 'full':
        this.navMode = 2;
        break;
      case 'default':
        if (window.innerWidth <= 700) {
          this.navMode = 0;
        } else {
          this.navMode = 2;
        }
    }

    switch (this.navMode) {
      case 0:
        this.setClose();
        break;
      case 1:
        this.setHidden();
        break;
      case 2:
        this.setOpen();
        break;
    }
  }

  private setHidden() {
    const sideBar: HTMLElement = document.querySelector('[sideBar]')!;
    sideBar.classList.add('hidden');
    sideBar.classList.remove('close');
  }

  private setClose() {
    const sideBar: HTMLElement = document.querySelector('[sideBar]')!;
    sideBar.classList.add('close');
    sideBar.classList.remove('hidden');
  }

  private setOpen() {
    const sideBar: HTMLElement = document.querySelector('[sideBar]')!;
    sideBar.classList.remove('close');
    sideBar.classList.remove('hidden');
  }

  navigate(route: string): void {
    const currentpath = this.route.url;
    if(currentpath.includes('config') && route.includes('config')) {
      // close the config page if open
      this.route.navigate([route,{action: 'CLOSE', cacheBust: Date.now()}]);
      return;
    }
    this.route.navigate([route]);
  }

  navigateLabel(labelName: string): void {
    this.route.navigate(['label', labelName]);
  }

  ngOnInit(): void {
    this.Shared.setSideBar.subscribe(mode => this.collapseSideBar(mode));
    this.routeSub = this.route.events.subscribe((event: any) => {
      if (event.type != 1) return;
      //NavigationEnd event
      const url: string = event.urlAfterRedirects;
      if (url && url.startsWith('/label')) {
        const ename = url.split('/')[2];
        const name = decodeURIComponent(ename);
        Console.log(name);
        this.selectedLabelName = name;
      } else {
        this.selectedLabelName = 'NONE';
      }
    });

    this.collapseSideBar('default');
  }

  ngOnDestroy(): void {
    this.routeSub.unsubscribe();
    this.Shared.setSideBar.unsubscribe();
  }
}
