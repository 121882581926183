import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { db } from 'src/app/db/json';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from "../../modal/modal.component";
import { Router } from '@angular/router';
import { AuthRegionModalComponent } from '../../auth/region-modal/region-modal.component';
import { Archive, Location } from 'src/app/interfaces/archive';
import { SpinnerService } from 'src/app/services/spinner.service';

interface Space {
  id: number;
  name: string;
  keys: SafeAccess[];
  locName: string;
  locid: string;
  archive: Archive;
}

@Component({
  selector: 'app-key-space-manager',
  standalone: true,
  imports: [CommonModule, FormsModule, TranslateModule, ModalComponent, AuthRegionModalComponent],
  templateUrl: './key-space-manager.component.html',
  styleUrl: './key-space-manager.component.scss'
})
export class KeySpaceManagerComponent implements OnInit {
  @Output() done = new EventEmitter<string>();
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  allKeys: SafeAccess[] = [];
  allSpacesKeys: SafeAccess[] = [];
  spaces!: { id: number, name: string, locid: string, locName: string; archive: Archive, keys: SafeAccess[]; }[];

  constructor(private db: db, private apiSvc: ApiService, private translate: TranslateService, private router: Router, private spinnerSvc: SpinnerService) { }

  async ngOnInit() {
    await this.init();
  }

  async init() {
    try {
      this.spinnerSvc.showSpinner(true)
      this.allKeys = await this.apiSvc.getAccesses('device');
      this.allSpacesKeys = this.allKeys.filter(key => key.mode == 'admin' || key.spaceIds.length == 0);

      this.allKeys = this.allKeys.filter(key => key.mode != 'admin');

      const archives = this.db.spaces;
      this.spaces = archives.map(archive => ({
        id: archive.sid,
        name: archive.m.spaceName || `Space ${archives.indexOf(archive) + 1}`,
        keys: this.keysOfSpace(archive.sid),
        locid: archive.l,
        locName: '',
        archive: archive,
        moved: false
      }));
      for (const space of this.spaces) {
        space.locName = await this.apiSvc.getLocationName(space.locid);
      }
    } catch (error) {
      Console.error('Failed to initialize key space manager');
      this.close();
    } finally {
      this.spinnerSvc.showSpinner(false);
    }
  }

  private keysOfSpace(spaceId: number): SafeAccess[] {
    return this.allKeys.filter(key => key.spaceIds && key.spaceIds.includes(spaceId));
  }

  toggleKey(space: Space, key: SafeAccess) {
    const index = space.keys.findIndex(k => k.accessID === key.accessID);
    if (index > -1) {
      // Remove key from space
      space.keys.splice(index, 1);
      key.spaceIds = key.spaceIds?.filter(id => id !== space.id);
      if (key.spaceIds.length === 0) {
        this.allSpacesKeys.push(key);
      }
    } else {
      // Add key to space
      space.keys.push(key);
      const index = this.allSpacesKeys.indexOf(key);
      if (index > -1) {
        this.allSpacesKeys.splice(index, 1);//remove from allSpacesKeys
      }
      key.spaceIds.push(space.id);
    }
    this.updateKeySpaces(key);
  }

  isKeyInSpace(space: Space, key: SafeAccess): boolean {
    return space.keys.some(k => k.accessID === key.accessID);
  }

  async updateKeySpaces(key: SafeAccess) {
    Console.log('Updating key spaces', key);
    await this.apiSvc.updateAccess(key);
  }

  getKeyType(key: SafeAccess): string {
    return key.mode === 'admin' ? 'APP_CONFIG.SPACES.ADMIN' : key.mode === 'ro' ? 'APP_CONFIG.SPACES.READ_ONLY' : 'APP_CONFIG.SPACES.READ_WRITE';
  }

  async deleteSpace(space: Space) {
    const regret = this.translate.instant('APP_CONFIG.SPACES.DELETE.REGRET');
    const response = await this.modalController.displayInput(this.translate.instant('APP_CONFIG.SPACES.DELETE.TITLE'), this.translate.instant('APP_CONFIG.SPACES.DELETE.MSG'), false);
    if (response === regret) {
      Console.log('Deleting space', space);
      Console.log('current spaces', this.spaces);

      this.spaces = this.spaces.filter(s => s.id != space.id);
      Console.log('new spaces', this.spaces);
      await this.db.deleteSpace(space.id);
    }

    //update space names
    for (let i = 0; i < this.spaces.length; i++) {
      Console.log('Updating space name', this.spaces[i]);
      this.spaces[i].name = this.spaces[i].archive.m.spaceName || `Space ${i + 1}`;
    }
  }

  locations: Location[] | null = null; // controls display of regions modal
  locationSpaceID = "1";
  private selectedSpace: Space | null = null;

  async changeLocation(space: Space) {
    Console.log('Change location', space);
    this.selectedSpace = space;
    this.locationSpaceID = space.locid;
    this.locations = await this.apiSvc.getLocations();
  }

  async newLocation(location: Location) {
    this.locations = null;
    if (location && this.selectedSpace && location.id !== this.selectedSpace.locid) { // if location is selected and is different from current location
      this.modalController.displaySpinner(true, "Moving data");
      try {
        this.selectedSpace.archive.moved = true;
        await this.apiSvc.moveSpaceArchives(this.selectedSpace.id, location.id);
        this.selectedSpace.locid = location.id;
        this.selectedSpace.locName = await this.apiSvc.getLocationName(location.id);
        this.selectedSpace.archive.l = location.id;
        this.modalController.displayMessage("Moved", "Space moved to " + location.name);
      } catch (error) {
        Console.error('Failed to move space to ' + location.name, error);
        this.modalController.displayMessage("Error", "Failed to move space to " + location.name);
      } finally {
        this.modalController.displaySpinner(false);
      }
    }
  }

  close() {
    this.done.emit();
  }

  async newSpace() {
    await this.db.addSpace();
    this.init();
  }

  keyAdmin(key: SafeAccess) {
    Console.log('keyAdmin', key);
    this.close();
    this.done.emit(key.accessID);
  }

  gotoSpace(space: Space, event) {

    event.stopPropagation();
    const spaceindex = this.spaces.indexOf(space);
    Console.log('gotoSpace', spaceindex);
    this.db.setSpace(spaceindex + 1); // set current space
    this.router.navigate(['/']);
    this.close();
  }

  async editSpaceName(space: Space, event) {
    event.stopPropagation();
    Console.log('editSpaceName', space);
    const newName = await this.modalController.displayInput('Edit Space Name', 'Enter new space name', false, space.name);
      if (newName) {
        space.name = newName;
        space.archive.m.spaceName = newName;
        const spaceindex = this.spaces.indexOf(space);
        await this.db.setSpaceName(spaceindex + 1, newName);
      }
  }
}

