import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { ApiService } from './api.service';
import { lastValueFrom } from 'rxjs';
import { AuthzService } from './authz.service';
import { ErrorService } from './error.service';
import { Archive } from '../interfaces/archive';
import { chunkerTransformer } from '../lib/chunkerTransformer';
import { BroadcastChannelHandler } from '../lib/broadcastChannelHandler';
import { FileUploadMessage } from '../lib/fileUploadMessage';
import { Console } from '../lib/console';
import { toBase64 } from '@aws-sdk/util-base64-browser';

const CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
@Injectable({
  providedIn: 'root'
})
export class FileService {
  public static CHUNK_SIZE = CHUNK_SIZE;
  private serviceWorkerBroadcastChannelHandler: BroadcastChannelHandler;
  private static eventsMap = new Map<string, any>();

  constructor(private errorSvc: ErrorService, private apiSvc: ApiService, private authzSvc: AuthzService, private http: HttpClient) {
    this.serviceWorkerBroadcastChannelHandler = new BroadcastChannelHandler('FileService', this.handleServiceWorkerMessage);
    this.authzSvc.authorized.subscribe(async (authorized) => {
      if (authorized) {
        this.serviceWorkerBroadcastChannelHandler.open();
      } else {
        this.serviceWorkerBroadcastChannelHandler.close();
      }
    });
  }

  /**
   * This function does not use the service worker so we handle encryption here
   * @param archive
   * @param data
   * @returns
   */
  public async postData(archive: Archive, data: any) {
    const archiveID = archive.id;
    if (archive.m == '') {
      archive.m = { name: "records", description: "Records" };
    }
    const type = archive.m.type ? archive.m.type : 'text/plain';

    const stringData = JSON.stringify(data);
    let encrypted = await this.authzSvc.encryptString(stringData);
    // kek ensures that even if someone got the data and the client master data key, they would still not be able to decrypt the data without the server metadata which is deleted when the archive is deleted or replaced.
    // Perfect forward secrecy
    let kek = archive.m.kek;

    kek = this.authzSvc.crypto.xorEncryptedDataKeysInHeaderString(encrypted, kek);
    if (kek != archive.m.kek) {
      archive.m.kek = kek;
      await this.apiSvc.updateArchive(archive);
    }
    const blob = new Blob([encrypted], { type: type, });
    const file = new File([blob], "data", { lastModified: Date.now() });
    const linkObject = await this.apiSvc.getUploadObject(archiveID);
    return this.postFile(file, linkObject);
  }

  // data is encrypted by AES256_GCM_IV12_TAG16_NO_PADDING so no need to check the download hash here
  public async getData(archive: Archive): Promise<any> {
    const archiveID = archive.id;
    const kek = archive.m.kek;
    const url = await this.apiSvc.getDownloadLink(archiveID);
    const encrypted = await lastValueFrom(this.http.get(url, { responseType: 'text' }));

    if (kek) {
      this.authzSvc.crypto.xorEncryptedDataKeysInHeaderString(encrypted, kek);
    } else {
      Console.error('FileSvc: getData', 'No KEK found');
    }
    const data = await this.authzSvc.decryptString(encrypted as string);

    const retdata = JSON.parse(data!);
    Console.log('FileSvc: getData', retdata);
    return retdata;
  }

  // hash of encrypted data is checked by the server ensuring the data has not been tampered with or otherwise corrupted while uploading
  private async postFile(file: File, linkObject: any) {
    let res: (value: any) => void;
    let rej: (value: any) => void;

    const result = new Promise<any>((resolve: any, reject: any) => { res = resolve, rej = reject });
    const formData = new FormData();

    // Add the signature data for the signed POST
    let key: keyof typeof linkObject;
    for (key in linkObject) {
      const value = linkObject[key];
      if (key == 'url') continue;
      formData.append(key, value);
    }

    let fileHash = await this.calculateSHA256(file);
    formData.append('x-amz-checksum-sha256', fileHash);

    formData.append('file', file); // file must be last
    const observable = this.http.post(linkObject.url, formData, {
      reportProgress: true,
      observe: 'events'
    });
    observable.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total) {
          Console.log(`Upload progress: ${Math.round(event.loaded / event.total * 100)}%`);
        } else {
          Console.log(`Upload transfered: +${event.loaded}B`);
        }
      } else if (event.type === HttpEventType.Response) {
        res(event);
      }
    }, error => { Console.log(error), rej(error) });
    return result;
  }

  private async calculateSHA256(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target?.result as ArrayBuffer;
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        const hashArray = new Uint8Array(hashBuffer);
        // corrupt the hash to test the server side hash check
        // hashArray[0] = hashArray[0] + 1;
        const hashBase64 = toBase64(hashArray);
        resolve(hashBase64);
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Initiate a local encrypted file fetch
   * @param file
   * @param requestedarchiveID
   * @returns archiveID
   */
  public async initFetchLocalFile(file: File, requestedarchiveID?: string): Promise<string> {
    //get first 21 bytes as header archiveID
    //Archive ID is random ie: non identifiable
    const header = file.slice(0, 21);
    const archiveID = await header.text();
    if (requestedarchiveID && requestedarchiveID != archiveID) {
      throw new Error('Wrong file');
    }
    const archive = await this.apiSvc.getArchive(archiveID);
    Console.log('header archiveID', archiveID);
    const cancelObject = { cancel: false };
    const emitter = new EventEmitter<{ transferd: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>();
    FileService.eventsMap.set(archive.id, { file, archive, emitter, cancelObject });
    return archiveID;
  }

  /**
   * encrypt for Upload or for local storage
   * @param file
   * @param metadata
   */
  public async encryptFile(file: File, metadata: any, local = false): Promise<{ archive: Archive, progress: EventEmitter<{ transferd: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }> }> {

    let archive: Archive | undefined;
    const emitter = new EventEmitter<{ transferd: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>();
    try {
      let type = file.type;
      if (!type) {
        type = 'application/octet-stream';
      }
      if (!metadata.type) metadata.type = type;
      if (!metadata.name) metadata.name = file.name;
      const size = file.size;

      const archiveRequest = new Archive();
      archiveRequest.t = local ? 'Local' : 'Cloud';
      archiveRequest.m = metadata;
      archiveRequest.l = local ? 'Local' : this.apiSvc.getAppConfig().locID;
      archiveRequest.fs = size;

      archive = await this.apiSvc.createArchive(archiveRequest);
      if (local) {
        const cancelObject = { cancel: false };
        FileService.eventsMap.set(archive.id, { emitter, file, archive, cancelObject });
        const data = file.slice(0, CHUNK_SIZE);
        await this.serviceWorkerBroadcastChannelHandler.postMessage({ op: 'INIT_LOCAL', archive, data });
        Console.log('INIT_LOCAL');
      } else {
        const readableStream = file.stream();
        FileService.eventsMap.set(archive.id, { emitter, readableStream });

        const cancelObject = await this.chunkFile(readableStream, archive, emitter);
        FileService.eventsMap.set(archive.id, { emitter, readableStream, cancelObject });
      }
      return { archive, progress: emitter };
    } catch (error) {
      if (archive) {
        this.uploadError(archive, error, emitter);
      }
      this.errorSvc.process(error);
      throw error;
    }
  }

  /**
   * Upload a file in chunks
   * @param readableStream
   * @param archive
   */
  private async chunkFile(readableStream: ReadableStream<Uint8Array>, archive: Archive, event: EventEmitter<{ transferd: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>):
    Promise<{ cancel: boolean }> {
    Console.log("chunkFile");
    const cancelObject = { cancel: false };
    const that = this;
    let part = 0;
    let transfered = 0;
    event.emit({ transferd: 0, stage: 'READING' });
    try {
      const writeable = new WritableStream<Uint8Array>({
        async write(chunk: Uint8Array, controller: WritableStreamDefaultController) {
          try {
            if (cancelObject.cancel) {
              throw new Error('Cancelled');
            }
            that.apiSvc.ping(); // keep session alive
            part++;
            transfered += chunk.byteLength;
            let message: FileUploadMessage;
            if (part == 1) {
              message = { op: 'INIT', archive: archive, partNumber: part, data: chunk };
            } else {
              message = { op: 'PART', archiveId: archive.id, partNumber: part, data: chunk };
            }
            Console.log('Sending message', message.op);
            await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
            event.emit({ transferd: transfered, stage: 'READING' });
          } catch (error: any) {
            if (cancelObject.cancel) {
              return;
            }
            controller.error(error);

            const message = { op: 'ABORT', archiveId: archive.id };
            await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
            that.uploadError(archive, error, event);
          }
        },
        async close() {
          Console.log('close');
          try {
            const message: FileUploadMessage = { op: 'COMPLETED', archiveId: archive.id };
            // Todo we should check at start of upload when we at least have the fs size
            //will fail if out of tier space
            const safeExpObject: { safeExp: number } = await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
            if (safeExpObject) {
              that.authzSvc.safeExpiryEPOCms = safeExpObject.safeExp * 1000;
            }
            await that.apiSvc.getArchive(archive.id, true); // force refresh
            event.emit({ transferd: transfered, stage: 'COMPLETED' });
          } catch (error) {
            Console.log('close error', error);
            const message: FileUploadMessage = { op: 'ABORT', archiveId: archive.id };
            await that.serviceWorkerBroadcastChannelHandler.postMessage(message);

            event.emit({ transferd: -1, stage: 'ERROR' });
            await that.errorSvc.process(error);
          }
          FileService.eventsMap.delete(archive.id);
        },
        async abort(reason) {
          Console.log('abort reason', reason);
          const message: FileUploadMessage = { op: 'ABORT', archiveId: archive.id };
          that.serviceWorkerBroadcastChannelHandler.postMessage(message);
          that.uploadError(archive, reason, event);
        }
      });
      const chunker = chunkerTransformer([CHUNK_SIZE], 0);
      const transformer = chunker.chunkerTransformStream;
      readableStream.pipeThrough(transformer).pipeTo(writeable);

      return cancelObject;

    } catch (error) {
      Console.log("Upload failed", error);
      try {
        const message: FileUploadMessage = { op: 'ABORT', archiveId: archive.id };
        await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
        if (!cancelObject.cancel) {
          this.uploadError(archive, error, event);
        }
        await readableStream.cancel();
      } catch (ignore) {
      }
      throw error;
    }
  }

  private async uploadError(archive: Archive, error: any, event: EventEmitter<{ transferd: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>) {
    try {
      await this.apiSvc.deleteArchive(archive.id);
    } catch (ignore) {
    }
    const data = FileService.eventsMap.get(archive.id);
    FileService.eventsMap.delete(archive.id);
    if (data && data.cancelObject.cancel) {
      return;  // don't emit error if cancelled
    }
    event.emit({ transferd: -1, stage: 'ERROR', error });
    await this.errorSvc.process(error);
  }

  private async handleServiceWorkerMessage(message: FileUploadMessage): Promise<any> {
    if (message.op == 'ERROR') {
      Console.log('ERROR', message, this.errorSvc);
      ErrorService.instance.process(message.error); // this.ref not always initialized yet
      return;
    }
    const archiveId = message.archiveId;
    if (!archiveId) {
      Console.log('No archiveId: ignoring message');
      throw new Error('Ignore');// ignore message form service worker as it must not be for this client
    }
    const data = FileService.eventsMap.get(archiveId!);
    if (data) {

      switch (message.op) {
        case 'WRITING':
        case 'ENCRYPTING':
          const msg = message.op;
          data.emitter.emit({ stage: msg, transferd: 0 });
          break;
        case 'FETCH_PART':
          const partNumber = message.partNumber!;
          const { file, archive, cancelObject } = FileService.eventsMap.get(archiveId);
          if (cancelObject.cancel) {
            Console.log('Cancelled');
            if (data.emitter) {
              data.emitter.emit({ stage: 'COMPLETED', transferd: 0 });
            }
            return { data: null };
          }
          let start = 0;
          let end = 0;
          if (!archive.p || archive.p.length == 0) {
            end = CHUNK_SIZE * partNumber
            start = end - CHUNK_SIZE;
          } else {
            if (partNumber == archive.p.length) {
              if (data.emitter) {
                data.emitter.emit({ stage: 'COMPLETED', transferd: 0 });
              }
              return { data: null };
            }
            //add up all the part sizes up to and including the part we want
            const size = archive.p[partNumber]
            for (let i = 0; i < partNumber + 1; i++) {
              end += archive.p[i];
            }

            start = end - size;
          }
          if (!message.encrypting) {
            Console.log('Skipping header');
            start += 21; //add header size
            end += 21; //add header size
          }
          const chunk = (<File>file).slice(start, end);
          if (chunk.size == 0) {
            Console.log('No more data');
            data.emitter.emit({ stage: 'COMPLETED', transferd: chunk.size });
            return { data: null };
          } else {
            data.emitter.emit({ stage: 'READING', transferd: end });
            return { data: chunk };
          }

        case 'ABORT':
          Console.log('ABORT');
          await this.apiSvc.deleteArchive(archiveId);
          FileService.eventsMap.delete(archiveId);
          data.emitter.emit({ stage: 'ERROR', transferd: -1 });

          break;
      }
    } else {
      Console.log('No data: ignoring message');
      throw new Error('Ignore'); // ignore message form service worker as it must not be for this client
    }
  }

  public async abortUpload(archiveId: string) {
    const data = FileService.eventsMap.get(archiveId);
    if (data) {
      data.cancelObject.cancel = true;
    }
    const message = { op: 'ABORT', archiveId: archiveId };
    await this.serviceWorkerBroadcastChannelHandler.postMessage(message);
    try {
      setTimeout(() => {
        this.apiSvc.deleteArchive(archiveId);
      }, 5000);
    } catch (ignore) {
    }
  }

  public async handleBlobDownload(url: string, fileName: string): Promise<void> {
    Console.log('handleBlobDownload ', url);
    try {
      const blob = await lastValueFrom(this.http.get(url, { responseType: 'blob' }));
      if (!blob) {
        alert('Download failed');
        return
      }

      // remove .ulf extra extension if present in fileName
      fileName = fileName.replace('.ulf', '');

      if (navigator.share) {
        const file = new File([blob], fileName, { type: blob.type });
        await navigator.share({
          files: [file],
          title: 'Download File',
          text: 'Please download this file'
        });
      } else {
        Console.log('Download failed: navigator.share not supported. Tryting download with anchor');
        // Fallback for browsers that don't support navigator.share
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      Console.error('Download failed:', error);
      this.errorSvc.process(error);
    }
  }
}
