import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ErrorService, ErrorType, SafeError } from 'src/app/services/error.service';
import { NgIf } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthRegionModalComponent } from "../auth/region-modal/region-modal.component";
import { Location } from 'src/app/interfaces/archive';
import { db } from 'src/app/db/json';
import { Console } from 'src/app/lib/console';
import { AppConfig } from 'src/app/interfaces/appConfig';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule, AuthRegionModalComponent]
})
export class ErrorComponent implements OnInit, OnDestroy {
  locations!: Location[]

  private paymentURL = environment.paymentURL ? environment.paymentURL : 'https://payments.safe.' + environment.envVar.DOMAIN_NAME;
  showMessage = false;
  title = '';
  message = '';
  private error!: SafeError;
  displayModal = 'none';
  subscription: any;
  blurOverlay = false;
  supressBlurOverlay = false;
  constructor(private errorSvc: ErrorService, private apiSvc: ApiService, private translate: TranslateService, private db: db, private changeDetectorRef: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.subscription = this.errorSvc.errorEmiter.subscribe((error) => { this.process(error) });
    // keep session alive when mouse is in use
    document.body.addEventListener('mouseup', (event) => {
      if (this.blurOverlay) {
        this.hideBlurOverlay(event);
      }
    });

    //Keep session alive while typing
    document.body.addEventListener('keyup', (event) => {
      if (this.blurOverlay) {
        this.hideBlurOverlay(event);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private async process(error: SafeError) {
    this.error = error;
    switch (error.type) {
      case ErrorType.SIZE:
        this.title = this.translate.instant('ERRORSVC.SIZE.TITLE');
        this.message = this.translate.instant('ERRORSVC.SIZE.MSG');
        this.showMessage = true;
        setTimeout(() => { this.db.load() }, 2000);
        break;
      case ErrorType.EXPIRED:
        this.title = this.translate.instant('ERRORSVC.EXPIRED.TITLE');
        this.message = this.translate.instant('ERRORSVC.EXPIRED.MSG');
        this.showMessage = true;
        break;
      case ErrorType.UNKNOWN:
      case ErrorType.SERVER:
        const isChrome = navigator.userAgent.includes('Chrome');
        if (error.value.status && error.value.status === 504 && error.value.url && error.value.url.includes('mrap.accesspoint') && isChrome) {
          this.title = this.translate.instant('Chrome browser and AWS storage issue');
          this.message = "<p>There is a known issue with the Chrome browser and AWS storage services that can cause long network delays and timeouts.</p><p>This issue is related to an experimental feature in Chrome that is not yet fully compatible with AWS. Disabling this feature should resolve the problem without impacting other functionalities.</p> <p>To disable the feature, type <strong>chrome://flags/#enable-tls13-kyber</strong> in the address bar and press Enter. Then locate the setting for <strong>TLS 1.3 Hybridized Kyber Support</strong> and change it to 'Disabled'.</p><p>For more detailed information and updates, visit the Chromium issues tracker: <a href=\"https://issues.chromium.org/issues/41487796\">https://issues.chromium.org/issues/41487796</a></p>";
        } else {
          this.title = this.translate.instant('ERRORSVC.UNKNOWN.TITLE');
          this.message = '<center>';
          if (error.value.message) {
            const msg = error.value.message;
            if (typeof msg === 'string') {
              this.message += msg;
            } else {
              this.message += JSON.stringify(error.value.message);
            }
          } else if (error.value) {
            const value = error.value;
            if (typeof value === 'string') {
              this.message += value;
            } else {
              this.message += JSON.stringify(error.value);
            }
          } else {
            this.message = this.translate.instant('ERRORSVC.UNKNOWN.MSG');
          }
          this.message += '</center>';
        }
        this.showMessage = true;

        break;
      case ErrorType.NO_ARCHIVES:
        // Probably caused when the user did not complete the registration process
        this.getandsetRecordLocation();
        break;
      case ErrorType.TIER:
        this.title = this.translate.instant('ERRORSVC.TIER.TITLE');
        this.message = this.translate.instant('ERRORSVC.TIER.MSG');
        this.showMessage = true;
        break;
      case ErrorType.LOCKED:
        const config: AppConfig = await this.apiSvc.refreshAppConfig();
        config.ro = true;
        config.isAdmin = false;
        this.title = this.translate.instant('ERRORSVC.LOCKED.TITLE');
        this.message = this.translate.instant('ERRORSVC.LOCKED.MSG');
        this.showMessage = true;
        break;
      case ErrorType.LOCKED_OUT:
        this.title = this.translate.instant('ERRORSVC.LOCKED.TITLE');
        this.message = this.translate.instant('ERRORSVC.LOCKED.MSG');
        this.showMessage = true;
        setTimeout(() => {
          location.reload();
        }, 5000);
        break;
      case ErrorType.CRYPTO_NOT_INITIALIZED:
        this.title = this.translate.instant('ERRORSVC.CRYPTO_NOT_INITIALIZED.TITLE');
        this.message = this.translate.instant('ERRORSVC.CRYPTO_NOT_INITIALIZED.MSG');
        this.showMessage = true;
        break;
      case ErrorType.VALIDATION:
        this.title = 'Validation Error'
        this.message = error.value;
        this.showMessage = true;
        break;
      case ErrorType.RECORD_LOAD:
        //There was an error loading the records so we need to reload the page so the user does not save enpty records over the existing ones.
        this.title = this.translate.instant('ERRORSVC.RECORD_LOAD.TITLE');
        this.message = this.translate.instant('ERRORSVC.RECORD_LOAD.MSG');
        this.showMessage = true;

        break;
      case ErrorType.RECORD_SAVE:
        this.title = this.translate.instant('ERRORSVC.RECORD_SAVE.TITLE');
        this.message = this.translate.instant('ERRORSVC.RECORD_SAVE.MSG');

        if (error.value.status === 400) {
          Console.log('ErrorService.processHttpErrorResponse: status 400', error);
          const errorData = error.value.error;
          if (errorData && errorData.includes('EntityTooLarge')) {
            Console.log('Size error', errorData);
            const sizes = this.extractSizesFromAWSError(errorData);
            Console.log('Sizes', sizes);
            this.title = this.translate.instant('ERRORSVC.RECORD_SAVE_SIZE.TITLE');
            this.message = this.translate.instant('ERRORSVC.RECORD_SAVE_SIZE.MSG', { "SIZE": sizes.maxSizeAllowed });
          }
        }

        this.showMessage = true;
        break;
      case ErrorType.HIDE_SCREEN:
        this.showBlurOverlay();
        break
      case ErrorType.INSUFFICIENT_FUNDS:
        const fee = error.value;
        this.title = this.translate.instant('ERRORSVC.INSUFFICIENT_FUNDS.TITLE');
        this.message = this.translate.instant('ERRORSVC.INSUFFICIENT_FUNDS.MSG', { fee });
        this.showMessage = true;
        break;
      case ErrorType.PERMISSION:
        this.title = this.translate.instant('ERRORSVC.PERMISSION.TITLE');
        this.message = this.translate.instant('ERRORSVC.PERMISSION.MSG');
        this.showMessage = true;
        break;
      default:
        Console.error('Unknown safeerror ', error);
        this.title = this.translate.instant('ERRORSVC.UNKNOWN.TITLE');
        this.message = this.translate.instant('ERRORSVC.UNKNOWN.MSG');
        this.showMessage = true;
        break;
    }
    this.changeDetectorRef.detectChanges();
  }

  private extractSizesFromAWSError(xmlString: string): { proposedSize: string | null, maxSizeAllowed: string | null } {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    const proposedSize = xmlDoc.getElementsByTagName("ProposedSize")[0].childNodes[0].nodeValue;
    const maxSizeAllowed = xmlDoc.getElementsByTagName("MaxSizeAllowed")[0].childNodes[0].nodeValue;

    return {
      proposedSize,
      maxSizeAllowed
    };
  }

  async ok() {
    this.showMessage = false
    if (this.error.type == ErrorType.EXPIRED) {
      this.title = this.translate.instant('ERRORSVC.EXPIRED.RDIR_TITLE');
      this.message = this.translate.instant('ERRORSVC.EXPIRED.RDIR_MSG');
      this.showMessage = true;
      const at = await this.apiSvc.getCreditTokenAccessToken();
      window.open(`${this.paymentURL}/#/?code=${at}`, '_self');
    } else if (this.error.type == ErrorType.CRYPTO_NOT_INITIALIZED) {
      location.reload();
    }
  }

  private async getandsetRecordLocation() {
    this.locations = await this.apiSvc.getLocations();
    this.displayModal = 'Regions';
  }

  async createRecords(location: Location) {
    this.displayModal = 'none';
    await this.db.initialize(location);
  }

  private showBlurOverlay() {
    if (!this.supressBlurOverlay) {
      this.blurOverlay = true;
    }
  }

  hideBlurOverlay(event) {
    Console.log('hideBlurOverlay event', event);
    event.stopPropagation();
    event.preventDefault();
    this.blurOverlay = false
  }

  captureEvent(event) {
    event.stopPropagation();
    event.preventDefault();
  }
}
