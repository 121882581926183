import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable, interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { Console } from '../lib/console';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);
  private pollingIntervalMs = 5000; // Poll every 5 seconds when offline
  private isPolling = false; // Prevent duplicate polling loops

  constructor(private ngZone: NgZone) {
    window.addEventListener('online', () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
  }

  private updateOnlineStatus(status: boolean) {
    this.ngZone.run(() => this.onlineStatus.next(status));
  }

  get networkStatus$(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  get isOnline(): boolean {
    return this.onlineStatus.value;
  }

  public async onlineByFetch(): Promise<boolean> {
    Console.log('Onlinefetch check ');

      try {
        const response = await fetch(`${window.location.origin}/ngsw.json?cb=${Date.now()}`, {
          method: "HEAD",
          mode: "no-cors", // Prevents CORS enforcement
          cache: "no-store", // Ensure no caching
        });

        if (response.ok) {
          return true; // Online
        }
      } catch (error) {
        // Detected offline status
        this.handleOffline();
        return false;
      }


    this.handleOffline();
    return false;
  }

  private handleOffline() {
    if (!this.isOnline) return; // Avoid unnecessary updates if already offline

    this.updateOnlineStatus(false);
    this.startPollingForOnline(); // Start polling if we go offline
  }

  private startPollingForOnline() {
    if (this.isPolling) return; // Prevent multiple polling loops
    this.isPolling = true;

    interval(this.pollingIntervalMs)
      .pipe(takeWhile(() => !this.isOnline)) // Continue polling while offline
      .subscribe(async () => {
        const online = await this.onlineByFetch();
        if (online) {
          this.updateOnlineStatus(true);
          this.isPolling = false; // Stop polling when online
        }
      });
  }
}

