<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" [innerHTML]="data.title"></h2>
        <div #authBody class="input-body">
          <div [innerHTML]="data.message" class="notranslate"></div>
          <div>
              <input autofocus #number type="range" [min]="data.min" [max]="data.max" [step]="data.step" [(ngModel)]="value">
          </div>
          <div>
            <p>{{ data.label }} <output id="value" [value]="value"></output></p>
          </div>
          <div #buttonContainer class="button-container">
            <button tabindex="3" class="btn" (click)="cancel()"> {{ 'CANCEL' | translate
              }}</button>
            <button tabindex="2" class="btn-primary" (click)="send()"> {{ 'NEXT' | translate
              }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
