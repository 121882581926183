import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { RecordsComponent } from './records/records.component';
import { InputComponent } from './input/input.component';
import { ModalComponent } from '../modal/modal.component';

@Component({
  selector: 'app-main-records',
  templateUrl: './main-records.component.html',
  styleUrls: ['./main-records.component.scss'],
  standalone: true,
  imports: [InputComponent, RecordsComponent, ModalComponent]
})
export class MainRecordsComponent implements AfterViewInit {
  @ViewChild('component') modalComponent!: ModalComponent;

  constructor(private cdr: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }
}
