import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { QrCodeComponent } from 'src/app/components/qrcode/qrcode.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorService, ErrorType, SafeError } from 'src/app/services/error.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Console } from 'src/app/lib/console';
import { Wallet } from 'src/app/lib/wallet';
import { HelpDirective } from 'src/app/components/help/help-directive';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { State } from '../state';
import { ApiService } from 'src/app/services/api.service';
import { WalletService } from 'src/app/services/wallet.service';
import { AddressType, CoinType } from 'src/app/lib/paperWallet';
import { CoinSelectorModalComponent } from "./coin-selector-modal/coin-selector-modal.component";
import { SharedService } from 'src/app/services/shared.service';
import { InputModalComponent } from "../input-modal/input-modal.component";
import { TransferComponent } from "./transfer/transfer.component";
import { CopyTextDirective } from 'src/app/components/copyDirective/copy-text.directive';
import { ApiKeyPair } from 'src/app/interfaces/ApiProvider';
import { ApiKeyModalComponent } from "./ApiKey-modal/apikey-modal.component";
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'wallet-modal',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  standalone: true,
  imports: [QrCodeComponent, TranslateModule, NgIf, NgFor, NgClass, HelpDirective, QuestionModalComponent, CoinSelectorModalComponent, InputModalComponent, TransferComponent, CopyTextDirective, ApiKeyModalComponent]
})

export class WalletModalComponent implements AfterViewInit {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<Wallet | null>();
  @Output() done = new EventEmitter<void>();
  @ViewChild('questionemodal') questionComponent!: QuestionModalComponent;
  @Input() data!: { wallet: Wallet | null };

  address: string | undefined = '';
  imageURL = '';
  smallImageURL = '';
  title = '';
  showPrivateKey = false;
  btnText = '';
  readonly = false;
  private showAddressText = '';
  private showPrivateKeyText = '';
  private addressText = '';
  private privateKeyText = '';
  // private testnet = environment.production;
  private testnet = false
  balance = '';
  wallet!: Wallet;
  clipboardSupported = navigator.clipboard ? true : false;
  displayMode: 'qrcode' | 'mnemonics' = 'qrcode';
  mnemonicWords: string[] = [];
  ethereumBalance = '';

  constructor(private errorSvc: ErrorService, private translate: TranslateService, private apiSvc: ApiService, private walletSvc: WalletService, private sharedSvc: SharedService, private printservice: PrintService) {
    this.showAddressText = this.translate.instant('MODALS.WALLET.BTN_ADDRESS');
    this.readonly = this.apiSvc.getAppConfig().ro;
    this.showPrivateKeyText = this.translate.instant('MODALS.WALLET.BTN_PRIVATE');
    this.btnText = this.showPrivateKeyText;
    Console.log('WalletModalComponent created')
  }

  async ngAfterViewInit(): Promise<void> {

    try {
      if (!this.data.wallet) {
        const coinType = await this.displayCoinSelector();
        if (!coinType) {
          this.done.emit();
          return;
        }

        this.wallet = await this.createWallet(coinType);

        const config = this.apiSvc.getAppConfig();
        let message = this.translate.instant('MODALS.WALLET.NEW.MSG');
        if (config.tier == 0 || config.tier == 4) {
          message = this.translate.instant('MODALS.WALLET.NEW.MSGFREETIER');
        }
        await this.displayQuestion(this.translate.instant('MODALS.WALLET.NEW.TITLE'), message, null, null, this.translate.instant('OK'));
      } else {
        this.wallet = Wallet.fromJSON(this.data.wallet);
      }
      this.addressText = this.translate.instant('MODALS.WALLET.ADDRESS', { type: this.wallet?.coinType });
      this.title = this.addressText;
      this.privateKeyText = this.translate.instant('MODALS.WALLET.PRIVATE_KEY', { type: this.wallet?.coinType });
      this.address = this.wallet.address;
      this.imageURL = this.wallet.getIcon();
      this.smallImageURL = this.wallet.getSmallIcon();
      if (this.wallet.mnemonic) {
        this.mnemonicWords = this.wallet.mnemonic.split(' ');
      }

      this.data.wallet = Wallet.toJSON(this.wallet);
      this.response.emit(this.data.wallet);
    } catch (err: any) {
      Console.error(err);
      const errormsg = err.message || "Unkown";
      this.errorSvc.process(new SafeError(ErrorType.UNKNOWN, errormsg));
      this.response.emit(null);
    }
  }

  private async createWallet(coinType): Promise<Wallet> {

    const currentWallets = this.sharedSvc.getWallets();
    if (currentWallets.some(wallet => wallet.coinType === coinType)) {
      throw new Error('Cointype already exists');
    }
    let wallet: Wallet;
    const isEthereum = CoinType.getAddressType(coinType) === AddressType.Ethereum;
    if (isEthereum) {
      // get the first wllet with  wallet.type === AddressType.Ethereum
      const ethWallet = currentWallets.find(wallet => wallet.type === AddressType.Ethereum);
      if (ethWallet) {
        Console.log('Using existing Ethereum wallet');
        wallet = Wallet.fromJSON(ethWallet);
        wallet.coinType = coinType;
      } else {
        Console.log('Creating new Ethereum wallet');
        wallet = await this.walletSvc.createWallet(coinType, this.testnet);
      }
    } else {
      Console.log('Creating new non etherium wallet');
      wallet = await this.walletSvc.createWallet(coinType, this.testnet);
    }
    return wallet;
  }

  next() {
    this.address = undefined;
    this.done.emit();
  }

  async print() {
    if (!await this.areyousure()) {
      return;
    }

    this.printservice.print('print-container');
    // Get the content to be printed
    // const printContainer = document.getElementById('print-container');
    // if (!printContainer) {
    //   Console.error('Print container not found');
    //   return;
    // }

    // // Create a copy of the content to modify for printing
    // const contentCopy = printContainer.cloneNode(true) as HTMLElement;

    // // If mnemonics are being displayed, ensure they're visible in the print
    // if (this.displayMode === 'mnemonics' && this.mnemonicWords) {
    //   const mnemonicContainer = contentCopy.querySelector('.mnemonic-container');
    //   if (mnemonicContainer) {
    //     mnemonicContainer.innerHTML = this.mnemonicWords.map((word, index) =>
    //       `<div class="mnemonic-word" data-index="${index + 1}">${word}</div>`
    //     ).join('');
    //   }
    // }

    // // Create an iframe for printing
    // const printFrame = document.createElement('iframe');
    // printFrame.style.position = 'absolute';
    // printFrame.style.top = '-9999px';
    // document.body.appendChild(printFrame);

    // // Write the content to the iframe
    // const frameDoc = printFrame.contentWindow?.document;
    // if (frameDoc) {
    //   frameDoc.open();
    //   frameDoc.write(`
    //     <html>
    //       <head>
    //         <title>Print</title>
    //         <style>
    //           body { font-family: Arial, sans-serif; }
    //           .mnemonic-container {
    //             display: flex;
    //             flex-flow: row wrap;
    //             justify-content: center;
    //             gap: 10px;
    //             padding: 20px;
    //             background-color: #f8f9fa;
    //             border-radius: 8px;
    //             max-width: 600px;
    //             margin: 0 auto;
    //           }
    //           .mnemonic-word {
    //             position: relative;
    //             background-color: white;
    //             border: 1px solid #dee2e6;
    //             border-radius: 4px;
    //             padding: 10px 15px 10px 25px;
    //             text-align: left;
    //             font-size: 16px;
    //             min-width: 100px;
    //           }
    //           .mnemonic-word::before {
    //             content: attr(data-index);
    //             position: absolute;
    //             top: 50%;
    //             left: 8px;
    //             transform: translateY(-50%);
    //             font-size: 12px;
    //             color: #6c757d;
    //             font-weight: bold;
    //           }
    //           .title img {
    //             display: none;
    //           }
    //           .title span {
    //             display: none;
    //           }
    //           .bitcoinimage img {
    //             display: none;
    //           }
    //           .bitcoinimage .button-container {
    //             display: none;
    //           }
    //           .tab-buttons {
    //             display: none;
    //           }
    //           .button-container {
    //             display: none;
    //           }
    //           .qrcode {
    //             display: flex;
    //             justify-content: center;
    //             padding: 20px;
    //           }
    //           .auth-title {
    //             display: flex;
    //             justify-content: center;
    //           }

    //         </style>
    //       </head>
    //       <body onload="window.print();">
    //         ${contentCopy.innerHTML}
    //       </body>
    //     </html>
    //   `);
    //   frameDoc.close();
    //}

    // Remove the iframe after printing
    // printFrame.onload = () => {
    //   setTimeout(() => {
    //     document.body.removeChild(printFrame);
    //   }, 100);
    // };
  }

  private async areyousure(): Promise<boolean> {
    if (!this.showPrivateKey) {
      return true;
    }
    //privaste key is being displayed
    const result = await this.displayQuestion(this.translate.instant('MODALS.WALLET.WARNING.TITLE'), this.translate.instant('MODALS.WALLET.WARNING.MSG'), null, this.translate.instant('OK'), this.translate.instant('CANCEL'));
    return result === 'ONE';
  }

  async toggle() {
    if (!this.showPrivateKey) {
      const result = await this.displayQuestion(this.translate.instant('MODALS.WALLET.SHOW_PRIVATE.TITLE'), this.translate.instant('MODALS.WALLET.SHOW_PRIVATE.MSG', { type: this.wallet?.type }), null, this.translate.instant('MODALS.WALLET.SHOW_PRIVATE.SHOW'), this.translate.instant('CANCEL'));
      if (result != 'ONE') {
        return;
      }
    } else {
      this.displayMode = 'qrcode'; // Reset to QR code view
    }
    try {
      this.showPrivateKey = !this.showPrivateKey;
      this.btnText = this.showPrivateKey ? this.showAddressText : this.showPrivateKeyText;
      this.title = this.showPrivateKey ? this.privateKeyText : this.addressText;
      if (this.showPrivateKey) this.displayQuestion(this.translate.instant('MODALS.ACCESS_ID.REAUTHZ'), '', null, null, null);
      const addr = this.showPrivateKey ? await this.walletSvc.getWalletPrivateKey(this.wallet) : this.wallet.address;
      this.address = addr;
    } catch (err: any) {
      Console.error(err);
      if (this.showPrivateKey) {
        this.showPrivateKey = false;
        this.btnText = this.showPrivateKeyText;
        this.title = this.addressText;
      }
    } finally {
      this.showQuestion = false;
    }
  }

  // ----------------------ApiKey Modal ------------------
  showApiKey = false;
  private apiKeyResolver: { resolve: Function, reject: Function } | null = null;
  public async displayApiKey(): Promise<ApiKeyPair | null> {
    return new Promise<ApiKeyPair | null>((resolve, reject) => {
      this.apiKeyResolver = { resolve, reject };
      this.showApiKey = true;
    });
  }

  // Called by modal from html
  async apiKeyReturn(coinType: ApiKeyPair | null) {
    this.showApiKey = false;
    if (this.apiKeyResolver) this.apiKeyResolver.resolve(coinType);
    this.apiKeyResolver = null;
  }

  // ----------------------CoinSelector Modal ------------------
  showCoinSelector = false;
  private selectCoinResolver: { resolve: Function, reject: Function } | null = null;
  public async displayCoinSelector(): Promise<CoinType | null> {
    return new Promise<CoinType | null>((resolve, reject) => {
      this.selectCoinResolver = { resolve, reject };
      this.showCoinSelector = true;
    });
  }

  // Called by modal from html
  async coinSelectorReturn(coinType: CoinType | null) {
    this.showCoinSelector = false;
    if (this.selectCoinResolver) this.selectCoinResolver.resolve(coinType);
    this.selectCoinResolver = null;
  }

  // ----------------------Question Modal ------------------
  showQuestion = false;
  questionData!: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null };
  private questionResolver: { resolve: Function, reject: Function } | null = null;
  /**
  returns 'ZERO' if first button is pressed
  returns 'ONE' if second button is pressed
  returns 'TWO' if third button is pressed
  */
  public async displayQuestion(title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null): Promise<string> {

    this.questionData = { title, message, btnCancelLable, btnOneLable, btnTwoLable };
    return new Promise<string>((resolve, reject) => {
      this.questionResolver = { resolve, reject };
      this.showQuestion = true;
    });
  }

  // Called by modal from html
  async questionReturn(state: State) {
    this.showQuestion = false;
    const button = state.value;
    if (this.questionResolver) this.questionResolver.resolve(button);
    this.questionResolver = null;
  }

  //-------------- Display Input --------------------------------

  showInput = false;
  inputData!: { title: string, message: string, isPasswd: boolean }
  private inputResolver: { resolve: Function, reject: Function } | null = null;
  public async displayInput(title: string, message: string, isPasswd = true): Promise<string> {
    this.inputData = { title, message, isPasswd };
    return new Promise<string>((resolve, reject) => {
      this.inputResolver = { resolve, reject };
      this.showInput = true;
    });
  }

  // Called by modal from html
  async inputReturn(state: State) {
    this.showInput = false;
    if (this.inputResolver) this.inputResolver.resolve(state.value);
    this.inputResolver = null;
  }

  //----------------------------------------------------
  async getbalance() {
    //set busy cursor while waiting for the balance
    document.body.style.cursor = 'wait';
    try {
      const bl = (await this.walletSvc.getBalance(this.wallet)).toString();
      if (bl == '0') {
        this.balance = '0';
      } else {
        this.balance = (this.wallet.convertToLargestAmount(bl)) + ' ' + this.wallet.coinType;
        if (this.wallet.type == AddressType.Ethereum && this.wallet.coinType != CoinType.ETH && this.wallet.coinType != CoinType.BNB) {
          const ebl = (await this.walletSvc.getBalance(this.wallet, true)).toString();
          this.ethereumBalance = this.wallet.convertToLargestAmount(ebl, CoinType.ETH);
        }
      }
    } finally {
      document.body.style.cursor = 'default';
    }
  }

  // ----------------------Transfer Eth Modal ------------------
  // Used for Etherium based coins
  showTransferEth = false;
  apiKeyPair: ApiKeyPair | null = null;
  async transfer() {
    if (this.wallet.type == AddressType.Ethereum && this.wallet.coinType != CoinType.ETH && this.wallet.coinType != CoinType.BNB && this.ethereumBalance == '0') {
      await this.displayQuestion(this.translate.instant('MODALS.WALLET.TRANSFER.INSUFFICIENT_FUNDS'), this.translate.instant('MODALS.WALLET.TRANSFER.MISSING_ETH', { type: this.wallet.coinType }), null, null, this.translate.instant('OK'));
      return;
    }

    this.apiKeyPair = this.walletSvc.getConfiguredApiKey(this.wallet.coinType);
    if (!this.apiKeyPair) {
      this.apiKeyPair = await this.displayApiKey();
    }
    Console.log(`APIkey: ${JSON.stringify(this.apiKeyPair)}`);
    if (!this.apiKeyPair) {
      return;
    }
    this.showTransferEth = true;
  }

  transferEthReturn(success: boolean) {
    Console.log('Transfer return:', success);
    this.showTransferEth = false;
  }
}

