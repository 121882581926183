import { NgIf } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import { FileService } from 'src/app/services/file.service';
import { Console } from 'src/app/lib/console';
import { Router } from '@angular/router';

@Component({
  selector: 'progress-modal',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, FormsModule]
})

export class ProgressComponent implements OnInit, OnDestroy {

  @ViewChild("input") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<boolean>();
  @Input() progress: EventEmitter<{ size?: number, transferred: number, stage: 'READING' | 'WRITING' | 'HEADER' | 'ENCRYPTING' | 'COMPLETED' | 'ATTACHMENT' | 'ERROR', error?: any }> | null = null;

  stage = 'READING';
  private updateTimer!: number;
  private fileSize = 0;
  private transferred = 0;
  speedbps = 0;
  private smoothProgressUpdateInterval: any;
  private chunkPercentage = 0;
  title = '';
  percent = 1;
  message = '';

  constructor(public router: Router, public translate: TranslateService, public changeDetector: ChangeDetectorRef) {
  }

  ngOnInit(): void {
    this.updateTimer = Date.now();
    if (!this.progress) {
      this.error('FileUploadComponent progress is null');
      return;
    }

    this.progress.subscribe((event) => {
      Console.log(event);
      if (event.size) {
        if (this.fileSize !== event.size) {
          this.fileSize = event.size;
          this.chunkPercentage = Math.round(FileService.CHUNK_SIZE / this.fileSize * 100); // used by the progress bar updater
        }
      }
      this.stage = event.stage;
      if (event.stage === 'ERROR') {
        this.error(event.error);
      } else if (event.stage === 'COMPLETED') {
        this.respond();
      } else if (event.transferred > 0) {
        this.updateTransfered(event);
      }

      this.title = event.stage;
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    if (this.progress) {
      this.progress.unsubscribe();
    }
  }

  private error(error: any) {
    Console.error('FileUploadComponent error', error);
    this.response.emit(false);
  }

  private async respond() {
    Console.log('FileUploadComponent respond');
    this.response.emit(true);
  }

  /**
  * Calculate transferred time and update the progress bar updater.
  * @param transferred
  */
  private updateTransfered(event: { transferred: number; stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'COMPLETED' | 'ATTACHMENT' | 'ERROR'; error?: any; }) {
    const uploaded = event.transferred - this.transferred;
    if (uploaded > 0) {
      this.transferred = event.transferred;
      const now = Date.now();
      const chunkUploadTime = now - this.updateTimer;
      this.updateTimer = now;
      this.percent = Math.max(Math.round(this.transferred / this.fileSize * 100), 0.1);
      //Update the progress bar "newPercentage amount" over the time it took to upload the last chunk instead of sitting and waiting for the next chunk to upload
      this.speedbps = uploaded / chunkUploadTime * 1000;
      Console.log(`Upload transfered: +${uploaded}B in ${chunkUploadTime}ms at ${this.speedbps}Bps`);
      this.smoothProgressUpdate(chunkUploadTime);
    }
  }

  /**
    * Smoothlyish updates the progress percentage by ASSuming the next chunk will take the same amount of time to upload as the last chunk.
    *
    * @param chunkPercent - The additional percentage to add to the current progress.
    * @param chunkUploadTime - The time it took to upload the last chunk, in milliseconds.
    */
  smoothProgressUpdate(chunkUploadTime: number) {
    Console.log('smoothProgressUpdate: chunkuploadtime', chunkUploadTime);
    clearInterval(this.smoothProgressUpdateInterval);
    const updateInterval = 500; // Update every 500 ms (half-second)
    const totalUpdates = chunkUploadTime / updateInterval;
    const incrementPerUpdate = this.chunkPercentage / totalUpdates;
    Console.log('smoothProgressUpdate: incrementPerUpdate', incrementPerUpdate);
    this.smoothProgressUpdateInterval = setInterval(() => {
      let currentPercent = this.percent;
      currentPercent += incrementPerUpdate;

      // Ensure the percentage does not exceed the target or 99%
      if (currentPercent >= 99) {
        currentPercent = 99;
        clearInterval(this.smoothProgressUpdateInterval);
      }
      this.percent = Math.max(parseFloat(currentPercent.toFixed(1)), 0.1);
      // Update your progress bar UI here
      this.changeDetector.detectChanges();
    }, updateInterval);
  }

}
