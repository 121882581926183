import { Directive, Input, ViewContainerRef, ComponentRef, OnInit } from '@angular/core';
import { HelpButtonComponent } from './help-button/helpbutton-component';

@Directive({
  selector: '[appHelp]',
  standalone: true,
})
export class HelpDirective implements OnInit{
  @Input('appHelp')
  helpCode!: string;
  private componentRef!: ComponentRef<HelpButtonComponent>;

  constructor(private viewContainerRef: ViewContainerRef) {}

  ngOnInit() {
    this.createComponent(this.helpCode);
  }

  private createComponent(helpCode: string) {
    this.viewContainerRef.clear();
    this.componentRef = this.viewContainerRef.createComponent(HelpButtonComponent);
    this.componentRef.instance.code = helpCode;
    const parentElement: Element = this.viewContainerRef.element.nativeElement.parentElement;
    parentElement.append(this.componentRef.location.nativeElement);
  }

  ngOnDestroy() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}

