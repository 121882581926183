import * as bip39 from 'bip39';
import { BIP32Factory } from 'bip32';
import * as ecc from '@bitcoinerlab/secp256k1';
import { ECPairFactory, ECPairInterface } from 'ecpair';
import * as ed25519 from '@noble/ed25519';
import { sha512 } from '@noble/hashes/sha512';
import { derivePath } from 'ed25519-hd-key';
import bs58 from 'bs58';
import { Wallet } from './wallet';
import { Console } from './console';

export enum AddressType {
  Bitcoin = 'Bitcoin',
  Ethereum = 'Ethereum',
  Cardano = 'Cardano',
  Solana = 'Solana',
  Ripple = 'Ripple',
  Litecoin = 'Litecoin',
  Dogecoin = 'Dogecoin',
 // Binance = 'Binance',
 // BitcoinORIG = 'p2pkh'
}

export enum NetworkType {
  Mainnet = 'Mainnet',
}

export enum CoinType {
  BTC = 'Bitcoin',
  ETH = 'Ethereum',
  // BNB = 'Binance Coin',
 LINK = 'Chainlink',
  ADA = 'Cardano',
  SOL = 'Solana',
  XRP = 'Ripple',
  LTC = 'Litecoin',
  DOGE = 'Dogecoin',
 USDT = 'Tether',
 USDC = 'USD Coin',
 DAI = 'Dai'
}

export const CoinTypeArray = [
  CoinType.BTC,
  CoinType.ETH,
  // CoinType.BNB,
 // CoinType.LINK,
  CoinType.ADA,
  CoinType.SOL,
  CoinType.XRP,
  CoinType.LTC,
  CoinType.DOGE,
 // CoinType.USDT,
 // CoinType.USDC,
 // CoinType.DAI
];

export namespace CoinType {
  export function getSvgPath(coin: string): string {
    const key = Object.keys(CoinType).find(key => CoinType[key as keyof typeof CoinType] === coin);
    return `assets/images/svg/coins/${key}.svg`;
  }

  export function getSmallSvgPath(coin: CoinType): string {
    const key = Object.keys(CoinType).find(key => CoinType[key as keyof typeof CoinType] === coin);
    return `assets/images/svg/coins/Coins_Gold/${key}.svg`;
  }

  export function getAddressType(coin: string): AddressType {
    switch (coin) {
      case CoinType.BTC: //https://github.com/Blockstream/esplora/blob/master/API.md#post-tx post body to https://blockstream.info/api/tx
      case CoinType.LTC:
      case CoinType.DOGE:
        return AddressType.Bitcoin;
       case CoinType.ETH:
      // case CoinType.USDT:
      // case CoinType.USDC:
      // case CoinType.DAI:
      // case CoinType.LINK:
        return AddressType.Ethereum; // Cloudflare Ethereum Gateway rpc free https://cryptodevhub.io/cloudflare-ethereum-gateway
      case CoinType.ADA:
        return AddressType.Cardano;
      case CoinType.SOL:
        return AddressType.Solana;
      case CoinType.XRP:
        return AddressType.Ripple;
      // case CoinType.BNB:
      //   return AddressType.Binance;
      default:
        throw new Error('Unsupported coin type');
    }
  }
}

export const networks = {
  Bitcoin: {
    mainnet: {
      messagePrefix: '\x18Bitcoin Signed Message:\n',
      bech32: 'bc',
      bip32: {
        public: 0x0488b21e,
        private: 0x0488ade4,
      },
      pubKeyHash: 0x00,
      scriptHash: 0x05,
      wif: 0x80,
    }
  },
  Litecoin: {
    mainnet: {
      messagePrefix: '\x19Litecoin Signed Message:\n',
      bech32: 'ltc',
      bip32: { public: 0x019da462, private: 0x019d9cfe },
      pubKeyHash: 0x30,
      scriptHash: 0x32,
      wif: 0xb0
    }
  },
  Dogecoin: {
    mainnet: {
      messagePrefix: '\x19Dogecoin Signed Message:\n',
      bech32: 'doge',
      bip32: { public: 0x02facafd, private: 0x02fac398 },
      pubKeyHash: 0x1e,
      scriptHash: 0x16,
      wif: 0x9e
    }
  },
  binance: {
    mainnet: {
      messagePrefix: '\x19Binance Signed Message:\n',
      bech32: 'bnb',
      bip32: { public: 0x0488b21e, private: 0x0488ade4 },
      pubKeyHash: 0x1e,
      scriptHash: 0x16,
      wif: 0x80
    }
  },
  ethereum: {
    mainnet: {
      messagePrefix: '\x19Ethereum Signed Message:\n',
      bech32: 'eth',
      bip32: { public: 0x0488b21e, private: 0x0488ade4 },
      pubKeyHash: 0x1e,
      scriptHash: 0x16,
      wif: 0x80
    }
  }
};

const bip32 = BIP32Factory(ecc);
const ECPair = ECPairFactory(ecc);
ed25519.etc.sha512Sync = (...m) => sha512(ed25519.etc.concatBytes(...m));
export class PaperWallet {
  private mnemonic!: string; // space-separated words
  private seed!: Buffer;

  private constructor(public coinType: CoinType) {
  }

  public static async create(coinType: CoinType): Promise<{ wallet: Wallet, privateKey: string, mnemonic: string }> {
    const paperWallet = new PaperWallet(coinType);
    await paperWallet.generateMnemonic();
    return paperWallet.generateWallet();
  }

  public async generateMnemonic(): Promise<void> {
    this.mnemonic = bip39.generateMnemonic(256); // 24 words
    this.seed = await bip39.mnemonicToSeed(this.mnemonic);
  }

  private async generateWallet(): Promise<{ wallet: Wallet, privateKey: string, mnemonic: string }> {
    const network =  NetworkType.Mainnet;
    const type = CoinType.getAddressType(this.coinType);
    let address: string;
    let privateKey: string;

    switch (this.coinType) {
      case CoinType.BTC:
        ({ address, privateKey } = await this.generateBitcoinLikeWallet('Bitcoin'));
        break;
      case CoinType.LTC:
        ({ address, privateKey } = await this.generateBitcoinLikeWallet('Litecoin'));
        break;
      case CoinType.DOGE:
        ({ address, privateKey } = await this.generateBitcoinLikeWallet('Dogecoin'));
        break;
      case CoinType.ETH:
    //  case CoinType.BNB:
      // case CoinType.LINK:
      // case CoinType.USDT:
      // case CoinType.USDC:
      // case CoinType.DAI:
        ({ address, privateKey } = await this.generateEthereumBasedWallet());
        break;
      case CoinType.SOL:
        ({ address, privateKey } = await this.generateSolanaWallet());
        break;
      case CoinType.ADA:
        ({ address, privateKey } = await this.generateCardanoWallet());
        break
      case CoinType.XRP:
        ({ address, privateKey } = await this.generateRippleWallet());
        break;
      default:
        throw new Error('Unsupported coin type');
    }
    const wallet = new Wallet(address, type, network, this.coinType);
    await this.validateAddress(wallet, privateKey);
    return { wallet, privateKey, mnemonic: this.mnemonic };
  }

  ////////////////////////////// Cardano Wallet //////////////////////////////

  private readonly HARDENED_OFFSET = 0x80000000; // Hardened derivation
  private readonly PURPOSE = 1852;   // CIP-1852
  private readonly COIN_TYPE = 1815; // Cardano coin type
  private readonly ACCOUNT_INDEX = 0;
  private readonly NETWORK_ID = 0x01; // Mainnet

  /**
   * Generate Cardano Shelley base address and root private key
   */
  async generateCardanoWallet(): Promise<{ address: string; privateKey: string }> {
    const cardanoCrypto = await import('cardano-crypto.js');
    const DERIVATION_SCHEME = 2;
    // Step 1: Derive root keypair from mnemonic
    const rootKeypair = await cardanoCrypto.mnemonicToRootKeypair(this.mnemonic, DERIVATION_SCHEME);

    // Step 2: Derive account-level private key => m/1852'/1815'/0'
    const accountKey = cardanoCrypto.derivePrivate(
      rootKeypair,
      this.HARDENED_OFFSET + this.PURPOSE,
      DERIVATION_SCHEME
    );

    const coinKey = cardanoCrypto.derivePrivate(
      accountKey,
      this.HARDENED_OFFSET + this.COIN_TYPE,
      DERIVATION_SCHEME
    );

    const accountIndexKey = cardanoCrypto.derivePrivate(
      coinKey,
      this.HARDENED_OFFSET + this.ACCOUNT_INDEX,
      DERIVATION_SCHEME
    );

    // Step 3: Derive payment and staking keys
    // Derive the payment key => m/1852'/1815'/0'/0/0
    const paymentKey = cardanoCrypto.derivePrivate(
      cardanoCrypto.derivePrivate(accountIndexKey, 0, DERIVATION_SCHEME), // m/1852'/1815'/0'/0
      0, // Address index
      DERIVATION_SCHEME
    ); // m/1852'/1815'/0'/0/0

    // Derive the staking key => m/1852'/1815'/0'/2/0
    const stakeKey = cardanoCrypto.derivePrivate(
      cardanoCrypto.derivePrivate(accountIndexKey, 2, DERIVATION_SCHEME), // m/1852'/1815'/0'/2
      0, // Final index
      DERIVATION_SCHEME
    ); // m/1852'/1815'/0'/2/0


    // Step 4: Convert private keys to public keys
    const paymentKeySlice = paymentKey.slice(0, 64); // Extract 64 bytes for toPublic()
    const paymentPubKey = cardanoCrypto.toPublic(paymentKeySlice);

    const stakeKeySlice = stakeKey.slice(0, 64); // Extract 64 bytes for toPublic()
    const stakePubKey = cardanoCrypto.toPublic(stakeKeySlice);

    // Step 5: Hash the public keys using Blake2b-224
    const paymentKeyHash = cardanoCrypto.getPubKeyBlake2b224Hash(paymentPubKey);
    const stakeKeyHash = cardanoCrypto.getPubKeyBlake2b224Hash(stakePubKey);

    // Step 6: Pack the base address
    const addressBuffer = cardanoCrypto.packBaseAddress(
      paymentKeyHash,
      stakeKeyHash,
      this.NETWORK_ID
    );

    // Step 7: Bech32-encode the address
    const address = cardanoCrypto.bech32.encode('addr', addressBuffer);

    // Step 8: Return the address and root private key
    return {
      address,
      privateKey: rootKeypair.toString('hex'), // Hexadecimal representation of root private key
    };
  }

  /////////////////////////////////////////Ripple Wallet//////////////////////////////////////

  private async generateRippleWallet(): Promise<{ address: string; privateKey: string }> {
    const { Wallet } = await import('xrpl');

    // Create wallet from seed using xrpl.js
    const wallet = Wallet.fromMnemonic(this.mnemonic);

    // Assign the address to display
    const address = wallet.classicAddress;
    const privatekey = wallet.privateKey;
    return {
      address,                     // Ripple wallet address
      privateKey: privatekey, // Private key in Base58 format
    };
  }

  ///////////////////////////////////////Solana Wallet//////////////////////////////////////

  private async generateSolanaWallet(): Promise<{ address: string; privateKey: string }> {
    const { Keypair } = await import('@solana/web3.js');

    // Solana's BIP44 derivation path
    const derivationPath = `m/44'/501'/0'`;

    // Derive the keypair seed using the derivation path
    const { key: derivedSeed } = derivePath(derivationPath, this.seed.toString('hex'));

    // Create the Solana keypair
    const keypair = Keypair.fromSeed(derivedSeed);

    // Encode public and private keys
    const address = keypair.publicKey.toBase58();
    const privateKey = bs58.encode(keypair.secretKey);

    return {
      address,
      privateKey,
    };
  }
  ///////////////////////////////////////////////////////////////////////////////////////


  private getCoinTypeIndex(coin: string): number {
    switch (coin) {
      case 'Bitcoin':
        return  0;
      case 'Litecoin':
        return 2;
      case 'Dogecoin':
        return 3;
      default:
        throw new Error('Unsupported coin type');
    }
  }

  private async generateBitcoinLikeWallet(coin: 'Bitcoin' | 'Litecoin' | 'Dogecoin'): Promise<{ address: string; privateKey: string; }> {
    await this.loadBitcoinLib();
    const networkConfig = networks[coin];
    const network =  networkConfig.mainnet;
    const coinTypeIndex = this.getCoinTypeIndex(coin);

    // Dogecoin typically uses BIP44 (44') instead of BIP84 (84'), especially for legacy addresses.
    const path = coin === 'Dogecoin'
      ? `m/44'/${coinTypeIndex}'/0'/0/0`
      : `m/84'/${coinTypeIndex}'/0'/0/0`;

    const keyPair = this.deriveBitcoinKeyPair(path, network);

    // Use p2pkh for Dogecoin to generate a legacy address.
    const payment = coin === 'Dogecoin'
      ? this.bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network })
      : this.bitcoin.payments.p2wpkh({ pubkey: keyPair.publicKey, network });

    return { address: payment.address!, privateKey: keyPair.toWIF() };
  }

  private deriveBitcoinKeyPair(path: string, network: any): ECPairInterface {
    const root = bip32.fromSeed(this.seed, network);
    const child = root.derivePath(path);

    if (!child.privateKey) {
      throw new Error('No private key found');
    }

    return ECPair.fromPrivateKey(child.privateKey, { network });
  }

  private async generateEthereumBasedWallet(): Promise<{ address: string; privateKey: string; }> {
    const ethers = await this.getEtherLib();
    const wallet = ethers.Wallet.fromPhrase(this.mnemonic);
    return { address: wallet.address, privateKey: wallet.privateKey };
  }

  private async validateAddress(wallet: Wallet, privateKey): Promise<void> {

    switch (this.coinType) {
      case CoinType.ETH:
     // case CoinType.BNB:
      // case CoinType.LINK:
      // case CoinType.USDT:
      // case CoinType.USDC:
      // case CoinType.DAI:
        return this.validateEthereumWallet(wallet, privateKey);
      case CoinType.DOGE:
        return this.validateDogecoinWallet(wallet, privateKey);
      case CoinType.LTC:
      case CoinType.BTC:
        return this.validateBitcoinWallet(wallet, privateKey);
      case CoinType.SOL:
        return this.validateSolWallet(wallet, privateKey);
      case CoinType.ADA:
        return this.validateCardanoWallet(wallet, privateKey);
      case CoinType.XRP:
        return this.validateRippleWallet(wallet, privateKey);
      default:
        throw new Error('Unsupported coin type');
    }
  }

  private async validateCardanoWallet(wallet: Wallet, privateKey: string): Promise<void> {
    return
  }

  private async validateRippleWallet(wallet: Wallet, privateKey: string): Promise<void> {
    //ToDo implement
    return;
  }

  private async validateSolWallet(wallet: Wallet, privateKey): Promise<void> {
    const { Keypair } = await import('@solana/web3.js');
    const bsprivateKey = bs58.decode(privateKey);
    const keypair = Keypair.fromSecretKey(bsprivateKey);

    if (wallet.address !== keypair.publicKey.toBase58()) {
      throw new Error('Invalid address: public key mismatch');
    }
  }

  private async validateEthereumWallet(wallet: Wallet, privateKey): Promise<void> {
    const ethers = (window as any).ethers;
    const ewallet = new ethers.Wallet(privateKey);
    if (wallet.address != ewallet.address) {
      throw new Error('Invalid address');
    }
  }

  private async validateBitcoinWallet(wallet: Wallet, privateKey) {
    const networkConfig = networks[wallet.coinType];
    const network = networkConfig.mainnet;

    const keyPair = ECPair.fromWIF(await privateKey, network);
    const payment = this.bitcoin.payments.p2wpkh({ pubkey: keyPair.publicKey, network });
    const derivedAddress = payment.address;

    if (wallet.address !== derivedAddress) {
      throw new Error('Invalid address');
    }
  }

  private async validateDogecoinWallet(wallet: Wallet, privateKey) {
    const networkConfig = networks[wallet.coinType];
    const network = networkConfig.mainnet;

    // Derive key pair from the wallet's WIF private key
    const keyPair = ECPair.fromWIF(await privateKey, network);

    // Generate a P2PKH address for Dogecoin
    const payment = this.bitcoin.payments.p2pkh({ pubkey: keyPair.publicKey, network });
    const derivedAddress = payment.address;

    // Check if the derived address matches the provided address
    if (wallet.address !== derivedAddress) {
      throw new Error('Invalid address');
    }
  }

  public getMnemonic(): string {
    return this.mnemonic;
  }

  private bitcoin: any;
  private async loadBitcoinLib(): Promise<any> {
    if (!this.bitcoin) {
      this.bitcoin = await import('bitcoinjs-lib');
    }
    return this.bitcoin;
  }

  private async getEtherLib(): Promise<any> {
    if (!(window as any).ethers) {
      (window as any).ethers = await import('ethers');
    }
    return (window as any).ethers;
  }
}
