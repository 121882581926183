import { Component, OnDestroy } from '@angular/core';
import { QuestionModalComponent } from './modals/question-modal/question-modal.component';
import { MessageModalComponent } from './modals/message-modal/message-modal.component';
import { KeypadModalComponent } from './modals/keypad-modal/keypad-modal.component';
import { State } from './modals/state';
import { ApiService } from 'src/app/services/api.service';
import { InputModalComponent } from './modals/input-modal/input-modal.component';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgIf } from '@angular/common';
import { NumberModalComponent } from './modals/number-modal/number-modal.component';
import { NotificationModalComponent } from './modals/notification-modal/notification-modal.component';
import { AccessidModalComponent } from './modals/access-id/access-id.component';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { FileUploadComponent } from './modals/file-upload/file-upload.component';
import { Archive } from 'src/app/interfaces/archive';
import { PinWarningComponent } from "./modals/pin-warning/pin-warning.component";
import { Console } from 'src/app/lib/console';
import { ListSelectModalComponent, Item } from "./modals/list-select-modal/list-select-modal.component";
import { Wallet } from 'src/app/lib/wallet';
import { WalletModalComponent } from './modals/wallet/wallet.component';
import { AffiliateLinkModalComponent } from "./modals/affiliate-link/affiliate-link-modal.component";
import { ErrorService } from 'src/app/services/error.service';
import { SpaceSelectorComponent } from "./modals/space-selector/space-selector.component";
@Component({
  selector: 'app-modals',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  standalone: true,
  imports: [QuestionModalComponent,
    MessageModalComponent,
    KeypadModalComponent,
    InputModalComponent,
    SpinnerComponent,
    NumberModalComponent,
    AccessidModalComponent,
    NotificationModalComponent,
    FileUploadComponent,
    NgIf, PinWarningComponent,
    ListSelectModalComponent,
    WalletModalComponent, AffiliateLinkModalComponent, SpaceSelectorComponent]
})
export class ModalComponent implements OnDestroy {
  private errorSvcsubscription: any;
  constructor(private apiSvc: ApiService, private errorSvc: ErrorService) {
    this.errorSvcsubscription = this.errorSvc.errorEmiter.subscribe((error) => { this.processError(error) });
  }

  ngOnDestroy(): void {
    this.errorSvcsubscription.unsubscribe();
  }
  /**
   *
   * @param error We hide spinner on error
   */
  private processError(error: any) {
    this.displaySpinner(false);
  }

  spinnerMessage = '';
  showSpinner = false;
  /**
   * Show or hide spinner
   * Spinner will be automatically hidden when any other modal is displayed or error is shown by ErrorService
   * @param show  true to show spinner, false to hide spinner
   * @param message optional message to display with spinner
   */
  public displaySpinner(show: boolean, message?: string) {
    if (show) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'default';
    }

    this.showSpinner = show;
    if (message) {
      this.spinnerMessage = message;
    } else {
      this.spinnerMessage = '';
    }
  }

  //------- Key Pad ---------------------------------------

  showPad = false;
  padData!: { title: string, padImage: string, codeImage: string | null };
  private padResolver: { resolve: Function, reject: Function } | null = null;
  public async displayPad(title: string, padImage?: string, codeImage?: string): Promise<{ x: number, y: number }[] | null> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    if (!padImage) {
      padImage = await this.getPadImage();
    }
    this.padData = { title: title, padImage: padImage, codeImage: codeImage ? codeImage : null };
    return new Promise<{ x: number, y: number }[]>((resolve, reject) => {
      this.padResolver = { resolve, reject };
      this.showPad = true;
    });
  }

  private async getPadImage(): Promise<string> {
    this.showSpinner = true;
    const callBack = await this.apiSvc.getSafeAuthzConfig();
    this.showSpinner = false;
    return callBack.action.result;
  }

  // Called by modal from html
  async padResponse(state: State) {
    this.showPad = false;
    const clicks: { x: number, y: number }[] = state.value;
    if (this.padResolver) {
      this.padResolver.resolve(clicks);
      this.padResolver = null;
    } else {
      throw new Error('No pad resolver');
    }
  }

  //-------- Display Number --------------------------------------

  showNumber = false;
  numberData!: { title: string, message: string, label: string, def: number, min?: number, max?: number, step?: number };
  private numberResolver: { resolve: Function, reject: Function } | null = null;
  /**
   *
   * @param title
   * @param message
   * @param label
   * @param def default value
   * @param min
   * @param max
   * @param step
   * @returns value or null if cancelled
   */
  public async displayNumber(title: string, message: string, label: string, def: number, min?: number, max?: number, step?: number): Promise<number | null> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.numberData = { title, message, label, def, min, max, step };
    return new Promise<number | null>((resolve, reject) => {
      this.numberResolver = { resolve, reject };
      this.showNumber = true;
    });
  }

  // Called by modal from html
  async numberReturn(state: State) {
    this.showNumber = false;
    if (this.numberResolver) { this.numberResolver.resolve(state.value); }
    this.numberResolver = null;
  }

  //----------- Display Message -----------------------------------

  showMessage = false;
  messageData!: { title: string, message: string };
  private messageResolver: { resolve: Function, reject: Function } | null = null;
  public async displayMessage(title: string, message: string): Promise<void> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.messageData = { title, message };
    return new Promise<void>((resolve, reject) => {
      this.messageResolver = { resolve, reject };
      this.showMessage = true;
    });
  }

  // Called by modal from html
  async messageReturn() {
    this.showMessage = false;
    if (this.messageResolver) this.messageResolver.resolve();
    this.messageResolver = null;
  }


  //--------------- Display Question -------------------------------

  showQuestion = false;
  questionData!: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null };
  private questionResolver: { resolve: Function, reject: Function } | null = null;
  /**
  returns 'ZERO' if first button is pressed
  returns 'ONE' if second button is pressed
  returns 'TWO' if third button is pressed
  */
  public async displayQuestion(title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null): Promise<string> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.questionData = { title, message, btnCancelLable, btnOneLable, btnTwoLable };
    return new Promise<string>((resolve, reject) => {
      this.questionResolver = { resolve, reject };
      this.showQuestion = true;
    });
  }

  // Called by modal from html
  async questionReturn(state: State) {
    this.showQuestion = false;
    const button = state.value;
    if (this.questionResolver) this.questionResolver.resolve(button);
    this.questionResolver = null;
  }

  //-------------- Display Input --------------------------------

  showInput = false;
  inputData!: { title: string, message: string, isPasswd: boolean }
  private inputResolver: { resolve: Function, reject: Function } | null = null;
  public async displayInput(title: string, message: string, isPasswd = true): Promise<string> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.inputData = { title, message, isPasswd };
    return new Promise<string>((resolve, reject) => {
      this.inputResolver = { resolve, reject };
      this.showInput = true;
    });
  }

  // Called by modal from html
  async inputReturn(state: State) {
    this.showInput = false;
    if (this.inputResolver) this.inputResolver.resolve(state.value);
    this.inputResolver = null;
  }

  //----------- Display Notification -----------------------------------

  showNotification = false;
  notificationData!: { title: string, message: string };
  private notificationResolver: { resolve: Function, reject: Function } | null = null;
  public async displayNotification(title: string, message: string): Promise<string | null> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.notificationData = { title, message };
    return new Promise<string | null>((resolve, reject) => {
      this.notificationResolver = { resolve, reject };
      this.showNotification = true;
    });
  }

  // Called by modal from html
  async notificationReturn(state: State) {
    this.showNotification = false;
    if (this.notificationResolver) this.notificationResolver.resolve(state.value);
    this.notificationResolver = null;
  }

  // AccessID ------------ Display AccessID ----------------------------------

  showAccessID = false;
  accessIDData!: { title: string, action: 'access' | 'register', passphrase: string, access: SafeAccess, safeName?: string };
  private accessIDResolver: { resolve: Function, reject: Function } | null = null;
  public async displayAccessID(title: string, action: 'access' | 'register', passphrase: string, access: SafeAccess, safeName?: string): Promise<boolean> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.accessIDData = { title, action, passphrase, access, safeName };
    return new Promise<boolean>((resolve, reject) => {
      this.accessIDResolver = { resolve, reject };
      this.showAccessID = true;
    });
  }

  // Called by modal from html
  async accessIDReturn(result: boolean) {
    this.showAccessID = false;
    if (this.accessIDResolver) {
      this.accessIDResolver.resolve(result);
    }
    this.accessIDResolver = null;
  }

  // File upload --------------- Display File Upload -------------------------------
  showFileUploader = false;
  file: File | null = null;
  private fileUploaderResolver: { resolve: Function, reject: Function } | null = null;
  public async displayFileUploader(file: File | null = null): Promise<Archive> {
    this.file = file;
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    return new Promise<Archive>((resolve, reject) => {
      this.fileUploaderResolver = { resolve, reject };
      this.showFileUploader = true;
    });
  }

  // Called by modal from html
  async fileUploaderReturn(result: Archive | null) {
    Console.log('fileUploaderReturn', result);
    this.showFileUploader = false;
    if (this.fileUploaderResolver) this.fileUploaderResolver.resolve(result);
    this.fileUploaderResolver = null;
  }

  //------------ Display PIN Warning ----------------------------------
  private pinWarningResolver: { resolve: Function, reject: Function } | null = null;
  showPinWarning = false;
  public async displayPinWarning(): Promise<void> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    return new Promise<void>((resolve, reject) => {
      this.pinWarningResolver = { resolve, reject };
      this.showPinWarning = true;
    });
  }
  // Called by modal from html
  async pinWarningReturn() {
    this.showPinWarning = false;
    if (this.pinWarningResolver) this.pinWarningResolver.resolve();
    this.pinWarningResolver = null;
  }

  //------------- Display List Select --------------------------------
  showListSelect = false;
  listSelectData!: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, items: { label: string, object: any }[] };
  private listSelectResolver: { resolve: Function, reject: Function } | null = null;
  public async displayListSelect(title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, items: { label: string, object: any }[]): Promise<Item | null> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    this.listSelectData = { title, message, btnCancelLable, btnOneLable, items };
    return new Promise<Item | null>((resolve, reject) => {
      this.listSelectResolver = { resolve, reject };
      this.showListSelect = true;
    });
  }
  listSelectReturn(item: Item | null) {
    this.showListSelect = false;
    if (this.listSelectResolver) this.listSelectResolver.resolve(item);
    this.listSelectResolver = null;
  }

  // Wallet ------------ Display Wallet ----------------------------------

  showWallet = false;
  walletData!: { wallet: Wallet | null };
  private walletResolver: { resolve: Function, reject: Function } | null = null;
  public async displayWallet(wallet: Wallet | null): Promise<Wallet | null> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }
    if (this.apiSvc.getAppConfig().tier == 0 || this.apiSvc.getAppConfig().tier == 4) {
      return null
    }

    this.walletData = { wallet };
    return new Promise<Wallet | null>((resolve, reject) => {
      this.walletResolver = { resolve, reject };
      this.showWallet = true;
    });
  }

  // Called by modal from html
  async walletReturn(wallet: Wallet | null) {
    this.walletData = { wallet: wallet };
    Console.log('walletReturn', wallet);
    if (this.walletResolver) {
      if (wallet) {
        this.walletResolver.resolve(wallet);
      } else {
        this.showWallet = false;
        this.walletResolver.reject(null);
      }
      this.walletResolver = null;
    }
  }

  async walletDone() {
    this.showWallet = false;
  }

  //-------------- Display affiliate-link --------------------------------

  showAffiliateLink = false;

  private affiliateLinkResolver: { resolve: Function, reject: Function } | null = null;
  public async displayAffiliateLink(): Promise<string> {
    if (this.showSpinner) {
      this.displaySpinner(false);
    }

    return new Promise<string>((resolve, reject) => {
      this.affiliateLinkResolver = { resolve, reject };
      this.showAffiliateLink = true;
    });
  }

  // Called by modal from html
  async affiliateLinkReturn(link: String | null) {
    this.showAffiliateLink = false;
    if (this.affiliateLinkResolver) this.affiliateLinkResolver.resolve(link);
    this.affiliateLinkResolver = null;
  }

   //-------------- Display Space selector --------------------------------

   showSpaceSelector = false;
   limitSpaces = false;
   currentSpaces: number[] = [];
   spaceMessage: { title: string, message: string } | null = null;

   private spaceSelectorResolver: { resolve: Function, reject: Function } | null = null;
   public async displaySpaceSelector(currentSpaces:number[] = [], message:{title:string; message:string}, limit=false): Promise<number[] | null> {
    this.currentSpaces = currentSpaces;
    this.limitSpaces = limit;
    this.spaceMessage = message;
     if (this.showSpinner) {
       this.displaySpinner(false);
     }

     return new Promise<number[] | null>((resolve, reject) => {
       this.spaceSelectorResolver = { resolve, reject };
       this.showSpaceSelector = true;
     });
   }

   // Called by modal from html
   async spaceSelectorReturn(link: number[] | null) {
    this.currentSpaces.length = 0;
    this.spaceMessage = null;
     this.showSpaceSelector = false;
     if (this.spaceSelectorResolver) this.spaceSelectorResolver.resolve(link);
     this.spaceSelectorResolver = null;
   }
}
