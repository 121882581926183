import { Console } from "./console";

/**
 * A TransformStream that transforms input chunks to requested size output chunks.
 * If there are more chunks than requested chink sizes, loops through the array of chunk sizes.
 * Providing a single chunk size produces fized size output with the exception of the last chunk
 */
class ChunkerTransformer implements Transformer<Uint8Array, Uint8Array> {
  private remainder: Uint8Array = new Uint8Array(0);
  private currentIndex: number = 0;
  public processdBytes: number = 0;
  public processedChunks: number = 0;
  public largestChunk = 0;
  private canceled = false;

  /**
   * @param chunkSizes - The desired size of the output chunks in bytes.
   */
  constructor(private chunkSizes: number[], private transactionID: number) {
    this.largestChunk = Math.max(...chunkSizes);
  }

  transform(chunk: Uint8Array, controller: TransformStreamDefaultController) {
    this.processdBytes += chunk.byteLength;
    let offset = 0;

    // Process existing remainder first
    if (this.remainder.byteLength > 0) {
      const needed = this.chunkSizes[this.currentIndex] - this.remainder.byteLength;
      if (chunk.byteLength >= needed) {
        // Form a full chunk from remainder and part of the new chunk
        const totalChunk = new Uint8Array(this.chunkSizes[this.currentIndex]);
        totalChunk.set(this.remainder);
        totalChunk.set(chunk.subarray(0, needed), this.remainder.byteLength);
        this.enqueueChunk(totalChunk, controller);

        offset = needed;
        this.remainder = new Uint8Array(0); // Reset remainder
      } else {
        // Not enough in the new chunk to complete the current chunk
        const newRemainder = new Uint8Array(this.remainder.byteLength + chunk.byteLength);
        newRemainder.set(this.remainder);
        newRemainder.set(chunk, this.remainder.byteLength);
        this.remainder = newRemainder;
        return; // Nothing more to process
      }
    }

    // Process new chunk
    while (chunk.byteLength - offset >= this.chunkSizes[this.currentIndex]) {
      if(this.canceled) {
        return;
      }
      const end = offset + this.chunkSizes[this.currentIndex];
      const totalChunk = chunk.subarray(offset, end);
      this.enqueueChunk(totalChunk, controller);

      offset = end;
    }

    // Store any remaining part of the chunk as the new remainder
    this.remainder = chunk.subarray(offset);
  }

  enqueueChunk(chunk: Uint8Array, controller: TransformStreamDefaultController) {
    try {
      controller.enqueue(chunk);
      this.processedChunks++;
      this.currentIndex = (this.currentIndex + 1) % this.chunkSizes.length;
    } catch (e) {
      Console.error(`ChunkerTransformer enqueuing error: tx:${this.transactionID}, part#:${this.processedChunks}`, e);
      return;
    }
  }


  // transform(chunk: Uint8Array, controller: TransformStreamDefaultController) {
  //   this.processdBytes += chunk.byteLength;
  //   let offset = 0;
  //   const totalLength = this.remainder.byteLength + chunk.byteLength;
  //   // Create a new Uint8Array from the remainder and the new chunk
  //   const mergedChunk = new Uint8Array(totalLength);
  //   mergedChunk.set(this.remainder);
  //   mergedChunk.set(chunk, this.remainder.byteLength);

  //   while (totalLength - offset >= this.chunkSizes[this.currentIndex]) {
  //     if(this.canceled) {
  //       return;
  //     }
  //     try {
  //       const totalChunk = mergedChunk.subarray(offset, offset + this.chunkSizes[this.currentIndex]);
  //       controller.enqueue(totalChunk);
  //       this.processedChunks++;
  //       offset += this.chunkSizes[this.currentIndex];
  //       this.currentIndex = (this.currentIndex + 1) % this.chunkSizes.length;
  //     } catch (e) {
  //       Console.error(`ChunkerTransformer enqueuing error: tx:${this.transactionID}, part#:${this.processedChunks}`, e);
  //       return;
  //     }
  //   }

  //   this.remainder = mergedChunk.subarray(offset);
  // }
  cancel() {
    this.canceled = true;
    Console.error(`ChunkerTransformer cancel: tx:${this.transactionID}`);
  }

  /**
   * Flushes any remaining bytes when the stream is closed.
   *
   * @param controller - The TransformStreamDefaultController used to enqueue any remaining bytes.
   */
  flush(controller: TransformStreamDefaultController) {
    if (this.remainder.byteLength > 0) {
      try {
        controller.enqueue(this.remainder);
        this.processedChunks++;
      } catch (e) {
        Console.error(`ChunkerTransformer flushing error: tx:${this.transactionID}, part#:${this.processedChunks}`, e);
      }
    }
  }
}

/**
 * Creates and returns a TransformStream configured to transform input chunks to requested size output chunks
 *
 * @param chunkSizes - The desired sizes of the output chunks.
 * Loops through the array of chunk sizes, using each size in turn until the input is exhausted.
 * @returns - A TransformStream configured to transform input chunks to fixed size output chunks.
 */
function chunkerTransformer(chunkSizes: number[], transactionID: number): { chunkerTransformStream: TransformStream<Uint8Array, Uint8Array>, chunker: ChunkerTransformer } {
  const chunker = new ChunkerTransformer(chunkSizes, transactionID);
  const chunkerTransformStream = new TransformStream<Uint8Array, Uint8Array>(chunker, new CountQueuingStrategy({ highWaterMark: 1 }), new CountQueuingStrategy({ highWaterMark: 1 }));
  return { chunkerTransformStream, chunker };
}

export { chunkerTransformer };
