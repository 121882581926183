// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  envVar: {
      DOMAIN_NAME: "mike.dev.1two.be",
      VAPID_PUBLIC: "BOAYs9cbBwED5pt0LplE6vJaMET4dfBhf-Mi8FOBj79I3294iUORebZtxwEo7okrUA6Ft_nqqgs4FOutcZsrJOI",
      serverSigningValidationKey: "{\"key_ops\":[\"verify\"],\"ext\":true,\"kty\":\"EC\",\"x\":\"R3gKjICh5JcrRCzlciT_onAU0m7YlsyJLI521DwhnjzfGzwxQdnAijzhIT_tHObL\",\"y\":\"CgZeaKVKcn47u7DPMhI67FgUSoQ53SdTey8dazfgkNj8VNM0KWBfJMGArp0wTl7g\",\"crv\":\"P-384\"}"
},
  apiURL: '',
  paymentURL: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
