<app-modals></app-modals>
<div *ngIf="showEditor">
  <div class="guard-editor">
    <div class="section">
      <h4>{{'GUARD.LOCKOUT_GUARD' | translate}}</h4>
      <div class="value-entry">
        <label for="interval">{{ guardAccess.pushToken ? ('GUARD.DAYS' | translate:{DAYS: guardAccess.interval}): '' }}
        </label>
        <input id="interval" [(ngModel)]="guardAccess.interval" (ngModelChange)="onChanged()" type="range" min="1"
          [max]="intervalMax">
      </div>

      <div *ngIf="guardAccess.pushToken" class="value-entry">
        <label for="failures">{{ 'GUARD.ENABLE_DAYS' | translate:{DAYS: guardAccess.failures} }} </label>
        <input id="failures" [(ngModel)]="guardAccess.failures" (ngModelChange)="onChanged()" type="range" min="1"
          [max]="failuresMax">
      </div>
      <p>{{'GUARD.TOTAL_DAYS' | translate:{triggerInDays: guardAccess.interval!+ guardAccess.failures!} }}</p>

      <div class="button-container center">
        <button class="btn" (click)="generateQR()">{{'GUARD.GEN' | translate}}</button>
        <button *ngIf="!guardAccess.pushToken" class="btn" (click)="configureNotifications(true)">
          {{'GUARD.NOTIFICATION.TITLE' | translate}}</button>

      </div>
    </div>
    <div *ngIf="authzSvc.appConfig.tier > 0 " class="section legacylink-container">
      <h4>{{'GUARD.LEGACY_LINK' | translate}}</h4>
      <div *ngIf="!guardAccess.legacyId || !legacyAccess" class="button-container center">
        <button class="btn" (click)="configureLegacyLink()">{{'GUARD.CFG_LEGACY'| translate}}</button>
      </div>
      <app-legacylink-editor #legacylinkEditor [legacyAccess]="legacyAccess" [guardAccess]="guardAccess"
        [legacyDelayMax]="legacyDelayMax" (onChange)="onLegacyChanged($event)"></app-legacylink-editor>
    </div>
    <div *ngIf="!authzSvc.appConfig.sub" class="section">
      <p>{{'GUARD.EXPIERS_IN' | translate:{safeExpiresInDays} }}</p>
      <div class="button-container center">
        <button class="btn" (click)="extendSafe()">{{'APP_CONFIG.EXTEND' | translate }}</button>
      </div>
    </div>
    <div class="button-container">
      <button class="btn" (click)="delete(true)">{{'DELETE' | translate }}</button>
      <button *ngIf="changed" class="btn" (click)="onCancel()">{{'CANCEL' | translate }}</button>
      <button *ngIf="!changed"  class="btn-primary" (click)="onCancel()">{{'CLOSE' | translate }}</button>
      <button *ngIf="changed" class="btn-primary" (click)="onSubmit()">{{'SAVE' | translate }}</button>
    </div>
  </div>
</div>
