<div class="inbox-container">

  <div class="eyes-config-container">
    <button class="eyes-config-btn" (click)="openConfig()">
      <img src="../../../../assets/images/svg/settings.svg" alt="settings">
    </button>
  </div>
  <header class="inbox-header">
    <p class="user-address" appCopyText [copyText]="userAddress">Your Address: {{ userAddress }}</p>
    <div class="button-container center">
      <button class="btn-primary" (click)="onCompose()">Compose</button>
      <div class="msg-input">
        <label for="file" id="fileInputLabel" class="btn-primary">
          Open File
        </label>
        <input id="file" type="file" (change)="onOpenLocalFile($event)">
      </div>
    </div>
  </header>

  <div *ngIf="newmessages.length>0" class="message-list">
    <hr>
    <h3>New</h3>
    <ul>
      <li *ngFor="let message of newmessages" (click)="acceptMessage(message)">
        <div class="message-info">
          <div class="txt">
            <span class="message-sender">{{ message.sender }}</span>
            <span class="message-subject">{{ message.subject}}</span>
            <span *ngIf="message.size" class="message-size">{{ message.size | fileSize }}</span>
            <span class="message-date">{{ message.date | date: 'short' }}</span>
          </div>
          <button class="btn-delete" (click)="deleteMessage(message,$event)">
            <img src="../../../../assets/images/svg/trash-filled.svg" alt="delete"></button>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="procmessages.length>0" class="message-list">
    <hr>
    <h3>Messages</h3>
    <ul>
      <li *ngFor="let message of procmessages" (click)="openMessage(message)">
        <div class="message-info">
          <div class="txt">
            <span class="message-sender">{{ message.sender }}</span>
            <span class="message-subject">{{ message.subject}}</span>
            <span *ngIf="message.size" class="message-size">{{ message.size | fileSize }}</span>
            <span class="message-date">{{ message.date | date: 'short' }}</span>
          </div>
          <button class="btn-delete" (click)="deleteMessage(message, $event)">
            <img src="../../../../assets/images/svg/trash-filled.svg" alt="delete"></button>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="outboundMessages.length>0" class="message-list">
    <hr>
    <h3>Outbound</h3>
    <ul>
      <li *ngFor="let message of outboundMessages">
        <div class="message-info">
          <div class="txt">
            <span class="message-to">{{ message.a}}</span>
            <span class="message-date">{{ message.messageID| date: 'short' }}</span>
          </div>
          <button class="btn-delete" (click)="deleteOutBoundMessage(message)"> <img
              src="../../../../assets/images/svg/trash-filled.svg" alt="delete"></button>
        </div>
      </li>
    </ul>
  </div>
</div>
<app-modals></app-modals>
