
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Archive, Location } from '../interfaces/archive';
import { RecordI } from '../interfaces/records';
import { ApiService } from '../services/api.service';
import { AuthzService } from '../services/authz.service';
import { ErrorService, ErrorType, SafeError } from '../services/error.service';
import { FileService } from '../services/file.service';
import { SpinnerService } from '../services/spinner.service';
import { TranslateService } from '@ngx-translate/core';
import { LabelI } from '../interfaces/labels';
import { Console } from '../lib/console';
declare var Snackbar: any;
function clone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

export class Records {
  recordsArchive: Archive | undefined;
  constructor(private authzSvc: AuthzService, private apiSvc: ApiService, private fileSvc: FileService, private errorSvc: ErrorService, private spinnerSvc: SpinnerService, private translate: TranslateService) { }
  private initializedProperly = false; // don't allow any operations until records are loaded safely to avoid overwriting good data with bad data
  private dirty = false;
  private nextRecordID = 0;
  private records: RecordI[] = [];
  private labels: LabelI[] = [];
  private nextLabelID = 0;

  async initializeNew(recordsArchive: Archive) {
    this.recordsArchive = recordsArchive;
    try {
      await this.fileSvc.sendData(this.recordsArchive, { title: 'Records', data: { nextRecordID: this.nextRecordID, records: this.records, nextLabelID: this.nextLabelID, labels: this.labels } });
    } catch (error) {
      this.processError(error);
      throw error;
    }
    this.initializedProperly = true
    this.eventLabelEmiter.emit(clone(this.labels));
  }

  /**
   *
   * @param archive called when creating Safe data
   * @param reset if true, clear all current records and labels
   */
  async setArchive(archive: Archive, reset: boolean) {
    this.initializedProperly = false;
    if (reset) {
      this.records.length = 0;
      this.eventRecordEmiter.emit(clone(this.records));
      this.labels.length = 0;
      this.eventLabelEmiter.emit(clone(this.labels));
      await this.load({ title: 'Records', data: {} }, archive);
    }
    this.recordsArchive = archive;
    this.initializedProperly = true
    try {
      await this.save(true); //no debounce
    } catch (err) {
      Console.error(err);
      this.initializedProperly = false;
    }

  }

  private getNextRecordID(): number {
    this.nextRecordID += 1;
    return this.nextRecordID;
  }

  private getRecordIndexByID(id: number): number {
    return this.records.findIndex(record => record.id == id);
  }

  private getNextLabelID(): number {
    this.nextLabelID += 1;
    return this.nextLabelID;
  }

  private getLabelIndexByID(id: number): number {
    return this.labels.findIndex(label => label.id == id);
  }

  saveTimeout: any;
  /**
   * Debounce save by default
   * @param now if true, save immediately- no debounce
   * @returns Queue the save and send it in a delayed batch
   */
  private async save(now = false) {
    Console.log('save', now);
    if (!this.initializedProperly) {
      throw new SafeError(ErrorType.RECORD_LOAD, 'Records not initialized properly 2');
    }
    this.apiSvc.ping();
    if (this.apiSvc.isReadOnly()) {
      return;
    }
    if (this.recordsArchive) {
      if (now) {
        await this.fireAndForgetSave();
        return;
      } else {
        if (this.saveTimeout) { //cancel pending saves
          clearTimeout(this.saveTimeout);
          this.saveTimeout = undefined;
        }

        //sort records by id descending
        this.records.sort((a, b) => b.id! - a.id!);

        this.eventRecordEmiter.emit(clone(this.records));
        this.eventLabelEmiter.emit(clone(this.labels));
        this.saveTimeout = setTimeout(() => {
          this.fireAndForgetSave();
        }, 1000);
      }
    } else {
      Console.log('No records archive');
      Snackbar.show({
        pos: 'top-center',
        text: 'There is a problem, data NOT saved',
        duration: 10000,
      });
    }
  }
  private async fireAndForgetSave() {
    if (!this.initializedProperly || !this.recordsArchive) {
      throw new SafeError(ErrorType.RECORD_LOAD, 'Records not initialized properly 1');
    }
    this.spinnerSvc.showSpinner(true);
    try {
      const start = Date.now();
      this.dirty = true;
      await this.fileSvc.sendData(this.recordsArchive, { title: 'Records', data: { nextRecordID: this.nextRecordID, records: this.records, nextLabelID: this.nextLabelID, labels: this.labels } });
      const timer = Date.now() - start;
      if (timer > 15000 && navigator.userAgent.includes('Chrome')) {
        const serro = new SafeError(ErrorType.UNKNOWN, { status: 504, url: 'mrap.accesspoint' });
        throw serro;
      }
      this.dirty = false;
      Console.log('Save time', timer);
      Snackbar.show({
        pos: 'top-center',
        text: 'Changes Saved',
        duration: 5000,
      });
    } catch (error: any) {
      this.dirty = true
      this.processError(new SafeError(ErrorType.RECORD_SAVE, error));
    } finally {
      this.spinnerSvc.showSpinner(false);
    }
  }

  private processError(error: any) {
    Console.error(error);
    if (error instanceof SafeError) {
      const val = error.value;
      if (val && val.type === "VALIDATION") {
        this.errorSvc.process(val);
        return;
      }
    }
    this.errorSvc.process(error);
  }

  /**
   * Loads record data
   * @param archiveData
   * @param archive
   */
  async load(archiveData: any, archive: Archive) {
    this.recordsArchive = archive;
    this.initializedProperly = false;
    try {
      const nextRecordID = archiveData.data.nextRecordID;
      if (nextRecordID) {
        this.nextRecordID = nextRecordID;
      } else {
        this.nextRecordID = 0;
        archiveData.data.nextLabelID = 0;
      }
      const records = archiveData.data.records;
      let migratied = false;
      if (records) {
        this.records = records;
        //migration
        for (const record of this.records) {
          if (record.archive) {
            record.archives = [record.archive];
            delete record.archive
            migratied = true;
          }
        }
      }
      const nextLabelID = archiveData.data.nextLabelID;
      if (nextLabelID) {
        this.nextLabelID = nextLabelID;
      } else {
        this.nextLabelID = 0;
        archiveData.data.nextLabelID = 0;
      }

      const labels = archiveData.data.labels;
      this.labels = labels ? labels : [];

      // make sure wallet label is present
 
        const walletLabel = this.labels.find(x => x.name === 'Wallet');
        if (!walletLabel) {
          const label: any = { name: 'Wallet' };
          const id = this.getNextLabelID();
          label.id = id;
          this.labels.push(clone(label));
        }
     

      this.eventRecordEmiter.emit(clone(this.records));
      this.eventLabelEmiter.emit(clone(this.labels));
      this.initializedProperly = true;

      if (migratied) {
        await this.save();
      }
    } catch (error) {
      const safeError = new SafeError(ErrorType.RECORD_LOAD, error);
      this.processError(safeError);
    }
  }

  async deleteRecord(id: number) {
    if (this.apiSvc.isReadOnly()) {
      return;
    }
    const index = this.getRecordIndexByID(id);
    if (id == -1) {
      throw new Error('Invalid record id');
    }
    const record = this.records[index];
    this.records.splice(index, 1);

    await this.DeleteLabelIfNotUsed(record.labels);
    await this.save();
  }

  async deleteLabel(id: number) {
    if (this.apiSvc.isReadOnly()) {
      return;
    }
    const index = this.getLabelIndexByID(id);
    if (id == -1) {
      throw new Error('Invaid label id');
    }
    if (this.labels[index].name === 'Wallet' && this.authzSvc.appConfig.tier !== 0 && this.authzSvc.appConfig.tier !== 4) {
      return;
    }
    this.labels.splice(index, 1);
    await this.updateAllLabels(id, '');
  }

  async getRecord(id: number): Promise<RecordI> {
    const record = this.records.find((record) => record.id === id);
    if (!record) {
      throw new Error('Invalid id');
    }
    return clone(record);
  }

  removeArchive(id: string) {
    let changed = false;
    for (const record of this.records) {
      if (record.archives) {
        for (let i = 0; i < record.archives.length; i++) {
          if (record.archives[i].archiveID === id) {
            record.archives!.splice(i, 1);
            changed = true;
          }
        }
      }
    }
    if (changed) {
      this.save();
    }
  }

  getRecordByArchiveID(archiveId: string): RecordI | null {
    Console.log('getRecordByArchiveID', archiveId);
    for (const record of this.records) {
      if (record.archives) {
        Console.log('record.archives', record.archives);
        for (const archive of record.archives) {
          if (archive.archiveID === archiveId) {
            return record;
          }
        }
      }
    }
    return null;
  }
  async updateRecord(id: number, record: RecordI) {
    if (this.apiSvc.isReadOnly()) {
      return;
    }

    record.id = id;
    const index = this.getRecordIndexByID(id);
    if (index == -1) throw new Error('Invalid record id');
    // don't bother if no change
    const current = this.records[index]
    if (!this.dirty && JSON.stringify(record) == JSON.stringify(current)) {
      return;
    }
    //remove record
    this.records.splice(index, 1, clone(record));

    //check if labes were removed
    const removedLabels: LabelI[] = [];
    current.labels.forEach(label => {
      if (!record.labels.find(x => x.id === label.id)) {
        removedLabels.push(label);
      }
    });
    if (removedLabels.length > 0) {
      await this.DeleteLabelIfNotUsed(removedLabels);
    }
    await this.save();
  }

  /**
   *
   * @param removedLabels search for the removed labels in all records and delete them if not used
   */
  private async DeleteLabelIfNotUsed(removedLabels: LabelI[]) {
    for (const label of removedLabels) {
      if (label.name === 'Wallet' && this.authzSvc.appConfig.tier !== 0 && this.authzSvc.appConfig.tier !== 4) {
        continue;
      }
      let index = this.records.findIndex(record => record.labels.findIndex(x => x.id === label.id) !== -1);
      if (index === -1) {
        await this.deleteLabel(label.id!);
        //if removed label was the current label, go home
        const name = label.name;
        const path = window.location.hash;
        if (path === '#/label/' + name) {
          setTimeout(() => window.location.hash = '#/', 500);
        }
      }
    }
  }

  async updateLabel(id: number, label: LabelI) {
    if (this.apiSvc.isReadOnly()) {
      return;
    }
    label.id = id;
    const index = this.getLabelIndexByID(id);
    if (index == -1) {
      throw new Error('Invalid label id');
    }
    //don't update if there is already a lable with that name.
    const labelIndex = this.labels.findIndex((label) => label.name === label.name);
    if (labelIndex !== -1) {
      throw new Error('Label name already exists');
    }
    // don't bother if no change
    if (JSON.stringify(label) == JSON.stringify(this.labels[index])) {
      return;
    }

    this.labels.splice(index, 1, clone(label));
    await this.updateAllLabels(id, label.name);
  }

  async updateRecordKey(id: number, updates: RecordI) {
    if (this.apiSvc.isReadOnly()) {
      return;
    }

    const current = await this.getRecord(id);
    let updated ;
    if (!current) throw new Error('Invalid id ' + id);
    if (updates.archives) {
     updated = {...current}
      updated.archives = updates.archives;
    } else {
      updated = { ...current, ...updates };
    }
    const index = this.getRecordIndexByID(id);

    if (updates.labels) {
      const removeLabels = updates.labels.filter(x => !x.added);
      updated.labels = updates.labels.filter(x => x.added);
      updated.labels.forEach(x => { if (!x.added) { delete x.added } });
      this.records.splice(index, 1, clone(updated));
      if (removeLabels.length > 0) {
        await this.DeleteLabelIfNotUsed(removeLabels);
      }
    } else {
      this.records.splice(index, 1, clone(updated));
    }

    await this.save();
  }

  async addRecord(recordObj: RecordI): Promise<number> {
    if (this.apiSvc.isReadOnly()) {
      return 0;
    }
    const id = this.getNextRecordID();
    recordObj.id = id;
    this.records.push(clone(recordObj));
    await this.save();
    return id;
  }
  async addLabel(label: LabelI): Promise<number> {
    if (this.apiSvc.isReadOnly()) {
      return 0;
    }
    //don't update if there is already a lable with that name.
    const labelIndex = this.labels.findIndex((x) => x.name === label.name);
    if (labelIndex !== -1) {
      throw new Error('Name Exists');
    }
    const id = this.getNextLabelID();
    label.id = id;
    this.labels.push(clone(label));
    await this.save();
    return id;
  }

  private async updateAllLabels(labelId: number, labelValue: string) {

    if (this.apiSvc.isReadOnly()) {
      return;
    }
    this.records.forEach(record => {
      let index = record.labels.findIndex(label => label.id === labelId);
      if (index !== -1) {
        if (labelValue === '') {
          record.labels.splice(index, 1);
        } else {
          record.labels[index].name = labelValue;
        }
      }
    });
    await this.save();
  }

  private eventRecordEmiter = new EventEmitter<RecordI[]>();
  getRecordObservable(): Observable<RecordI[]> {
    return this.eventRecordEmiter;
  }

  private eventLabelEmiter = new EventEmitter<LabelI[]>();
  getLabelObservable(): Observable<LabelI[]> {
    return this.eventLabelEmiter;
  }
}

@Injectable({
  providedIn: 'root'
})

export class db {

  public records: Records;
  spaces: Archive[] = [];
  public currentSpaceNumber = 0;
  public spaceName = '';

  constructor(private apiSvc: ApiService, private authzSvc: AuthzService, private fileSvc: FileService, private errorSvc: ErrorService, private spinnerSvc: SpinnerService, private translate: TranslateService) {
    this.records = new Records(authzSvc, apiSvc, fileSvc, errorSvc, spinnerSvc, translate);
    this.authzSvc.authorized.subscribe(async (authorized: boolean) => { if (authorized) { await this.load(); this.checkMissingSpaces() } });
  }

  private checkMissingSpaces() {
    const tier = this.authzSvc.appConfig.tier;
    if (tier === 0 || tier === 4) {
      return;
    }
    if (this.spaces.length < 1 && this.authzSvc.appConfig.isAdmin) {
      this.addSpace('', this.spaces.length === 0); // switch to new space if it is the only one
    }
  }

  /**
 *
 * @param spaceNumber starting at 0
 */
  public async setSpace(spaceNumber: number) {
    spaceNumber--;
    if (spaceNumber >= this.spaces.length || spaceNumber < 0) {
      throw new SafeError(ErrorType.UNKNOWN, 'Invalid space number');
    }
    await this.load(spaceNumber);
    this.currentSpaceNumber = spaceNumber;
  }

  public async addSpace(name?: string, setCurrent = true) {
    Console.log('addSpace', name, setCurrent);
    const locationID = this.authzSvc.appConfig.locID;
    const archiveRequest = new Archive();
    archiveRequest.t = 'Records';
    archiveRequest.m = { name: "records", description: "Records", spaceName: name ? name : '' };
    archiveRequest.l = locationID;
    archiveRequest.sid = Math.round(Date.now() / 1000);
    const recordsArchive = await this.apiSvc.createArchive(archiveRequest);
    if (!recordsArchive || <any>recordsArchive === 'UNWILLING_TO_PERFORM' || <any>recordsArchive === 'SAFE_EXPIRED') {
      Console.log('Add Space:  UNWILLING_TO_PERFORM || SAFE_EXPIRED');
      return;
    }
    this.spaces.push(recordsArchive);
    await this.fileSvc.sendData(recordsArchive, { title: 'Records', data: { nextRecordID: 0, records: [], nextLabelID: 0, labels: [] } });
    if (setCurrent) {
      this.setSpace(this.spaces.length);
    }
  }

  async deleteSpace(id: number) {
    const index = this.spaces.findIndex(space => space.sid === id);
    if (index === -1) {
      throw new SafeError(ErrorType.UNKNOWN, 'Invalid space id');
    }

    const iscurrent = this.currentSpaceNumber === index;
    const archiveID = this.spaces[index].id;
    this.dataCache.delete(archiveID);
    this.strongCache.delete(archiveID);
    await this.apiSvc.deleteSpaceArchives(id);
    await this.apiSvc.deleteArchive(archiveID);
    this.spaces.splice(index, 1);
    if (this.spaces.length == 0) {
      await this.addSpace();
    } else {
      if (iscurrent) {
        this.load(0);
      }
    }
  }

  public async setSpaceName(space: number, name: string) {
    const archive = this.spaces[--space];
    archive.m.spaceName = name;
    await this.apiSvc.updateArchive(archive);
    if (space === this.currentSpaceNumber) {
      this.spaceName = name;
    }
  }

  public async refresh() {
    Console.log('refresh');
    this.flushDataCache();
    this.spinnerSvc.showSpinner(true, this.translate.instant('RECORDS.LOADING'));
    await this.loadSpaces();
    this.spinnerSvc.showSpinner(false);
    this.load(this.currentSpaceNumber);
  }

  /**
   * Called when creating a new safe
   * @param location
   */
  public async initialize(location: Location) {
    Console.log('initialize');
    const archiveRequest = new Archive();
    archiveRequest.t = 'Records';
    archiveRequest.m = { name: "records", description: "Records", spaceName: '' };
    archiveRequest.l = location.id;
    archiveRequest.sid = Math.round(Date.now() / 1000);
    this.apiSvc.setSpaceID(archiveRequest.sid, archiveRequest.l);
    const recordsArchive = await this.apiSvc.createArchive(archiveRequest);
    await this.records.initializeNew(recordsArchive);
    this.spaces.push(recordsArchive);
    this.authzSvc.appConfig.locID = location.id;
    this.apiSvc.setAppConfig(this.authzSvc.appConfig);
  }

  private async loadSpaces() {
    const archives = await this.apiSvc.getArchives();
    if (<any>archives === 'UNWILLING_TO_PERFORM' || <any>archives === 'SAFE_EXPIRED') {
      Console.log('UNWILLING_TO_PERFORM || SAFE_EXPIRED');
      return;
    }
    this.spaces.length = 0;
    this.spaces.push(...archives.filter(archive => archive.t == 'Records'));
    this.spaces.sort((a, b) => a.sid - b.sid);
  }

  public async load(spaceNumber = 0) {
    Console.log('load', spaceNumber);
    if (this.authzSvc.expired) {
      this.errorSvc.process(new SafeError(ErrorType.EXPIRED, 'Session expired'));
      return;
    }

    try {
      if (this.spaces.length === 0) {
        await this.loadSpaces();
      }
      const recordsArchive = this.spaces[spaceNumber];

      if (!recordsArchive) {
        this.errorSvc.process(new SafeError(ErrorType.NO_ARCHIVES, 'NO_NOTES_ARCHIVE'));
        return;
      }

      const archiveData: any = await this.getData(recordsArchive);
      await this.records.load(archiveData, recordsArchive);
      this.currentSpaceNumber = spaceNumber;
      this.spaceName = recordsArchive.m.spaceName;
      if (!this.spaceName) {
        this.spaceName = '';
      }
      this.apiSvc.setSpaceID(recordsArchive.sid, recordsArchive.l);

    } catch (error) {
      const safeError = new SafeError(ErrorType.RECORD_LOAD, error);
      this.errorSvc.process(safeError);
      throw safeError;
    }
  }

  // private dataCache = new Map<string, any>();
  // private async getData(archive: Archive) {
  //   if (this.dataCache.has(archive.id)) {
  //     return this.dataCache.get(archive.id);
  //   }
  //   this.spinnerSvc.showSpinner(true, this.translate.instant('RECORDS.LOADING'));
  //   try {
  //     const data = await this.fileSvc.getData(archive);
  //     this.dataCache.set(archive.id, data);
  //     return data;
  //   } finally {
  //     this.spinnerSvc.showSpinner(false);
  //   }
  // }

  /**
  * A caching mechanism that balances strong and weak references.
  * - Frequently accessed items stay in strong cache.
  * - Items move to weak cache if inactive for a while.
  * - Uses FinalizationRegistry to clean up weak cache entries.
  */

  /** Stores weak references to cached data */
  private dataCache = new Map<string, WeakRef<any>>();

  /** Holds temporary strong references to prevent immediate GC */
  private strongCache = new Map<string, any>();

  /** Stores active timers to reset expiration for hot data */
  private cacheTimers = new Map<string, NodeJS.Timeout>();

  /** Finalization registry to clean up weak cache entries when GC occurs */
  private finalizationRegistry = new FinalizationRegistry<string>((id) => {
    Console.log(`Cache entry for ${id} was garbage collected.`);
    this.dataCache.delete(id);
    this.cacheTimers.delete(id);
  });

  /**
   * Retrieves data from the cache or fetches it if not available.
   * - Resets the expiration timer for frequently accessed items.
   * - Moves inactive items to weak cache after a timeout.
   *
   * @param {Archive} archive - The archive object containing the unique ID.
   * @returns {Promise<any>} - The cached or newly fetched data.
   */
  public async getData(archive: Archive): Promise<any> {
    // Check strong cache first (prevents immediate GC)
    if (this.strongCache.has(archive.id)) {
      this.resetExpirationTimer(archive.id); // Reset timer on access
      return this.strongCache.get(archive.id);
    }

    // Check weak reference cache
    const ref = this.dataCache.get(archive.id);
    if (ref) {
      const cachedData = ref.deref();
      if (cachedData) {
        // Move to strong cache temporarily when accessed
        this.strongCache.set(archive.id, cachedData);
        this.resetExpirationTimer(archive.id); // Reset timer
        return cachedData;
      }
    }

    // Fetch new data if not cached or garbage collected
    this.spinnerSvc.showSpinner(true, this.translate.instant('RECORDS.LOADING'));
    try {
      const data = await this.fileSvc.getData(archive);
      this.dataCache.set(archive.id, new WeakRef(data));
      this.strongCache.set(archive.id, data); // Keep strong reference
      this.finalizationRegistry.register(data, archive.id);

      // Reset the expiration timer to ensure hot items stay cached
      this.resetExpirationTimer(archive.id);

      return data;
    } finally {
      this.spinnerSvc.showSpinner(false);
    }
  }

  /**
   * Resets the expiration timer for an item in the strong cache.
   * - Ensures frequently accessed items are retained.
   * - Moves the item to weak cache only after inactivity.
   *
   * @param {string} id - The unique ID of the cached entry.
   */
  private resetExpirationTimer(id: string): void {
    // Clear any existing timer
    if (this.cacheTimers.has(id)) {
      clearTimeout(this.cacheTimers.get(id)!);
    }

    // Set a new timer to release the strong reference after inactivity
    const timeout = setTimeout(() => this.releaseStrongRef(id), 60000);
    this.cacheTimers.set(id, timeout);
  }

  /**
   * Releases the strong reference for a given cache entry after timeout.
   * - Moves the item to weak cache.
   * - Allows it to be garbage collected if no longer referenced elsewhere.
   *
   * @param {string} id - The unique ID of the cached entry.
   */
  private releaseStrongRef(id: string): void {
    Console.log(`Releasing strong reference for ${id}`);
    this.strongCache.delete(id);
    this.cacheTimers.delete(id); // Cleanup timer reference
  }

  /**
  * Flushes the entire cache, removing all stored data.
  * - Clears both strong and weak caches.
  * - Cancels all active expiration timers.
  */
  public flushDataCache(): void {
    Console.log("Flushing cache...");
    this.apiSvc.clearCaches();
    this.strongCache.clear(); // Remove all strong references
    this.dataCache.clear(); // Remove all weak references
    this.cacheTimers.forEach((timeout) => clearTimeout(timeout)); // Cancel timers
    this.cacheTimers.clear(); // Clear timer records
  }
}
