import { Console } from "../lib/console";
import { SafeCrypto } from "../lib/safeCrypto";

export abstract class Action {
  message: string[] = [];
  reason = '';
  request: any = {};
  result: any = {};
  safeExp?: any;
}
export class EmptyAction extends Action {
}
export class CallBack {
  constructor(public type: string, public action: Action) {
  }
}

export class CallBackDTO {
  state!: string; // Encrytped server side state data bounced of client
  e?: string;
  u?: any;
  exp?: number;
  private constructor() {
  }

  static async build(safeCrypto: SafeCrypto, callback: CallBack, state : string): Promise<CallBackDTO> {
    const dto = new CallBackDTO();
    dto.state = state;
    const action: any  = callback.action;
    action.type = callback.type;
    if (callback.type == 'Captcha' || callback.type == 'ECDHE' || callback.type == 'PQ_KEY_EXCHANGE') {
      dto.u = callback.action;
    } else {
      dto.e = await safeCrypto.encryptAction(action);
    }
    Console.log('CallBackDTO.build', dto);
    return dto;
  }

  public static async getCallBack(safeCrypto: SafeCrypto, dto: CallBackDTO): Promise<CallBack> {
    Console.log('CallBackDTO.getCallBack DTO', dto);
    let action: any;
    if (dto.u) {
      action = dto.u;
    } else {
      action = await safeCrypto.decryptAction(dto.e!);
    }
    const type = action.type;
    delete action.type;
    const cb = new CallBack(type, action);
    Console.log('CallBackDTO.getCallBack callback', cb);
    return cb;
  }
}
