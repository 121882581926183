import { NgModule, APP_INITIALIZER  } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ConfigComponent } from './components/config/config.component';
import { MainRecordsComponent } from './components/main-records/main-records.component';
import { ArchivesComponent } from './components/main-records/archives/archives.component';
import { ArchiveComponent } from './components/main-records/archives/archive/archive.component';
import { LicenseComponent } from './license/license.component';
import {
  AuthGuardService as AuthGuard
} from './authguard.service';
import { InitializationService } from './services/initialization-service';

function initializeApp(initService: InitializationService) {
  return () => initService.getParam('partnerId'); // Ensure any initialization logic runs
}

const routes: Routes = [
  { path: '', component: MainRecordsComponent },
  { path: 'config', component: ConfigComponent, canActivate: [AuthGuard] },
  { path: "label/:name", component: MainRecordsComponent, canActivate: [AuthGuard] },
  { path: "records/:id", component: MainRecordsComponent, canActivate: [AuthGuard] },
  { path: "archives", component: ArchivesComponent, canActivate: [AuthGuard] },
  { path: "archives/:id", component: ArchiveComponent, canActivate: [AuthGuard] },
  { path: 'register/:accessid/:passphrase/:safename', component: MainRecordsComponent }, // path fragments are not sent to the server
  { path: 'access/:accessid/:passphrase', component: MainRecordsComponent }, // path fragments are not sent to the server
  { path: 'licenses', component: LicenseComponent },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    InitializationService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [InitializationService],
      multi: true
    }
  ],
})
export class AppRoutingModule { }
