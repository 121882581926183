<div class="key-container">
  <div class="key-header" (click)="toggleShow()">
    <span class="key-name">{{ key.name || key.accessID }}</span>
    <button class="toggle-button" (click)="toggleShow()">
      {{ show ? '▲' : '▼' }}
    </button>
  </div>

    <div class="key-details" [ngClass]="{'visible': show}">
      <div class="checkbox-group">
        <label>
          <input type="checkbox" [disabled]="currentKey" (click)="toggleEnable($event)" [(ngModel)]="key.enabled">
          {{ 'KEY.ENABLED' | translate }}
        </label>
      </div>
      <div class="radio-group">
        <label>
          <input type="radio" [disabled]="currentKey || ! modes" (click)="setAccessMode('ro', $event)"
            [checked]="accessMode === 'ro'" [name]="key.accessID">
          {{ 'KEY.READONLY' | translate }}
        </label>
      </div>
      <div class="radio-group">
        <label>
          <input type="radio" [disabled]="currentKey || ! modes" (click)="setAccessMode('rw', $event)"
            [checked]="accessMode === 'rw'" [name]="key.accessID">
          {{ 'KEY.READWRITE' | translate }}
        </label>
      </div>
      <div class="radio-group">
        <label>
          <input type="radio" [disabled]="currentKey || ! modes" (click)="setAccessMode('admin', $event)"
            [checked]="accessMode === 'admin'" [name]="key.accessID">
          {{ 'KEY.ADMIN' | translate }}
        </label>
      </div>
    <div class="button-container">
      <button *ngIf="!currentKey" class="btn btn-delete" [disabled]="key.enabled" (click)="deleteKey()">
        <span>{{ 'DELETE' | translate }}</span>
      </button>
      <button *ngIf="!currentKey && key.mode!='admin'" class="btn-primary" (click)="spaces()" [disabled]="!modes">
        <span>{{ 'KEY.SPACES' | translate }}</span>
      </button>
      <button class="btn-primary rename" (click)="rename()">
        <span>{{ 'KEY.RENAME.BTN' | translate }}</span>
      </button>
    </div>
  </div>
</div>
<app-modals></app-modals>
