import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ApiKeyPair, ApiProvider } from 'src/app/interfaces/ApiProvider';
import { WalletService } from 'src/app/services/wallet.service';
import { Wallet } from 'src/app/lib/wallet';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'apikey-modal',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, TranslateModule],
  templateUrl: './apikey-modal.component.html',
  styleUrl: './apikey-modal.component.scss'
})
export class ApiKeyModalComponent {
  title = '';
  message = '';

  @Input() wallet!: Wallet;
  @Output() response = new EventEmitter<ApiKeyPair | null>();
  supportedProviders: ApiProvider[] = [];
  apiKeyForm!: FormGroup;

  constructor(private translation: TranslateService, private fb: FormBuilder, private walletSvc: WalletService) {

  }

  ngOnInit() {
    this.supportedProviders = this.walletSvc.getProvidersForCoin(this.wallet.coinType);


    this.apiKeyForm = this.fb.group({
      provider: [this.supportedProviders[0] || ''],
      apiKey: ['']
    });

    this.setSetAPI();
    // this.loadExistingApiKey();
    this.title = `Manage API Key for ${ this.wallet.coinType}`;
    this.message = `An API key is required to send transactions for ${this.wallet.coinType}. Please select a provider and enter your API key.`;
  }

  /**
   * Set selected provider to first one with an api key if any
   */
  private setSetAPI() {
    for (let provider of this.supportedProviders) {
      const existingKey = this.walletSvc.getApiKey(provider);
      if (existingKey) {
        this.apiKeyForm.get('provider')?.setValue(provider);
        this.apiKeyForm.get('apiKey')?.setValue(existingKey);
        this.apiKeyForm.get('apiKey')?.markAsPristine(); // Reset dirty state
        Console.log('setSetAPI', provider, existingKey);
        break;
      }
    }
  }

  loadExistingApiKey() {
    const selectedProvider = this.apiKeyForm.get('provider')?.value as ApiProvider;
    const existingKey = this.walletSvc.getApiKey(selectedProvider);
    this.apiKeyForm.get('apiKey')?.setValue(existingKey || '');
    this.apiKeyForm.get('apiKey')?.markAsPristine(); // Reset dirty state
  }

  onProviderChange() {
    this.loadExistingApiKey();
  }

  onSubmit() {
    const provider = this.apiKeyForm.get('provider')?.value as ApiProvider;
    const key = this.apiKeyForm.get('apiKey')?.value;

    if (key) {
      const apiKeyPair: ApiKeyPair = { provider, key };
      this.walletSvc.setApiKey(apiKeyPair);
      this.response.emit(apiKeyPair);
    }
      // Reset form state after submission
      this.apiKeyForm.get('apiKey')?.markAsPristine();
  }
  cancel() {
    this.response.emit(null);
  }

  OpenRegistrationPage(){
    const provider = this.apiKeyForm.get('provider')?.value as ApiProvider;
    const url = this.walletSvc.getProviderRegistrationUrl(provider);
    window.open(url, '_blank');
  }

}
