import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from 'src/app/interfaces/archive';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { Console } from 'src/app/lib/console';
import { ThisReceiver } from '@angular/compiler';

interface Option {
  label: string;
  region: string;
  selected?: boolean;
}

@Component({
  selector: 'auth-region-modal',
  templateUrl: './region-modal.component.html',
  styleUrls: ['./region-modal.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, TranslateModule, FormsModule]
})
export class AuthRegionModalComponent implements OnInit {
  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @Output() reply = new EventEmitter<Location>();
  @Input() locations!: Location[];
  @Input() locationID: string = '1';
  @Input() multiRegion: boolean = false;

  selectedLocation: Location | null = null;
  xRegions: Option[] = [] //replicated
  regions: Option[] = [] //basic
  selectedRegion: Option | null = null;
  selectedOptionsOrder: Option[] = [];
  currentLocationName = 'Canada';
  multiRegionSelected = false;

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    Console.log('locations', this.locations);
    Console.log('locationID', this.locationID);
    Console.log('multiRegion', this.multiRegion);
    for (const location of this.locations) {
      if (location.class == 'basic') {
        location.name = this.translateID(location.region);
        if (!this.OptionsHasValue(this.regions, location.region)) {
          const option: Option = {
            label: location.name,
            region: location.region,
            selected: false
          }

          if (location.id == this.locationID) { // default
            Console.log('default selected', location);
            option.selected = true;
            this.selectedLocation = location;
            this.selectedRegion = option;
            this.currentLocationName = location.name;
          }

          this.regions.push(option);
        }
      } else {
        //replicated
        if (!this.multiRegion) continue; //only show multi region if enabled
        const regions = location.region.split(',');
        const region1 = this.translate.instant('LOCATIONS.XRegion.' + regions[0]);
        const region2 = this.translate.instant('LOCATIONS.XRegion.' + regions[1]);
        location.name = region1 + ' & ' + region2;

        for (const region of regions) {
          if (!this.OptionsHasValue(this.xRegions, region)) {
            const label = this.translate.instant('LOCATIONS.XRegion.' + region);
            const option: Option = {
              label: label,
              region: region,
              selected: false
            }

            this.xRegions.push(option);
          }
          if (location.id == this.locationID) { // default
            this.selectedLocation = location;
            this.currentLocationName = location.name;

            const selectedOption = this.xRegions.filter(opt => opt.region == region)[0];
            selectedOption.selected = true;
            this.multiRegionSelected = true;
            this.selectedOptionsOrder.push(selectedOption);
            Console.log('current xregion', region);
          }
        }
      }
    }
    Console.log('post init names', this.currentLocationName);
  }

  private OptionsHasValue(options: Option[], region: string): boolean {
    return options.filter(opt => opt.region == region).length > 0;
  }

  private translateID(regionID: string): string {
    return this.translate.instant('LOCATIONS.' + regionID);
  }

  onXRangeChange(changedOption): void {

    this.selectedRegion = null; //reset single option
    if (changedOption.selected) {
      // Remove the option from the tracking array
      this.selectedOptionsOrder = this.selectedOptionsOrder.filter(opt => opt !== changedOption);
    } else {
      // Add the option to the tracking array
      this.selectedOptionsOrder.push(changedOption);
    }
    // Toggle the selection
    changedOption.selected = !changedOption.selected;

    // If more than two options are selected, uncheck the oldest
    if (this.selectedOptionsOrder.length > 2) {
      const oldestSelected = this.selectedOptionsOrder.shift(); // Remove the oldest
      if (oldestSelected) {
        oldestSelected.selected = false;
      }
    } else {
      // unset selected location if only one option is selected
      this.selectedLocation = null;
    }

    if (this.selectedOptionsOrder.length == 2) {
      this.setLocation(this.selectedOptionsOrder[0].region, this.selectedOptionsOrder[1].region);
      this.multiRegionSelected = true;
    } else {
      this.multiRegionSelected = false;
    }
  }

  onRangeChange(): void {
    // Reset all checkboxes
    this.xRegions.forEach(opt => opt.selected = false);
    this.selectedOptionsOrder = [];
    if (this.selectedRegion) {
      this.setLocation(this.selectedRegion!.region, undefined);
    }
  }

  async send() {
    if (this.selectedLocation) {
      this.reply.emit(this.selectedLocation);
    }
  }

  cancel() {
    this.selectedLocation = this.locations.filter(loc => loc.id == this.locationID)[0];
    this.send();
  }

  private setLocation(region1: string, region2: string | undefined) {

    let location: Location | null = null;
    if (region2) {
      location = this.locations.filter(loc => (loc.region == region1 + ',' + region2) || (loc.region == region2 + ',' + region1))[0];
    } else {
      location = this.locations.filter(loc => loc.region == region1)[0];
    }
    this.selectedLocation = location;
    this.currentLocationName = location.name!;
    this.multiRegionSelected = !!this.selectedLocation && parseInt(this.selectedLocation.id) > 16;
  }

  onSingleCountrySelected(): void {
    this.onRangeChange();
    this.selectedRegion = null;
    this.selectedLocation = null;
  }
}
