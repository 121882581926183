import { TranslateService } from "@ngx-translate/core";
import { Archive } from "src/app/interfaces/archive";
import { Console } from "src/app/lib/console";
import { ApiService } from "src/app/services/api.service";
import { RecordsService } from "src/app/services/records.service";
import { ModalComponent } from "../modal/modal.component";
import { RecordI } from "src/app/interfaces/records";

export class FileAttachmentLib {
  constructor(private apiSvc: ApiService, private modalController: ModalComponent, private translate: TranslateService, private recordsSvc: RecordsService , private record: RecordI) { }

  async attachFile() : Promise<{ archiveID: string, name: string } | undefined> {
    const files = await this.getFileArchives();
    if (files.length > 0) {
      const result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.FILE.NEWOREXISTING.TITLE'), this.translate.instant('RECORDS.FILE.NEWOREXISTING.MSG'), this.translate.instant('CANCEL'), this.translate.instant('RECORDS.FILE.NEWOREXISTING.NEW'), this.translate.instant('RECORDS.FILE.NEWOREXISTING.EXISTING'));
      if (result === 'ONE') {
       return await this.attachNewArchive();
      } else if (result === 'TWO') {
       return await this.attachExistingArchive();
      } else {
        return;
      }
    } else {
     return await this.attachNewArchive();
    }
  }

  private  async attachNewArchive(): Promise<{ archiveID: string, name: string } | undefined> {
    const archive: Archive = await this.modalController.displayFileUploader();
    if (!archive) return;
    return { archiveID: archive.id, name: archive.m.name };
  }

  private async getFileArchives(): Promise<Archive[]> {
    //Create list of archivs that do not have a record attached
    let archives = await this.apiSvc.getArchives();
    for (const archive of archives) {
      const record = this.recordsSvc.getRecordByArchiveID(archive.id);
      if (record) {
        archive.recordId = record.id;
      }
    }
    return archives.filter(x => x.t != 'Records');
  }

  private async attachExistingArchive(): Promise<{ archiveID: string, name: string } | undefined> {

    //Create list of archivs that do not have a record attached
    let archives = await this.getFileArchives();
    Console.log(archives);
    // turn it into a list of Items for the list-select modal
    let items = archives.map(x => ({ label: x.m.name, object: x }));
    // open the list-select modal
    let selected = await this.modalController.displayListSelect(this.translate.instant('RECORDS.FILE.SELECT.TITLE'), this.translate.instant('RECORDS.FILE.SELECT.MSG'), this.translate.instant('CANCEL'), this.translate.instant('RECORDS.FILE.SELECT.SELECT'), items);
    if (selected) {
      const archive = selected.object as Archive;
      const currentRecordId = archive.recordId;
      if (currentRecordId && currentRecordId !== this.record.id) {
        const result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.FILE.ALREADY.TITLE'), this.translate.instant('RECORDS.FILE.ALREADY.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('RECORDS.FILE.ALREADY.MOVE'));
        if (result === 'ZERO') {
          return;
        }
        // get the record that the file is attached to
        const record = this.recordsSvc.getRecordByArchiveID(archive.id);
        if (record) {
          record.archive = null;
          await this.recordsSvc.update(record, record.id!);
        }
      }
      return { archiveID: selected.object.id, name: selected.object.m.name };
    }
    return;
  }
}
