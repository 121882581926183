import { HttpErrorResponse } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Console } from '../lib/console';

export enum ErrorType {
  SIZE = "SIZE",
  EXPIRED = "EXPIRED",
  UNKNOWN = "UNKNOWN",
  SERVER = "SERVER",
  TIER = "TIER",
  LOCKED = "LOCKED",
  NO_ARCHIVES = "NO_ARCHIVES",
  VALIDATION = "VALIDATION",
  CRYPTO_NOT_INITIALIZED = "CRYPTO_NOT_INITIALIZED",
  RECORD_LOAD = "RECORD_LOAD",
  RECORD_SAVE = "RECORD_SAVE",
  HIDE_SCREEN = "HIDE_SCREEN",
  INSUFFICIENT_FUNDS = "INSUFFICIENT_FUNDS",
  LOCKED_OUT = "LOCKED_OUT",
  PERMISSION = "PERMISSION",
  OFFLINE = "OFFLINE",
}

export class SafeError {
  constructor(public type: ErrorType, public value: any) { }
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  static instance: ErrorService;
  private supressBlur=false ;

  constructor() { ErrorService.instance = this }

  errorEmiter = new EventEmitter<SafeError>();
  async process(error: SafeError | HttpErrorResponse | any): Promise<SafeError> {
    Console.log('ErrorService.process', error);
    let safeError: SafeError | undefined;
    if (error instanceof SafeError) {
      if(error.type === ErrorType.HIDE_SCREEN && this.supressBlur){
        return error;
      }
      safeError = error;

    } else if (error instanceof HttpErrorResponse) {
      Console.log('HttpErrorResponse', error);
      safeError = this.processHttpErrorResponse(error);
    } else if (error instanceof Response) {
      Console.log('Response', error);
      safeError = await this.processAppError(error);
    }

    if (error instanceof Error && error.message === 'CRYPTO_NOT_INITIALIZED') {
      safeError = new SafeError(ErrorType.CRYPTO_NOT_INITIALIZED, error);
    }

    if (!safeError) {
      Console.error('Unknown error ', error);
      safeError = new SafeError(ErrorType.UNKNOWN, error);
    }
    this.errorEmiter.emit(safeError);
    return safeError
  }

  private processHttpErrorResponse(error: HttpErrorResponse): SafeError {
    let safeError: SafeError

    if (error.status > 499) {
      return this.processServerError(error);
    }
    try {
      const data = this.parseXmlError(error);
      safeError = new SafeError(ErrorType.SIZE, data);
    } catch (e) {
      Console.error('ErrorService.processHttpErrorResponse', e);
      let msg = 'Network error';
      if (error.message) {
        msg = error.message;
      } else if (error.statusText) {
        msg = error.statusText;
      }
      safeError = new SafeError(ErrorType.UNKNOWN, msg);
    }
    this.errorEmiter.emit(safeError);
    return safeError;
  }

  private processServerError(error: HttpErrorResponse) {
    Console.error('Server Error', error);
    const safeError = new SafeError(ErrorType.SERVER, error);
    this.errorEmiter.emit(safeError);
    return safeError;
  }

  private async processAppError(error: Response) {
    let text = '';
    try {
      text = await error.text();
    } catch (e) {
      Console.error('ErrorService.processAppError', e);
      return new SafeError(ErrorType.UNKNOWN, e);
    }
    try {
      const body = JSON.parse(text);
      const type = body.type;
      const value = body.value;
      const safeError = new SafeError(ErrorType[type], value);
      this.errorEmiter.emit(safeError);
      Console.error('App Error', safeError);
      return safeError;
    } catch (e) {
      Console.error('ErrorService.processAppError', text);
      return new SafeError(ErrorType.UNKNOWN, text);
    }
  }

  private parseXmlError(error: HttpErrorResponse): any {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(error.error, 'application/xml');
    const errorNode = xmlDoc.getElementsByTagName('Error')[0];
    const errorDoc: any = {};
    for (let i = 0; i < errorNode.childNodes.length; i++) {
      const node = errorNode.childNodes[i];
      if (node.nodeType === 1) {
        errorDoc[node.nodeName] = node.textContent;
      }
    }
    return errorDoc;
  }


  /**
   *
   * @param timeout in seconds
   */
  public supressScreenBlur(timeout = 30) {
    this.supressBlur = true;
    setTimeout(() => {
      this.supressBlur = false;
    }, timeout*1000);
  }
}
