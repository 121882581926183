import { Component, OnInit, ViewChild } from '@angular/core';
import { SpinnerService } from 'src/app/services/spinner.service';
import { SpinnerComponent } from '../spinner/spinner.component';
import { NgIf } from '@angular/common';
import { ErrorComponent } from '../error/error.component';
import { AuthzComponent } from '../auth/authz.component';
import { Router, RouterOutlet } from '@angular/router';
import { NavComponent } from '../sidenav/sidenav.component';
import { NavbarComponent } from '../navbar/navbar.component';
import { SwUpdate } from '@angular/service-worker';

import { TranslateService } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from '../modal/modal.component';
import { Console } from 'src/app/lib/console';
import { HelpComponent } from "../help/help-component/help-component";
import { AuthzService } from 'src/app/services/authz.service';
import { Platform } from '@angular/cdk/platform';
import { SpacecontrolComponent } from "../spacecontrol/spacecontrol.component";
import { db } from 'src/app/db/json';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  standalone: true,
  imports: [NavbarComponent, NavComponent, RouterOutlet, AuthzComponent, ErrorComponent, NgIf, SpinnerComponent, ModalComponent, HelpComponent, SpacecontrolComponent]
})

export class MainComponent implements OnInit {
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  showSpinner = false;
  spinnerMessage = '';
  done = false;
  deferredPrompt: Event | undefined;
  authStarted = false;
  lastPingTime = 0;
  swready = false;
  showSpaces = false;
  updateCalled = false;

  constructor(public db: db, private spinnerSvc: SpinnerService, private translate: TranslateService, private swUpdate: SwUpdate, private apiSvc: ApiService, private authzSvc: AuthzService, private platform: Platform, public route: Router, public Shared: SharedService) {

    try {
      history.pushState(null, ''); //disable back button
      //kill bf/page cache
      window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
          location.reload();
        }
      });

      window.addEventListener("beforeinstallprompt", (event) => {
        Console.log('beforeinstallprompt fired');
        event.preventDefault();
        this.apiSvc.deferredInstallPrompt = event;
      });

      this.swUpdate.versionUpdates
        .subscribe(async evt => {
          this.showSpinner = false;
          console.log('version updates called', evt);
          if (evt.type === 'VERSION_DETECTED') {
            console.log('version detected', JSON.stringify(evt));
            const hash = evt.version.hash;
            const splitHash = hash.match(/.{1,8}/g) || [];
            const joinedHash = splitHash.join(" ");
            console.log(hash, joinedHash);
            this.apiSvc.updateAvailable = joinedHash;
            if (!this.authStarted) {
              this.updateApp();
            }
          } else if (evt.type === 'VERSION_INSTALLATION_FAILED') {
            if (evt.error === 'SIGNATURE_INVALID ') {
              await this.modalController.displayMessage(this.translate.instant('MAIN.UP_SIG_ERROR.TITLE'), this.translate.instant('MAIN.UP_SIG_ERROR.MSG'));
            }
          }
        });
      this.swUpdate.unrecoverable.subscribe(async event => {
        Console.log('unrecoverable error', event);
        const msg = 'An error occurred that we cannot recover from:\n' + event.reason + '. The Pasge will be reloaded.';
        setTimeout(() => { window.location.reload() }, 5000);
        this.modalController.displayMessage('Unrecoverable error', msg);
      });
      this.swUpdate.checkForUpdate();
    } catch (e:any) {
      Console.error(e);
      const message = 'An error occurred while initializing the app. Please try again later. '+ e.message? e.message : JSON.stringify(e);
      alert(message);
      window.location.href = 'https://unolock.com';
    }
  }

  ngOnInit(): void {
    this.spinnerSvc.spinnerEmiter.subscribe((showSpinner: { show: boolean, message: string }) => { this.show(showSpinner) });
    this.checkServiceWorkerReady();
  }

  private checkServiceWorkerReady() {
    if ('serviceWorker' in navigator) {
      if (navigator.serviceWorker.controller) {
        this.hideLoader();
      } else {
        // Wait for the service worker to be activated and reload the page if it takes too long
        this.waitForServiceWorkerActivation();
      }
    } else {
      alert('Service Worker not supported. Unolock can not work in a browser without service worker support.');
      window.location.href = 'https://unolock.com';
    }
  }

  private async waitForServiceWorkerActivation() {
    Console.log('Waiting for service worker activation...');
    //change text in htmlelement with class loader
    const loaderDiv = document.querySelector('div.loading');
    if (loaderDiv) {
      loaderDiv.innerHTML = "Loading Service Worker<span>.</span><span>.</span><span>.</span>";
    } else {
      Console.log('loaderDiv not found');
    }
    try {
      const result = await fetch('/ping');
      if (result.ok) {
        Console.log('Service Worker activated', result);
        this.hideLoader();
        return;
      }
    } catch (e) {
      Console.log('Service Worker not activated yet');
    }

    const isApple = (this.platform.SAFARI || this.platform.IOS || this.platform.WEBKIT);
    const timeout = isApple ? 60000 : 5000;

    let swActivated = navigator.serviceWorker.controller != null;

    if (!swActivated) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        console.log('Service worker is now controlling the page.');
        swActivated = true;
        this.hideLoader();
      });

      setTimeout(() => {
        if (!swActivated) {

          window.location.reload();
        }
      }, timeout);
    } else {
      this.hideLoader();
    }
  }

  show(showSpinner: { show: boolean, message: string }) {
    Console.log('Main: show spinner', showSpinner);
    this.spinnerMessage = showSpinner.message;
    this.showSpinner = showSpinner.show;
  }

  doneAuthz() {
    console.log('doneAuthz');
    this.done = true;
    if (this.apiSvc.updateAvailable) {
      setTimeout(() => {
        this.updateApp();
      }, 300000 //5 minutes
      );
    }
    setInterval(() => {
      this.pingServiceWorker();
    }, 10000);

    this.lastPingTime = Date.now();
    // Attach user activity event listeners
    document.body.addEventListener('mousemove', () => { this.debouncedPing() });
    document.body.addEventListener('mouseup', () => { this.debouncedPing() });
    document.body.addEventListener('keyup', () => { this.debouncedPing() });
    document.body.addEventListener('touchstart', () => { this.debouncedPing() });

    const tier = this.authzSvc.appConfig.tier;
    if (tier === 1 || tier === 2 || tier === 3) {
      this.showSpaces = true;
    }
  }

  private debouncedPing(): void {
    const currentTime = Date.now();
    if (currentTime - this.lastPingTime >= 10000) {  // 10 seconds
      if (this.authzSvc.isAuthorized()) {
        this.authzSvc.ping();
        this.lastPingTime = currentTime;
      }
    }
  }

  //ping service worker to keep it alive
  private async pingServiceWorker() {
    try {
      fetch('/ping');
    } catch (e) {
      Console.log('Error while pinging service worker', e);
    }
  }

  authzStarted() {
    this.authStarted = true;
  }

  async updateApp() {
    if (this.updateCalled) {
      return;
    }

    this.updateCalled = true
    console.log('updateApp called');
    const result = await this.modalController.displayQuestion(this.translate.instant('MAIN.UP_AVAIL.TITLE'), this.translate.instant('MAIN.UP_AVAIL.MSG', { sig: this.apiSvc.updateAvailable }), this.translate.instant('CANCEL'), null, this.translate.instant('APP_CONFIG.UPDATE.BTN'));
    if (result == 'TWO') {
      this.modalController.displaySpinner(true);
      this.swUpdate.versionUpdates.subscribe(async (event) => {
        Console.log('version ready', event);
        if (event.type == 'VERSION_READY') {
          this.modalController.displayMessage(this.translate.instant('MAIN.UP_COMP.TITLE'), this.translate.instant('MAIN.UP_COMP.MSG'));
          setTimeout(() => {
            location.reload();
          }, 2000);
        } else if (event.type == 'VERSION_INSTALLATION_FAILED') {
          Console.error('Error while updating', event.error);
          this.modalController.displayMessage(this.translate.instant('MAIN.UP_ERROR.TITLE'), this.translate.instant('MAIN.UP_ERROR.MSG') + "\n" + event.error);
          this.modalController.displaySpinner(false);
        } else {
          Console.log('version updates event', event);
          this.modalController.displaySpinner(false);
        }
      });
      await this.swUpdate.activateUpdate();
    }
    this.updateCalled = false;
    this.hideLoader();
  }

  private hideLoader() {
    if(this.updateCalled){
      return;
    }
    const loadingScreen = document.getElementById('loading-screen');
    if (loadingScreen) {
      loadingScreen.style.display = 'none';
    } else {
      alert('loadingScreen not found');
      Console.log('loadingScreen not found');
    }
    this.swready = true;
  }

  isNavigationLocked = false;
  async onSpaceChange(newPage: number) {
    this.isNavigationLocked = true;
    try {
      const url = this.route.url;
      await this.db.setSpace(newPage);
      //get current label from route. if current label exists in new space, navigate to it else navigate to root.
      if (url && url.startsWith('/label')) {
        const ename = url.split('/')[2];
        const name = decodeURIComponent(ename);
        const labels = this.Shared.label.list.map((label) => { return label.name });
        if (labels.includes(name)) {
          this.route.navigate(['label', name]);
        } else {
          this.route.navigate(['/']);
        }
      }
    } finally {
      this.isNavigationLocked = false;
    }
  }

  async onAddSpace() {
    this.isNavigationLocked = true;
    try {
      await this.db.addSpace();
    } finally {
      this.isNavigationLocked = false;
    }
  }
  async onSpaceNameChange($event: { space: number; name: string; }) {
    this.isNavigationLocked = true;
    try {
      await this.db.setSpaceName($event.space, $event.name);
    } finally {
      this.isNavigationLocked = false;
    }
  }
}
