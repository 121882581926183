<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" > {{'CONFIG.PIN.SELECT_PIN'| translate}} </h2>
        <div #authBody class="auth-body">
          <div  class="textHolder notranslate" [innerHTML]="'CONFIG.PIN.NEW.MSG'| translate"> </div>
          <div class="warning-container">
            <div class="warning-item" *ngFor="let warning of warnings; let i = index">
              <input type="checkbox" [id]="'warning' + i" [(ngModel)]="warning.checked" [name]="'warning' + i">
              <label [for]="'warning' + i">{{warning.message}}</label>
            </div>
          </div>
        </div>
        <div class="button-container">
          <button class="btn-primary" [disabled]="!allChecked()" (click)="next()"> {{ 'NEXT' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
