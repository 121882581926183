<app-navbar *ngIf="done"></app-navbar>
<section class="main-section">
  <div class="d-flex">
    <div *ngIf="done">
      <app-sidenav></app-sidenav>
    </div>
    <div class="w-100 padding">
      <router-outlet *ngIf="done"></router-outlet>
      <app-authz *ngIf="!done && swready" (done)="doneAuthz()" (started)="authzStarted()"></app-authz>
      <app-error></app-error>
      <app-spinner *ngIf='showSpinner' [message]='spinnerMessage'></app-spinner>
    </div>
  </div>
</section>
<helpComponent></helpComponent>
<app-modals></app-modals>
<app-spacecontrol *ngIf="done && showSpaces" [totalSpaces]="db.spaces.length" [currentSpaceIndex]="db.currentSpaceNumber" (spaceChange)="onSpaceChange($event)"
  (newSpace)="onAddSpace()" [isLocked]="isNavigationLocked" [currentSpaceName]="db.spaceName"
  (spaceNameChange)="onSpaceNameChange($event)"></app-spacecontrol>
