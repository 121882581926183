import { CommonModule } from '@angular/common';
import { Component, ElementRef, ViewChild, Output, EventEmitter, OnInit } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HelpDirective } from '../../help/help-directive';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Console } from 'src/app/lib/console';
import { FormsModule } from '@angular/forms';
import { NetworkService } from 'src/app/services/network.service';

@Component({
  selector: 'auth-start-modal',
  templateUrl: './start-modal.component.html',
  styleUrls: ['./start-modal.component.scss'],
  imports: [CommonModule, TranslateModule, HelpDirective, FormsModule],
  standalone: true
})
export class AuthStartModalComponent implements OnInit {

  @Output() selection = new EventEmitter<string>();
  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>

  imageSrc = 'assets/images/Unolock-Logo_Subline_black.svg';
  supported = true
  warning: SafeHtml | undefined;
  currentLang = 'en';

  constructor(public translate: TranslateService, private sanitizer: DomSanitizer, public networkSvc: NetworkService) {
  }

  ngOnInit(): void {
    this.currentLang = this.translate.getBrowserLang() || this.translate.getDefaultLang();
    this.translate.use(this.currentLang);
    if (!window.PublicKeyCredential) {
      this.translate.get('AUTH.START.NOT_SUPPORTED').subscribe((htmlString: string) => {
        Console.log(htmlString);
        this.warning = this.sanitizer.bypassSecurityTrustHtml(htmlString);
        Console.log(this.warning);
        this.supported = false;
      });
    }
  }

  changeLanguage(lang: string) {
    this.currentLang = lang;
    this.translate.use(lang);
  }

  buttonClick(selected: string) {
    this.selection.emit(selected);
  }
}
