
<div *ngIf="isAffiliate" class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title">
          <p> Your Affiliate Information </p>
        </div>
        <div *ngIf="suffix" class="auth-body affiliate-container">
          <input type="text" id="affiliate-url" class="affiliate-url-input" [value]="baseUrl+suffix" readonly>
          <div class="button-container">
            <button [disabled]="!suffix" class="btn"(click)="copyAffiliateLink()">{{'COPY' | translate}}</button>
            <button [disabled]="!affiliate_data?.url" class="btn" (click)="goToDashboard()">Dashboard</button>
          </div>
        </div>
        <div class="button-container">
          <button class="btn-primary" (click)="cancel()"> {{'CLOSE' | translate}} </button>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modals></app-modals>
