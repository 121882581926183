import { NgIf } from '@angular/common';
import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as QRCode from 'qrcode';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'qrcode',
  template: `<img [src]="dataUrl"/>`,
  standalone: true,
  imports: [NgIf]
})
export class QrCodeComponent implements OnChanges, AfterViewInit {
  @Input() level: string = 'H';
  @Input() size: number = 300;
  @Input() value: string = '';
  dataUrl: string = '';

  constructor() {
  }
  ngAfterViewInit(): void {
    this.generate();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.generate();
  }

  generate() {
    if (!this.value) return; // Make sure value is not empty

    const options = {
      errorCorrectionLevel: this.level,
      margin: 1,
      width: this.size
    };

    QRCode.toDataURL(this.value, options)
      .then((url: string) => {
        this.dataUrl = url;
      })
      .catch((err: { message: any; }) => {
        Console.error(`Could not generate QR Code: ${err.message}`);
      });
  }
}
