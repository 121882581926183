import { Component } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EyesEncryptionService } from './services/eyesEncryption.service';
import { EyesAddressService } from './services/eyesAddress.service';
import { QuillModule } from 'ngx-quill';
import { NgFor, NgIf } from '@angular/common';
import { EyesHeader } from './interfaces/eyesHeader';

@Component({
  selector: 'app-eyesonly',
  standalone: true,
  imports: [ReactiveFormsModule, QuillModule, NgIf,NgFor],
  templateUrl: './eyesonly.component.html',
  styleUrls: ['./eyesonly.component.scss']
})
export class EyesonlyComponent {
 private isMobile = window.innerWidth < 600;
  messageForm: FormGroup;
  attachments: File[] = [];
  quillModules = {
    toolbar: {
      container: this.isMobile ? [
        ['bold', 'italic', 'underline'],        // Simplified toolbar for mobile
        [{ 'list': 'bullet' }, { 'list': 'ordered' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      ] : [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['formula'],
        ['clean']
      ]
    }
  };
  loading = false;
  errorMessage: string | null = null;
  recipientError: string | null = null; // error message for recipient validation

  constructor(
    private fb: FormBuilder,
    private encryptionService: EyesEncryptionService,
    private addressService: EyesAddressService
  ) {
    this.messageForm = this.fb.group({
      recipient: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }

  /**
   * When the recipient field loses focus.
   */
  async onRecipientBlur(): Promise<void> {
    await this.validateRecipient();
  }

  /**
   * When the user presses Enter in the recipient field.
   */
  async onRecipientEnter(event: any): Promise<void> {
    // Prevent form submission if Enter is pressed in the recipient field.
    event.preventDefault();
    await this.validateRecipient();
  }

  /**
   * Validate the recipient by fetching the public key.
   * Sets recipientError if no public key is found.
   */
  private async validateRecipient(): Promise<void> {
    const recipient = this.messageForm.get('recipient')?.value;
    if (recipient) {
      try {
        const publicKey = await this.addressService.getPublicKey(recipient);
        if (!publicKey) {
          this.recipientError = 'Recipient address not found.';
        } else {
          this.recipientError = null;
        }
      } catch (error) {
        console.error('Error fetching public key:', error);
        this.recipientError = 'Error fetching recipient public key.';
      }
    } else {
      this.recipientError = null;
    }
  }

  /**
   * Handle file selection event.
   */
  onFileChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files.item(i);
        if (file) {
          this.attachments.push(file);
        }
      }
    }
  }

  /**
   * Remove an attachment by its index.
   */
  removeAttachment(index: number): void {
    if (index >= 0 && index < this.attachments.length) {
      this.attachments.splice(index, 1);
    }
  }

  /**
   * Called when the user clicks "Send Message".
   */
  async sendMessage(): Promise<void> {
    // Ensure the recipient has been validated.
    await this.validateRecipient();
    if (this.messageForm.invalid || this.recipientError) {
      return;
    }

    this.loading = true;
    this.errorMessage = null;

    const { recipient, subject, message } = this.messageForm.value;

    try {
      // Lookup public key for the recipient (if it somehow wasn't already loaded).
      const recipientPublicKey = await this.addressService.getPublicKey(recipient);
      if (!recipientPublicKey) {
        throw new Error('Recipient public key not found.');
      }

      // Build header object based on your internal header format.
      const header: EyesHeader = {
        subject: subject,
        message: message,
        timestamp: Date.now(),
        attachments: []
      };

      // Optionally, add attachment info to header message.
      for (const file of this.attachments) {
        header.message += `\nAttachment: ${file.name} (${file.size} bytes)`;
      }

      // Seal (encrypt) the message payload.
      const encryptedPayload: ArrayBuffer = await this.encryptionService.seal(header, this.attachments, recipientPublicKey);

      // Reset form and attachments on success.
      this.messageForm.reset();
      this.attachments = [];
      this.downloadArrayBuffer(encryptedPayload, 'message.bin');
    } catch (error) {
      console.error('Encryption error:', error);
      this.errorMessage = (error as Error).message;
    } finally {
      this.loading = false;
    }
  }

  downloadArrayBuffer(buffer: ArrayBuffer, filename: string, mimeType = 'application/octet-stream'): void {
    // Wrap the ArrayBuffer in a Blob.
    const blob = new Blob([buffer], { type: mimeType });

    // Create an object URL for the Blob.
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger the download.
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // This sets the filename for the download.

    // Append the anchor to the DOM (required for Firefox).
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download.
    a.click();

    // Cleanup: remove the anchor and revoke the object URL.
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
