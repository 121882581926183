import { CheckboxI, RecordI } from '../../../interfaces/records';
import { Component, OnInit, ViewChild, ElementRef, ViewChildren, QueryList, OnDestroy, Input, AfterViewChecked } from '@angular/core';
// @ts-ignore
import Bricks from 'bricks.js'
import { SharedService } from 'src/app/services/shared.service';
import { bgImages, bgColors, bgColorsConst, bgImagesConst } from 'src/app/interfaces/tooltip';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthzService } from 'src/app/services/authz.service';
import { RecordsToolsPipe } from '../../../pipes/records-tools.pipe';
import { CboxSortPipe } from '../../../pipes/cbox-sort.pipe';
import { ph } from '../../../pipes/ph.pipe';
import { InputComponent } from '../input/input.component';
import { NgTemplateOutlet, NgIf, NgFor, NgStyle, SlicePipe, KeyValuePipe } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LabelI } from 'src/app/interfaces/labels';
import { RecordArchive } from 'src/app/interfaces/archive';
import { ModalComponent } from '../../modal/modal.component';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { RecordsService } from 'src/app/services/records.service';
import { FileAttachmentLib } from '../fileAttachmentLib';
import { QuillModule } from 'ngx-quill';
import { Wallet } from 'src/app/lib/wallet';
@Component({
  selector: 'app-records',
  templateUrl: './records.component.html',
  styleUrls: ['./records.component.scss'],
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgIf,
    InputComponent,
    NgFor,
    NgStyle,
    SlicePipe,
    KeyValuePipe,
    ph,
    CboxSortPipe,
    RecordsToolsPipe,
    TranslateModule,
    QuillModule
  ],
})
export class RecordsComponent implements OnInit, OnDestroy, AfterViewChecked {


  constructor(public Shared: SharedService, private apiSvc: ApiService, private recordsSvc: RecordsService, private route: ActivatedRoute, public authSvc: AuthzService, private translate: TranslateService, private router: Router) {
    if (this.authSvc.appConfig.tier === 0 || this.authSvc.appConfig.tier === 4) this.walletSupport = false;
  }

  @ViewChild("mainContainer") mainContainer!: ElementRef<HTMLInputElement>;
  @ViewChild("modalContainer") modalContainer!: ElementRef<HTMLInputElement>;
  @ViewChild("modal") modal!: ElementRef<HTMLInputElement>;
  @ViewChild("labelError") labelError !: ElementRef<HTMLInputElement>
  @ViewChildren('recordEl') recordEl!: QueryList<ElementRef<HTMLDivElement>>;
  @ViewChildren('title') title!: QueryList<ElementRef<HTMLDivElement>>;
  @Input() modalController!: ModalComponent
  public walletSupport = true;

  touchEnabled = ("ontouchstart" in document.documentElement);

  currentPageName = 'home';
  bgColorsConst = bgColorsConst;
  bgImagesConst = bgImagesConst;
  labels: LabelI[] = [];
  recordWidth = 360;
  clickedRecordData: RecordI = {} as RecordI;

  trackBy(item: any) { return item.id };
  showLableName = false;
  private setSidebarMode() {
    if (this.currentPageName != 'home' && this.currentPageName != 'Wallet') {
      const sideBar: HTMLElement = document.querySelector('[sideBar]')!;
      if (sideBar.classList.contains('close') || sideBar.classList.contains('hidden')) {
        this.showLableName = true;
      }
    } else {
      this.showLableName = false;

    }
  }
  openFile(archive: RecordArchive) {
    this.router.navigate(['/archives', archive.archiveID]);
  }

  buildMasonry() {
    let clientWidth = this.mainContainer.nativeElement.clientWidth;
    const bodyWidth = document.body.clientWidth;

    //The following is a hack to fix the issue of the sidebar affecting teh width can causing resize bouncing
    const sideBar: HTMLElement = document.querySelector('[sideBar]')!;
    const sidNavClosed = sideBar.classList.contains('close');
    if (sidNavClosed && clientWidth > (bodyWidth - 100)) clientWidth = bodyWidth - 100;

    const islist = this.Shared.recordViewType.value === 'list';
    let gutter = 25;
    let totalRecordWidth = this.recordWidth + gutter;
    let containerWidth = clientWidth;
    let numberOfColumns = 0;
    let masonryWidth = '0px';

    // --
    if (!islist) {
      this.recordWidth = Math.min(380, clientWidth);
      numberOfColumns = Math.floor(containerWidth / totalRecordWidth);
    } else {
      if (clientWidth >= 610) {
        // 610 + half the difference between the record width and the client width
        const halfDiff = (clientWidth - 610) / 1.25;
        this.recordWidth = 610 + halfDiff - 10;
      } else {
        this.recordWidth = clientWidth - 10;
      }
      numberOfColumns = 1;
    }
    document.documentElement.style.setProperty('--record-width', this.recordWidth + "px");

    // --
    const sizes = [{ columns: numberOfColumns, gutter: gutter }];
    this.recordEl.toArray().forEach(el => {
      brikcs(el.nativeElement);
      if (el.nativeElement.style.width) {
        masonryWidth = el.nativeElement.style.width;
      }
    });
    function brikcs(node: HTMLDivElement) { const instance = Bricks({ container: node, packed: 'data-packed', sizes: sizes, position: false }); instance.pack() };

    window.onresize = () => {
      if (islist && clientWidth > 800) this.Shared.recordViewType.next('grid');
      if (islist && window.innerWidth < 801) this.Shared.recordViewType.next('list');

    };
    //? we align the titles to the masonry width
    this.title.forEach(el => {
      if (islist) el.nativeElement.style.maxWidth = masonryWidth;
      else el.nativeElement.style.maxWidth = '';
    })
  }

  getPinTranslation(pinned: boolean): string {
    return pinned ? this.translate.instant('RECORDS.UNPIN') : this.translate.instant('RECORDS.PIN');
  }

  // called when a record is clicked and opened
  openRecord(clickedRecord: HTMLDivElement, recordData: RecordI) {
    this.Shared.record.id = recordData.id!;
    this.clickedRecordData = recordData;
    this.clickedRecordEl = clickedRecord;
    let modalContainer = this.modalContainer.nativeElement;
    let modal = this.modal.nativeElement;
    this.setModalStyling();
    setTimeout(() => { modal.removeAttribute("style") });
    clickedRecord.classList.add('hide');
    modalContainer.style.display = 'block';;
    document.addEventListener('mousedown', this.mouseDownEvent);
  }

  labelClicked(recordData: RecordI, label: LabelI, event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    this.clickedRecordData = recordData;
    if (label.name == 'Wallet' && this.walletSupport) {
      this.openWalletModal();
      return;
    }
    document.body.style.cursor = 'default';
    this.router.navigate(['/label', label.name]);
  }

  mouseDownEvent = (event: Event) => {
    let isTooltipOpen = !!document.querySelector('[data-is-tooltip-open="true"]');
    isTooltipOpen = isTooltipOpen;

    let modalEL = this.modal.nativeElement
    if (!(modalEL as any).contains(event.target)) {
      if (!isTooltipOpen) {
        Console.log('Close Modal');
        this.Shared.saveRecord.next(true);
        this.closeModal();
      }
    }
  }

  clickedRecordEl!: HTMLDivElement // needed in setModalStyling()
  closeModal() {
    document.removeEventListener('mousedown', this.mouseDownEvent);
    let modalContainer = this.modalContainer.nativeElement;
    this.setModalStyling();
    setTimeout(() => {
      if (this.clickedRecordEl) {
        this.clickedRecordEl.classList.remove('hide');
      }
      modalContainer.style.display = 'none';
    }, 400);
  }

  setModalStyling() {
    if (!this.clickedRecordEl) return;
    let bounding = this.clickedRecordEl.getBoundingClientRect();
    let modal = this.modal.nativeElement;
    modal.style.transform = `translate(${bounding.x}px, ${bounding.y}px)`;
    modal.style.width = bounding.width + 'px';
    modal.style.height = bounding.height + 'px';
    modal.style.top = `0`;
    modal.style.left = `0`;
  }

  checkBoxTools(record: RecordI, event: Event) {
    this.Shared.record.id = record.id!;
    event?.stopPropagation();
    let actions = {
      check: (cb: CheckboxI) => {
        cb.done = !cb.done;
      },
      remove: (cb: CheckboxI) => {
        let index = record.checkBoxes?.findIndex(x => x === cb);
        if (index !== undefined) record.checkBoxes?.splice(index, 1);
      }
    }
    this.Shared.record.db.updateKey({ checkBoxes: record.checkBoxes });
    return actions;
  }

  async togglePin(recordId: number, pinned: boolean) {
    this.Shared.record.id = recordId;
    pinned = !pinned;
    this.Shared.record.db.updateKey({ pinned: pinned });
  }

  Ttbutton?: HTMLDivElement // used in moreMenu.openLabelMenu
  async openTooltip(button: HTMLDivElement, tooltipEl: HTMLDivElement, recordData: RecordI) {
    this.clickedRecordData = recordData;
    this.Shared.record.id = recordData.id!;
    this.Ttbutton = button;
    this.Shared.createTooltip(button, tooltipEl);
  }

  moreMenu(tooltipEl: HTMLDivElement) {
    let actions = {
      trash: async () => {
        if (this.clickedRecordData.wallet || this.clickedRecordData.archive) {
          //'No Delete with Wallet or Archive
          return;
        }
        const result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_CONFIRMATION'), this.translate.instant('CANCEL'), null, this.translate.instant('RECORDS.DELETE'));
        if (result === 'TWO') {
          this.Shared.record.db.delete();
        }
      }, attach: async () => {
        this.attachFile();
      },
      wallet: async () => {
        this.openWalletModal();
      },
      toggleReadOnly: async () => {
        this.toggleReadOnly();
      },
      clone: async () => {
        this.Shared.record.db.clone();
      },
      openLabelMenu: (tooltipEl: HTMLDivElement) => {
        this.labels = JSON.parse(JSON.stringify(this.Shared.label.list))
        this.Shared.createTooltip(this.Ttbutton!, tooltipEl)
        this.Shared.record.db.get().then(record => {

          record.labels.forEach(recordLabel => {
            let label = this.labels.find(x => x.name === recordLabel.name)
            if (label) label.added = recordLabel.added
          })
        })
      }
    }
    this.Shared.closeTooltip(tooltipEl);
    return actions;
  }

  colorMenu = {
    bgColor: async (data: bgColors) => {
      await this.Shared.record.db.updateKey({ bgColor: data.bg });
      await this.Shared.record.db.updateKey({ color: data.color })
      this.Shared.saveRecord.next(true);
      this.closeTooltip();
    },
    bgImage: async (data: bgImages) => {
      await this.Shared.record.db.updateKey({ bgImage: `url(${data.img})` });
      await this.Shared.record.db.updateKey({ color: data.color });
      this.closeTooltip();
    }
  }
  labelMenuClick(label: LabelI) {
    label.added = !label.added
    this.Shared.record.db.updateKey({ labels: this.labels });
    this.closeTooltip();
  }

  removeLabel(record: RecordI, label: LabelI) {
    this.Shared.record.id = record.id!
    label.added = !label.added
    this.Shared.record.db.updateKey({ labels: record.labels })
  }

  // ? labels ----------------------------------------------------

  async addLabel(el: HTMLInputElement) {
    if (!el) return
    try {
      const labelName = el.value;
      this.labelError.nativeElement.hidden = true;
      el.value = '';
      await this.addLabelByName(labelName);
      this.closeTooltip();
    } catch (x: any) {
      Console.error(x);
      if (x.message === "Name Exists") {
        this.labelError.nativeElement.hidden = false; el.focus();
      }
    }
  }

  private async addLabelByName(labelName: string) {
    // do not readd if alread in  list
    const index = this.labels.findIndex(x => x.name === labelName);
    if (index !== -1) return;

    const labels = this.Shared.label.list;
    let cl = labels.find((x: { name: string; }) => x.name === labelName);
    if (!cl) {
      const id = await this.Shared.label.db.add({ name: labelName });
      cl = { id: id, name: labelName, added: true };
    }
    if (cl) {
      cl.added = true;
      this.labels.push(cl);
      this.Shared.record.db.updateKey({ labels: this.labels })
    } else {
      Console.error('Label not found');
    }
  }

  private closeTooltip() {
    const element = document.querySelector('[data-is-tooltip-open="true"]');
    element?.removeAttribute('data-is-tooltip-open');
  }

  async removeRecord(recordId: number) {
    this.Shared.record.id = recordId;
    const result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_CONFIRMATION'), this.translate.instant('CANCEL'), null, this.translate.instant('RECORDS.DELETE'));
    if (result === 'TWO') {
      await this.Shared.record.db.delete();
    }
  }

  async attachFile() {
    Console.log('Attach File');
    const lib = new FileAttachmentLib(this.apiSvc, this.modalController, this.translate, this.recordsSvc, this.clickedRecordData);
    const archive = await lib.attachFile();
    if (archive) {
      this.Shared.record.db.updateKey({ archive: archive });
    }
    Console.log('Archive', archive);
  }

  getCoinIcon(wallet: Wallet | undefined): string {
    if (!wallet) return '';
    if (typeof wallet == 'object') {
      wallet = Wallet.fromJSON(wallet);
    }
    return wallet.getIcon();
  }

  private async openWalletModal() {
    if (!this.walletSupport) {
      return;
    }
    this.modalController.displaySpinner(true);
    let current = this.clickedRecordData.wallet;
    const wallet = await this.modalController.displayWallet(current ? current : null);
    if (wallet) {
      if (current && wallet.address == current.address) {
        // no change
        return;
      } else {
        if (!current) { // new wallet
          if (!this.clickedRecordData.labels.find(x => x.name === 'Wallet')) {
            await this.addLabelByName('Wallet');
          } else {
            Console.log('Wallet Label already exists on this record');
          }
        }
        this.clickedRecordData.wallet = wallet;
        this.Shared.record.db.updateKey({ wallet: wallet });
      }
    }
  }

  private async toggleReadOnly() {
    this.clickedRecordData.ro = !this.clickedRecordData.ro;
    Console.log('Toggle RO', this.clickedRecordData.ro);
    this.Shared.record.db.updateKey({ ro: this.clickedRecordData.ro });
  }

  ngAfterViewChecked() {
    this.buildMasonry();
  }

  private closeSideBarSub!: Subscription;
  private closeModalSub!: Subscription;
  private recordViewSub!: Subscription;
  private routeSub!: Subscription;

  ngOnInit(): void {

    //Set list as default on mobile
    if (window.innerWidth < 801) this.Shared.recordViewType.next('list');

    this.closeSideBarSub = this.Shared.setSideBar.subscribe(() => { this.setSidebarMode(); setTimeout(() => { this.buildMasonry() }, 200) });
    this.closeModalSub = this.Shared.closeModal.subscribe(x => { if (x) this.closeModal() });
    this.recordViewSub = this.Shared.recordViewType.subscribe(() => { setTimeout(() => this.buildMasonry(), 300); });
    this.routeSub = this.route.params.subscribe((event: any) => {
      Console.log('loadingby browse : event =', event);
      if (event['name']) {
        //load label
        const labelName = event['name'];
        this.currentPageName = labelName ? labelName : 'home';
      } else if (event['id']) {
        this.currentPageName = 'home';
        //load record
        const recordId = event['id'];
        setTimeout(() => {
          const element = <HTMLDivElement>document.getElementById('record' + recordId);
          if (!element) {
            Console.error('Element not found');
            return;
          }
          let record = this.Shared.record.all.find(x => x.id == recordId);
          if (!record) {
            Console.error('Record not found');
            return;
          }
          Console.log('Element ', element);
          this.openRecord(element, record);
        }, 1000);
      } else {
        this.currentPageName = 'home';
      }
      this.setSidebarMode();
    })
  }

  ngOnDestroy(): void {
    this.closeSideBarSub.unsubscribe();
    this.closeModalSub.unsubscribe();
    this.recordViewSub.unsubscribe();
    this.routeSub.unsubscribe();
  }
}
