<div class="modal-container">
  <div class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title">
          <h2 appHelp="MODALS.WALLET.TRANSFER.HELP">{{ 'MODALS.WALLET.TRANSFER.TRANSFER' | translate }}</h2>
        </div>
        <div class="auth-body">
          <form *ngIf="!signedTransaction" [formGroup]="transferForm">
            <div class="form-group">
              <label for="recipient">{{ 'MODALS.WALLET.TRANSFER.RECIPIENT_ADDRESS' | translate }}</label>
              <input type="text"  id="recipient" formControlName="recipient"
              [placeholder]="'MODALS.WALLET.TRANSFER.ENTER_RECIPIENT_ADDRESS' | translate"
              appAutocomplete  [suggestions]="wallet.savedAddresses" (itemChanged)="addressListChanged()" required>
              <div
                *ngIf="transferForm.get('recipient')?.invalid && (transferForm.get('recipient')?.dirty || transferForm.get('recipient')?.touched)"
                class="error-message">
                <div *ngIf="transferForm.get('recipient')?.errors?.['required']">{{ 'MODALS.WALLET.TRANSFER.RECIPIENT_REQUIRED' | translate }}
                </div>
                <div *ngIf="transferForm.get('recipient')?.errors?.['invalidAddress']">{{
                  'MODALS.WALLET.TRANSFER.INVALID_ADDRESS' | translate
                  }}</div>
              </div>
            </div>
            <div class="form-group">
              <label for="amount">{{ 'MODALS.WALLET.TRANSFER.AMOUNT' | translate }} ({{ wallet.coinType }})</label>
              <input type="text" id="amount" formControlName="amount" (input)="onInputChange($event)" required>
              <div
                *ngIf="transferForm.get('amount')?.invalid && (transferForm.get('amount')?.dirty || transferForm.get('amount')?.touched)"
                class="error-message">
                <div *ngIf="transferForm.get('amount')?.errors?.['required']">{{ 'MODALS.WALLET.TRANSFER.AMOUNT_REQUIRED' |
                  translate }}</div>
                <div *ngIf="transferForm.get('amount')?.errors?.['invalidFormat']">{{ 'MODALS.WALLET.TRANSFER.INVALID_AMOUNT_FORMAT' |
                  translate }}</div>
                <div *ngIf="transferForm.get('amount')?.errors?.['tooManyDecimals']">{{
                  'MODALS.WALLET.TRANSFER.TOO_MANY_DECIMALS' | translate
                  }}</div>
                <div *ngIf="transferForm.get('amount')?.errors?.['invalidAmount']">{{ 'MODALS.WALLET.TRANSFER.INVALID_AMOUNT' |
                  translate }}
                </div>
                <div *ngIf="transferForm.get('amount')?.errors?.['amountTooSmall']">{{ 'MODALS.WALLET.TRANSFER.AMOUNT_TOO_SMALL'
                  | translate }}
                </div>
                <div *ngIf="transferForm.get('amount')?.errors?.['insufficientFunds']">{{
                  'MODALS.WALLET.TRANSFER.INSUFFICIENT_FUNDS' |
                  translate }}</div>
              </div>
            </div>

            <div class="button-container">
              <button (click)="manageProvider()" class="btn"  >
                {{ apiKeyPair?.provider }}
              </button>
            </div>


            <div class="form-group">
              <input type="range" [min]="0" [max]="balance.toString()" [step]="1000" [value]="0"
                (input)="onSliderChange($event)">
              <span>{{ wallet.convertToLargestAmount(amount?.value || '0') }} {{ wallet.coinType }}</span>
            </div>
            <div class="button-container">
              <button class="btn" type="cancel" (click)="cancel()" type="button">
                {{ 'CANCEL' | translate }}
              </button>
              <button class="btn-primary" [disabled]="!transferForm.valid" (click)="generateTransaction()" type="button">
                {{ 'MODALS.WALLET.TRANSFER.TRANSFER' | translate }}
              </button>
            </div>
          </form>
          <form *ngIf="signedTransaction" class="signed-transaction">
            <label for="signedTransaction">{{ 'MODALS.WALLET.TRANSFER.SIGNED_TRANSACTION' | translate }}</label>
            <div class="form-group display-container">
              <div appCopyText [copyText]="signedTransaction" #hexDisplay class="display">{{ signedTransaction }}</div>
            </div>

            <label for="txamount">{{ 'MODALS.WALLET.TRANSFER.TRANSFER_AMOUNT' | translate }}:</label>
            <div class="form-group display-container">
              <div id="txamount" class="display">{{ transferedAmount }}: ({{ wallet.coinType }})</div>
            </div>

            <label for="fee">{{ 'MODALS.WALLET.TRANSFER.FEE' | translate }} </label>
            <div class="form-group display-container">
              <div id="fee" class="display">{{ fee }} {{feeUnit}}</div>
            </div>

            <label for="txid">{{ 'MODALS.WALLET.TRANSFER.TXID' | translate }}:</label>
            <div class="form-group display-container">
              <div appCopyText [copyText]="txid" id="txid" class="display">{{ txid }}</div>
            </div>

            <div class="button-container">
              <button class="btn" (click)="cancel()" type="button">
                {{ 'CLOSE' | translate }}
              </button>
              <button class="btn-primary" (click)="sendTransaction()" type="button">
                {{ 'MODALS.WALLET.TRANSFER.SEND' | translate }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <question-modal #questionemodal *ngIf="showQuestion" [data]="questionData"
  (response)="questionReturn($event)"></question-modal>
  <apikey-modal *ngIf="showApiKey" [wallet]="wallet" (response)="apiKeyReturn($event)"></apikey-modal>
