<div class="eyes-config-container">
  <h2>Eyes-Only Configuration</h2>

  <div class="config-section">
    <label>Current Eyes Address:</label>
    <input type="text" [value]="currentEyesAddress" readonly />
    <button class="btn-primary" disabled (click)="rotateEyesAddress()">Rotate Address</button>
  </div>

  <div class="config-section">
    <label>Available Key IDs:</label>
    <ul>
      <li *ngFor="let keyID of currentKeyIDs">{{ keyID*1000 | date: 'long' }}</li>
    </ul>
  </div>

  <div class="config-section">
    <label>Current Signing Key:</label>
    <span class="signing-key" *ngIf="!currentSigningKey">No signing key available</span>
    <span class="signing-key" *ngIf="currentSigningKey">{{ currentSigningKey*1000 | date: 'long' }}</span>
    <button disabled class="btn-primary" (click)="rotateKeys()">Rotate Keys</button>
  </div>

  <div class="config-section">
    <label>Notifications:</label>
    <button [disabled]="!apiSvc.serviceworker" class="btn-primary" (click)="registerForNotifications()">Register for Notifications</button>
  </div>

  <div class="config-section">
    <label>Address Book:</label>
    <button class="btn-primary" (click)="openAddressBook()">Open Address Book</button>
  </div>

</div>
<app-modals></app-modals>
