export abstract class Action {
  message: string[] = [];
  reason = '';
  request: any = {};
  result: any = {};
}
export class EmptyAction extends Action {

}
export class CallBack {
  state!: any; // Encrytped server side state data bounced of client
  exp?: number;
  safeExp?: any;

  constructor(public type: string, public action: Action) {
  }
}

export class CallBackEncryptedDTO {
  type = ''; // Used by client callback processor to identify the apropriate handler
  state!: string; // Encrytped server side state data bounced of client
  action!: string;

  constructor(callback: CallBack) {
    this.type = callback.type;
    this.state = callback.state
  }
}
