import { Injectable } from '@angular/core';
import { Wallet } from '../lib/wallet';
import { AddressType, CoinType, PaperWallet } from '../lib/paperWallet';
import { AuthzService } from './authz.service';
import { CallBack, EmptyAction } from '../interfaces/callback';
import * as bitcoin from 'bitcoinjs-lib';
import * as ecc from '@bitcoinerlab/secp256k1';

import { ApiService } from './api.service';
import { SharedService } from './shared.service';
import { CryptoTokenData } from '../interfaces/cryptoTokenData';
import { Console } from '../lib/console';


bitcoin.initEccLib(ecc);

@Injectable({
  providedIn: 'root',
})
export class WalletService {


  constructor(private authzSvc: AuthzService, private apiSvc: ApiService, private sharedSvc: SharedService) {
  }

  //---------------------- Wallet Management ----------------------
  public async createWallet(coinType: CoinType): Promise<Wallet> {
    const data = await PaperWallet.create(coinType);
    //encrypt private key
    data.wallet.privateKey = await this.apiSvc.authnEncryptData(data.privateKey);
    const firstHalf = data.mnemonic.split(' ').slice(0, 12).join(' ');
    const secondHalf = data.mnemonic.split(' ').slice(12).join(' ');
    //  data.wallet.mnemonic = await this.apiSvc.authnEncryptData(data.mnemonic);
    data.wallet.mnemonicFirstHalf = await this.apiSvc.authnEncryptData(firstHalf);
    data.wallet.mnemonicSecondHalf = await this.apiSvc.authnEncryptData(secondHalf);
    return data.wallet;
  }

  public async getWalletPrivateKey(wallet: Wallet, half: 'FIRST' | 'SECOND' | 'ALL' = 'ALL'): Promise<string> {
    let key = '';
    if (wallet.privateKey.length > 100) {
      key = await this.apiSvc.authnDecryptData(wallet.privateKey);
    } else {
      //encrypted private key
      const encrypteddata = await this.apiSvc.authnEncryptData(wallet.privateKey);
      const test = await this.apiSvc.authnDecryptData(encrypteddata);
      if (test === wallet.privateKey) {
        wallet.privateKey = encrypteddata;
        await this.sharedSvc.saveWallet(wallet);
        key = test;
      } else {
        key = wallet.privateKey;
      }
    }
    return key;
  }

  public async getMnemonic(wallet: Wallet, half: 'FIRST' | 'SECOND'): Promise<string> {
    let mns = '';
    if (wallet.mnemonic) {
      //backwards compatibility
      Console.log('Backwards compatibility mnemonic');
      const tmp = wallet.mnemonic;
      if (half === 'FIRST') {
        mns = tmp.split(' ').slice(0, 12).join(' ');
      } else if (half === 'SECOND') {
        mns = tmp.split(' ').slice(12).join(' ');
      }
    } else {
      if (half === 'FIRST') {
        return this.apiSvc.authnDecryptData(wallet.mnemonicFirstHalf);
      } else if (half === 'SECOND') {
        return  this.apiSvc.authnDecryptData(wallet.mnemonicSecondHalf);
      }
    }
    return mns
  }

  // Define the cache at the service level.
  private balanceCache: Map<string, { balance: string; tokens: CryptoTokenData[] }> = new Map();
  private CACHE_DURATION_MS = 3 * 60 * 1000; // 3 minutes
  /**
 *
 * @param wallet
 * @param nonToken  if true, get the balance of the coin ie: Eth, otherwise get the balance of the token
 * @returns
 */
  public async getBalance(wallet: Wallet, nonToken = false): Promise<{ balance: string; tokens: CryptoTokenData[] }> {
    if (nonToken && wallet.type !== AddressType.Ethereum) {
      throw new Error('Unsupported coin');
    }

    // Generate a unique key for caching, including wallet address and nonToken flag.
    const cacheKey = `${wallet.address}-${nonToken}`;

    // Check if the value is cached.
    if (this.balanceCache.has(cacheKey)) {
      return this.balanceCache.get(cacheKey)!;
    }

    const type = nonToken ? CoinType.ETH : wallet.coinType;
    const callback = new CallBack('GetCryptoBalance', new EmptyAction());
    const request = { type, address: wallet.address };
    callback.action.request = request;

    // Perform the API request if not cached.
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }

    // Convert the result to bigint and cache it.
    const data: { balance: string; tokens: CryptoTokenData[] } = result.action.result;
    //ToDo optimize this so that svgs are cached locally and we add then with missing in the returned data
    this.balanceCache.set(cacheKey, data);
    // Set a timeout to clear this cache after a specific duration.
    setTimeout(() => {
      this.balanceCache.delete(cacheKey);
    }, this.CACHE_DURATION_MS);
    return data;
  }

}
