import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { ApiService } from './api.service';
import { lastValueFrom } from 'rxjs';
import { AuthzService } from './authz.service';
import { ErrorService, ErrorType, SafeError } from './error.service';
import { Archive } from '../interfaces/archive';
import { chunkerTransformer } from '../lib/chunkerTransformer';
import { BroadcastChannelHandler } from '../lib/broadcastChannelHandler';
import { FileUploadMessage } from '../lib/fileUploadMessage';
import { Console } from '../lib/console';
import { toBase64 } from '@aws-sdk/util-base64-browser';
import * as LZString from 'lz-string';
import { TranslateService } from '@ngx-translate/core';
var MD5 = require('md5.js')

@Injectable({
  providedIn: 'root'
})
export class FileService {
  public static CHUNK_SIZE = 10 * 1024 * 1024; // 10MB
  private serviceWorkerBroadcastChannelHandler: BroadcastChannelHandler;
  private static eventsMap = new Map<string, any>();

  private static etagMap = new Map<string, string>();

  constructor(private errorSvc: ErrorService, private apiSvc: ApiService, private authzSvc: AuthzService, private http: HttpClient, private translate: TranslateService) {
    this.serviceWorkerBroadcastChannelHandler = new BroadcastChannelHandler('FileService', this.handleServiceWorkerMessage);
    this.authzSvc.authorized.subscribe(async (authorized) => {
      if (authorized) {
        this.serviceWorkerBroadcastChannelHandler.open();
      } else {
        this.serviceWorkerBroadcastChannelHandler.close();
      }
    });
  }

  /**
   * Convoluted due to histroy. The issue is that MRAP does not support Signed POST, but we need it for free tier to limit space used :-(. And once an archive is POSTED we can not get it through the service worker. So we flag it in the metadata as a post.
   * @param archive
   * @param data
   * @returns
   */
  public async sendData(archive: Archive, data: any): Promise<any> {
    const tier = this.apiSvc.getAppConfig().tier;
    const method = tier == 2 || tier == 3 ? 'lput' : 'post'; // Only Locksafe and Life safe support MRAP witch needs signed PUT

    const archiveID = archive.id;
    const type = archive.m.type ? archive.m.type : 'text/plain';
    const stringData = JSON.stringify(data);

    let tr: string | undefined = archive.m.tr;

    if (method != tr) {
      Console.log(`Method ${method} does not match ${tr}`);
      tr = method;
      if (!archive.m) archive.m = {};
      archive.m.tr = method;
      if (method == 'post' || method == 'lput') {
        //An archive update will be done later when doing a put but not nessesary for a post.
        await this.apiSvc.updateArchive(archive);
      }
    }

    const zipped = LZString.compressToBase64(stringData);

    if (tr == 'post') {
      let encrypted = await this.authzSvc.encryptString(zipped);
      // kek ensures that even if someone got the data and the client master data key, they would still not be able to decrypt the data without the client encrypted serverside data which is deleted when the archive is deleted or replaced.
      let kek = archive.m.kek;

      kek = this.authzSvc.crypto.xorEncryptedDataKeysInHeaderString(encrypted, kek);
      if (kek != archive.m.kek) {
        archive.m.kek = kek;
        await this.apiSvc.updateArchive(archive);
      }
      const blob = new Blob([encrypted], { type: type, });
      const file = new File([blob], "data", { lastModified: Date.now() });
      const linkObject = await this.apiSvc.getUploadPostObject(archiveID);

      return this.postFile(file, linkObject, archive.id);

    } else if (tr == 'put') {
      const blob = new Blob([zipped], { type: type, });
      const file = new File([blob], "data", { lastModified: Date.now() });
      const result: { archive: Archive, progress: EventEmitter<{ transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }> } = await this.sendFile(file, archive.m, false, archive);
      // we need to wait for the upload to complete before we can return or throw an error
      await this.waitForEmitterCompletion(result.progress);

    } else if (tr == 'lput') {
      let encrypted = await this.authzSvc.encryptString(zipped);
      // kek ensures that even if someone got the data and the client master data key, they would still not be able to decrypt the data without the client encrypted serverside data which is deleted when the archive is deleted or replaced.
      let kek = archive.m.kek;

      kek = this.authzSvc.crypto.xorEncryptedDataKeysInHeaderString(encrypted, kek);
      if (kek != archive.m.kek) {
        archive.m.kek = kek;
        await this.apiSvc.updateArchive(archive);
      }
      const blob = new Blob([encrypted], { type: type, });
      return this.putFile(blob, archiveID);

    } else {
      throw new Error('Unknown tr');
    }
  }

  private async putFile(blob: Blob, archiveID: string) {
    try {
      const md5 = new MD5().update(Buffer.from(await blob.arrayBuffer())).digest();
      const newEtag = `"${md5.toString('hex')}"`; // etage are quoted...
      Console.log('FileSvc: putData: calculated newEtag=', newEtag);
      const md5base64 = toBase64(md5);
      Console.log('FileSvc: putData', 'md5', md5base64);

      let currentEtag = FileService.etagMap.get(archiveID);
      Console.log('FileSvc: putData: retrieved currentEtag=', currentEtag);
      let url = '';
      try {
        url = await this.apiSvc.getUploadPutUrl(archiveID, md5base64, currentEtag, newEtag);
      } catch (error) {
        if (error instanceof SafeError && error.type == ErrorType.VALIDATION) {
          this.collisionDetected();
        } else {
          throw error;
        }
      }
      Console.log('FileSvc: putData', 'url', url);
      const headers: Record<string, string> = {};
      headers['Content-MD5'] = md5base64; // **MUST match the signed URL**

      if (currentEtag) {
        headers["If-Match"] = currentEtag; // Enforce conditional write
      }

      const response = await fetch(url, {
        method: "PUT",
        body: blob,
        headers: headers,
      });
      Console.log('FileSvc: putData', 'response', response);
      if (response.status === 412 || response.status === 409) {
        this.collisionDetected();
      }

      if (!response.ok) {
        throw new Error(`Upload failed with status: ${response.status} - ${response.statusText}`);
      }
      let returnedEtag = response.headers.get('ETag') || response.headers.get('etag'); // Ensure case insensitivity
      Console.log('FileSvc: putData', 'returned etag', returnedEtag);
      if (returnedEtag != newEtag) {
        Console.error('FileSvc: putData', 'Etag mismatch ' + returnedEtag + '!=' + newEtag);
        this.collisionDetected();
      }
      FileService.etagMap.set(archiveID, newEtag); // Store ETag in map
      return response;
    } catch (error) {
      console.error("Error uploading file:", error);
      throw error;
    }
  }

  private collisionDetected() {
    const message = this.translate.instant('RECORDS.CONFLICT');
    throw new SafeError(ErrorType.VALIDATION,
      message
    );
  }

  private async waitForEmitterCompletion(
    progress: EventEmitter<{
      transferred: number;
      stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR';
      error?: any;
    }>
  ): Promise<void> {
    return new Promise((resolve, reject) => {
      const subscription = progress.subscribe(event => {
        if (event.stage === 'COMPLETED') {
          subscription.unsubscribe(); // Correct way to clean up
          resolve();
        } else if (event.stage === 'ERROR') {
          subscription.unsubscribe(); // Correct way to clean up
          reject(event.error || new Error("Unknown error occurred during upload"));
        }
      });
    });
  }

  // data is encrypted by AES256_GCM_IV12_TAG16_NO_PADDING so no need to check the download hash here
  public async getData(archive: Archive): Promise<any> {

    const archiveID = archive.id;
    let tr: string = archive.m.tr;
    if (!tr) {
      //backwards compatibility
      tr = 'post';
    }

    let zipped: string;
    Console.log('FileSvc: getData', 'tr', tr);
    if (tr == 'post' || tr == 'lput') {
      const kek = archive.m.kek;
      let url: string;
      if (tr == 'lput') {
        url = await this.apiSvc.getDownloadUrl(archiveID);
      } else {
        url = await this.apiSvc.getRegionalDownloadUrl(archiveID);
      }

      let encrypted;
      try {
        encrypted = await this.fetchWithRetry<string>(url, 'text', archiveID, 15, 2000); // 15 retries, 2 sec delay

        if (kek) {
          try {
            this.authzSvc.crypto.xorEncryptedDataKeysInHeaderString(encrypted, kek);
          } catch (error) {
            Console.error('FileSvc: getData KEK error, trying put');
            archive.m.tr = 'put';
            try {
              Console.error('FileSvc: Error getData', 'Trying to update archive');
              return this.getData(archive);
            } catch (error) {
              throw new Error('KEK error');
            }
          }
        } else {
          Console.error('FileSvc: getData', 'No KEK found');
        }
        zipped = await this.authzSvc.decryptString(encrypted as string);
      } catch (error) {
        Console.error('FileSvc: getData', error);
        Console.error('FileSvc: getData', encrypted);
        archive.m.tr = 'put';
        Console.error('FileSvc: Error getData', 'Trying put');
        return this.getData(archive);
      }
    } else {
      //using service worker to decrypt
      Console.log('FileSvc: getData', 'Using service worker to decrypt');
      const url = '/archive/' + archiveID;
      const data = await this.fetchWithRetry<Blob>(url, 'blob', archiveID, 10, 2000); // 10 retries, 2 sec delay
      if (!data) {
        Console.error('FileSvc: getData', 'No data found');
        throw new Error('No data found');
      }
      zipped = await data.text();
    }

    return JSON.parse(LZString.decompressFromBase64(zipped));
  }

  private async chekEtag(url: string, etag: string) {
    console.log('FileSvc: checking etag', etag);
    // Check if the record has changed by doing a HEAD request and comparing ETags
    const response = this.http.head(url, { observe: 'response', headers: { 'ngsw-bypass': 'true' } });
    const head = await lastValueFrom(response) as HttpResponse<any>;
    const newEtag = head.headers.get('ETag') || head.headers.get('etag');

    if (newEtag !== etag) {
      console.error('FileSvc: getData', 'Etag mismatch ' + newEtag + '!=' + etag);
      throw new SafeError(ErrorType.VALIDATION,
        'The record has been modified by another user. Please refresh the page to see the latest changes.'
      );
    }
  }

  private async fetchWithRetry<T>(
    url: string,
    responseType: 'json' | 'text' | 'blob' | 'arraybuffer',
    archiveID: string,
    retries: number = 5,
    delayMs: number = 1000
  ): Promise<T> {  // Return both data & ETag
    for (let i = 0; i < retries; i++) {
      try {
        let request;

        // Explicitly handle each responseType and cast response as HttpResponse<T>
        switch (responseType) {
          case 'json':
            request = this.http.get<T>(url, { responseType: 'json', observe: 'response' });
            break;
          case 'text':
            request = this.http.get(url, { responseType: 'text', observe: 'response' });
            break;
          case 'blob':
            request = this.http.get(url, { responseType: 'blob', observe: 'response' });
            break;
          case 'arraybuffer':
            request = this.http.get(url, { responseType: 'arraybuffer', observe: 'response' });
            break;
        }

        // Convert Observable to Promise and assert type
        const response = await lastValueFrom(request as any) as HttpResponse<T>;

        // Extract ETag from response headers for optimistic conccurency control
        const etag = response.headers.get('ETag') || response.headers.get('etag'); // Ensure case insensitivity

        if (etag) {
          Console.log('FileSvc: etag', etag);
          FileService.etagMap.set(archiveID, etag); // Store ETag in map
        }
        return response.body as T

      } catch (error) {
        if (i === retries - 1) {
          this.errorSvc.process(new SafeError(ErrorType.OFFLINE, error));
          throw error;
        }
        console.error(`Attempt ${i + 1} failed. Retrying in ${delayMs}ms...`, error);
        await new Promise(resolve => setTimeout(resolve, delayMs));
      }
    }

    throw new Error('fetchWithRetry: Exhausted retries');
  }

  // hash of encrypted data is checked by the server ensuring the data has not been tampered with or otherwise corrupted while uploading
  private async postFile(file: File, linkObject: any, archiveID: string): Promise<any> {
    const fields = linkObject.fields;
    const headUrl = linkObject.headUrl;
    //Check if the record has changed by doing a HEAD request and comparing ETags

    const etag = FileService.etagMap.get(archiveID);
    Console.log('FileSvc: getData', 'etag', etag);

    Console.log('FileSvc: getData', 'etagMap', FileService.etagMap);
    if (etag) {
      await this.chekEtag(headUrl, etag);
    }

    let res: (value: any) => void;
    let rej: (value: any) => void;

    const result = new Promise<any>((resolve: any, reject: any) => { res = resolve, rej = reject });
    const formData = new FormData();

    // Add the signature data for the signed POST
    let key: keyof typeof fields;
    for (key in fields) {
      const value = fields[key];
      if (key == 'url') continue;
      formData.append(key, value);
    }

    let fileHash = await this.calculateSHA256(file);
    let fileMD5 = await this.calculateMD5(file);
    Console.log('FileSvc: postFile', 'fileMD5', fileMD5);


    formData.append('x-amz-checksum-sha256', fileHash);

    formData.append('file', file); // file must be last
    const observable = this.http.post(fields.url, formData, {
      reportProgress: true,
      observe: 'events'
    });
    observable.subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        if (event.total) {
          Console.log(`Upload progress: ${Math.round(event.loaded / event.total * 100)}%`);
        } else {
          Console.log(`Upload transfered: +${event.loaded}B`);
        }
      } else if (event.type === HttpEventType.Response) {
        FileService.etagMap.set(archiveID, fileMD5); // Store ETag in map
        res(event);
      }
    }, error => { Console.log(error), rej(error) });
    return result;
  }

  private async calculateSHA256(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const buffer = e.target?.result as ArrayBuffer;
        const hashBuffer = await crypto.subtle.digest('SHA-256', buffer);
        const hashArray = new Uint8Array(hashBuffer);
        const hashBase64 = toBase64(hashArray);
        resolve(hashBase64);
      };
      reader.onerror = error => reject(error);
      reader.readAsArrayBuffer(file);
    });
  }

  private async calculateMD5(file: File): Promise<string> {
    const buffer = await file.arrayBuffer(); // Read file as ArrayBuffer
    const uint8Array = new Uint8Array(buffer); // Convert to Uint8Array
    const md5 = new MD5().update(Buffer.from(uint8Array)).digest('hex'); // Convert to Buffer before hashing
    return `"${md5}"`;
  }

  /**
   * Initiate a local encrypted file fetch
   * @param file
   * @param requestedarchiveID
   * @returns archiveID
   */
  public async initFetchLocalFile(file: File, requestedarchiveID?: string): Promise<string> {
    //get first 21 bytes as header archiveID
    //Archive ID is random ie: non identifiable
    const header = file.slice(0, 21);
    const archiveID = await header.text();
    if (requestedarchiveID && requestedarchiveID != archiveID) {
      throw new Error('Wrong file');
    }
    const archive = await this.apiSvc.getArchive(archiveID);
    Console.log('header archiveID', archiveID);
    const cancelObject = { cancel: false };
    const emitter = new EventEmitter<{ transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>();
    FileService.eventsMap.set(archive.id, { file, archive, emitter, cancelObject });
    return archiveID;
  }

  /**
   * encrypt for Upload or for local storage
   * @param file
   * @param metadata
   */
  public async sendFile(file: File, metadata: any, local = false, archive?: Archive): Promise<{ archive: Archive, progress: EventEmitter<{ size?: number, transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }> }> {

    const emitter = new EventEmitter<{ transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>();
    try {
      let type = file.type;
      if (!type) {
        type = 'application/octet-stream';
      }
      if (!metadata.type) metadata.type = type;
      if (!metadata.name) metadata.name = file.name;
      const size = file.size;
      if (!archive) {
        const archiveRequest = new Archive();
        archiveRequest.t = local ? 'Local' : 'Cloud';
        archiveRequest.m = metadata;
        archiveRequest.l = local ? 'Local' : this.apiSvc.getCurrentLocationID();
        archiveRequest.fs = size;

        archive = await this.apiSvc.createArchive(archiveRequest);
      } else {
        if (!archive.m && metadata) {
          archive.m = metadata;
        }
        archive.fs = size;
        await this.apiSvc.updateArchive(archive);
      }
      if (local) {
        const cancelObject = { cancel: false };
        FileService.eventsMap.set(archive.id, { emitter, file, archive, cancelObject });
        const data = file.slice(0, FileService.CHUNK_SIZE);
        await this.serviceWorkerBroadcastChannelHandler.postMessage({ op: 'INIT_LOCAL', archive, data });
        Console.log('INIT_LOCAL');
      } else {
        const readableStream = file.stream();
        FileService.eventsMap.set(archive.id, { emitter, readableStream });

        const cancelObject = await this.chunkFile(readableStream, archive, emitter);
        FileService.eventsMap.set(archive.id, { emitter, readableStream, cancelObject });
      }
      return { archive, progress: emitter };
    } catch (error) {
      if (archive) {
        this.uploadError(archive, error, emitter);
      }
      this.errorSvc.process(error);
      throw error;
    }
  }


  /**
     * encrypt for Upload or for local storage
     * @param streamToUpload
     * @param archive
     */
  public async sendStream(readableStream: ReadableStream, archive: Archive): Promise<EventEmitter<{ size?: number, transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>> {
    const emitter = new EventEmitter<{ transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>();
    try {
      FileService.eventsMap.set(archive.id, { emitter, readableStream });
      const cancelObject = await this.chunkFile(readableStream, archive, emitter);
      FileService.eventsMap.set(archive.id, { emitter, readableStream, cancelObject });
      return emitter;
    } catch (error) {
      if (archive) {
        this.uploadError(archive, error, emitter);
      }
      this.errorSvc.process(error);
      throw error;
    }
  }

  /**
   * Converts an Archive from Msg to Cloud or vice versa by creating a new Archive and having the serviceworker reencrypt the origonal archive data to the new Archive.
   * @param archive
   * @param keyData
   * @returns { newArchive: Archive, emitter: EventEmitter<{  size?: number,,transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }> }
   */
  public async convertArchive(archive: Archive, keyData?: {
    aesKey: CryptoKey;
    b64CompositeCipherText: string;
  }, base64iv?: string): Promise<{ newArchive: Archive; emitter: EventEmitter<{ size?: number, transferred: number, attachmentNumber?: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'ATTACHMENT' | 'COMPLETED' | 'ERROR', error?: any }> }> {
    //create a new Archive for the converted data
    let convertedArchive = new Archive();
    convertedArchive.t = archive.t == 'Msg' ? 'Cloud' : 'Msg';
    if (convertedArchive.t == 'Msg') {
      if (!keyData || !base64iv) {
        throw new Error('keyData is required for creating Msg archives');
      }
      convertedArchive.arr = { key: keyData.b64CompositeCipherText, iv: base64iv };
      this.apiSvc.setEyesCryptoKey(keyData);
    }
    convertedArchive.m = archive.m;
    convertedArchive.l = archive.l;
    convertedArchive.fs = archive.fs;
    convertedArchive.sid = this.apiSvc.spaceID;
    convertedArchive = await this.apiSvc.createArchive(convertedArchive);
    Console.log('FileSvc: convertArchive', 'created archive', convertedArchive);

    const emitter = new EventEmitter<{ size?: number, transferred: number, attachmentNumber?: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'ATTACHMENT' | 'COMPLETED' | 'ERROR', error?: any }>();
    const msg = {
      size: archive.fs,
      transferred: 0,
      stage: 'READING' as 'READING'
    }
    emitter.emit(msg);
    FileService.eventsMap.set(convertedArchive.id, { emitter , archive: convertedArchive});
    this.serviceWorkerBroadcastChannelHandler.postMessage({ op: 'CONVERT', archive: convertedArchive, archiveId: archive.id });
    return { newArchive: convertedArchive, emitter };
  }

  /**
   * Upload a file in chunks
   * @param readableStream
   * @param archive
   */
  private async chunkFile(readableStream: ReadableStream<Uint8Array>, archive: Archive, event: EventEmitter<{ size?: number, transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>):
    Promise<{ cancel: boolean }> {
    Console.log("chunkFile");
    const cancelObject = { cancel: false };
    const that = this;
    let part = 0;
    let transfered = 0;
    event.emit({ size: archive.fs, transferred: 0, stage: 'READING' });
    try {
      const writeable = new WritableStream<Uint8Array>({
        async write(chunk: Uint8Array, controller: WritableStreamDefaultController) {
          try {
            if (cancelObject.cancel) {
              throw new Error('Cancelled');
            }
            that.apiSvc.ping(); // keep session alive
            part++;
            transfered += chunk.byteLength;
            let message: FileUploadMessage;
            if (part == 1) {
              message = { op: 'INIT', archive: archive, partNumber: part, data: chunk };
            } else {
              message = { op: 'PART', archiveId: archive.id, partNumber: part, data: chunk };
            }
            Console.log('Sending message', message.op);
            await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
            event.emit({ size: archive.fs, transferred: transfered, stage: 'READING' });
          } catch (error: any) {
            if (cancelObject.cancel) {
              return;
            }
            controller.error(error);

            const message = { op: 'ABORT', archiveId: archive.id };
            await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
            that.uploadError(archive, error, event);
          }
        },
        async close() {
          Console.log('close');
          try {
            const message: FileUploadMessage = { op: 'COMPLETED', archiveId: archive.id };
            // Todo we should check at start of upload when we at least have the fs size
            //will fail if out of tier space
            const safeExpObject: { safeExp: number } = await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
            if (safeExpObject) {
              that.authzSvc.safeExpiryEPOCms = safeExpObject.safeExp * 1000;
            }
            await that.apiSvc.getArchive(archive.id, true); // force refresh
            event.emit({ size: archive.fs, transferred: transfered, stage: 'COMPLETED' });
          } catch (error) {
            Console.log('close error', error);
            const message: FileUploadMessage = { op: 'ABORT', archiveId: archive.id };
            await that.serviceWorkerBroadcastChannelHandler.postMessage(message);

            event.emit({ transferred: -1, stage: 'ERROR' });
            await that.errorSvc.process(error);
          }
          FileService.eventsMap.delete(archive.id);
        },
        async abort(reason) {
          Console.log('abort reason', reason);
          const message: FileUploadMessage = { op: 'ABORT', archiveId: archive.id };
          that.serviceWorkerBroadcastChannelHandler.postMessage(message);
          that.uploadError(archive, reason, event);
        }
      });
      const chunker = chunkerTransformer([FileService.CHUNK_SIZE], 0);
      const transformer = chunker.chunkerTransformStream;
      readableStream.pipeThrough(transformer).pipeTo(writeable);

      return cancelObject;

    } catch (error) {
      Console.log("Upload failed", error);
      try {
        const message: FileUploadMessage = { op: 'ABORT', archiveId: archive.id };
        await that.serviceWorkerBroadcastChannelHandler.postMessage(message);
        if (!cancelObject.cancel) {
          this.uploadError(archive, error, event);
        }
        await readableStream.cancel();
      } catch (ignore) {
      }
      throw error;
    }
  }

  private async uploadError(archive: Archive, error: any, event: EventEmitter<{ size?: number, transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }>) {
    try {
      if (archive.t != 'Records') {
        await this.apiSvc.deleteArchive(archive.id);
      }
    } catch (ignore) {
    }
    const data = FileService.eventsMap.get(archive.id);
    FileService.eventsMap.delete(archive.id);
    if (data && data.cancelObject.cancel) {
      return;  // don't emit error if cancelled
    }
    event.emit({ transferred: -1, stage: 'ERROR', error });
    await this.errorSvc.process(error);
  }

  private async handleServiceWorkerMessage(message: FileUploadMessage): Promise<any> {
    if (message.op == 'ERROR') {
      Console.log('ERROR', message, this.errorSvc);
      ErrorService.instance.process(message.error); // this.ref not always initialized yet
      return;
    }
    const archiveId = message.archiveId;
    if (!archiveId) {
      Console.log('No archiveId: ignoring message');
      throw new Error('Ignore');// ignore message form service worker as it must not be for this client
    }
    const data = FileService.eventsMap.get(archiveId!);
    if (data) {
      const { file, archive, cancelObject } = FileService.eventsMap.get(archiveId);

      switch (message.op) {
        case 'READING':
        case 'WRITING':
        case 'ENCRYPTING':
          const msg = message.op;
          data.emitter.emit({ stage: msg, transferred: message.transferred, size: archive? archive.fs : 0 });
          break;
        case 'FETCH_PART':
          const partNumber = message.partNumber!;

          if (cancelObject.cancel) {
            Console.log('Cancelled');
            if (data.emitter) {
              data.emitter.emit({ stage: 'COMPLETED', transferred: 0 });
            }
            return { data: null };
          }
          let start = 0;
          let end = 0;
          if (!archive.p || archive.p.length == 0) {
            end = FileService.CHUNK_SIZE * partNumber
            start = end - FileService.CHUNK_SIZE;
          } else {
            if (partNumber == archive.p.length) {
              if (data.emitter) {
                data.emitter.emit({ stage: 'COMPLETED', transferred: 0 });
              }
              return { data: null };
            }
            //add up all the part sizes up to and including the part we want
            const size = archive.p[partNumber]
            for (let i = 0; i < partNumber + 1; i++) {
              end += archive.p[i];
            }

            start = end - size;
          }
          if (!message.encrypting) {
            Console.log('Skipping header');
            start += 21; //add header size
            end += 21; //add header size
          }
          const chunk = (<File>file).slice(start, end);
          if (chunk.size == 0) {
            Console.log('No more data');
            data.emitter.emit({ stage: 'COMPLETED', transferred: chunk.size });
            return { data: null };
          } else {
            const size = (<File>file).size;
            data.emitter.emit({ size, stage: 'READING', transferred: end });
            return { data: chunk };
          }

        case 'ABORT':
          Console.log('ABORT');
          if (data.archive && data.archive.t != 'Records') {
            await this.apiSvc.deleteArchive(archiveId);
          }

          FileService.eventsMap.delete(archiveId);
          data.emitter.emit({ stage: 'ERROR', transferred: -1 });
          break;
        case 'COMPLETED':
          Console.log('COMPLETED');
          if (data.emitter) {
            data.emitter.emit({ stage: 'COMPLETED', transferred: 0 });
          }
          FileService.eventsMap.delete(archiveId);
          break;
        default:
          Console.log('Unknown message', message);
          break;
      }
    } else {
      Console.log('No data: ignoring message');
      throw new Error('Ignore'); // ignore message form service worker as it must not be for this client
    }
  }

  public async abortUpload(archive: Archive) {
    const archiveId = archive.id;
    const data = FileService.eventsMap.get(archiveId);
    if (data) {
      data.cancelObject.cancel = true;
    }
    const message = { op: 'ABORT', archiveId: archiveId };
    await this.serviceWorkerBroadcastChannelHandler.postMessage(message);
    try {
      setTimeout(async () => {
        if (archive.t != 'Records') {
          this.apiSvc.deleteArchive(archiveId);
        }
        FileService.eventsMap.delete(archiveId);
      }, 5000);
    } catch (ignore) {
    }
  }

  public async handleBlobDownload(url: string, fileName: string): Promise<void> {
    Console.log('handleBlobDownload ', url);
    try {
      const blob = await lastValueFrom(this.http.get(url, { responseType: 'blob' }));
      if (!blob) {
        alert('Download failed');
        return
      }

      // remove .ulf extra extension if present in fileName
      fileName = fileName.replace('.ulf', '');

      if (navigator.share) {
        const file = new File([blob], fileName, { type: blob.type });
        await navigator.share({
          files: [file],
          title: 'Download File',
          text: 'Please download this file'
        });
      } else {
        Console.log('Download failed: navigator.share not supported. Tryting download with anchor');
        // Fallback for browsers that don't support navigator.share
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    } catch (error) {
      Console.error('Download failed:', error);
      this.errorSvc.process(error);
    }
  }
}
