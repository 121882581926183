<app-modals></app-modals>
<div *ngIf="archive">
  <div class="header">
    <div *ngIf="!view" class="archiveInfo">
      <p class="filename" (click)="rename()"> {{archive.m.name}}</p>
      <!-- <p> {{archive.m.type}}</p> -->
      <p> {{archive.fs! | fileSize}}</p>
      <div *ngIf="!view" class="button-container">
        <button *ngIf="url && isIOSStandalone" class="btn-primary"
          (click)="fileSvc.handleBlobDownload(url,archive.m.name)">{{'ARCHIVES.DOWNLOAD' | translate}}</button>
        <a *ngIf="url && !isIOSStandalone" class="downloadlink btn" [href]="url" target="_blank">{{'ARCHIVES.DOWNLOAD' |
          translate}}</a>
        <button *ngIf="!readonly" class="btn" (click)="deleteArchive()">
          <span class="text"> </span>
          <img src="./assets/images/svg/trash-filled.svg" alt="Delete" class="pic">
        </button>
        <button *ngIf="archive.recordId" class="btn" (click)="openRecord()">
          <span class="text"> </span>
          <img src="./assets/images/svg/file.svg" alt="open record" class="pic">
        </button>
      </div>
    </div>
    <div *ngIf="local && !url">
      <div class="local">
        <label for="input">
          {{'ARCHIVES.LOCAL_FILE' | translate}}
        </label>
        <input id="input" #input type="file" accept=".ulf" (change)="onFileSelected($event)">
      </div>
      <div class="button-container center">
        <button class="btn-primary" (click)="close()">
          <span class="text">{{'CLOSE' | translate}}</span>
        </button>
      </div>
    </div>
  </div>
  <div *ngIf="url && type!='unknown'">
    <div *ngIf="!view" class="button-container center">
      <button class="btn" (click)="viewArchive()">
        <span class="text">{{'VIEW' | translate}}</span>
      </button>
      <button class="btn" (click)="close()">
        <span class="text">{{'CLOSE' | translate}}</span>
      </button>
    </div>
    <div *ngIf="view" class="button-container wideOnly center">
      <button class="btn-primary" (click)="viewArchive()">
        <span class="text">{{'CLOSE' | translate}}</span>
      </button>
    </div>
    <div *ngIf="view">
      <img class="image" *ngIf="type=='image'" [src]="url">
      <audio class="audio" *ngIf="type=='audio'" preload="none" controls>
        <source [src]="url" attr.type="{{archive.m.type}}" />
      </audio>
      <video class="video" *ngIf="type=='video'" preload="none" attr.type="{{archive.m.type}}" [src]="url" controls
        muted autoplay height="400"></video>
      <pre class="text notranslate" autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off"
        *ngIf="type=='text'">{{textData}}</pre>
      <ngx-extended-pdf-viewer *ngIf="type=='pdf'" [showDownloadButton]="false" [showHighlightEditor]="false" [showPrintButton]="false"
        [showOpenFileButton]="false" [showFindButton]="false" [showDrawEditor]="false" [showStampEditor]="false"
        [showTextEditor]="false" [useInlineScripts]="false" [language]="language" [zoom]="zoom" [src]="url"></ngx-extended-pdf-viewer>
    </div>
  </div>
</div>
