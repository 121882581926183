<div #authModalContainer class="modal-container nobackground">
  <div #authModal ngClass="{'nobackground': !message }" class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div #authBody class="auth-body">
          <div  #authBody class="input-body"  [ngStyle]="{'background': !!message?'#ffffff':'unset'}">
          <div *ngIf="message" [innerHTML]="message"></div>
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
