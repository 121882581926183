<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div *ngIf="message && message.title">
          <h2 class="auth-title" [innerHTML]="message.title"></h2>
        </div>
        <div *ngIf="message && message.message" #authBody class="auth-body">
          <div [innerHTML]="message.message" class="textHolder notranslate"></div>
        </div>
        <div #authBody class="input-body">
          <div class="select-actions button-container">
            <button class="btn" (click)="selectAll()">{{ 'MODALS.SPACES.SELECT_ALL' | translate }}</button>
            <button class="btn" (click)="deselectAll()">{{ 'MODALS.SPACES.DESELECT_ALL' | translate }}</button>
          </div>
          <div class="space-list">
            <div *ngFor="let space of spaces">
              <div class="space-item">
                <label>
                  <input type="checkbox" [(ngModel)]="selectedSpaces[space.sid]" (click)="toggleSpace(space.sid)">
                  <span>{{ space.name }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="button-container">
            <button class="btn" (click)="cancel()">{{ 'CANCEL' | translate }}</button>
            <button class="btn-primary" (click)="confirm()" [disabled]="!hasSelectedSpaces || (limit && allSelected) ">
              {{ 'OK' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
