import { ApiKeyPair } from "../interfaces/ApiProvider";
import { CoinType } from "./paperWallet";
import { Wallet } from "./wallet";

export interface ConfigData {
  language: string;
  url: string;
  body: any;
  fee: string;
  amount: string;
  coinType: string;
  recipient: string;
  headers: Record<string, string>;
  type: "POST" | "GET";
  hash: string;
}

export class TransactionDataGenerator {

  generateTransferData(signedTransaction: string, fee: string, amount: string, wallet: Wallet, recipient: string, hash, apiKeyPair: ApiKeyPair | null): ConfigData {
    switch (apiKeyPair?.provider) {
      case "NowNodes":
        return new NowNodes().generateTransferData(signedTransaction, fee, amount, wallet, recipient, hash, apiKeyPair);
      default:
        return new BlockChair().generateTransferData(signedTransaction, fee, amount, wallet, recipient, hash, apiKeyPair);
    }
  }
}
class BlockChair {
  generateTransferData(signedTransaction: string, fee: string, amount: string, wallet: Wallet, recipient: string, hash, apiKeyPair: ApiKeyPair | null): ConfigData {

    let url = this.getUrl(wallet.coinType);
    if (apiKeyPair?.key && apiKeyPair.key !== 'Free Tier') {
      url += `?key=${apiKeyPair.key}`;
    }
    const body: any = { data: signedTransaction }
    const headers: Record<string, string> = { "Content-Type": "application/json" };
    const type = "POST";
    const configData: ConfigData = {
      url,
      body,
      fee,
      amount,
      coinType: wallet.coinType,
      headers,
      recipient,
      hash: hash,
      type,
      language: 'en'
    };

    return configData;
  }

  private getUrl(coinType: CoinType): string {
    switch (coinType) {
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.LINK:
        return `https://api.blockchair.com/ethereum/push/transaction`;
      case CoinType.BTC:
        return `https://api.blockchair.com/bitcoin/push/transaction`;
      case CoinType.BNB:
        return `https://api.blockchair.com/bnb/push/transaction`;
      case CoinType.LTC:
        return `https://api.blockchair.com/litecoin/push/transaction`;
      case CoinType.DOGE:
        return `https://api.blockchair.com/dogecoin/push/transaction`;

      default:
        throw new Error('Unsupported coin type');
    }
  }
}
class NowNodes {
  generateTransferData(signedTransaction: string, fee: string, amount: string, wallet: Wallet, recipient: string, hash, apiKeyPair: ApiKeyPair | null): ConfigData {

    const url = this.getUrl(signedTransaction, wallet.coinType, apiKeyPair);
    const body = this.getBody(signedTransaction, wallet, apiKeyPair);
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
      'api-key': apiKeyPair?.key || ''
    };

    const type: "POST" | "GET" = this.getType(wallet.coinType);
    const configData: ConfigData = {
      url,
      body,
      fee,
      amount,
      coinType: wallet.coinType,
      headers,
      recipient,
      hash: hash,
      type,
      language: 'en'
    };

    return configData;
  }

  private getType(coinType: CoinType): "POST" | "GET" {
    switch (coinType) {
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.LINK:
      case CoinType.BTC:
      case CoinType.LTC:
      case CoinType.DOGE:
      case CoinType.SOL:
        return "POST";
      case CoinType.BNB:
        return "GET";
      default:
        throw new Error('Unsupported coin type');
    }
  }

  private getBody(signedTransaction: string, wallet: Wallet, apiKeyPair: ApiKeyPair | null): any {
    let body: any;
    switch (wallet.coinType) {
      case CoinType.SOL:
        body = {
          jsonrpc: "2.0",
          id: 1,
          method: "sendTransaction",
          params: [
            signedTransaction]
        }
        break;
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.LINK:
        //https://nownodes.gitbook.io/documentation/nodeapis/eth-ethereum/mainnet#eth_sendrawtransaction
        body = { jsonrpc: "2.0", method: "eth_sendRawTransaction", params: [signedTransaction], id: 1 }
        break;
      case CoinType.BNB:
        body = ''; // no body needed
        break;
      case CoinType.DOGE:
      case CoinType.LTC:
      case CoinType.BTC:
        body = { jsonrpc: "1.0", method: "sendrawtransaction", params: [signedTransaction], id: 1 }
        break;
      default:
        body = signedTransaction;
    };

    return body;
  }

  private getUrl(signedTransaction: string, coinType: CoinType, apiKeyPair: ApiKeyPair | null): string {
    switch (coinType) {
      case CoinType.ETH:
      case CoinType.USDT:
      case CoinType.USDC:
      case CoinType.DAI:
      case CoinType.LINK:
        return `https://eth.nownodes.io`;
      case CoinType.BTC:
        return `https://btc.nownodes.io`;
      case CoinType.BNB:
        return `https://bnb.nownodes.io/broadcast_tx_sync?tx=${signedTransaction}`;
      case CoinType.LTC:
        return `https://ltc.nownodes.io`;
      case CoinType.DOGE:
        return `https://doge.nownodes.io`;
      case CoinType.SOL:
        return `https://sol.nownodes.io`;
      default:
        throw new Error('Unsupported coin type');
    }
  }
}
