import { Injectable } from '@angular/core';
import { RecordI, RecordModelI } from '../interfaces/records';
import { RecordsService } from './records.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { createPopper } from '@popperjs/core';
import { LabelI, LabelModelI } from '../interfaces/labels';
import { LabelsService } from './labels.service';
import { Console } from '../lib/console';
import { Wallet } from '../lib/wallet';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private recordsSvc: RecordsService, private Labels: LabelsService) {
    this.get.labels();
    this.get.records();
  }

  private get = {
    records: () => {
      this.recordsSvc.recordsList$.subscribe({
        next: (result: RecordI[]) => {
          this.record.pinned = result.filter(x => x.pinned === true);
          this.record.unpinned = result.filter(x => x.pinned === false);
          this.record.all = result;
        },
        error: error => Console.error(error)
      })
    },
    labels: () => {
      this.Labels.labelsList$.subscribe({
        next: (result: LabelI[]) => { this.label.list = result },
        error: error => Console.error(error)
      })
    }
  }

  setSideBar = new Subject<'full' | 'short' | 'hidden' | 'default' | 'toggle'>();
  saveRecord = new Subject<boolean>();
  closeModal = new Subject<boolean>();
  recordViewType = new BehaviorSubject<'list' | 'grid'>('grid');

  // ? record -------------------------------------------------

  record: RecordModelI = {
    id: -1,
    pinned: [],
    unpinned: [],
    all: [],
    db: {

      add: async (data: RecordI) => await this.recordsSvc.add(data),
      update: async (data: RecordI) => { await this.recordsSvc.update(data, this.record.id) },
      updateKey: async (data: RecordI) => await this.recordsSvc.updateKey(data, this.record.id),
      get: () => this.recordsSvc.get(this.record.id),
      clone: () => this.recordsSvc.clone(this.record.id),
      delete: async () => {
        await this.recordsSvc.delete(this.record.id)
      }
    }
  }

  // ? labell -------------------------------------------------

  label: LabelModelI = {
    id: -1,
    list: [],
    db: {
      add: async (data: LabelI) => await this.Labels.add(data),
      update: async (data: LabelI) => await this.Labels.update(data, this.label.id),
      delete: async () => await this.Labels.delete(this.label.id)
    }
  }

  createTooltip(button: HTMLDivElement, tooltipEl: HTMLDivElement) {

    tooltipEl.dataset['isTooltipOpen'] = 'true';
    createPopper(button, tooltipEl, { placement: 'top' });
    //
    let fct = (event: Event) => {
      if (!(tooltipEl as any).contains(event.target)) {
        document.removeEventListener('mousedown', fct);
        tooltipEl.dataset['isTooltipOpen'] = 'false';
      }
    }
    document.addEventListener('mousedown', fct);
  }

  closeTooltip(tooltipEl: HTMLDivElement) {
    tooltipEl.dataset['isTooltipOpen'] = 'false';
  }

  getWallets(): Wallet[] {
    let wallets = this.record.all.map(x => x.wallet).filter((wallet): wallet is Wallet => !!wallet);
    wallets = wallets.map(wallet => Wallet.fromJSON(wallet) as Wallet);
    return Array.from(new Set(wallets));
  }

  async saveWallet(wallet: Wallet) {
    //find the record the wallet belongs to
    let record = this.record.all.find(x => x.wallet?.address === wallet.address);
    if (!record) {
      Console.error('wallet Record not found');
      return;
    }
    Console.log('record', record);
    record.wallet = wallet;
    this.recordsSvc.update(record, record.id!);

  }
}
