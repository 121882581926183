import { Injectable } from "@angular/core";
import { UploadingState } from "./uploadingState";
import { environment } from "src/environments/environment";
import { Utilities } from "src/app/lib/utilities";
import { AbstractState } from "./abstractState";
import { Console } from "src/app/lib/console";

@Injectable()
export class UploadFileSelectedState extends AbstractState {
  needCredits = false;

  private paymentURL = environment.paymentURL ? environment.paymentURL : 'https://payments.safe.' + environment.envVar.DOMAIN_NAME;
  constructor() {
    super();
  }

  async init(): Promise<void> {
    this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.TITLE');
    this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.MSG');
    this.button1Text = this.modal.translate.instant('CANCEL');
    this.button2Text = this.modal.translate.instant('MODALS.FILE_UPLOAD.UPLOAD');
    this.fileName = this.modal.file!.name;
    const fileSize = this.fileSize(this.modal.file!.size);
    this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.SIZE', { size: fileSize });

    const newSafeExpiryDate = await this.calculateNewExpiry(this.modal.file!.size);
    if (newSafeExpiryDate) {
      let threasholdDate: Date;
      const hasSubscription = this.modal.authzSvc.appConfig.sub;
      if (hasSubscription) {
        threasholdDate = this.getDateOneMonthInFuture();
      } else {
        threasholdDate = new Date();
      }

      if (newSafeExpiryDate < threasholdDate) {
        this.button2Text = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.SELECTED.GET');
        this.needCredits = true;
        this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.SELECTED.NOT_ENOUGH_CREDITS');
      } else {
        this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.SELECTED.NEW_DATE', { date: newSafeExpiryDate });
      }
    }
  }

  override showFileName = true;

  override click1(): void {
    this.modal.respond(null);
  }

  override async click2(): Promise<void> {
    if (this.needCredits) {
      const at = await this.modal.apiSvc.getCreditTokenAccessToken();
      window.open(`${this.paymentURL}/#/?code=${at}`, '_self');
      return;
    }
    if (this.fileName) {
      //check is the file.name has an extention and re-add it to this.filename if it is missing
      const extention = this.fileName.split('.').pop();
      if (extention && !this.fileName.endsWith(extention)) {
        this.fileName = `${this.fileName}.${extention}`;
      }
    } else {
      this.fileName = this.modal.file!.name
    }
    this.modal.fileName = this.fileName;
    this.modal.setState(new UploadingState());
  }

  private fileSize(number) {
    if (number < 1024) {
      return `${number} bytes`;
    } else if (number >= 1024 && number < 1048576) {
      return `${(number / 1024).toFixed(1)} KB`;
    } else if (number >= 1048576) {
      return `${(number / 1048576).toFixed(1)} MB`;
    } else {
      return `${(number / 1073741824).toFixed(1)} GB`;
    }
  }

  private async calculateNewExpiry(addedUsage: number): Promise<Date | null> {
    if (this.modal.replicated) {
      addedUsage *= 2; //replicated data is stored twice
    }
    const tier = this.modal.authzSvc.appConfig.tier;
    const currentExpiryEpochSeconds = this.modal.authzSvc.safeExpiryEPOCms / 1000;
    let currentUsage = (await this.modal.apiSvc.getArchives()).reduce((acc, archive) => acc + archive.s, 0);
    const totalUsage = currentUsage + addedUsage;
    //if the total usage is more than the current tier, then we need to calculate the new expiry date
    if (totalUsage < Utilities.getBaseStorageByTier(tier)) {
      return null;
    }

    const currentTimeEpochSeconds = Math.floor(Date.now() / 1000);

    // Calculate the total credits based on remaining time and current usage
    const currentCredits = Utilities.getCreditsFromExpiry(currentExpiryEpochSeconds - currentTimeEpochSeconds, tier, currentUsage);
    Console.log(`currentCredits: ${currentCredits}, currentUsage: ${currentUsage}, currentExpiryEpoch: ${currentExpiryEpochSeconds}, currentTimeEpoch: ${currentTimeEpochSeconds}`);
    // Calculate new total GB

    Console.log(`totalUsage: ${totalUsage}, addedUsage: ${addedUsage}`);
    // Calculate the new expiry date
    const newExpiryEpochSeconds = Utilities.getExpiryInSeconds(totalUsage, tier, currentCredits) + currentTimeEpochSeconds;
    Console.log(`newExpiryEpoch: ${newExpiryEpochSeconds}, totalUsage:${totalUsage}, addedUsage:${addedUsage}`)
    return new Date(newExpiryEpochSeconds * 1000);
  }

  private getDateOneMonthInFuture(): Date {
    const currentDate = new Date();
    const futureDate = new Date(currentDate);
    futureDate.setMonth(currentDate.getMonth() + 1);

    // If the setMonth overflows to the next year or adjusts days, fix it
    if (futureDate.getDate() < currentDate.getDate()) {
      futureDate.setDate(0); // This sets the date to the last day of the previous month
    }

    return futureDate;
  }
}
