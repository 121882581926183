<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" [innerHTML]="state.title"></h2>
        <div *ngIf="state.percent" id="progress">
          <div id="bar" [style.width]="state.percent + '%'">
            <span id="percent-text">{{ state.percent }}%</span>
          </div>
        </div>
        <div #authBody class="auth-body">
          <div [innerHTML]="state.message" class="textHolder notranslate"></div>
          <input id="input" #input *ngIf="!file" type="file" (change)="state.onFileSelected($event)">
          <textarea class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false"
            autocomplete="off" *ngIf="state.showFileName" type="text" [(ngModel)]="state.fileName"></textarea>
        </div>
        <div class="button-container">
          <button *ngIf="state.button1Text" class="btn" (click)="state.click1()"> {{ state.button1Text }} </button>
          <button *ngIf="state.button2Text" class="btn-primary" (click)="state.click2()"> {{ state.button2Text }}
          </button>
          <iframe *ngIf="state.url && !ios" id="downloadIframe" name="downloadIframe" ></iframe>
          <button *ngIf="state.url && isIOSStandalone" class="btn-primary" (click)="state.localDownloadTriggered(true)">{{'ARCHIVES.LOCAL_START' | translate}}</button>
          <a (click)="state.localDownloadTriggered(false)" *ngIf="state.url && ios && !isIOSStandalone " class="downloadlink"  [href]="state.url" target="_blank" >{{'ARCHIVES.LOCAL_START' | translate}}</a>
          <a (click)="state.localDownloadTriggered(false)" *ngIf="state.url && !ios" class="downloadlink" [href]="state.url"
            target="downloadIframe">{{'ARCHIVES.LOCAL_START' | translate}}</a>
        </div>
      </div>
    </div>
  </div>
</div>
