import { Component } from '@angular/core';
import { MainComponent } from './components/main/main.component';
import { TranslateService } from '@ngx-translate/core';
import { Console } from './lib/console';

import localefr from '@angular/common/locales/fr';
import localees from '@angular/common/locales/es';
import localede from '@angular/common/locales/de';
import localenl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';

@Component({
  selector: 'app-root',
  template: '<app-main></app-main>',
  standalone: true,
  imports: [MainComponent],
})

export class AppComponent {

  constructor(public translate: TranslateService) {
    translate.addLangs(['en', 'fr', 'es', 'de', 'nl']);
    translate.setDefaultLang('en');

    const browserLang = translate.getBrowserLang();
    Console.log(browserLang);
    translate.use(browserLang?.match(/^en|^fr|^es|^de|^nl/) ? browserLang : 'en');
  }

  ngOnInit() {
    registerLocaleData(localefr, 'fr');
    registerLocaleData(localees, 'es');
    registerLocaleData(localede, 'de');
    registerLocaleData(localenl, 'nl');

  }
}
