<keypad-modal *ngIf="showPad" [data]="padData" (response)="padResponse($event)"></keypad-modal>
<message-modal *ngIf="showMessage" [data]="messageData" (response)="messageReturn()"></message-modal>
<input-modal *ngIf="showInput" [data]="inputData" [currentValue]="inputCurrentValue" (response)="inputReturn($event)"></input-modal>
<question-modal *ngIf="showQuestion" [data]="questionData" (response)="questionReturn($event)"></question-modal>
<app-spinner *ngIf='showSpinner' [message]="spinnerMessage"></app-spinner>
<number-modal *ngIf="showNumber" [data]="numberData" (response)="numberReturn($event)"></number-modal>
<notification-modal *ngIf="showNotification" [data]="notificationData" (response)="notificationReturn($event)"></notification-modal>
<accessid-modal *ngIf="showAccessID" [data]="accessIDData" (response)="accessIDReturn($event)"></accessid-modal>
<file-uploader-modal *ngIf="showFileUploader" [file]="file" (response)="fileUploaderReturn($event)"></file-uploader-modal>
<progress-modal *ngIf="showTransferProgress" [progress]="transferProgress" (response)="transferProgressReturn($event)"></progress-modal>
<pin-warning-modal *ngIf="showPinWarning" (response)="pinWarningReturn()"></pin-warning-modal>
<list-select-modal *ngIf="showListSelect" [data]="listSelectData" (response)="listSelectReturn($event)"></list-select-modal>
<wallet-modal *ngIf="showWallet" [data]="walletData" (done)="walletDone()"  (response)="walletReturn($event)"></wallet-modal>
<affiliate-link-modal *ngIf="showAffiliateLink" (response)="affiliateLinkReturn($event)"></affiliate-link-modal>
<app-space-selector *ngIf="showSpaceSelector" [limit]="limitSpaces" [currentSpaces]="currentSpaces" [message]="spaceMessage" (response)="spaceSelectorReturn($event)"></app-space-selector>
