import { EventEmitter, Injectable, OnDestroy } from "@angular/core";
import { State } from "./state";
import { FileService } from "src/app/services/file.service";
import { CompletedState } from "./completedState";
import { AbstractState } from "./abstractState";
import { Console } from "src/app/lib/console";

@Injectable()
export class UploadingState extends AbstractState implements OnDestroy {
  private progress: EventEmitter<{ transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }> | undefined;
  stage = 'READING';
  private updateTimer!: number;
  private fileSize = 0;
  private transferred = 0;
  speedbps = 0;
  private smoothProgressUpdateInterval: any;
  private chunkPercentage = 0;
  completedState: CompletedState;

  constructor() { super(); this.completedState = new CompletedState(); }

  async init() {
    if (this.modal.local) {
      this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.UPLOADING.LOCAL_TITLE');
      this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.UPLOADING.LOCAL_MSG');
    } else {
      this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.UPLOADING.TITLE');
      this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.UPLOADING.MSG');
      this.percent = 0.1;
    }
    this.message += '<p>' + this.modal.file.name + '</p>';
    this.uploadFile();
  }

  override percent = 0;

  override localDownloadTriggered(asBlob: boolean): void {
    this.percent = 0.1;
    const url = this.url;
    Console.log('localDownloadTriggered');
    // kickoff progress bar updater assuming fast 3G connection
    this.smoothProgressUpdate(128188);
    this.url = '';
    this.button2Text = this.modal.translate.instant('CANCEL');
    if (asBlob) {
      const fileName = (this.modal.archive!.m.name + '.ulf')
      this.modal.fileSvc.handleBlobDownload(url, fileName);
    }
  }

  override async click2(): Promise<void> {
    if (this.modal.archive) {
      await this.modal.fileSvc.abortUpload(this.modal.archive);
    }
    this.modal.respond(null);
  }

  async uploadFile(): Promise<void> {
    try {
      if (this.modal.file) {
        this.fileSize = this.modal.file.size;
        this.chunkPercentage = Math.round(FileService.CHUNK_SIZE / this.fileSize * 100); // used by the progress bar updater
        this.updateTimer = Date.now();

        const metatdata = { name: this.modal.fileName, type: this.modal.file.type };

        const { archive, progress } = await this.modal.fileSvc.sendFile(this.modal.file, metatdata, this.modal.local);
        if (archive.t == 'Local') {
          this.url = `/archive/${archive.id}`;
        } else {
          // kickoff progress bar updater assuming fast 3G connection
          this.button2Text = this.modal.translate.instant('CANCEL');
          this.smoothProgressUpdate(128188);
        }
        this.progress = progress;
        this.modal.archive = archive;

        progress.subscribe((event) => {
          Console.log(event);

          this.stage = event.stage;
          if (event.stage === 'ERROR') {
            this.uploadError();
          }
          if (event.stage === 'COMPLETED') {
            this.modal.setState(<State>this.completedState);
          }
          if (event.transferred > 0) {
            this.updateTransfered(event);
          }

          this.title = event.stage;
          this.modal.changeDetector.detectChanges();
        });

      } else {
        this.modal.respond(null);
      }
    } catch (error) {
      clearInterval(this.smoothProgressUpdateInterval);
      this.modal.errorSvc.process(error);
      this.modal.respond(null);
    }
  }

  /**
   * Calculate transferred time and update the progress bar updater.
   * @param transferred
   */
  private updateTransfered(event: { transferred: number; stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR'; error?: any; }) {
    const uploaded = event.transferred - this.transferred;
    if (uploaded > 0) {
      this.transferred = event.transferred;
      const now = Date.now();
      const chunkUploadTime = now - this.updateTimer;
      this.updateTimer = now;
      this.percent = Math.max(Math.round(this.transferred / this.fileSize * 100), 0.1);
      Console.log('percentage correction ' + this.percent)
      //Update the progress bar "newPercentage amount" over the time it took to upload the last chunk instead of sitting and waiting for the next chunk to upload
      this.speedbps = uploaded / chunkUploadTime * 1000;
      Console.log(`Upload transfered: +${uploaded}B in ${chunkUploadTime}ms at ${this.speedbps}Bps`);
      this.smoothProgressUpdate(chunkUploadTime);
    }
  }

  /**
    * Smoothlyish updates the progress percentage by ASSuming the next chunk will take the same amount of time to upload as the last chunk.
    *
    * @param chunkPercent - The additional percentage to add to the current progress.
    * @param chunkUploadTime - The time it took to upload the last chunk, in milliseconds.
    */
  smoothProgressUpdate(chunkUploadTime: number) {
    Console.log('smoothProgressUpdate: chunkuploadtime', chunkUploadTime);
    clearInterval(this.smoothProgressUpdateInterval);
    const updateInterval = 500; // Update every 500 ms (half-second)
    const totalUpdates = chunkUploadTime / updateInterval;
    const incrementPerUpdate = this.chunkPercentage / totalUpdates;
    Console.log('smoothProgressUpdate: incrementPerUpdate', incrementPerUpdate);
    this.smoothProgressUpdateInterval = setInterval(() => {
      let currentPercent = this.percent;
      currentPercent += incrementPerUpdate;

      // Ensure the percentage does not exceed the target or 99%
      if (currentPercent >= 99) {
        currentPercent = 99;
        clearInterval(this.smoothProgressUpdateInterval);
      }
      this.percent = Math.max(parseFloat(currentPercent.toFixed(1)),0.1);
      // Update your progress bar UI here
      this.modal.changeDetector.detectChanges();
    }, updateInterval);
  }

  private uploadError() {
    Console.error('upload error');
    this.modal.respond(null);
  }

  ngOnDestroy(): void {
    if (this.progress) {
      this.progress.unsubscribe();
    }
    clearInterval(this.smoothProgressUpdateInterval);
  }
}
