import { C2Service } from "../services/c2.service";
import { C2CMD } from "./C2CMD";
import { SafeContact } from "./safeContact";

export abstract class C2cmdExecutor {
    c2Svc: C2Service;
    constructor(c2Service: C2Service) {
        this.c2Svc = c2Service;
      }

    abstract executeCommand(cmd: C2CMD, contact: SafeContact, cmdService: C2Service): Promise<C2CMD | null>;
}