// Define the directive
import { Directive, ElementRef, Input, Output, EventEmitter, Renderer2, HostListener, OnInit, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  selector: '[appAutocomplete]',
  standalone: true,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => AutocompleteDirective),
    multi: true,
  }]
})
export class AutocompleteDirective implements ControlValueAccessor, OnInit {
  @Input() suggestions: string[] = [];
  @Output() itemChanged: EventEmitter<string[]> = new EventEmitter<string[]>();

  private currentInput: string = '';
  private inputElement: HTMLInputElement;
  private saveButton: HTMLElement | null = null;
  private container: HTMLElement;
  private dropdown: HTMLElement;
  private dropdownVisible: boolean = false;

  constructor(private el: ElementRef, private renderer: Renderer2) {
    this.inputElement = this.el.nativeElement;
    this.container = this.renderer.createElement('div');
    this.dropdown = this.renderer.createElement('div');
  }

  ngOnInit(): void {
    this.setupContainer();
    this.applyStyles();
    this.disableBrowserAutocomplete();
    this.setupDropdown();
  }

  private setupContainer(): void {
    // Wrap input in a container div for better positioning
    const parent = this.inputElement.parentNode;
    this.renderer.insertBefore(parent, this.container, this.inputElement);
    this.renderer.appendChild(this.container, this.inputElement);
    this.renderer.setStyle(this.container, 'position', 'relative');
    this.renderer.setStyle(this.container, 'display', 'flex');
    this.renderer.setStyle(this.container, 'align-items', 'center');
  }

  private applyStyles(): void {
    this.renderer.setStyle(this.inputElement, 'padding', '8px');
    this.renderer.setStyle(this.inputElement, 'border', '1px solid #ccc');
    this.renderer.setStyle(this.inputElement, 'border-radius', '4px');
    this.renderer.setStyle(this.inputElement, 'box-shadow', '0 2px 4px rgba(0, 0, 0, 0.1)');
    this.renderer.setStyle(this.inputElement, 'outline', 'none');
    this.renderer.setStyle(this.inputElement, 'flex', '1');
  }

  private disableBrowserAutocomplete(): void {
    this.renderer.setAttribute(this.inputElement, 'autocomplete', 'off');
  }

  private setupDropdown(): void {
    this.renderer.setStyle(this.dropdown, 'position', 'absolute');
    this.renderer.setStyle(this.dropdown, 'top', '100%');
    this.renderer.setStyle(this.dropdown, 'left', '0');
    this.renderer.setStyle(this.dropdown, 'width', '100%');
    this.renderer.setStyle(this.dropdown, 'background', '#fff');
    this.renderer.setStyle(this.dropdown, 'box-shadow', '0 2px 4px rgba(0, 0, 0, 0.1)');
    this.renderer.setStyle(this.dropdown, 'z-index', '9999');
    this.renderer.setStyle(this.dropdown, 'display', 'none'); // Initially hide the dropdown
    this.renderer.appendChild(this.container, this.dropdown);
  }

  private renderSuggestions(): void {
    // Clear previous suggestions
    this.renderer.setProperty(this.dropdown, 'innerHTML', '');

    const filteredSuggestions = this.suggestions.filter(suggestion =>
      suggestion.toLowerCase().includes(this.currentInput.toLowerCase())
    );

    if (filteredSuggestions.length > 0 && this.currentInput) {
      this.renderer.setStyle(this.dropdown, 'display', 'block');
      this.dropdownVisible = true;
    } else {
      this.renderer.setStyle(this.dropdown, 'display', 'none');
      this.dropdownVisible = false;
    }

    filteredSuggestions.forEach((entry) => {
      const suggestionItem = this.renderer.createElement('div');
      this.renderer.setStyle(suggestionItem, 'padding', '8px');
      this.renderer.setStyle(suggestionItem, 'cursor', 'pointer');
      this.renderer.setStyle(suggestionItem, 'display', 'flex');
      this.renderer.setStyle(suggestionItem, 'justify-content', 'space-between');
      this.renderer.setStyle(suggestionItem, 'align-items', 'center');

      this.renderer.listen(suggestionItem, 'click', () => {
        this.selectSuggestion(entry);
      });

      const text = this.renderer.createText(entry);
      this.renderer.appendChild(suggestionItem, text);

      // Add delete button (red X)
      const deleteButton = this.renderer.createElement('span');
      deleteButton.innerHTML = '&times;';
      this.renderer.setStyle(deleteButton, 'margin-left', '8px');
      this.renderer.setStyle(deleteButton, 'cursor', 'pointer');
      this.renderer.setStyle(deleteButton, 'color', 'red');
      this.renderer.listen(deleteButton, 'click', (event: Event) => {
        event.stopPropagation(); // Prevent selecting the suggestion
        this.removeSuggestion(entry);
      });
      this.renderer.appendChild(suggestionItem, deleteButton);

      this.renderer.appendChild(this.dropdown, suggestionItem);
    });
  }

  private selectSuggestion(entry: string): void {
    this.currentInput = entry;
    this.renderer.setProperty(this.inputElement, 'value', entry);
    this.onChange(entry);
    this.removeSaveButton();
    this.hideDropdown();
  }

  writeValue(value: any): void {
    this.renderer.setProperty(this.inputElement, 'value', value || '');
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  private onChange = (value: any) => {};
  private onTouched = () => {};

  @HostListener('input', ['$event'])
  onInput(event: any): void {
    const value = event.target.value;
    this.currentInput = value;
    this.onChange(value);
    if (this.currentInput && !this.suggestions.includes(this.currentInput)) {
      if (!this.saveButton) {
        this.renderSaveButton();
      }
    } else {
      this.removeSaveButton();
    }
    this.renderSuggestions();
  }

  @HostListener('blur')
  onBlur(): void {
    setTimeout(() => {
      this.hideDropdown();
    }, 200); // Delay to allow click event on suggestions
  }

  @HostListener('focus')
  onFocus(): void {
    if (this.suggestions.length > 0) {
      this.renderSuggestions();
      this.renderer.setStyle(this.dropdown, 'display', 'block');
      this.dropdownVisible = true;
    }
  }

  @HostListener('keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent): void {
    event.preventDefault();
    if (this.dropdownVisible) {
      const firstSuggestion = this.dropdown.querySelector('div');
      if (firstSuggestion) {
        this.selectSuggestion(firstSuggestion.textContent || '');
      }
    }
  }

  private renderSaveButton(): void {
    this.saveButton = this.renderer.createElement('span');
    if (this.saveButton) {
      this.saveButton.innerHTML = '&#10003;'; // Checkmark icon
      this.renderer.setStyle(this.saveButton, 'margin-left', '8px');
      this.renderer.setStyle(this.saveButton, 'cursor', 'pointer');
      this.renderer.setStyle(this.saveButton, 'color', 'green');
      this.renderer.setStyle(this.saveButton, 'position', 'absolute');
      this.renderer.setStyle(this.saveButton, 'right', '8px');
      this.renderer.setStyle(this.saveButton, 'top', '50%');
      this.renderer.setStyle(this.saveButton, 'transform', 'translateY(-50%)');
      this.renderer.setStyle(this.saveButton, 'z-index', '9999');
      this.renderer.listen(this.saveButton, 'click', () => {
        this.saveCurrentInput();
      });
      this.renderer.appendChild(this.container, this.saveButton);
    }
  }

  private removeSaveButton(): void {
    if (this.saveButton) {
      this.renderer.removeChild(this.container, this.saveButton);
      this.saveButton = null;
    }
  }

  private saveCurrentInput(): void {
    if (this.currentInput && !this.suggestions.includes(this.currentInput)) {
      this.suggestions.push(this.currentInput);
      this.itemChanged.emit(this.suggestions);
      this.renderSuggestions();
    }
  }

  private removeSuggestion(entry: string): void {
    const index = this.suggestions.indexOf(entry);
    if (index !== -1) {
      this.suggestions.splice(index, 1);
      this.itemChanged.emit(this.suggestions);
      this.refreshSuggestions();
    }
  }

  private refreshSuggestions(): void {
    this.renderSuggestions();
  }

  private hideDropdown(): void {
    this.renderer.setStyle(this.dropdown, 'display', 'none');
    this.dropdownVisible = false;
  }
}
