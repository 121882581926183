import { NgIf } from '@angular/common';
import { Component, Input, ViewChild, ViewContainerRef } from '@angular/core';
import { MessageModalComponent } from "../../modal/modals/message-modal/message-modal.component";
import { HelpService } from 'src/app/services/help.service';

@Component({
  selector: 'app-help',
  standalone: true,
  templateUrl: './helpbutton-component.html',
  styleUrl: './helpbutton-component.scss',
  imports: [NgIf]
})
export class HelpButtonComponent {

  constructor(private helpSvc: HelpService, public viewContainerRef: ViewContainerRef) { }

  @ViewChild(MessageModalComponent, { static: false })
  messageModal!: MessageModalComponent;
  @Input() code!: string;

  triggerHelp(event) {
event.preventDefault();
event.stopPropagation();
    this.helpSvc.triggerHelp(this.code);
  }
}
