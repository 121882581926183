import { Component, OnInit } from '@angular/core';
import { NgFor } from '@angular/common';
import { EyesAddressService } from 'src/app/services/eyesAddress.service';
import { Router } from '@angular/router';
import { Console } from 'src/app/lib/console';
import { SafeContact } from 'src/app/interfaces/safeContact';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-address-book',
  standalone: true,
  imports: [NgFor, FormsModule],
  templateUrl: './address-book.component.html',
  styleUrls: ['./address-book.component.scss']
})
export class AddressBookComponent implements OnInit {

  contacts: SafeContact[] = [];
  trustLevels = ['Blocked', 'Limited', 'Standard', 'Admin'];

  constructor(private addressBookService: EyesAddressService, private router: Router) { }

  async ngOnInit() {
    await this.loadAddressBook();
  }

  /**
   * Loads all Safe contacts from the address book.
   */
  async loadAddressBook() {
    this.contacts = await this.addressBookService.getContacts();
  }

  /**
   * Updates the trust level of a address in the address book.
   */
  async updateTrustLevel(address: SafeContact, target: any | null) {
    if (!target) {
      return;
    }

    const newLevel: "Blocked" | "Limited" | "Standard" | "Admin" = target.value as "Blocked" | "Limited" | "Standard" | "Admin";
    Console.log(`updateTrustLevel Address : ${address.Id} Trust Level : ${newLevel}`);
    address.trustLevel = newLevel as SafeContact['trustLevel'];
    await this.addressBookService.setTrustLevel(address.Id, newLevel);
  }

  /**
   * Removes a address from the address book.
   */
  async removeContact(addressId: string) {
    await this.addressBookService.deleteContact(addressId);
    await this.loadAddressBook();
  }

  async updateName(contact: SafeContact) {
    await this.addressBookService.setName(contact.Id, contact.name);
  }

  exit() {
    this.router.navigate(['/eyes/inbox']);
  }
}
