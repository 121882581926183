import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { QrCodeComponent } from 'src/app/components/qrcode/qrcode.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeCrypto } from 'src/app/lib/safeCrypto';
import { ApiService } from 'src/app/services/api.service';
import { ErrorService, ErrorType, SafeError } from 'src/app/services/error.service';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { NgIf } from '@angular/common';
import base64url from 'base64url';
import { AuthzService } from 'src/app/services/authz.service';
import { Console } from 'src/app/lib/console';
import { PrintService } from 'src/app/services/print.service';

@Component({
  selector: 'accessid-modal',
  templateUrl: './access-id.component.html',
  styleUrls: ['./access-id.component.scss'],
  standalone: true,
  imports: [QrCodeComponent, TranslateModule, NgIf]

})
export class AccessidModalComponent implements OnInit {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<boolean>();

  @Input() data!: { title: string, action: 'access' | 'register', passphrase: string, access: SafeAccess, safeName?: string };

  url = '';
  title = '';
  private encodedPassphrase = '';
  private encodedAccessID = '';
  private success = true;
  constructor(private apiSvc: ApiService, private errorSvc: ErrorService, public authzSvc: AuthzService, private translate: TranslateService, private printService: PrintService) {
    this.title = this.translate.instant('MODALS.ACCESS_ID.REAUTHZ');
  }

  async ngOnInit(): Promise<void> {
    try {
      const access = this.data.access;

      this.encodedPassphrase = base64url.encode(this.data.passphrase);
      this.encodedAccessID = base64url.encode(access.accessID);

      const keys = await this.calculateKeysByPassphrase(this.data.passphrase, access.accessID);
      if (!keys) {
        this.errorSvc.process('Error calculating keys');
        this.response.emit(false);
        return;
      }
      access.wrappedClientKey = keys.wrappedClientKey;
      access.wrappedServerKey = keys.wrappedServerKey;
      await this.apiSvc.updateAccess(this.data.access);
      this.title = this.data.title;
      this.url = `${window.location.origin}/#/${this.data.action}/${this.encodedAccessID}/${this.encodedPassphrase}`; // path fragments are not sent to the server
      if (this.data.safeName) {
        this.url += `/${this.data.safeName}`;
      }
    } catch (err: any) {
      Console.error(err);
      const errormsg = err.message || "Unkown";
      this.errorSvc.process(new SafeError(ErrorType.UNKNOWN, this.translate.instant('MODALS.ACCESS_ID.ERROR_MSG') + errormsg));
      this.response.emit(false);
    }
  }

  next() {
    this.response.emit(this.success);
  }

  /**
   *
   * @param passphrase Get the keys from the server and encrypt them with the passphrase
   * @param accessID
   * @returns
   */
  private async calculateKeysByPassphrase(passphrase: string, accessID: string) {
    const keys = await this.apiSvc.getServerKeys(); // keys are encrypted with the current safeaccess master key
    const serverMetadatakey = keys.server;
    const clientDataMasterkey = await this.decryptClientDataMasterKey(keys.client);
    if (!clientDataMasterkey) {
      this.success = false;
      throw new Error('client key not found');
    }
    //Init keyring with passphrase and clientid
    const crypto = new SafeCrypto(false);
    await crypto.initCryptoWithPassphrase(passphrase, accessID);
    const wrappedServerKey = await crypto.encryptServerMetadataKey(serverMetadatakey);
    const wrappedClientKey = await crypto.encryptClientDataMasterKey(clientDataMasterkey);

    return { wrappedServerKey, wrappedClientKey };
  }

  // Get the clientDataKey from the authorization service, or
  // if it doesn't exist, get the clientDataKey from the API service
  private async decryptClientDataMasterKey(wrappedClientDataKey: string) {
    const credentialObject = await this.apiSvc.getCredentialObject();

    if (!credentialObject) {
      this.success = false;
      throw new Error('No data returned from webauthn');
    }
    const crypto = new SafeCrypto(false);

    await crypto.initCryptoWithSafeAccessMasterKey(credentialObject.pass);
    const clientDataMasterKey = await crypto.decryptClientDataMasterKey(wrappedClientDataKey);
    return clientDataMasterKey;
  }

  print() {
    this.printService.print('print-container');
  }

  copyURL() {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(this.url);
    }
  }
}

