import { Archive } from "../interfaces/archive";

export class EyesFile {
  getBlob(): Blob {
    throw new Error('Method not implemented.');
  }
  private file?: File;
  private archive?: Archive;
  public name!: string;
  public size!: number;
  public type!: string;

  private constructor() {}

  public static fromFile(file: File): EyesFile {
    const eyeFile = new EyesFile();
    eyeFile.file = file;
    eyeFile.name = file.name;
    eyeFile.size = file.size;
    eyeFile.type = file.type;
    return eyeFile;
  }

  public static fromArchive(archive: Archive): EyesFile {
    const eyeFile = new EyesFile();
    eyeFile.archive = archive;
    eyeFile.name = archive.m?.name || "None";
    eyeFile.size = archive.fs || 0;
    eyeFile.type = archive.m?.type || "application/octet-stream";
    return eyeFile;
  }

  public async stream(): Promise<ReadableStream<Uint8Array>> {
    if (this.file) {
      return this.file.stream();
    }

    if (!this.archive || !this.archive.id) {
      throw new Error("Archive is missing or invalid.");
    }

    try {
      const url = `/archive/${this.archive.id}`;
      const response = await fetch(url);

      if (!response.ok) {
        throw new Error(`Failed to fetch archive: ${response.statusText}`);
      }

      return response.body!;
    } catch (error) {
      console.error("Error streaming archive:", error);
      return new ReadableStream({
        start(controller) {
          controller.close();
        },
      });
    }
  }
}
