import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import base64url from 'base64url';
@Injectable({
  providedIn: 'root'
})
export class EyesAddressService {
  constructor(private apiService: ApiService) {
  }

  async getPublicKey(id: any): Promise<Uint8Array | null> {
    const b64key = await this.apiService.getEyesPublicKey(id);
    if (b64key) {
      return base64url.toBuffer(b64key);
    } else {
      return null;
    }
  }

}
