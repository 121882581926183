<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" [innerHTML]="title"></h2>
        <div #authBody class="input-body">
          <div [innerHTML]="message" class="notranslate"></div>
          <div class="affiliate-link-creator">
            <form [formGroup]="linkForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <label for="suffix">Affiliate Link Suffix</label>
                <div class="input-group">
                  <span class="input-group-addon">{{ baseUrl }}</span>
                  <input type="text" id="suffix" formControlName="suffix" placeholder="yoursuffix" maxlength="20">
                </div>
              </div>
              <div *ngIf="suffixControl.invalid && (suffixControl.dirty || suffixControl.touched)"
                class="error-message">
                <div *ngIf="suffixControl.errors?.['required']">Please enter a suffix for your affiliate link.</div>
                <div *ngIf="suffixControl.errors?.['pattern']">Only letters, numbers, underscores, and hyphens are
                  allowed.</div>
              </div>
              <button type="submit" [disabled]="linkForm.invalid">
                Create Affiliate Link
              </button>
            </form>
            <div *ngIf="affiliateLink" class="result">
              <h3>Your Affiliate Link:</h3>
              <p>{{ affiliateLink }}</p>
            </div>
          </div>
          <div #buttonContainer class="button-container">
            <button tabindex="4" class="btn" (click)="cancel()"> {{ 'CANCEL' | translate
              }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
