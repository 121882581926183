<div
  *ngIf="!showGuard && !showAppConfig && !showManageKeys &&! showManageSpaces && !showUpgrade && !disabledAB && ! showManageAffiliate"
  class="cfgBody">
  <div [ngClass]="{'disabled-item': !networkSvc.isOnline}">
    <div *ngIf="authzSvc.FIDO2" class="item" (click)="appConfig()" (keydown.enter)="appConfig()" tabindex="0"
      [ngClass]="{'disabled-item': !isAdmin}">
      <div appHelp="APP_CONFIG.HELP.APP_CONFIG">
        <img src="./assets/images/svg/editcfg.svg" alt="App Config" class="pic">
        <span class="text">{{ 'APP_CONFIG.APP_CONFIG' | translate }}</span>
      </div>
    </div>

    <div *ngIf="authzSvc.FIDO2" class="item" (click)="setPin()" (keydown.enter)="setPin()" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.SET_PIN">
        <img src="./assets/images/svg/pin-pad.svg" alt="Set Pin" class="pic">
        <span class="text">{{'APP_CONFIG.SET_PIN' | translate }}</span>
      </div>
    </div>

    <div *ngIf="!authzSvc.FIDO2" class="item" (click)="registerWebAuthn()" (keydown.enter)="registerWebAuthn()"
      tabindex="0">
      <div appHelp="APP_CONFIG.HELP.REGISTER">
        <img src="./assets/images/svg/webauthn.svg" alt="Register Key" class="pic">
        <span class="text">{{'APP_CONFIG.WEBAUTHN.KEY' | translate }}</span>
      </div>
    </div>

    <div *ngIf="authzSvc.FIDO2" [ngClass]="{'disabled-item': tier==0 ||  tier==4 || safeLocked || !isAdmin}"
      class="item" (click)="setAB()" (keydown.enter)="setAB()" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.AB">
        <img src="./assets/images/svg/plausible.svg" alt="Set A/B Mode" class="pic">
        <span class="text">{{ abText }}</span>
      </div>
    </div>

    <div *ngIf="authzSvc.FIDO2" [ngClass]="{'disabled-item': tier==0 ||tier==4  || ! isAdmin }" class="item"
      (click)="keys(true)" (keydown.enter)="keys(true)" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.KEYS">
        <img src="./assets/images/svg/add-key.svg" alt="Manage Keys" class="pic">
        <span class="text">{{'APP_CONFIG.KEYS' | translate }}</span>
      </div>
    </div>

    <div *ngIf="authzSvc.FIDO2" [ngClass]="{'disabled-item': tier==0 || tier==1 || tier==4  || ! isAdmin }" class="item"
      (click)="spaces(true)" (keydown.enter)="spaces(true)" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.SPACES">
        <img src="./assets/images/svg/spaces.svg" alt="Manage Spaces" class="pic">
        <span class="text">{{'APP_CONFIG.SPACES.TITLE' | translate }}</span>
      </div>
    </div>

    <div *ngIf="authzSvc.FIDO2" [ngClass]="{'disabled-item': safeLocked || !isAdmin }" class="item"
      (click)="guard(true)" (keydown.enter)="guard(true)" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.GUARD">
        <img src="./assets/images/svg/health.svg" alt="Loss" class="pic">
        <span class="text">{{ 'APP_CONFIG.GUARD' | translate }}</span>
      </div>
    </div>

    <div *ngIf="deferredInstallPrompt && authzSvc.FIDO2" class="item" (click)="installApp()"
      (keydown.enter)="installApp()" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.INSTALL">
        <img src="./assets/images/svg/install.svg" alt="Install" class="pic">
        <span class="text">{{'APP_CONFIG.INSTALL.KEY' | translate }}</span>
      </div>
    </div>

    <div *ngIf="authzSvc.FIDO2" [ngClass]="{'disabled-item': !isAdmin}" class="item" (click)="lockSafe()"
      (keydown.enter)="lockSafe()" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.SHIELD">
        <img src="./assets/images/svg/shield.svg" alt="Unlock" class="pic" tabindex="0">
        <span class="text">{{safeLocked ? lockedText : unlockedText }}</span>
      </div>
    </div>

    <div *ngIf="affiliatesCfg" class="item" (click)="manageAffiliate(true)" (keydown.enter)="manageAffiliate(true)"
      tabindex="0">
      <div appHelp="APP_CONFIG.HELP.Affiliate">
        <img src="./assets/images/svg/affiliate.svg" alt="Affiliate" class="pic">
        <span class="text">Affiliate Program</span>
      </div>
    </div>

    <div *ngIf="addCreditsOption" class="item" (click)="addCredits()" (keydown.enter)="addCredits()" tabindex="0">
      <div appHelp="APP_CONFIG.HELP.CREDITS">
        <img src="./assets/images/svg/extend.svg" alt="Credits" class="pic">
        <span class="text">{{ ADD_CREDIT_TEXT_KEY | translate }}</span>
      </div>
    </div>

    <div *ngIf="upgradeable && authzSvc.FIDO2" class="item upgrade" (click)="upgrade(true)"
      (keydown.enter)="upgrade(true)" tabindex="0" [ngClass]="{'disabled-item': !isAdmin}">
      <div appHelp="APP_CONFIG.HELP.UPGRADE">
        <img src="./assets/images/svg/upgrade.svg" alt="Upgrade" class="pic">
        <span class="text">{{'APP_CONFIG.UPGRADE' | translate }}</span>
      </div>
    </div>

    <div *ngIf="updateAvailable && authzSvc.FIDO2" class="item" (click)="updateApp()" (keydown.enter)="updateApp()"
      tabindex="0">
      <div appHelp="APP_CONFIG.HELP.UPDATE">
        <img src="./assets/images/svg/update.svg" alt="Update" class="pic">
        <span class="text">{{ 'APP_CONFIG.UPDATE.KEY' | translate }}</span>
      </div>
    </div>

  </div>
</div>

<app-features class='component' *ngIf='showAppConfig'
  (done)="showAppConfig=false; affiliatesCfg=authzSvc.appConfig.aff;"></app-features>
<app-guard-editor class='component' *ngIf='showGuard' (accessCreated)="guard(false)"></app-guard-editor>
<app-manage-keys class='component' *ngIf='showManageKeys' (done)="keys(false)" [accessID]="accessID"></app-manage-keys>
<app-key-space-manager class='component' *ngIf='showManageSpaces' (done)="spaces(false,$event)"></app-key-space-manager>
<app-upgrade class='component' *ngIf='showUpgrade' (done)="upgrade(false)"></app-upgrade>
<app-affiliate class='component' *ngIf='showManageAffiliate' (done)="manageAffiliate(false)"></app-affiliate>

<app-modals></app-modals>
