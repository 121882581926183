<div class="spacecontrol">
  <div class="tabs">
    <div *ngFor="let space of db.spaces; let i = index" class="tab" (click)="change(i)"
      [class.active]="i === db.currentSpaceNumber">
      <svg class="tab-bg" viewBox="0 0 100 40" preserveAspectRatio="none">
        <path d="M0,0 H85 L100,15 V40 H0 Z" fill="white" stroke="black" stroke-width="1" />
      </svg>
      <span class="tab-label">{{ space.m.spaceName? space.m.spaceName: "Space "+(i+1) }}</span>
    </div>

    <div *ngIf="isAdmin" class="tab new-tab" (click)="new()">
      <svg class="tab-bg" viewBox="0 0 100 40" preserveAspectRatio="none">
        <path d="M0,0 H85 L100,15 V40 H0 Z" fill="white" stroke="black" stroke-width="1" />
      </svg>
      <span class="tab-label">＋</span>
    </div>
  </div>
</div>