import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CallBack } from 'src/app/interfaces/callback';
import { AuthzService } from 'src/app/services/authz.service';
import { NgIf } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { ModalComponent } from '../../modal/modal.component';
import { InitializationService } from 'src/app/services/initialization-service';

@Component({
  selector: 'app-safe-config',
  templateUrl: './safe-config.component.html',
  styleUrls: ['./safe-config.component.scss'],
  standalone: true,
  imports: [NgIf, ModalComponent]
})

/*
*Gets authz data from the user and processes the configuration for a new Safe
*/
export class SafeConfigComponent implements AfterViewInit {
  @Output() reply = new EventEmitter<CallBack>();
  @Input() callback!: CallBack; // The new safeconfig request from the server
  @Input() flow!: string; // The authz flow
  @Input() accessID!: string; //
  @Input() safeName: string | undefined; //

  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  constructor(public api: AuthzService, private translate: TranslateService, private initSvc: InitializationService) {
  }

  ngAfterViewInit(): void {
    this.processCallback();
  }

  /**
   * Processes the callback from the server.
   * Encrypts the data key with the Safe Access Master key.
   * Warns user about PIN.
   * Gets the PIN clicks from the user.
   * returns the PIN clicks and the encrypted data key to the server.
   */
  private async processCallback() {

    // Get the partner id from the URL
    const pid = this.initSvc.getParam('pid');
    if (pid) {
      this.callback.action.result.partnerId = pid;
    }

    if (!this.safeName|| this.safeName === 'none') {
      this.safeName = await this.modalController.displayInput(this.translate.instant('CONFIG.NAME.TITLE'), this.translate.instant('CONFIG.NAME.MSG'), false);
      if (!this.safeName) {
        this.safeName = 'Unolock';
      }
    }
    this.callback.action.result.safeName = this.safeName;

    // Encrypt the data key with the Safe Access Master key. The data key will be used to initialize the Safes keystore after authz which will then be used to encrypt/decrypt data stored in S3. The server only persists the encrypted data key.
    this.callback.action.result.dataKey = await this.api.wrapServerMetadataKey(this.callback.action.request.dataKey); // encrypts the data key with the passphrase

    //Ping warning
    await this.modalController.displayPinWarning();

    //get pin
    const pin = await this.modalController.displayPad(this.translate.instant('CONFIG.PIN.SELECT_PIN'), this.callback.action.request.image);
    this.callback.action.result.pinClicks = pin

    // Notify the user that we are about to test the configuration.
    await this.modalController.displayQuestion(this.translate.instant('CONFIG.TEST.TITLE'), this.translate.instant('CONFIG.TEST.MSG'), null, null, this.translate.instant('NEXT'));

    //send collected data to server
    this.reply.emit(this.callback);
  }
}
