import { Injectable } from '@angular/core';
import { Archive, Location } from 'src/app/interfaces/archive';
import { CallBack, EmptyAction } from 'src/app/interfaces/callback';
import { AuthzService } from './authz.service';
import { fromBase64 } from '@aws-sdk/util-base64-browser'
import { AppConfig } from '../interfaces/appConfig';
import { RegistrationResponseJSON } from '@simplewebauthn/types';
import { SafeAccess } from '../interfaces/safeAccess';
import { startAuthentication } from '@simplewebauthn/browser';
import { SafeCrypto } from '../lib/safeCrypto';
import { BroadcastChannelHandler } from '../lib/broadcastChannelHandler';
import { Console } from '../lib/console';
import { ErrorService, ErrorType, SafeError } from './error.service';
import { Subject } from 'rxjs';
import { UserHandleConverter } from '../lib/userHandleConverter';
import { NetworkService } from './network.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  public updateAvailable: any // set by main component when new version detected, used by config component to show update option vaule will be the hash of the new version
  public deferredInstallPrompt: Event | undefined; // Used by config component to show PWD install option

  // Private variable to hold the current spaceID value
  private _spaceID = 0;

  // Subject to emit spaceID changes
  private spaceIDSubject = new Subject<number>();

  // Expose the observable part of the Subject
  public spaceID$ = this.spaceIDSubject.asObservable();
  private locations: Location[] | null = null;

  // Getter and setter for spaceID
  public get spaceID(): number {
    return this._spaceID;
  }

  public currentLocationID = '';
  public setSpaceID(value: number, locationID: string) {
    this.currentLocationID = locationID;
    // Check if the new value is different before emitting
    if (value !== this._spaceID) {
      this._spaceID = value;
      this.spaceIDSubject.next(value);
    }
  }

  public getCurrentLocationID() {
    if (!this.currentLocationID) {
      this.currentLocationID = this.getAppConfig().locID;
    }
    return this.currentLocationID;
  }

  private uploadCache = new Map<string, { expiryTime: number, uploadLinkObject: any }>();
  private archiveCache = new Map<string, Archive>();
  private apiServiceBroadcastChannelHandler!: BroadcastChannelHandler;

  clearCaches() {
    this.uploadCache.clear();
    this.archiveCache.clear();
    this.apiServiceBroadcastChannelHandler.postMessage({ action: 'CLEAR_CACHE' });
  }

  public whipeCrypto() {
    this.uploadCache.clear();
    this.archiveCache.clear();
    this.apiServiceBroadcastChannelHandler.postMessage({ action: 'WIPE_CRYPTO' });
  }

  constructor(private authzSvc: AuthzService, private networkSvc: NetworkService, private translate: TranslateService) {
    this.listenForServiceWorkerMessages();
  }

  private listenForServiceWorkerMessages() {

    this.apiServiceBroadcastChannelHandler = new BroadcastChannelHandler("ApiService", async (data) => {
      Console.log('ApiService', data);
      if (!this.authzSvc.isAuthorized()) {
        throw new Error('Ignore');
      }
      if (data == 'SW_RESTARTED') {
        const safeError = new SafeError(ErrorType.CRYPTO_NOT_INITIALIZED, 'Service worker restarted');
        setTimeout(() => { location.reload() }, 5000); //reload the page after 5 second
        ErrorService.instance.process(safeError);
        return;
      } else if (data == 'INITIALIZE_CRYPTO') {
        Console.log('INITIALIZE_CRYPTO');
        return await this.reInitServiceworkerCrypto();
      } else if (data == 'KEEP_ALIVE') {
        Console.log('Keep alive ping from service worker');
        return this.ping(); //soft ping
      }
      const functionName = data.functionName;
      const args: any[] = data.args;
      return await this[functionName].apply(this, args);

    });

    addEventListener("beforeunload", (event) => {
      if (this.authzSvc.isAuthorized()) {
        this.authzSvc.setAuthorized(false);
        this.apiServiceBroadcastChannelHandler.postMessage({ action: 'BEFORE_UNLOAD' });
      }
      this.clearCaches();
    });
  }

  //called by service worker
  private async swgetState(): Promise<any> {
    Console.log('swgetState');
    if (!this.authzSvc.isAuthorized()) {
      throw new Error('Ignore'); //Do not respond to service worker if not authorized
    }
    await this.authzSvc.ping(true); // force update the state
    return this.authzSvc.state;
  }

  //called by service worker before it wipes the state to test if any clients(tabs) are still authz
  private async swPing(): Promise<any> {
    Console.log('swPing');
    if (!this.authzSvc.isAuthorized()) {
      throw new Error('Ignore'); //Do not respond to service worker if not authorized
    }
  }

  public isReadOnly(): boolean {
    return (this.authzSvc.appConfig.ro || !this.networkSvc.isOnline)
  }


  ping() {
    if (this.networkSvc.isOnline) {
      this.authzSvc.ping();
    } else {
      Console.log('APiSvc ping: offline');
    }
  }

  async getEyesPublicKey(eyesAddr: string): Promise<string | null> {
    const callback = new CallBack('GetEyesPublicKey', new EmptyAction());
    const request = { eyesAddr };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const b64key = resultCallBack.action.result;
    if (!b64key) {
      return null;
    }
    return b64key;
  }

  async getLocationName(locationID: string): Promise<string> {
    const locations = await this.getLocations();
    const location = locations.find(l => l.id == locationID);
    if (!location) {
      throw new Error('Location not found');
    }
    let name = 'Canada';
    const locid = parseInt(locationID);
    if (locid > 16) {
      //multi region
      const regions = location.region.split(',');
      const region1 = this.translate.instant('LOCATIONS.XRegion.' + regions[0]);
      const region2 = this.translate.instant('LOCATIONS.XRegion.' + regions[1]);
      name = region1 + ' & ' + region2;
    } else {
      //single region
      name = this.translate.instant('LOCATIONS.' + location.region);
    }
    return name;
  }


  /**
   * Move all archives from a space to a different location
   * Can not move archives all at once due to 30 second limit on server by API gateway
   * @param spaceID
   * @param locationID
   * @returns
   * @throws SafeError
   *
   */
  async moveSpaceArchives(spaceID: number, locationID: string): Promise<{ recordsResult: any, filesResult: PromiseSettledResult<any>[] | undefined }> {
    const isLocalDev = location.hostname == 'localhost';
    Console.log('***********************hostname', location.hostname);

    const archives = await this.getArchives();
    let spaceArchives = archives.filter(a => a.sid == spaceID && a.l != locationID && a.t != 'Local');

    //can not move archives larger than 5GB
    const largeArchives = spaceArchives.filter(a => a.sid == spaceID && a.s >= 5 * 1024 * 1024 * 1024);
    if (largeArchives.length > 0) {
      // throw new SafeError(ErrorType.VALIDATION, 'Can not move archives larger than 5GB');
      Console.log('Can not move archives larger than 5GB');
      //remove the large archives from the list
      spaceArchives = spaceArchives.filter(a => a.sid != spaceID || a.s <= 5 * 1024 * 1024 * 1024);
    }
    //do Records first
    const records = spaceArchives.filter(a => a.t == 'Records');
    let recordsResult: any;
    //then other files
    const files = spaceArchives.filter(a => a.t != 'Records');
    let filesResult: PromiseSettledResult<string>[] | undefined;

    if (records.length > 0) {
      Console.log('Moving records', records);
      recordsResult = await this.moveArchive(records, locationID);
      Console.log('Move Records result', recordsResult);
    }
    if (files.length > 0) {
      Console.log('Moving files', files);
      if (isLocalDev) {
        Console.log('Dev Move File');
        for (const file of files) {
          const result = await this.moveArchive([file], locationID);
          Console.log('Dev Move File result ' + file.id, result);
        }
      } else {
        const promises: Promise<any>[] = [];
        for (const file of files) {
          promises.push(this.moveArchive([file], locationID));
        }
        filesResult = await Promise.allSettled(promises);
        Console.log('Move File result', filesResult);
      }
    }
    return { recordsResult, filesResult };
  }

  private async moveArchive(archives: Archive[], locationID: string): Promise<string> {
    const archiveIDs = archives.map(a => a.id);
    const callback = new CallBack('MoveArchives', new EmptyAction());
    const request = { archiveIDs, locationID };
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    this.clearCaches();
    return resultCallBack.action.result;
  }

  async setTimeLock(time: number, type: 'ALL' | 'DEVICE', accessID?: string) {
    const callback = new CallBack('SetTimeLock', new EmptyAction());
    const request = { time, type, accessID }
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  /**
   *
   * Data is locally encrypted then sent to the server for further encryption
   * Data can only be decrypted after webauthn authn user verification
   * @returns doubley encrypted data
   */
  async authnEncryptData(data: string): Promise<string> {
    const localyencrypted = await this.authzSvc.crypto.encryptString(data);
    const callback = new CallBack('EncryptData', new EmptyAction());
    callback.action.request = localyencrypted;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  /**
   * Requires webauthn authn user verification to decrypt the data
   * @param data encrypted by AuthnEncryptData method
   * @returns
   */
  async authnDecryptData(data: string): Promise<string> {
    //get webauthn authn data
    const challengsCallback = new CallBack('DecryptDataGetChallenge', new EmptyAction());
    const challengeResultCallBack = await this.authzSvc.apiRequest(challengsCallback);
    if (challengeResultCallBack.action.result == 'FAILURE') {
      throw new Error(challengeResultCallBack.action.reason);
    }
    const authnData = challengeResultCallBack.action.result;
    // trigger webauthn authn
    const response = await startAuthentication({ optionsJSON: authnData });
    const datacallback = new CallBack('DecryptData', new EmptyAction());
    datacallback.action.request = { authnResult: response, data };
    const dataResultCallBack = await this.authzSvc.apiRequest(datacallback);

    const locallyDecrypted = await this.authzSvc.crypto.decryptString(dataResultCallBack.action.result);
    return locallyDecrypted
  }

  async createAffiliate(): Promise<{ affiliate: any; url: string | undefined; onboarding: boolean }> {
    const callback = new CallBack('CreateAffiliate', new EmptyAction());
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result == 'FAILURE') {
      throw new Error(result.action.reason);
    }
    return result.action.result;
  }

  async createAffiliateLink(suffix: string): Promise<boolean> {
    const callback = new CallBack('CreateAffiliateLink', new EmptyAction());
    callback.action.request = suffix;
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result == 'FAILURE') {
      throw new Error(result.action.reason);
    }
    return result.action.result;
  }

  async getAffiliate(): Promise<{
    affiliate: any;
    url: string | undefined;
    onboarding: boolean | undefined;
  } | null> {
    const callback = new CallBack('GetAffiliate', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  async getRegistrationOptions(mode: 'platform' | 'cross-platform') {
    const callback = new CallBack('GetRegisterDeviceOpts', new EmptyAction());
    callback.action.request = mode;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  async sendRegistrationResponse(response: RegistrationResponseJSON, data: any) {
    const callback = new CallBack('AddDeviceRegistration', new EmptyAction());
    const request = { response, data }
    callback.action.request = request;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  private async getWebAuthAuth() {
    const callback = new CallBack('WebAuthnAuth', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    return resultCallBack.action.result;
  }

  async getArchives(skipCache = false): Promise<Archive[]> {

    if (!skipCache && this.archiveCache.size > 0) {
      return Array.from(this.archiveCache.values());
    }

    const callback = new CallBack('GetArchives', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const archives: Archive[] = resultCallBack.action.result;

    //decrypt the archives metadata
    for (const archive of archives) {
      if (!archive.m) continue;
      const decrypted = await this.authzSvc.crypto.decryptString(archive.m);
      archive.m = JSON.parse(decrypted);
    }

    //cache the archives in a map
    for (const archive of archives) {
      this.archiveCache.set(archive.id, archive);
    }
    return archives;
  }

  async getArchive(id: string, skipCache = false): Promise<Archive> {
    const archives = await this.getArchives(skipCache);
    const archive = archives.find(a => a.id == id);
    if (!archive) {
      throw new Error('Archive not found');
    }
    return archive;
  }

  async createArchive(archive: Archive): Promise<Archive> {
    Console.log('createArchive', archive);
    if (!archive.sid) {
      archive.sid = this.spaceID;
    }

    if (archive.t == 'Records') {
      //Set put or post method
      //New record. To POST or to PUT, that is the question.
      const tier = this.getAppConfig().tier;
      const putSupported = tier != 0 && tier != 4; //MRAP is not supported in free and lifesafe
      const tr = putSupported ? 'put' : 'post'; // if put is supported we can use MRAP
      if (!archive.m) {
        archive.m = {};
      }
      archive.m.tr = tr;
    }

    const archiveRequest = JSON.parse(JSON.stringify(archive)); //clone the object
    const metadata = archiveRequest.m;
    if (metadata) {
      const encryptedMetadata = await this.authzSvc.crypto.encryptString(JSON.stringify(metadata));
      archiveRequest.m = encryptedMetadata;
    }
    const callback = new CallBack('CreateArchive', new EmptyAction());
    callback.action.request = archiveRequest;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    const archiveResult: Archive = resultCallBack.action.result;
    if (archiveResult.m) {
      const decrypted = await this.authzSvc.crypto.decryptString(archiveResult.m);
      archiveResult.m = JSON.parse(decrypted);
      Console.log('archive Result', archiveResult);
      this.archiveCache.set(archiveResult.id, archiveResult);
    }
    Console.log('archiveResult', archiveResult);
    return archiveResult;
  }

  async setSensitiveSpaces(safeWordClicks: { x: number; y: number }[], spaces: number[]): Promise<Archive> {
    const callback = new CallBack('SetSensitiveSpaces', new EmptyAction());

    const request = { safeWordClicks, spaces }
    callback.action.request = request;
    this.clearCaches();
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }

    const newarchive = resultCallBack.action.result
    this.archiveCache.set(newarchive.id, newarchive);
    return newarchive;
  }

  async deleteArchive(id: string) {
    const callback = new CallBack('DeleteArchive', new EmptyAction());
    callback.action.request = id;
    this.archiveCache.delete(id);
    const result = await this.authzSvc.apiRequest(callback);
    const safeExp = result.action.result.safeExp;
    Console.log('safeExp', safeExp);
    this.authzSvc.safeExpiryEPOCms = safeExp * 1000;
    return result;
  }

  async updateArchive(archiveRequest: Archive) {
    const archive = JSON.parse(JSON.stringify(archiveRequest));

    const metadata = archive.m;
    if (metadata) {
      const encryptedMetadata = await this.authzSvc.crypto.encryptString(JSON.stringify(metadata));
      archive.m = encryptedMetadata;
    }
    const callback = new CallBack('UpdateArchive', new EmptyAction());
    callback.action.request = archive;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
    this.archiveCache.set(archiveRequest.id, archiveRequest);
    return resultCallBack.action.result;
  }

  async getSafeAuthzConfig() {
    const callback = new CallBack('GetAuthConfigTemplate', new EmptyAction());
    return this.authzSvc.apiRequest(callback);
  }

  async setSafeAuthzConfig(data: { pinClicks: { x: number, y: number }[] }) {
    const callback = new CallBack('SetSafeAuthConfig', new EmptyAction());
    callback.action.request = data;
    return await this.authzSvc.apiRequest(callback);
  }

  async deleteSafe(): Promise<{ code: string, credits: number } | undefined> {
    const callback = new CallBack('DeleteSafe', new EmptyAction());
    const ret = await this.authzSvc.apiRequest(callback);
    return ret.action.result
  }

  async getCreditTokenAccessToken(): Promise<string> {
    const callback = new CallBack('GetCreditTokenAccessToken', new EmptyAction());
    const ret = await this.authzSvc.apiRequest(callback);
    return ret.action.result;
  }

  async getLocations(): Promise<Location[]> {
    if (this.locations) {
      return JSON.parse(JSON.stringify(this.locations)); //clone the object because we cache it
    }
    const callback = new CallBack('GetLocations', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    this.locations = resultCallBack.action.result;
    return JSON.parse(JSON.stringify(this.locations));
  }

  async getUploadObject(archiveID: string): Promise<any> {
    const cache = this.uploadCache.get(archiveID);
    if (cache) {
      if (cache.expiryTime > Date.now() + 1000 * 600) { // dont use if less that 10 minutes

        return cache.uploadLinkObject
      } else {
        this.uploadCache.delete(archiveID);
      }
    }

    const callback = new CallBack('GetUploadObject', new EmptyAction());
    callback.action.request = archiveID;
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const uploadObject = resultCallBack.action.result;

    this.cacheUpload(archiveID, uploadObject);

    return uploadObject
  }

  private cacheUpload(archiveID: string, uploadLinkObject: any) {
    const policy = uploadLinkObject['Policy'];
    const expiration = this.extractPolicyExpiry(policy);
    this.uploadCache.set(archiveID, { expiryTime: expiration, uploadLinkObject: uploadLinkObject });
  }

  private extractPolicyExpiry(policy: string) {
    const policyArray = fromBase64(policy);
    const decoder = new TextDecoder();
    const policystring = decoder.decode(policyArray);
    const policyObject = JSON.parse(policystring);
    return Date.parse(policyObject.expiration);
  }

  async getDownloadLink(archiveID: string) {
    const callback = new CallBack('GetDownloadLink', new EmptyAction());
    callback.action.request = archiveID;
    const resultCallBack = await this.authzSvc.apiRequest(callback);

    const url = resultCallBack.action.result;
    return url
  }

  async setReadOnly(setreadonly: boolean) {
    const cfg = this.authzSvc.appConfig;
    cfg.ro = setreadonly;
    await this.setAppConfig(cfg);
    this.authzSvc.appConfig = cfg;
  }

  async setAppConfig(cfg: AppConfig) {
    const callback = new CallBack('SetAppConfig', new EmptyAction());
    callback.action.request = cfg;
    await this.authzSvc.apiRequest(callback);
    Object.assign(this.authzSvc.appConfig, cfg); //keep the same object
  }

  async getUpgradeInfo() {
    const callback = new CallBack('GetUpgradeInfo', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  getAppConfig(): AppConfig {
    return JSON.parse(JSON.stringify(this.authzSvc.appConfig));

  }

  async refreshAppConfig(): Promise<AppConfig> {
    const callback = new CallBack('GetAppConfig', new EmptyAction());;
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const appConfig: AppConfig = resultCallBack.action.result;
    this.authzSvc.appConfig = appConfig;
    return appConfig;
  }

  async getAccesses(types: string): Promise<SafeAccess[]> { // types = share or guard os share,guard or device
    const callback = new CallBack('GetAccessCfg', new EmptyAction());
    callback.action.request = { type: types };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const accesses: SafeAccess[] = resultCallBack.action.result;
    return accesses;
  }

  async createAccess(access: SafeAccess): Promise<void> {
    const callback = new CallBack('CreateAccessCfg', new EmptyAction());
    callback.action.request = { access };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    if (resultCallBack.action.result == 'FAILURE') {
      throw new Error(resultCallBack.action.reason);
    }
  }

  async updateAccess(access: SafeAccess): Promise<void> {
    const callback = new CallBack('UpdateAccessCfg', new EmptyAction());
    callback.action.request = { access };
    await this.authzSvc.apiRequest(callback);
  }

  async deleteAccess(accessID: string): Promise<void> {
    const callback = new CallBack('DeleteAccess', new EmptyAction());
    callback.action.request = { accessID };
    await this.authzSvc.apiRequest(callback);
  }

  async getServerKeys(): Promise<{ server: string, client: string }> {
    const callback = new CallBack('GetServerKeys', new EmptyAction());
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    return resultCallBack.action.result;
  }

  /**
   *
   * @returns the decrypted credential object containing the SafeAccess Master key and accessID
   */
  async getCredentialObject(): Promise<any> {
    const options = await this.getWebAuthAuth();
    const authenticatorData = await startAuthentication({ optionsJSON: options });
    if (!authenticatorData.response.userHandle) {
      throw new Error('No key returned from webauthn');
    }
    let skey = authenticatorData.response.userHandle;
    if (!skey) {
      throw new Error('No key returned from webauthn');
    }

    const key = UserHandleConverter.processUserHandle(skey);

    authenticatorData.response.userHandle = undefined; //!Important remove the key from the response
    const callback = new CallBack('GetDeviceDataPacket', new EmptyAction());
    callback.action.request = { authenticatorData };
    const resultCallBack = await this.authzSvc.apiRequest(callback);
    const data = resultCallBack.action.result.data;
    const decrypted = await SafeCrypto.decryptCredentialObject(data, key);
    return decrypted
  }

  /**
   *
   * @param passphrase Get the keys from the server and encrypt them with the passphrase
   * @param accessID
   * @returns
   */
  async reInitServiceworkerCrypto(): Promise<{ ClientDataMasterKey: Uint8Array, ECDHEKeyRAW: ArrayBuffer }> {
    Console.log('reInitServiceworkerCrypto');
    const ECDHEKeyRAW = this.authzSvc.crypto.ECDHEKeyRAW;
    if (!ECDHEKeyRAW) {
      throw new Error('ECDHEKeyRAW not initialized');
    }
    const keys = await this.getServerKeys(); // keys are encrypted with the current safeaccess master key
    Console.log('keys', keys);
    const credentialObject = await this.getCredentialObject();

    if (!credentialObject) {
      throw new Error('No data returned from webauthn');
    }
    const crypto = new SafeCrypto(true); //brodcast to serviceworker

    await crypto.initCryptoWithSafeAccessMasterKey(credentialObject.pass);
    const wrappedClientDataKey = keys.client;
    const ClientDataMasterKey = await crypto.decryptClientDataMasterKey(wrappedClientDataKey);

    return { ClientDataMasterKey, ECDHEKeyRAW };
  }
}
