import { NgClass, NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { Console } from 'src/app/lib/console';
import { AuthzService } from 'src/app/services/authz.service';
import { ModalComponent } from "../../../modal/modal.component";
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'key',
  templateUrl: './key.component.html',
  styleUrls: ['./key.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, FormsModule, TranslateModule, ModalComponent]
})
export class KeyComponent implements OnInit {
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  @Input() key!: SafeAccess;
  @Input() currentKeyID: string | null = null;
  @Output() action: EventEmitter<{ key: SafeAccess, action: string }> = new EventEmitter();

  show = false;
  currentKey = false;
  accessMode: 'admin' | 'ro' | 'rw' = 'ro';
  modes =true;

  constructor(private authzSvc: AuthzService, private apiSvc: ApiService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.accessMode = this.key.mode;
    Console.log('KeyComponent: ngOnInit: key', this.key);
    this.currentKey = this.key.accessID === this.authzSvc.accessID;
    if (this.key.accessID == this.currentKeyID) {
      this.show = true;
    }
const tier = this.authzSvc.appConfig.tier;
    if (tier == 1 || tier == 4) {
      this.modes = false;
    }
  }

  toggleShow() {
    this.show = !this.show;
  }

  emitAction(action: string, event?: Event) {
    if (event) {
      event.stopPropagation();
    }
    this.action.emit({ key: this.key, action });
  }

  deleteKey() {
    this.emitAction('delete');
  }

  toggleEnable(event: Event) {
    this.emitAction('toggleEnable', event);
  }

  setAccessMode(mode: 'admin' | 'ro' | 'rw', event: Event) {
    event.stopPropagation();
    if (this.accessMode !== mode) {
      this.accessMode = mode;
      this.emitAction(mode === 'admin' ? 'setAdmin' : (mode === 'ro' ? 'setReadOnly' : 'setReadWrite'), event);
    }
  }

  rename() {
    this.emitAction('rename');
  }

  async spaces() {
    if(this.key.mode === 'admin') {
      return;
    }
    const title = this.translate.instant('MODALS.SPACES.TITLE');

    const updatedSpaces = await this.modalController.displaySpaceSelector(this.key.spaceIds, { title, message: '' });
    if (updatedSpaces) {
      this.key.spaceIds = updatedSpaces;
      await this.apiSvc.updateAccess(this.key);
    }
  }
}
