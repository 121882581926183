<div class="keys">
  <div class='button-container'>
    <button class="btn" (click)="showRegisterKey=true">
      <span class="text">{{'KEY.ADD' | translate}} </span>
    </button>
  </div>
  <hr>
  <key class="item" [class.currentkey]="key.accessID == currentAccessID" *ngFor="let key of keys"
    [key]="key" [currentKeyID]="accessID" (action)="action($event)"></key>
  <app-register-key *ngIf='showRegisterKey' (accessCreated)="accessCreated($event)"></app-register-key>
  <div class="button-container">
    <button class="btn-primary" (click)="close()">{{'CLOSE' | translate}}</button>
  </div>
  <app-modals></app-modals>
</div>
