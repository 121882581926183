import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  public spinnerEmiter = new EventEmitter<{show:boolean,message:string}>();
  constructor() { }

  public showSpinner(show: boolean, message: string = '') {
    this.spinnerEmiter.emit({show, message});
  }
}
