export const bgColorsConst = {
    _ : {bg:'',color: '' },
    errorpurple : {bg:'#7163F1',color: 'white' },
    offpink : {bg:'#F2388F',color: 'white' },
    digitalgreen :{bg: '#2D7C5C',color: 'white' },
    frezeblue : {bg:'#5FC2D9',color: 'black' },
    bitgold : {bg:'#C18C21',color: 'black' },
    vaultgrey : {bg:'#646E6E',color: 'white' }
} as const;
export type bgColors = typeof bgColorsConst[keyof typeof bgColorsConst];


export const bgImagesConst = {
    i0 : {img:'', color:''},
} as const;
export type bgImages = typeof bgImagesConst[keyof typeof bgImagesConst];

