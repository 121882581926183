<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title">
          <p>{{'AUTH.REGION.TITLE'| translate}}</p>
        </div>
        <div #authBody class="region-body">
          <div class="message">
            <span [innerHTML]="'AUTH.REGION.MESSAGE'|translate"></span>
          </div>
          <div class="grid-container">
            <label class="radio-option" *ngFor="let option of regions">
              <input type="radio" name="radioOption" [(ngModel)]="selectedRegion" [value]="option"
                (change)="onRangeChange()">
              {{ option.label }}
            </label>
          </div>
          <div class="button-container">
            <button [disabled]="!selectedLocation" class="btn-primary" (click)="send()"> {{ 'NEXT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
