<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div id='print-container'>
          <h2 class="auth-title" [innerHTML]="title"></h2>
          <div class="qrcode">
            <qrcode *ngIf="url" (click)="copyURL()" [level]="'H'" [size]='300' [value]="url"></qrcode>
          </div>
          <div *ngIf="url && data.access.accesstext && data.action!='register'" #authBody class="auth-body">
            <div class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off">
              <p>{{'MODALS.ACCESS_ID.ID' | translate}}{{data.access.accesstext}}</p>
            </div>
            <div class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false" autocomplete="off">
              <p>{{'MODALS.ACCESS_ID.PASSPHRASE' | translate}} {{data.passphrase}}</p>
            </div>
          </div>
        </div>
        <div *ngIf="url" class="button-container">
          <button class="btn" (click)="copyURL()">{{'MODALS.ACCESS_ID.COPY_URL' | translate }}</button>
          <button class="btn" (click)="print()">{{'MODALS.ACCESS_ID.PRINT' | translate }}</button>
          <button class="btn-primary" (click)="next()"> {{ 'DONE' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
