<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" [innerHTML]="displayData.title"></h2>
        <div #authBody class="auth-body">
          <div [innerHTML]="displayData.message"></div>
          <div class="list-select-container">
            <div class="list-select-item" *ngFor="let item of displayData.items; let i = index" (click)="listItemClick(item)"  [class.selected]="item == selectedItem">
               <div class="list-select-item-label">{{item.label}}</div>
            </div>
        </div>
        <div class="button-container">
          <button *ngIf="displayData.btnCancelLable" class="btn" (click)="buttonClick('ZERO')">
            {{displayData.btnCancelLable}}</button>
          <button *ngIf="displayData.btnOneLable" class="btn-primary" (click)="buttonClick('ONE')" [disabled]="!selectedItem"> {{displayData.btnOneLable}} </button>
        </div>
      </div>
    </div>
  </div>
</div>
