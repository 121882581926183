import { AfterViewInit, Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { ModalComponent } from '../../modal/modal.component';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from 'src/app/services/api.service';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { AuthzService } from 'src/app/services/authz.service';

import base64url from 'base64url';
import { Utilities } from 'src/app/lib/utilities';
import { Console } from 'src/app/lib/console';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { db } from 'src/app/db/json';
@Component({
  selector: 'app-register-key',
  templateUrl: './register-key.component.html',
  styleUrls: ['./register-key.component.scss'],
  standalone: true,
  imports: [ModalComponent, TranslateModule]
})
export class RegisterKeyComponent implements AfterViewInit {

  @ViewChild(ModalComponent, { static: false })
  private modalController!: ModalComponent;
  @Output() accessCreated = new EventEmitter<SafeAccess>();

  constructor(private apiSvc: ApiService, private authzSvc: AuthzService, private translate: TranslateService, private db: db) {
  }

  async ngAfterViewInit(): Promise<void> {

    const passphrase = Utilities.generateSecurePassword();
    // const accesses = await this.apiSvc.getAccesses('key');
    // Console.log(accesses);

    const safeName = base64url.encode(this.authzSvc.appConfig.safeName);
    const access = new SafeAccess();
    const accessID = uuidv4();
    access.accessID = accessID;

    const keyName = await this.modalController.displayInput(this.translate.instant('KEY.REGISTER.NEW.TITLE'), this.translate.instant('KEY.REGISTER.NEW.MSG'), false);
    if (keyName) {
      access.name = keyName;
    } else {
      this.accessCreated.emit();
      return;
    }

    const setPin = await this.modalController.displayQuestion(this.translate.instant('KEY.REGISTER.PIN.TITLE'), this.translate.instant('KEY.REGISTER.PIN.MSG'), null, this.translate.instant('YES'), this.translate.instant('NO'));
    if (setPin == 'ONE') {
      const clicks = await this.modalController.displayPad(this.translate.instant('KEY.REGISTER.PIN.SET'));
      if (clicks) {
        access.clicks = clicks;
      }
    }
    access.enabled = true;
    access.type = 'd';

    const tier = this.authzSvc.appConfig.tier;
    if (tier == 1 || tier == 4) {
      access.mode = 'admin';
    } else {
      const mode = await this.modalController.displayQuestion(this.translate.instant('KEY.REGISTER.MODE.TITLE'), this.translate.instant('KEY.REGISTER.MODE.MSG'), this.translate.instant('KEY.REGISTER.MODE.ADMIN_BTN'), this.translate.instant('KEY.REGISTER.MODE.READWRITE_BTN'), this.translate.instant('KEY.REGISTER.MODE.READONLY_BTN'));
      switch (mode) {
        case 'ZERO':
          access.mode = 'admin';
          break;
        case 'ONE':
          access.mode = 'rw';
          break;
        case 'TWO':
          access.mode = 'ro';
          break;
      }
    }
    if (access.mode != 'admin') {
      const title = this.translate.instant('MODALS.SPACES.TITLE');

      const selectedspaces = await this.modalController.displaySpaceSelector(undefined, { title, message: '' });
      if (selectedspaces) {
        access.spaceIds = selectedspaces;
      } else {
        await this.apiSvc.deleteAccess(access.accessID);
        this.accessCreated.emit();
        return;
      }
    }
    await this.apiSvc.createAccess(access);
    delete access.clicks;
    await this.modalController.displayMessage(this.translate.instant('KEY.REGISTER.QR.TITLE'), this.translate.instant('KEY.REGISTER.QR.MSG'));
    //AccessID Modal Component does the work of calculating the keys and saving the access
    const success = await this.modalController.displayAccessID(this.translate.instant('KEY.REGISTER.QR.SCAN'), 'register', passphrase, access, safeName);
    if (!success) {
      await this.apiSvc.deleteAccess(access.accessID);
      this.accessCreated.emit();
      return;
    }
    this.accessCreated.emit(access);
  }
}

