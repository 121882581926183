import { NgClass, NgFor, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { db } from 'src/app/db/json';
import { Console } from 'src/app/lib/console';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-spacetabs',
  standalone: true,
  imports: [NgFor, NgIf, NgClass],
  templateUrl: './spacetabs.component.html',
  styleUrl: './spacetabs.component.scss'
})
export class SpacetabsComponent {

  isAdmin = true;
  constructor(public db: db, public route: Router, public Shared: SharedService) {
  }

  async change(index: number) {
    const newPage = index+1;
 Console.log('onSpaceChange', newPage);

      const url = this.route.url;
      await this.db.setSpace(newPage);
      //get current label from route. if current label exists in new space, navigate to it else navigate to root.
      if (url && url.startsWith('/label')) {
        const ename = url.split('/')[2];
        const name = decodeURIComponent(ename);
        const labels = this.Shared.label.list.map((label) => { return label.name });
        if (labels.includes(name)) {
          this.route.navigate(['label', name]);
        } else {
          this.route.navigate(['/']);
        }
      }
   
  }

  new() {
    this.db.addSpace();
  }

}