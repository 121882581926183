import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  private onlineStatus = new BehaviorSubject<boolean>(navigator.onLine);

  constructor(private ngZone: NgZone) {
    window.addEventListener('online', () => this.updateOnlineStatus(true));
    window.addEventListener('offline', () => this.updateOnlineStatus(false));
  }

  private updateOnlineStatus(status: boolean) {
    this.ngZone.run(() => this.onlineStatus.next(status));
  }

  get networkStatus$(): Observable<boolean> {
    return this.onlineStatus.asObservable();
  }

  get isOnline(): boolean {
    return this.onlineStatus.value;
  }
}
