import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuillModule } from 'ngx-quill';
import { ApiService } from 'src/app/services/api.service';
import { EyesEncryptionService } from 'src/app/services/eyesEncryption.service';
import base64url from 'base64url';
import { Console } from 'src/app/lib/console';
import { NgFor, NgIf } from '@angular/common';
import { EyesHeader, Message } from 'src/app/interfaces/eyesHeader';
import { EyesAttachment } from 'src/app/interfaces/eyesAttachment';
import { FileSizePipe } from 'src/app/pipes/fileSize.pipe';
import { ModalComponent } from '../../modal/modal.component';
import { db } from 'src/app/db/json';
import { RecordI } from 'src/app/interfaces/records';
import { EyesSenderService } from 'src/app/services/eyesSend.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorService } from 'src/app/services/error.service';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { Archive, RecordArchive } from 'src/app/interfaces/archive';
import { EyesAddressService } from 'src/app/services/eyesAddress.service';
import { C2Service } from 'src/app/services/c2.service';


@Component({
  selector: 'app-eyesonly-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  standalone: true,
  imports: [QuillModule, NgIf, NgFor, FileSizePipe, ModalComponent, TranslateModule, NgxExtendedPdfViewerModule],
})
export class EyesonlyViewerComponent implements OnInit {
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  header!: EyesHeader;  // Holds the header data
  message: Message | null = null;    // Holds the message data, eye to eye
  loading = true;       // Show loading indicator
  error: string | null = null; // Handle API errors
  encryptedFiles: Uint8Array | null = null;
  aesKey: CryptoKey | null = null;
  type: string | null = null;
  url: string | null = null;
  textData: string | null = null;
  zoom = 'auto';
  language = 'en';

  constructor(private router: Router, private addressSvc: EyesAddressService, private translate: TranslateService, private errorSvc: ErrorService, private route: ActivatedRoute, private apiService: ApiService, private eyesEncryptionService: EyesEncryptionService, private eyesSenderSvc: EyesSenderService, private db: db, private C2Svc: C2Service) { }

  async ngOnInit(): Promise<void> {
    this.language = this.translate.currentLang;
    // Get the "id" from the route and fetch the message
    this.route.paramMap.subscribe(async params => {
      const id = params.get('id');
      if (id && id != '0') {
        await this.fetchMessage(id);
      } else {
        this.loading = false;
      }
    });
    const file = this.eyesSenderSvc.getFile();
    console.log('file: ', file);
    if (file) {
      this.processFile(file);
    }
  }

  private async processFile(file: File) {
    try {
      const keys = await this.apiService.getEyesPrivateKeys();
      if (!keys) {
        this.error = 'Private keys not found.';
        this.loading = false;
        return;
      }
      const privateKey = new Uint8Array(base64url.toBuffer(keys.privateKeys[0].key));

      const fileBuffer: ArrayBuffer = await file.arrayBuffer();

      const { header, encryptedFiles, aesKey } = await this.eyesEncryptionService.unsealHeader(new Uint8Array(fileBuffer), privateKey);
      this.encryptedFiles = encryptedFiles;
      this.aesKey = aesKey;
      Console.log('header: ', header);
      if (header.signature) {
        const sender = header.sender!;
        const kid = header.kid!;
        const keys = await this.apiService.getEyesValidationKeys(sender, kid);
        if (!keys) {
          this.error = 'Validation keys not found.';
          this.loading = false;
          return;
        }

        const vkey = keys.find(k => k.id === kid);
        if (!vkey) {
          this.error = 'Validation key not found.';
          this.loading = false;
          return;
        }
        const validationKey = new Uint8Array(base64url.toBuffer(vkey.validationKey));
        let verified = false;
        try {
          verified = await this.eyesEncryptionService.verifyHeaderSignature(header, validationKey);
        } catch (e) {
          Console.log('error: ', e);
        }
        if (!verified) {
          this.error = 'Signature verification failed.';
          this.loading = false;
          return;
        } else {
          Console.log('Signature verified');
        }
      }

      header.sender = await this.updateName(header.sender);

      this.header = header;
      this.loading = false;
    } catch (error) {
      Console.log('error: ', error);
      this.error = 'Error processing file.';
      this.loading = false;
    }
  }
  private async fetchMessage(id: string): Promise<void> {
    this.message = this.eyesSenderSvc.retrievDroppedOffMessage();
    if (this.message && this.message.header) {
      this.message.header.sender = await this.updateName(this.message.header.sender);
      this.header = this.message.header;
    } else {
      this.error = 'header not found.';
    }
    this.loading = false;
  }

  private async updateName(address: string | undefined): Promise<string> {
    if (address) {
      const name = await this.addressSvc.getName(address);
      if (name) {
        return name;
      } else {
        return address;
      }
    }
    return 'Unknown';
  }

  /**
 * Allows editing the recipient's name.
 */
  async editRecipientName() {
    const recipient = this.header!.sender;
    if (!recipient || recipient == 'Unknown') return;
    const newName = await this.modalController.displayInput('Address Name', '<p>Please enter a new name for this recipient:</p>', false);
    if (newName) {
      this.header!.sender = newName;
      await this.addressSvc.setName(recipient, newName);
    }
  }

  async openAttachment(attachment: EyesAttachment) {
    Console.log('Opening attachment:', attachment);
    if (this.message) {
      return this.openArchiveAttachment(attachment);
    } else {
      this.openFileAttachment(attachment);
    }
  }

  private async openFileAttachment(attachment: EyesAttachment) {
    if (!this.encryptedFiles || !this.aesKey) {
      this.error = 'Can not open attachemnt.';
      return;
    }
    const attachmentIndex = this.header?.attachments.findIndex(a => a.name === attachment.name);
    if (attachmentIndex === undefined || attachmentIndex < 0) {
      this.error = 'Attachment not found.';
      return;
    }
    const { fileBuffer } = await this.eyesEncryptionService.unsealAttachment(this.header!, attachmentIndex, this.encryptedFiles.buffer, this.aesKey);
    const blob = new Blob([fileBuffer], { type: attachment.type });
    const url = URL.createObjectURL(blob);
    window.open(url);
  }

  private async openArchiveAttachment(attachment: EyesAttachment) {
    if (!this.message) {
      this.error = 'Message not found.';
      return;
    }
    Console.log('Opening attachment: message', this.message);
    Console.log('Opening attachment: header', this.header);
    const index = this.header!.attachments.findIndex(a => a.name === attachment.name);
    const archiveID = this.message.attachments![index].id;
    const archive = await this.apiService.getArchive(archiveID);
    if (!archive) {
      this.error = 'Archive not found.';
      return;
    }
    Console.log('Opening archive:', archive);
    this.url = '/archive/' + archive.id;
    this.setType(archive);

    setTimeout(() => {
      const element = document.getElementById('viewer');
      element!.scrollIntoView();
    }, 1000);

  }
  private async loadTextData() {
    try {
      const response = await fetch(this.url!)
      if (!response.ok) {
        this.errorSvc.process(response);
        this.textData = 'Error loading data.';
        return;
      }
      const data = await response.text();
      this.textData = data;
    } catch (error) {
      this.errorSvc.process(error);
      this.textData = 'Error loading data.';
    }
  }
  setType(archive: Archive) {
    const type: string = archive.m!.type;
    if (!type) {
      this.type = 'unknown';
      return;
    }
    if (type.includes('pdf')) {
      this.type = 'pdf';
    } else if (type.includes('image')) {
      this.type = 'image';
    } else if (type.includes('video')) {
      this.type = 'video';
      //  setTimeout(() => { this.logVideoEvents(); }, 1000);
    } else if (type.includes('audio')) {
      this.type = 'audio';
    } else if (type.includes('text') || type.includes('json') || type.includes('xml') || type.includes('html') || type.includes('yaml')) {
      this.type = 'text';
      this.loadTextData();
    } else {
      this.type = 'unknown';
    }
  }

  close() {
    this.url = null;
    this.type = null;
  }

  exit() {
    this.router.navigate(['/eyes/inbox']);
  }

  async saveAttachment(attachment: EyesAttachment): Promise<Archive> {
    if (this.encryptedFiles && this.aesKey) {
      return this.saveFileAttachment(attachment);
    } else {
      return this.saveArchiveAttachment(attachment);
    }
  }

  private async saveArchiveAttachment(attachment: EyesAttachment): Promise<Archive> {
    try {
      this.modalController.displaySpinner(true, 'Saving Attachment');
      const attiv = base64url.toBase64(attachment.iv); // iv is base64url encoded but the iv in the message.attachments is bse64 encoded for teh servicewworker
      const msgArchive = this.message?.attachments?.find(a => a.arr!.iv == attiv);
      if (!msgArchive) {
        this.error = 'Attachment Archive not found.';
        throw new Error('Attachment Archive not found.');
      }

      const origArchive = await this.apiService.getArchive(msgArchive.id);
      if (!origArchive) {
        this.error = 'Archive not found.';
        throw new Error('Attachment Archive not found.');
      }

      const { newArchive, emitter } = await this.eyesSenderSvc.convertArchive(origArchive);
      await this.waitForEmitterCompletion(emitter);
      Console.log('newArchive: ', newArchive);
      if (newArchive) {
        this.apiService.deleteArchive(origArchive.id);
        this.modalController.displayMessage('Attachment Saved', '<p>The attachment was saved as a new archive in the current Space.</p>');
      } else {
        this.error = 'Error saving attachment archive.';
        throw new Error('Attachment Archive not found.');
      }
      return newArchive;
    } catch (error) {
      this.error = `Error saving attachment archive. ${error}`;
      throw error;
    } finally {
      this.modalController.displaySpinner(false);
    }
  }

  private async saveFileAttachment(attachment: EyesAttachment): Promise<Archive> {
    if (!this.encryptedFiles || !this.aesKey) {
      this.error = 'Can not open attachemnt.';
      throw new Error('Can not open attachemnt.');
    }
    const attachmentIndex = this.header?.attachments.findIndex(a => a.name === attachment.name);
    if (attachmentIndex === undefined || attachmentIndex < 0) {
      this.error = 'Attachment not found.';
      throw new Error('Can not open attachemnt.');
    }
    const { fileBuffer } = await this.eyesEncryptionService.unsealAttachment(this.header!, attachmentIndex, this.encryptedFiles.buffer, this.aesKey);
    const blob = new Blob([fileBuffer], { type: attachment.type });
    const file = new File([blob], attachment.name, { type: attachment.type });
    const newArchive = await this.modalController.displayFileUploader(file);
    return newArchive;
  }

  async saveAsNote() {

    //safe the archives
    const archives: RecordArchive[] = [];
    if (this.header.attachments) {
      this.modalController.displaySpinner(true, 'Saving Note archives');
      Console.log('this.message.attachments: ', this.header.attachments);
      for (const att of this.header.attachments) {
        Console.log('att: ', att);
        const newArchive = await this.saveAttachment(att);
        if (newArchive) {
          archives.push({ archiveID: newArchive.id, name: newArchive.m.name });
        }
      }
      this.modalController.displaySpinner(false);
    }
    const title = (this.header?.sender ? this.header.sender + " - " : '') + this.header?.subject || '';
    const record: RecordI = {
      recordTitle: title,
      recordBody: this.header?.message || '',
      pinned: true,
      bgColor: '',
      bgImage: '',
      color: '',
      isCbox: false,
      labels: [],
      archives,
      wallet: undefined,
      ro: false,
    }
    const id = await this.db.records.addRecord(record);
    if (this.message) {
      this.C2Svc.deleteEyesMessage(this.message.id);
    }
    await this.modalController.displayMessage('Saved as a Note', '<p>The message body was saved and pinned as a new note in the current Space,</p>');
    this.router.navigate(['/records/' + id]);
  }

  private async waitForEmitterCompletion(
    emitter: EventEmitter<{
      size?: number;
      transferred: number;
      attachmentNumber?: number;
      stage: "READING" | "WRITING" | "ENCRYPTING" | "HEADER" | "ATTACHMENT" | "COMPLETED" | "ERROR";
      error?: any;
    }>): Promise<void> {
    await this.modalController.displaytransferProgress(emitter);
  }
}


