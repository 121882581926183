<div *ngIf="showLableName" class="pagename">{{currentPageName}}</div>
<section>
  <section recordsContainer #mainContainer class="main-container">
    <div class="pb-60" [hidden]="!(Shared.record.pinned | recordsTools:currentPageName).length">
      <p #title _title>{{ 'RECORDS.PINNED' | translate }}</p>
      <ng-container [ngTemplateOutlet]="recordTemplate" [ngTemplateOutletContext]="{records:Shared.record.pinned}">
      </ng-container>
    </div>
    <div [hidden]="!(Shared.record.unpinned | recordsTools:currentPageName).length">
      <ng-container [ngTemplateOutlet]="recordTemplate" [ngTemplateOutletContext]="{records:Shared.record.unpinned}">
      </ng-container>
    </div>
  </section>
</section>

<section *ngIf="!(this.Shared.record.all | recordsTools:currentPageName).length">
  <div class="no-records">
    <div class="image"></div>
    <div>
      <span class="text">{{ 'RECORDS.APPEAR_HERE' | translate }}</span>
    </div>
  </div>
</section>

<!--? create new note -->
<div #modalContainer class="modal-container">
  <div #modal class="modal">
    <app-input [modalController]="modalController" [recordToEdit]="clickedRecordData" [isEditing]="true"
      *ngIf="modalContainer.style.display == 'block'">
    </app-input>
  </div>
</div>

<!--*  record template -->
<ng-template #recordTemplate let-records="records">
  <div #recordEl [class.mx-auto]="Shared.recordViewType.value === 'list' ">
    <ng-container *ngFor="let record of records | recordsTools:currentPageName ;trackBy: trackBy">
      <div #recordEL [id]="'record'+record.id" class="record-container">
        <div class="record-main"
          [ngStyle]="{'color':record.color,'background-color':record.bgColor,'border-color':record.bgColor,'background-image' : record.bgImage}">
          <div (mouseup)="openRecord(recordEL, record)">
            <div *ngIf="record.recordTitle.length" class="title">
              <span #recordBody [innerHTML]="record.recordTitle"></span>
            </div>
            <!-- Don't show body if hide is true and there is a title -->
            <div *ngIf="!authSvc.appConfig.hide || !record.recordTitle  ">
              <section *ngIf="!record.isCbox;else checkboxes">
                <div class="body" [class.min]="record.recordBody!.length > 68">
                  <quill-view [content]="record.recordBody" format="json" theme="snow"></quill-view>
                </div>
              </section>
            </div>
            <ng-template #checkboxes>
              <div class="cboxes min">
                <ng-container
                  *ngFor="let cb of record.checkBoxes! | cboxSort | slice:0:7;trackBy: trackBy;let i = index">
                  <div class="cbox-container">
                    <div *ngIf="!authSvc.appConfig.ro && !record.ro" (mouseup)="checkBoxTools(record,$event).check(cb)"
                      [class.done]="cb.done" class="cbox-icon">
                    </div>
                    <div class="cbox-record-container min">
                      <div [attr.data-id]="cb.id" [class.cbox-done]="cb.done" [class.min]="cb.data.length > 68"
                        class="cbox-record" contenteditable="true" spellcheck="false" [innerHTML]="cb.data">
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="i === 6">
                    <div class="cbox-container">
                      <div class="cbox-icon"></div>
                      <div class="cbox-record-container min">
                        <div class="cbox-record">...</div>
                      </div>
                    </div>
                  </ng-container>

                </ng-container>
              </div>
            </ng-template>
          </div>
          <div class="labels-container min" (click)="openRecord(recordEL, record)" >
            <ng-container *ngFor="let label of record.labels">
              <div class="label-container" *ngIf="label.added">
                <div (click)="labelClicked(record,label,$event)" class="label">
                  <span>{{ label.name === 'Wallet' ? ('VIEW' | translate) : label.name }}</span>
                  <img *ngIf="label.name === 'Wallet'" [src]="getCoinIcon(record.wallet)" />
                </div>
              </div>
            </ng-container>

            <!--? attachments -->

            <div class="label-container">
              <div *ngIf="record.archive" class="label">
                <img (click)="openFile(record.archive)" src="./assets/images/svg/file.svg" alt="file" class="pic">
              </div>
            </div>

          </div>
          <div *ngIf="!authSvc.appConfig.ro && !touchEnabled && !record.ro" class="icons-container min">
            <div class="icons">
              <div #colorMenuTtBtn data-toolTipType="colorMenu" data-pop="Background Options"
                (click)="openTooltip(colorMenuTtBtn, colorMenuTt ,record)" class="color H pop">
              </div>
              <div data-toolTipType="moreMenu" #moreMenuTtBtn (click)="openTooltip(moreMenuTtBtn,moreMenuTt,record)"
                class="more H pop" data-pop="More">
              </div>
            </div>
          </div>
          <div *ngIf="record.ro || authSvc.appConfig.ro" class="locked"><img src="./assets/images/svg/lock-locked.svg"></div>
        </div>
        <div *ngIf="!authSvc.appConfig.ro">
          <div (click)="togglePin(record.id!,record.pinned)" [class.pinned]="record.pinned" class="pin-icon H pop"
            [attr.data-pop]="getPinTranslation(record.pinned)"></div>
        </div>
      </div>
    </ng-container>
  </div>
</ng-template>

<!--? --------tooltips-------- -->
<!--? more MENU -->

<div #moreMenuTt data-tooltip="true" data-is-tooltip-open="false" class="moreMenu">
  <div #labelMenuBtn (click)="moreMenu(moreMenuTt).openLabelMenu(labelMenuTt)">{{ 'RECORDS.LABEL' | translate }} </div>
  <div *ngIf="clickedRecordData && !clickedRecordData.archive" (click)="moreMenu(moreMenuTt).attach()">{{
    'RECORDS.ATTACH_FILE' | translate }}</div>
  <div *ngIf="walletSupport" (click)="moreMenu(moreMenuTt).wallet()">Wallet</div>
  <div (click)="moreMenu(moreMenuTt).toggleReadOnly()">{{ clickedRecordData.ro ? ('RECORDS.UNLOCK' | translate) :
    ('RECORDS.LOCK' | translate) }}</div>
  <div *ngIf="!clickedRecordData.wallet && !clickedRecordData.archive" (click)="moreMenu(moreMenuTt).trash()">{{ 'RECORDS.DELETE' | translate }}</div>

</div>
<!--? color MENU -->
<div #colorMenuTt data-tooltip="true" data-is-tooltip-open="false" class="colorMenu">
  <div class="first-row">
    <ng-container *ngFor="let color of bgColorsConst | keyvalue">
      <div [attr.data-bg-color]="color.key" [style.background-color]="color.value.bg"
        (click)="colorMenu.bgColor(color.value)" [class.nocolor]="color.value.bg === ''">
      </div>
    </ng-container>
  </div>
  <div class="second-row">
    <ng-container *ngFor="let image of bgImagesConst | keyvalue">
      <div [attr.data-bg-image]="image.key" [style.background-image]="image.value.img | ph"
        (click)="colorMenu.bgImage(image.value)" [class.noimage]="image.value.img === ''">
      </div>
    </ng-container>
  </div>
</div>

<!--? label MENU -->
<div #labelMenuTt data-tooltip="true" data-is-tooltip-open="false" class="labelMenu">
  <div class="title">{{'RECORDS.INPUT.LABEL_ARCHIVE' | translate}}</div>

  <div class="header">
    <div class="input-container">
      <input autofocus #labelInput class="notranslate" (keydown.enter)="addLabel(labelInput)" type="text"
        placeholder="Create new label" maxlength="50" (input)="''" autocorrect="off" autocapitalize="off"
        spellcheck="false" autocomplete="off">
      <div [style.display]="labelInput.value.length ? 'block' : 'none'" class="check-icon H"
        (click)="addLabel(labelInput)"></div>
    </div>
    <div #labelError class="error" hidden>{{''}}</div>
  </div>

  <div class="label-list-container">
    <div class="label-list" [class.disabled]="label.name === 'Wallet'" *ngFor="let label of labels"
      (click)="label.name !== 'Wallet' && labelMenuClick(label)">
      <div [class.done]='label.added' class="check-icon"></div>
      <div class="label-name">{{label.name}}</div>
    </div>
  </div>
</div>
