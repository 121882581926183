import { Console } from "./console";
import { Tiers } from "./Tiers";

const GIGIBYTE = 1024 * 1024 * 1024; // 1 GB in bytes
const SECONDS_IN_A_MONTH = 2630233; //~seconds/month
export class Utilities {
  static SECONDS_IN_A_MONTH = 2630233; //~seconds/month


  static setTiers(tierData: Tiers) {
    Console.log('Setting tiers', tierData);
    Utilities.TIER_1_FEE = tierData.TIER_1_FEE; //credits per month
    Utilities.TIER_2_FEE = tierData.TIER_2_FEE; //credits per month
    Utilities.TIER_3_FEE = tierData.TIER_3_FEE; //credits per month
    Utilities.TIER_4_FEE = tierData.TIER_4_FEE;
    Utilities.TIER_1_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_1_TTL_MONTHS;
    Utilities.TIER_2_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_2_TTL_MONTHS;
    Utilities.TIER_3_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_3_TTL_MONTHS;
    Utilities.TIER_4_TTL_SECONDS = SECONDS_IN_A_MONTH * tierData.TIER_4_TTL_MONTHS;
    Utilities.CREDIT_TO_STORAGE_GB = tierData.CREDIT_TO_STORAGE_GB; // credit per GB per month
    Utilities.BASE_CREDIT_VALUE = tierData.BASE_CREDIT_VALUE; //  USD per credit
    Utilities.TIER_1_BASE_STORAGE_GB = tierData.TIER_1_BASE_STORAGE_GB;
    Utilities.TIER_2_BASE_STORAGE_GB = tierData.TIER_2_BASE_STORAGE_GB;
    Utilities.TIER_3_BASE_STORAGE_GB = tierData.TIER_3_BASE_STORAGE_GB;
    Utilities.TIER_4_BASE_STORAGE_GB = tierData.TIER_4_BASE_STORAGE_GB;

    Utilities.TIER_0_KEYS = tierData.TIER_0_KEYS;
    Utilities.TIER_1_KEYS = tierData.TIER_1_KEYS;
    Utilities.TIER_2_KEYS = tierData.TIER_2_KEYS;
    Utilities.TIER_3_KEYS = tierData.TIER_3_KEYS;
    Utilities.TIER_4_KEYS = tierData.TIER_4_KEYS;
    Utilities.CREDIT_PER_KEY = tierData.CREDIT_PER_KEY;
  }

  static TIER_1_FEE: number;//credits per month
  static TIER_2_FEE: number; //credits per month
  static TIER_3_FEE: number; //credits per month
  static TIER_4_FEE: number; //credits per month
  static TIER_1_TTL_SECONDS: number;
  static TIER_2_TTL_SECONDS: number;
  static TIER_3_TTL_SECONDS: number;
  static TIER_4_TTL_SECONDS: number;
  static CREDIT_TO_STORAGE_GB: number; // credit per GB per month
  static BASE_CREDIT_VALUE: number; //  USD per credit

  static TIER_1_BASE_STORAGE_GB: any;
  static TIER_2_BASE_STORAGE_GB: any;
  static TIER_3_BASE_STORAGE_GB: any;
  static TIER_4_BASE_STORAGE_GB: any;

  static TIER_0_KEYS: number;
  static TIER_1_KEYS: number;
  static TIER_2_KEYS: number;
  static TIER_3_KEYS: number;
  static TIER_4_KEYS: number;
  static CREDIT_PER_KEY: number;


  static getKeyOverage(tier: number, numberOfKeys: number): number {
    let overage = 0;
    switch (tier) {
      case 0:
        overage = numberOfKeys - Utilities.TIER_0_KEYS;
        break;
      case 1:
        overage = numberOfKeys - Utilities.TIER_1_KEYS;
        break;
      case 2:
        overage = numberOfKeys - Utilities.TIER_2_KEYS;
        break;
      case 3:
        overage = numberOfKeys - Utilities.TIER_3_KEYS;
        break;
      case 4:
        overage = numberOfKeys - Utilities.TIER_4_KEYS;
        break;
      default:
        throw new Error('Invalid tier');
    }
    return Math.max(0, overage);
  }

  static getCreditsFromAmountPaid(amountPaid: number): number {
    const credits = amountPaid / Utilities.BASE_CREDIT_VALUE; // Calculate initial credits from the amount paid
    return Math.round(credits * 100) / 100;
  }

  /*
   * Calculates the late fee for a given epoc date in seconds that the safe expired
   * @param expired - the epoc date in seconds that the safe expired
   * @returns the late months
   */
  static calculateLateFeeCredits(keys: number, expiryDateInSeconds: number, tier: number, bytesUsed: number): number {
    const now = new Date().getTime() / 1000;
    const lateSeconds = Math.ceil((now - expiryDateInSeconds));
    const lateFeeIncredits = Utilities.getCreditsFromExpiry(keys, lateSeconds, tier, bytesUsed)
    return lateFeeIncredits;
  }

  /*
   * Calculate the price of the credits

   */
  static calculatePrice(credits: number): number {
    if (credits < 0) {
      // negative credits are not allowed
      throw new Error('Credits cannot be negative');
    }

    const price = credits * Utilities.BASE_CREDIT_VALUE;
    Console.log(`Calculated final price ${price}`);
    return Math.round(price * 100) / 100;
  }

  public static calculateNewSafeExpiry(
    keys: number,
    currentUsage: number,
    currentExpiryEpochSeconds: number,
    tier: number,
    addedUsage: number,
  ): number {
    if (tier == 0) {
      // do not recalculate expiry for trial safe
      return currentExpiryEpochSeconds;
    }
    const currentTimeEpochSeconds = Math.floor(Date.now() / 1000);
    // Calculate the total credits based on remaining time and current usage
    const currentCredits = Utilities.getCreditsFromExpiry(keys, currentExpiryEpochSeconds, tier, currentUsage);
    Console.log(
      `totalCredits: ${currentCredits}, currentUsage: ${currentUsage}, currentExpiryEpoch: ${currentExpiryEpochSeconds}, currentTimeEpoch: ${currentTimeEpochSeconds}`,
    );
    // Calculate the new  total usage
    const totalUsage = currentUsage + addedUsage;
    // Calculate the new expiry date
    const newExpiryEpochSeconds = Utilities.getExpiryInSeconds(keys, totalUsage, tier, currentCredits);
    Console.log(`newExpiryEpoch: ${newExpiryEpochSeconds}, totalUsage:${totalUsage}, addedUsage:${addedUsage}`);
    return newExpiryEpochSeconds;
  }

  static getBaseFeeForTier(tier: number): number {
    switch (tier) {
      case 0:
        return 0;
      case 1:
        return this.TIER_1_FEE;
      case 2:
        return this.TIER_2_FEE;
      case 3:
        return this.TIER_3_FEE;
      case 4:
        return this.TIER_4_FEE;
      default:
        throw new Error('Invalid tier');
    }
  }

  static getExpiryInSeconds(keys: number, storageUsed: number, tier: number, credits: number): number {
    if (tier == 0) {
        return Utilities.SECONDS_IN_A_MONTH * 24;
    }
    const storageUsedGB = Utilities.adjustUsedStorage(storageUsed, tier);
    const keyOveragekeys = Utilities.getKeyOverage(tier, keys);
    const keyOverageCredits = keyOveragekeys * this.CREDIT_PER_KEY;
    const totalCreditsNeeded = this.getBaseFeeForTier(tier) + (storageUsedGB / this.CREDIT_TO_STORAGE_GB) + keyOverageCredits;
    return Math.round((credits / totalCreditsNeeded) * Utilities.SECONDS_IN_A_MONTH);
}
  /**
   *
   * @param currentExpiryInSeconds , NotEpoch
   * @param tier
   * @param storageUsed
   * @returns
   */
  static getCreditsFromExpiry(keys: number, currentExpiryInSeconds: number, tier: number, storageUsed: number): number {
    if (tier == 0) {
      return 0;
    }
    const storageUsedGB = Utilities.adjustUsedStorage(storageUsed, tier);
    Console.log(`storageUsedGB: ${storageUsedGB}`);

   const keyOveragekeys = Utilities.getKeyOverage(tier, keys);
    const keyOverageCredits = keyOveragekeys * this.CREDIT_PER_KEY;
    Console.log(`keyOverageCredits: ${keyOverageCredits}`);

    const monthlyCreditsNeeded = this.getBaseFeeForTier(tier) + (storageUsedGB / this.CREDIT_TO_STORAGE_GB) + keyOverageCredits;
    Console.log(`monthlyCreditsNeeded: ${monthlyCreditsNeeded}`);
    const factor = currentExpiryInSeconds / Utilities.SECONDS_IN_A_MONTH;
    Console.log(`factor: ${factor}`);
    const val = monthlyCreditsNeeded * factor;
    // round val to 2 decimal places close enough
    return Math.round(val * 100) / 100;
  }

  static generateSecurePassword(length = 12) {
    const charset = "ABCDEFGHJKMNPQRSTUVWXYZabcdefghjkmnpqrstuvwxyz23456789!@#$%^&*()_+~`|}{[]:;?><,./-=";
    let password = "";
    const values = new Uint8Array(length);
    window.crypto.getRandomValues(values);

    for (let i = 0; i < length; i++) {
      password += charset[values[i] % charset.length];
    }

    return password;
  }

  private static adjustUsedStorage(storageUsed: number, tier: number): number {
    let storageUsedGB = storageUsed / GIGIBYTE;
    // adjust for included storage
    if (tier == 1) {
      storageUsedGB -= this.TIER_1_BASE_STORAGE_GB;
    } else if (tier == 2) {
      storageUsedGB -= this.TIER_2_BASE_STORAGE_GB;
    } else if (tier == 3) {
      storageUsedGB -= this.TIER_3_BASE_STORAGE_GB;
    } else if (tier == 4) {
      storageUsedGB -= this.TIER_4_BASE_STORAGE_GB;
    }
    //can not be negative
    return Math.max(0, storageUsedGB);
  }

  static getBaseStorageByTier(tier: number) {
    switch (tier) {
      case 0:
        return 0;
      case 1:
        return this.TIER_1_BASE_STORAGE_GB * GIGIBYTE;
      case 2:
        return this.TIER_2_BASE_STORAGE_GB * GIGIBYTE;
      case 3:
        return this.TIER_3_BASE_STORAGE_GB * GIGIBYTE;
      case 4:
        return this.TIER_4_BASE_STORAGE_GB * GIGIBYTE;
      default:
        throw new Error('Invalid tier');
    }
  }
}
