import { Injectable } from '@angular/core';
import { EyesHeader } from '../interfaces/eyesHeader';
import { EyesAttachment } from '../interfaces/eyesAttachment';
import { ml_kem1024 } from '@noble/post-quantum/ml-kem';
import { ml_dsa65 } from '@noble/post-quantum/ml-dsa';
import { randomBytes } from '@noble/post-quantum/utils';
import base64url from 'base64url';
import { EyesFile } from '../lib/eyesFile';

@Injectable({
  providedIn: 'root'
})
export class EyesEncryptionService {

  /**
   * Public method: Seals the header and attachments and returns a single combined ArrayBuffer.
   *
   * @param header The header to be encrypted.
   * @param attachmentFiles Files to attach.
   * @param recipientPublicKey The recipient’s ml_kem1024 public key.
   * @param signingSecretKey Optional ML‑DSA signing secret key.
   * @param signingKeyId Optional identifier for the signing key.
   * @returns The sealed message as a single ArrayBuffer.
   */
  public async seal(
    header: EyesHeader,
    attachmentFiles: EyesFile[],
    recipientPublicKey: Uint8Array,
    signingSecretKey?: Uint8Array,
    signingKeyId?: number
  ): Promise<ArrayBuffer> {
    // Call the internal sealing function with separateOutput = false.
    const result = await this.sealInternal(header, attachmentFiles, recipientPublicKey, signingSecretKey, signingKeyId, false);
    return result as ArrayBuffer;
  }

  /**
   * Public method: Seals the header and attachments and returns separate outputs.
   *
   * @param header The header to be encrypted.
   * @param attachmentFiles Files to attach.
   * @param recipientPublicKey The recipient’s ml_kem1024 public key.
   * @param signingSecretKey Optional ML‑DSA signing secret key.
   * @param signingKeyId Optional identifier for the signing key.
   * @returns An object with a separate header output and keying material to encrypt attachments.
   */
  public async sealSeparate(
    header: EyesHeader,
    attachmentFiles: EyesFile[],
    recipientPublicKey: Uint8Array,
    signingSecretKey?: Uint8Array,
    signingKeyId?: number
  ): Promise<{ header: ArrayBuffer, keyData: { aesKey: CryptoKey, b64CompositeCipherText: string } }> {
    // Call the internal sealing function with separateOutput = true.
    const result = await this.sealInternal(header, attachmentFiles, recipientPublicKey, signingSecretKey, signingKeyId, true);
    return result as { header: ArrayBuffer, keyData: { aesKey: CryptoKey, b64CompositeCipherText: string } };
  }

  /**
   * Private method that implements the common sealing logic.
   *
   * When separateOutput is true, returns an object with separate header and attachments outputs.
   * Otherwise, returns a single combined ArrayBuffer.
   *
   * @param header The header to be encrypted.
   * @param attachmentFiles Files to attach.
   * @param recipientPublicKey The recipient’s ml_kem1024 public key.
   * @param signingSecretKey Optional ML‑DSA signing secret key.
   * @param signingKeyId Optional signing key identifier.
   * @param separateOutput Flag determining the output format.
   * @returns Either a single ArrayBuffer or an object containing separate header and attachments.
   */
  private async sealInternal(
    header: EyesHeader,
    attachmentFiles: EyesFile[],
    recipientPublicKey: Uint8Array,
    signingSecretKey?: Uint8Array,
    signingKeyId?: number,
    separateOutput: boolean = false
  ): Promise<ArrayBuffer | { header: ArrayBuffer, keyData: { aesKey: CryptoKey, b64CompositeCipherText: string } }> {
    // Derive the AES key and obtain the composite ciphertext via ml_kem1024 encapsulation.
    const { aesKey, compositeCipherText } = await this.getEncryptionKeys(recipientPublicKey);
    const encryptedFileData: ArrayBuffer[] = [];

    // Encrypt each attachment:
    // - Read file, encrypt with AES-GCM.
    // - Store attachment metadata (including IV and encrypted length) into the header.
    for (const file of attachmentFiles) {
      if (separateOutput) {
        const attachment = this.buildSeparateHeaderAttachment(file);
        header.attachments.push(attachment);
      } else {
        const { attachment, encryptedFile } = await this.buildAttachment(file, aesKey);
        encryptedFileData.push(encryptedFile);
        header.attachments.push(attachment);
      }
    }

    // --- Optional Signing with ML‑DSA ---
    if (signingSecretKey) {
      if (!header.sender) {
        throw new Error('header.sender address is required for signing');
      }
      // Create a copy of the header excluding previous signature/kid fields.
      const headerForSigning = { ...header };
      delete headerForSigning.signature;
      delete headerForSigning.kid;

      const headerString = JSON.stringify(headerForSigning);
      const headerBuffer = new TextEncoder().encode(headerString);
      const signature = ml_dsa65.sign(signingSecretKey, headerBuffer);
      const signatureBase64 = base64url.encode(Buffer.from(signature));

      header.signature = signatureBase64;
      if (signingKeyId) {
        header.kid = signingKeyId;
      }
    }
    // --- End Optional Signing ---

    // Encrypt the header using AES-GCM.
    const headerIV = this.generateIV();
    const headerBytes = new TextEncoder().encode(JSON.stringify(header));
    const encryptedHeader = await this.encrypt(headerBytes, aesKey, headerIV);

    // Return outputs based on the separateOutput flag.
    if (separateOutput) {
      // Package the header IV, encrypted header, and composite ciphertext in one ArrayBuffer.
      const headerOutput = this.encodeHeaderOutput(encryptedHeader, headerIV, compositeCipherText);

      const b64CompositeCipherText = base64url.encode(Buffer.from(compositeCipherText));
      return { header: headerOutput, keyData: { aesKey, b64CompositeCipherText } };
    } else {
      // Combine all outputs into a single ArrayBuffer.
      const finalBuffer = this.encode(compositeCipherText, encryptedHeader, headerIV, encryptedFileData);
      return finalBuffer;
    }
  }

  // ------------------------------------------------------------------------------------
  // The remaining methods remain unchanged from before, with detailed inline comments.
  // They include unsealing, key derivation, encoding/decoding helpers, encryption, and file handling.
  // ------------------------------------------------------------------------------------

  async unseal(
    sealedData: Uint8Array,
    recipientPrivateKey: Uint8Array
  ): Promise<{
    header: EyesHeader,
    decryptedFiles: ArrayBuffer[]
  }> {
    const { kyberCiphertext, encryptedHeader, headerIV, encryptedFiles } = this.decode(sealedData);
    const aesKey = await this.getCryptoKey(kyberCiphertext, recipientPrivateKey);

    const decryptedHeader = await this.decrypt(encryptedHeader, aesKey, headerIV);
    const header: EyesHeader = JSON.parse(new TextDecoder().decode(decryptedHeader));

    const decryptedFiles: ArrayBuffer[] = [];
    let fileOffset = 0;
    for (const attachment of header.attachments) {
      if (fileOffset + attachment.encryptedLen > encryptedFiles.length) {
        throw new Error(`Insufficient encrypted data for attachment "${attachment.name}".`);
      }
      const fileData = encryptedFiles.slice(fileOffset, fileOffset + attachment.encryptedLen);
      fileOffset += attachment.encryptedLen;

      const fileIVdecoded = new Uint8Array(base64url.toBuffer(attachment.iv));
      const decryptedFile = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv: fileIVdecoded },
        aesKey,
        fileData
      );
      decryptedFiles.push(decryptedFile);
    }

    return { header: header, decryptedFiles };
  }

  async unsealHeader(
    fullBuffer: ArrayBuffer,
    recipientSecretKey: Uint8Array
  ): Promise<{ header: EyesHeader, encryptedFiles: Uint8Array, aesKey: CryptoKey }> {
    try {
      const { kyberCiphertext, encryptedHeader, headerIV, encryptedFiles } = this.decode(new Uint8Array(fullBuffer));
      const aesKey = await this.getCryptoKey(kyberCiphertext, recipientSecretKey);
      const decryptedHeader = await this.decrypt(encryptedHeader, aesKey, headerIV);
      const header = JSON.parse(new TextDecoder().decode(decryptedHeader)) as EyesHeader;
      return { header, encryptedFiles, aesKey };
    } catch (error) {
      throw new Error("Failed to unseal message.", {cause: error});
    }
  }

  async verifyHeaderSignature(
    metadata: any,
    verifyingKey: Uint8Array
  ): Promise<boolean> {
    if (!metadata.signature) {
      throw new Error("No signature present in header.");
    }
    const senderPublicSigningKey = verifyingKey;
    const { signature, kid, ...unsignedHeader } = metadata;
    const headerString = JSON.stringify(unsignedHeader);
    const headerBuffer = new TextEncoder().encode(headerString);
    const signatureBuffer = new Uint8Array(base64url.toBuffer(signature));
    const isValid = ml_dsa65.verify(senderPublicSigningKey, headerBuffer, signatureBuffer);
    if (!isValid) {
      throw new Error("Header signature verification failed.");
    }
    return true;
  }

  async unsealAttachment(
    header: EyesHeader,
    fileNumber: number,
    fileBuffer: ArrayBuffer,
    aesKey: CryptoKey
  ): Promise<{ attachment: EyesAttachment, fileBuffer: ArrayBuffer }> {
    if (fileNumber < 0 || fileNumber >= header.attachments.length) {
      throw new Error(`Invalid attachment index: ${fileNumber}. There are only ${header.attachments.length} attachments.`);
    }
    const attachment = header.attachments[fileNumber];
    const fileIV = new Uint8Array(base64url.toBuffer(attachment.iv));

    let fileOffset = 0;
    for (let i = 0; i < fileNumber; i++) {
      fileOffset += header.attachments[i].encryptedLen;
    }
    if (fileOffset + attachment.encryptedLen > fileBuffer.byteLength) {
      throw new Error(`Insufficient file data for attachment "${attachment.name}". Expected ${attachment.encryptedLen} bytes at offset ${fileOffset} but fileBuffer is only ${fileBuffer.byteLength} bytes long.`);
    }
    const fileData = fileBuffer.slice(fileOffset, fileOffset + attachment.encryptedLen);
    const decryptedFile = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: fileIV },
      aesKey,
      fileData
    );
    return { attachment, fileBuffer: decryptedFile };
  }

  async unsealSeparate(
    headerBuffer: ArrayBuffer,
    recipientPrivateKey: Uint8Array
  ): Promise<{ header: EyesHeader, keyData: { aesKey, b64CompositeCipherText: string } }> {
    const { encryptedHeader, headerIV, compositeCipherText } = this.decodeHeaderOutput(new Uint8Array(headerBuffer));
    const aesKey = await this.getCryptoKey(compositeCipherText, recipientPrivateKey);
    const decryptedHeader = await this.decrypt(encryptedHeader, aesKey, headerIV);
    const header: EyesHeader = JSON.parse(new TextDecoder().decode(decryptedHeader));

    const keyData: { aesKey, b64CompositeCipherText: string } = { aesKey, b64CompositeCipherText: base64url.encode(Buffer.from(compositeCipherText)) };
    return { header, keyData };
  }

  private encode(
    compositeCipherText: Uint8Array,
    encryptedHeader: ArrayBuffer,
    headerIV: Uint8Array,
    encryptedFileData: ArrayBuffer[]
  ): ArrayBuffer {
    const totalEncryptedFilesLength = encryptedFileData.reduce((acc, file) => acc + file.byteLength, 0);
    const encryptedFiles = new Uint8Array(totalEncryptedFilesLength);
    let fileBufferOffset = 0;
    for (const file of encryptedFileData) {
      encryptedFiles.set(new Uint8Array(file), fileBufferOffset);
      fileBufferOffset += file.byteLength;
    }

    const encryptedFilesLenBuffer = new Uint32Array([totalEncryptedFilesLength]);
    const maxHeaderSize = 10 * 1024 * 1024;
    const headerLen = encryptedHeader.byteLength;
    if (headerLen === 0 || headerLen > maxHeaderSize) {
      throw new Error(`Invalid header length: ${headerLen} bytes.`);
    }
    const headerLenBuffer = new Uint32Array([headerLen]);

    const finalSize =
      4 +
      totalEncryptedFilesLength +
      headerIV.byteLength +
      headerLenBuffer.byteLength +
      encryptedHeader.byteLength +
      compositeCipherText.byteLength;

    const finalBuffer = new Uint8Array(finalSize);
    let offset = 0;
    finalBuffer.set(new Uint8Array(encryptedFilesLenBuffer.buffer), offset);
    offset += 4;
    finalBuffer.set(encryptedFiles, offset);
    offset += totalEncryptedFilesLength;
    finalBuffer.set(headerIV, offset);
    offset += headerIV.byteLength;
    finalBuffer.set(new Uint8Array(headerLenBuffer.buffer), offset);
    offset += headerLenBuffer.byteLength;
    finalBuffer.set(new Uint8Array(encryptedHeader), offset);
    offset += encryptedHeader.byteLength;
    finalBuffer.set(compositeCipherText, offset);

    return finalBuffer.buffer;
  }

  private decode(buffer: Uint8Array): {
    encryptedFiles: Uint8Array,
    headerIV: Uint8Array,
    encryptedHeader: Uint8Array,
    kyberCiphertext: Uint8Array
  } {
    let offset = 0;
    const dataView = new DataView(buffer.buffer);
    if (buffer.byteLength < 4) {
      throw new Error("Buffer too short for encrypted files length.");
    }
    const encryptedFilesLength = dataView.getUint32(offset, true);
    offset += 4;
    if (offset + encryptedFilesLength > buffer.byteLength) {
      throw new Error("Buffer too short for encrypted files.");
    }
    const encryptedFiles = buffer.slice(offset, offset + encryptedFilesLength);
    offset += encryptedFilesLength;
    if (offset + 12 > buffer.byteLength) {
      throw new Error("Buffer too short for header IV.");
    }
    const headerIV = buffer.slice(offset, offset + 12);
    offset += 12;
    if (offset + 4 > buffer.byteLength) {
      throw new Error("Buffer too short for header length.");
    }
    const headerLen = dataView.getUint32(offset, true);
    offset += 4;
    const maxHeaderSize = 10 * 1024 * 1024;
    if (headerLen === 0 || headerLen > maxHeaderSize || offset + headerLen > buffer.byteLength) {
      throw new Error(`Invalid header length: ${headerLen} bytes.`);
    }
    const encryptedHeader = buffer.slice(offset, offset + headerLen);
    offset += headerLen;
    const expectedCompositeSize = 1568 + 16;
    if (offset + expectedCompositeSize !== buffer.byteLength) {
      throw new Error(
        `Invalid composite ciphertext length. Expected ${expectedCompositeSize} bytes, but got ${buffer.byteLength - offset} bytes.`
      );
    }
    const kyberCiphertext = buffer.slice(offset, offset + expectedCompositeSize);
    return { encryptedFiles, headerIV, encryptedHeader, kyberCiphertext };
  }

  private encodeHeaderOutput(
    encryptedHeader: ArrayBuffer,
    headerIV: Uint8Array,
    compositeCipherText: Uint8Array
  ): ArrayBuffer {
    const ivLengthBuffer = new Uint8Array([headerIV.byteLength]);
    const headerLenBuffer = new Uint32Array([encryptedHeader.byteLength]);
    const totalSize =
      ivLengthBuffer.byteLength +
      headerIV.byteLength +
      headerLenBuffer.byteLength +
      encryptedHeader.byteLength +
      compositeCipherText.byteLength;
    const headerOutput = new Uint8Array(totalSize);
    let offset = 0;
    headerOutput.set(ivLengthBuffer, offset);
    offset += ivLengthBuffer.byteLength;
    headerOutput.set(headerIV, offset);
    offset += headerIV.byteLength;
    headerOutput.set(new Uint8Array(headerLenBuffer.buffer), offset);
    offset += headerLenBuffer.byteLength;
    headerOutput.set(new Uint8Array(encryptedHeader), offset);
    offset += encryptedHeader.byteLength;
    headerOutput.set(compositeCipherText, offset);
    return headerOutput.buffer;
  }

  private decodeHeaderOutput(buffer: Uint8Array): {
    headerIV: Uint8Array,
    encryptedHeader: ArrayBuffer,
    compositeCipherText: Uint8Array
  } {
    let offset = 0;
    const ivLength = buffer[offset];
    offset += 1;
    const headerIV = buffer.slice(offset, offset + ivLength);
    offset += ivLength;
    const headerLenView = new DataView(buffer.buffer, offset, 4);
    const headerLen = headerLenView.getUint32(0, true);
    offset += 4;
    const encryptedHeader = buffer.slice(offset, offset + headerLen);
    offset += headerLen;
    const compositeCipherText = buffer.slice(offset);
    return { headerIV, encryptedHeader: encryptedHeader.buffer, compositeCipherText };
  }

  private async getEncryptionKeys(publicKey: Uint8Array): Promise<{ aesKey: CryptoKey, compositeCipherText: Uint8Array }> {
    const { sharedSecret, cipherText } = ml_kem1024.encapsulate(publicKey);
    const baseKey = await crypto.subtle.importKey(
      "raw",
      sharedSecret,
      { name: "HKDF" },
      false,
      ["deriveKey"]
    );
    const salt = crypto.getRandomValues(new Uint8Array(16));
    const info = new TextEncoder().encode("AES-GCM key derivation");
    const aesKey = await crypto.subtle.deriveKey(
      {
        name: "HKDF",
        hash: "SHA-256",
        salt: salt,
        info: info,
      },
      baseKey,
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
    );
    const compositeCipherText = new Uint8Array(salt.byteLength + cipherText.byteLength);
    compositeCipherText.set(salt, 0);
    compositeCipherText.set(cipherText, salt.byteLength);
    return { aesKey, compositeCipherText };
  }

  async getCryptoKey(compositeCipherText: Uint8Array, privateKey: Uint8Array): Promise<CryptoKey> {
    const salt = compositeCipherText.slice(0, 16);
    const cipherText = compositeCipherText.slice(16);
    const sharedSecret = ml_kem1024.decapsulate(cipherText, privateKey);
    const baseKey = await crypto.subtle.importKey(
      "raw",
      sharedSecret,
      { name: "HKDF" },
      false,
      ["deriveKey"]
    );
    const info = new TextEncoder().encode("AES-GCM key derivation");
    const aesKey = await crypto.subtle.deriveKey(
      {
        name: "HKDF",
        hash: "SHA-256",
        salt: salt,
        info: info,
      },
      baseKey,
      { name: "AES-GCM", length: 256 },
      true,
      ["encrypt", "decrypt"]
    );
    return aesKey;
  }

  private async buildAttachment(file: EyesFile, aesKey: CryptoKey): Promise<{ attachment: EyesAttachment, encryptedFile: ArrayBuffer }> {
    const iv = this.generateIV();
    const fileBuffer = await this.readFileAsArrayBuffer(file);
    const encryptedFile = await this.encrypt(fileBuffer, aesKey, iv);
    const attachment: EyesAttachment = {
      name: file.name,
      size: file.size,
      type: file.type,
      iv: base64url.encode(Buffer.from(iv)),
      encryptedLen: encryptedFile.byteLength
    };
    return { attachment, encryptedFile };
  }

  private buildSeparateHeaderAttachment(file: EyesFile): EyesAttachment {
    const iv = this.generateIV();
    const attachment: EyesAttachment = {
      name: file.name,
      size: file.size,
      type: file.type,
      iv: base64url.encode(Buffer.from(iv)),
      encryptedLen: file.size
    };
    return attachment
  }

  private readFileAsArrayBuffer(file: EyesFile): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file.getBlob());
    });
  }

  private async encrypt(data: ArrayBuffer, aesKey: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    return crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      aesKey,
      data
    );
  }

  private async decrypt(encryptedData: ArrayBuffer, aesKey: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    return crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      aesKey,
      encryptedData
    );
  }

  private generateIV(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(12));
  }

  public async generateKeys(): Promise<{ privateKey: string, publicKey: string, validationKey: string, signingKey: string }> {
    const { secretKey, publicKey } = ml_kem1024.keygen();
    const signingSeed = randomBytes(32);
    const signingKeys = ml_dsa65.keygen(signingSeed);
    const b64privateKey = base64url.encode(Buffer.from(secretKey));
    const b64publicKey = base64url.encode(Buffer.from(publicKey));
    const b64validationKey = base64url.encode(Buffer.from(signingKeys.publicKey));
    const b64signingKey = base64url.encode(Buffer.from(signingKeys.secretKey));
    return { privateKey: b64privateKey, publicKey: b64publicKey, validationKey: b64validationKey, signingKey: b64signingKey };
  }
}
