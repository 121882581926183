<div class="address-book">
  <h2>Trusted Addresses</h2>
  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>ID</th>
          <th>Name</th>
          <th>Trust Level</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of contacts">
          <td>{{ contact.Id }}</td>
          <td>
            <!-- Inline editing for name using two-way binding -->
             <input [(ngModel)]="contact.name" (change)="updateName(contact)" (blur)="updateName(contact)" />
          </td>
          <td>
            <!-- Use ngModel for select binding instead of [selected] -->
            <select [(ngModel)]="contact.trustLevel" (ngModelChange)="updateTrustLevel(contact, $event)">
              <option *ngFor="let level of trustLevels" [ngValue]="level">
                {{ level }}
              </option>
            </select>
          </td>
          <td>
            <button class="remove-btn" (click)="removeContact(contact.Id)">Remove</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

