<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title">
          <p>{{'AUTH.REGION.TITLE'| translate}}</p>
        </div>
        <div #authBody class="region-body">
          <div class="message">
            <span [innerHTML]="'AUTH.REGION.MESSAGE'|translate:{country:currentLocationName}"></span>
          </div>
          <div class="message">
            <span *ngIf="multiRegionSelected" [innerHTML]="'AUTH.REGION.MULTI_MESSAGE'|translate:{regions:currentLocationName}"></span>
          </div>
          <details class="location-dropdown">

            <summary>{{"AUTH.REGION.CHANGE_LOCATION" | translate}}</summary>
            <label class="accordion-label">{{"AUTH.REGION.SINGLE" |
              translate}}</label>
            <div class="grid-container">
              <label class="radio-option" *ngFor="let option of regions">
                <input type="radio" name="radioOption" [(ngModel)]="selectedRegion" [value]="option"
                  (change)="onRangeChange()">
                {{ option.label }}
              </label>
            </div>

            <label *ngIf="multiRegion"  class="accordion-label">{{"AUTH.REGION.MULTIPLE" |
              translate}}</label>
            <div class="accordion-content">
              <div class="grid-container">
                <label class="checkbox-option" *ngFor="let option of xRegions">
                  <input type="checkbox" [checked]="option.selected" (change)="onXRangeChange(option)">
                  {{ option.label }}
                </label>
              </div>
            </div>

          </details>
          <div class="button-container">
            <button [disabled]="!selectedLocation" class="btn-primary" (click)="send()"> {{ 'NEXT' | translate }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
