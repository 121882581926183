import { Injectable } from '@angular/core';
import { Wallet } from '../lib/wallet';
import { AddressType, CoinType, NetworkType, PaperWallet, networks } from '../lib/paperWallet';
import { AuthzService } from './authz.service';
import { CallBack, EmptyAction } from '../interfaces/callback';
import { Console } from '../lib/console';
import * as bitcoin from 'bitcoinjs-lib';
import * as ecc from '@bitcoinerlab/secp256k1';
import { ECPairFactory } from 'ecpair';
import { ErrorType, SafeError } from './error.service';
import { Buffer } from 'buffer';
import bs58 from 'bs58';
import { type TransactionRequest } from 'ethers';
import { ConfigData, TransactionDataGenerator } from '../lib/transactionDataGenerator';
import { ApiKeyPair, ApiProvider } from '../interfaces/ApiProvider';
import { ApiService } from './api.service';
interface TokenAddresses {
  mainnet: string;
  testnet: string;
}

const supportedApis: Record<CoinType, ApiProvider[]> = {
  [CoinType.BTC]: ["Blockchair", "NowNodes"],
  [CoinType.ETH]: ["Blockchair", "NowNodes"],
  [CoinType.BNB]: ["Blockchair", "NowNodes"],
  [CoinType.LINK]: ["Blockchair", "NowNodes"],
  [CoinType.SOL]: ["NowNodes"],
  [CoinType.LTC]: ["Blockchair", "NowNodes"],
  [CoinType.DOGE]: ["Blockchair", "NowNodes"],
  [CoinType.USDT]: ["Blockchair", "NowNodes"],
  [CoinType.USDC]: ["Blockchair", "NowNodes"],
  [CoinType.DAI]: ["Blockchair", "NowNodes"]
};

const TOKEN_ADDRESSES: Partial<Record<CoinType, TokenAddresses>> = {
  [CoinType.USDT]: {
    mainnet: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    testnet: '0x3b00EF435Fa4fCfF5C209A37D1F3dCfF37C705ad', // Sepolia USDT address
  },
  [CoinType.USDC]: {
    mainnet: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    testnet: '0x1c7D4B196Cb0C7B01d743Fbc6116a902379C7238', // Sepolia USDC address
  },
  [CoinType.DAI]: {
    mainnet: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    testnet: '0x68194a729C2450ad26072b3D33ADaCbceF39d574', // Sepolia DAI address
  },
  [CoinType.LINK]: {
    mainnet: '0x514910771AF9Ca656af840dff83E8264EcF986CA',
    testnet: '0x779877A7B0D9E8603169DdbD7836e478b4624789', // Sepolia LINK address
  },
};


const ECPair = ECPairFactory(ecc);
bitcoin.initEccLib(ecc);

@Injectable({
  providedIn: 'root',
})
export class WalletService {

  transactionURLProvider: TransactionDataGenerator;
  constructor(private authzSvc: AuthzService, private apiSvc: ApiService) {
    this.transactionURLProvider = new TransactionDataGenerator();
  }
  // ---------------------- API Key Management ----------------------
  // Method to set API key for a given provider
  public setApiKey(apiKeyPair: ApiKeyPair): void {
    this.authzSvc.appConfig.apiKeys[apiKeyPair.provider] = apiKeyPair.key
    this.apiSvc.setAppConfig(this.authzSvc.appConfig);
  }

  // Method to get API key for a given provider
  public getApiKey(apiProvider: ApiProvider): string | null {
    let key = this.authzSvc.appConfig.apiKeys[apiProvider] ?? null;
    if (apiProvider === 'Blockchair' && !key) {
      key = 'Free Tier';
    }
    return key;
  }

  // Method to list all stored API keys
  public listApiKeys(): Partial<Record<ApiProvider, string>> {
    return { ...this.authzSvc.appConfig.apiKeys };  // Return a shallow copy to avoid direct mutation
  }

  // Method to get a list of APIs based on a CoinType
  public getProvidersForCoin(coinType: CoinType): ApiProvider[] {
    return supportedApis[coinType] || [];
  }

  // method to get a configured API key for a given CoinType
  public getConfiguredApiKey(coinType: CoinType): ApiKeyPair | null {
    const providers = this.getProvidersForCoin(coinType);
    for (const provider of providers) {
      const key = this.getApiKey(provider);
      if (key) {
        return { provider, key };
      }
    }
    return null;
  }

  // Method to get a list of CoinTypes supported by a given API
  public getCoinsForProvider(apiProvider: ApiProvider): CoinType[] {
    return Object.keys(supportedApis)
      .filter((coin) => supportedApis[coin as CoinType].includes(apiProvider))
      .map((coin) => coin as CoinType);
  }

  getProviderRegistrationUrl(provider: string) {
    switch (provider) {
      case 'Blockchair':
        return 'https://blockchair.com/api/plans';
      case 'NowNodes':
        return 'https://nownodes.io/';
      default:
        throw new Error('Unsupported provider');
    }
  }

  //---------------------- Wallet Management ----------------------
  public async createWallet(coinType: CoinType, isTestnet: boolean = false): Promise<Wallet> {
    return PaperWallet.create(coinType, isTestnet);
  }

  // Define the cache at the service level.
  private balanceCache: Map<string, bigint> = new Map();
  private CACHE_DURATION_MS = 3 * 60 * 1000; // 3 minutes
  /**
 *
 * @param wallet
 * @param nonToken  if true, get the balance of the coin ie: Eth, otherwise get the balance of the token
 * @returns
 */
  public async getBalance(wallet: Wallet, nonToken = false): Promise<bigint> {
    if (nonToken && wallet.type !== AddressType.Ethereum) {
      throw new Error('Unsupported coin');
    }

    // Generate a unique key for caching, including wallet address and nonToken flag.
    const cacheKey = `${wallet.address}-${nonToken}`;

    // Check if the value is cached.
    if (this.balanceCache.has(cacheKey)) {
      Console.log('Returning cached balance for', cacheKey);
      return this.balanceCache.get(cacheKey) as bigint;
    }

    const type = nonToken ? CoinType.ETH : wallet.coinType;
    const callback = new CallBack('GetCryptoBalance', new EmptyAction());
    const request = { type, address: wallet.address, testnet: wallet.network === NetworkType.Testnet };
    callback.action.request = request;

    // Perform the API request if not cached.
    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }
    Console.log('getCryptoBalance', result.action.result);

    // Convert the result to bigint and cache it.
    const balance = BigInt(result.action.result);
    this.balanceCache.set(cacheKey, balance);

    // Set a timeout to clear this cache after a specific duration.
    setTimeout(() => {
      Console.log('Cache expired for', cacheKey);
      this.balanceCache.delete(cacheKey);
    }, this.CACHE_DURATION_MS);
    return balance;
  }

  private feeCache: { [key: string]: any } = {};

  private async getFee(wallet: Wallet): Promise<any> {
    const cacheKey = `${wallet.type}-${wallet.address}-${wallet.network}`;

    // Check if the fee is already cached
    if (this.feeCache[cacheKey]) {
      Console.log('Returning cached fee for', cacheKey);
      return this.feeCache[cacheKey];
    }

    // Proceed to get fee if not cached
    const callback = new CallBack('GetTransactionFee', new EmptyAction());
    const request = { type: wallet.type, address: wallet.address, testnet: wallet.network === NetworkType.Testnet };
    callback.action.request = request;

    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }

    // Cache the result
    this.feeCache[cacheKey] = result.action.result;
    Console.log('GetTransactionFee', result.action.result);

    return result.action.result;
  }

  public async generateTransaction(
    wallet: Wallet,
    to: string,
    amount: bigint,
  ): Promise<{ transaction: string; txid: string; fee: bigint; amount: bigint }> {
    const { type, coinType } = wallet;
    switch (type) {
      case AddressType.Ethereum:
        if (coinType === CoinType.ETH || coinType === CoinType.BNB) {
          return this.generateEthTransaction(wallet, to, amount);
        } else {
          const tokenAddresses = TOKEN_ADDRESSES[coinType];
          if (!tokenAddresses) {
            throw new Error('Unsupported ERC20 coin type');
          }
          const tokenAddress =
            wallet.network === NetworkType.Testnet ? tokenAddresses.testnet : tokenAddresses.mainnet;
          return this.generateERC20Transaction(wallet, tokenAddress, to, amount);
        }
      case AddressType.Bitcoin:
      case AddressType.Dogecoin:
      case AddressType.Litecoin:
        return this.generateBitcoinBasedTransaction(wallet, to, amount);
      case AddressType.Solana:
        return this.generateSolanaTransaction(wallet, to, amount);
      default:
        Console.error('Unsupported coin type', type);
        throw new Error('Unsupported coin type');
    }
  }

  private async generateBitcoinBasedTransaction(
    wallet: Wallet,
    toAddress: string,
    value: bigint, size: number = 0
  ): Promise<{ transaction: string; txid: string; fee: bigint; amount: bigint }> {
    try {
      const networkConfig = networks[wallet.coinType];
      const network = wallet.network === NetworkType.Testnet ? networkConfig.testnet : networkConfig.mainnet;

      const totalAmount = Number(value);

      const data = await this.getUTXOData(wallet, value);
      const utxos = data.utxos;
      Console.log('UTXOs:', utxos);

      const selectedFeeRate = data.feeRate;
      Console.log('Selected fee rate:', selectedFeeRate);

      const psbt = new bitcoin.Psbt({ network });
      let inputSum = 0;
      let fee = 0;
      let isSegWit = true;
      const numberOfOutputs = 2; // Recipient output and potential change output

      for (const utxo of utxos) {
        if (!utxo.isAvailable || !utxo.isConfirmed || utxo.address !== wallet.address) continue;
        Console.log('Adding UTXO:', utxo);

        const input: any = {
          hash: utxo.transactionId,
          index: utxo.index,
        };

        if (utxo.rawTransaction) {
          isSegWit = false;
          input.nonWitnessUtxo = Buffer.from(utxo.rawTransaction, 'hex');
        } else {
          input.witnessUtxo = {
            script: bitcoin.address.toOutputScript(wallet.address, network),
            value: utxo.amount,
          };
        }

        psbt.addInput(input);
        inputSum += utxo.amount;
        const {estimatedSize,transactionFee }  = this.calculateTransactionFee(wallet.coinType, psbt.inputCount, numberOfOutputs, selectedFeeRate, isSegWit, size);
        fee = transactionFee;
        size = estimatedSize;

        if (fee > totalAmount - 1) {
          throw new SafeError(ErrorType.INSUFFICIENT_FUNDS, fee);
        }
        if (inputSum >= totalAmount) break;
      }

      if (inputSum < totalAmount) {
        throw new SafeError(ErrorType.INSUFFICIENT_FUNDS, fee);
      }

      const transferAmount = totalAmount - fee;

      psbt.addOutput({
        address: toAddress,
        value: transferAmount,
      });

      const change = inputSum - totalAmount;

      const dustLimit = wallet.coinType === CoinType.DOGE ? 1000000 : 546;
      if (change > dustLimit) {
        psbt.addOutput({
          address: wallet.address,
          value: change,
        });
        Console.log('Added change output', wallet.address);
      } else if (change > 0) {
        fee += change;
        Console.log('Change below dust limit, added to fee. New fee:', fee);
      }

      const keyPair = ECPair.fromWIF(wallet.privateKey, network);
      psbt.signAllInputs(keyPair);
      psbt.validateSignaturesOfAllInputs(this.validator);
      psbt.finalizeAllInputs();

      const tx = psbt.extractTransaction(true);

      const vsize = tx.virtualSize();
      Console.log('Transaction size ', vsize, 'vbytes');
      if (vsize > size) {
        Console.log('Final transaction size exceeds estimated size. Recalculating fee');
        return this.generateBitcoinBasedTransaction(wallet, toAddress, value, vsize);
      }

        return {
          transaction: tx.toHex(),
          txid: tx.getId(),
          fee: BigInt(fee),
          amount: BigInt(transferAmount),
        };
    } catch (error) {
      Console.error('Transaction creation failed', error);
      if (error instanceof SafeError) {
        throw error;
      } else {
        throw new SafeError(ErrorType.UNKNOWN, 'Transaction creation failed');
      }
    }
  }

  /** Validate inputs */
  private validator = (pubkey: Buffer, msghash: Buffer, signature: Buffer): boolean => {
    return ECPair.fromPublicKey(pubkey).verify(msghash, signature);
  };

  private calculateTransactionFee(
    coinType: CoinType,
    numberOfInputs: number,
    numberOfOutputs: number,
    feeRate: number,
    isSegWit: boolean = true,
    size: number = 0
  ): { estimatedSize: number, transactionFee: number } {
    Console.log('Calculating transaction fee', coinType, numberOfInputs, numberOfOutputs, feeRate, isSegWit);

    let estimatedSize: number;
    if (!size) {
      const inputSize = isSegWit ? 100 : 165;// Adjust for SegWit if applicable
      const outputSize = 34;
      const baseSize = 20;
      const minFeeRate = coinType === CoinType.DOGE ? 98 : undefined; // Minimum fee rate for Dogecoin


      // Ensure fee rate meets minimum requirement for the coin type
      feeRate = Math.max(feeRate, minFeeRate || 0);

      // Calculate the estimated transaction size
      estimatedSize = baseSize + (inputSize * numberOfInputs) + (outputSize * numberOfOutputs);
    } else {
      estimatedSize = size;
    }
    // Calculate transaction fee with a buffer to ensure confirmation
    let transactionFee = Math.ceil(estimatedSize * feeRate);
    transactionFee = Math.ceil(transactionFee); // Round up to ensure fee sufficiency

    Console.log(`Transaction Estimated Size: ${estimatedSize} bytes, Calculated Fee: ${transactionFee}`);
    return { estimatedSize, transactionFee };
  }

  private async generateEthTransaction(
    wallet: Wallet,
    to: string,
    amount: bigint
  ): Promise<{ transaction: string; txid: string; fee: bigint; amount: bigint }> {

    const {
      Wallet: EthersWallet,
      keccak256,
      formatEther,
      getAddress
    } = await import('ethers');

    // Validate the 'from', 'to', and 'tokenAddress'
    let validatedFromAddress: string;
    let validatedToAddress: string;

    try {
      validatedFromAddress = getAddress(wallet.address);
      validatedToAddress = getAddress(to);
    } catch (error: any) {
      throw new SafeError(ErrorType.VALIDATION, 'Invalid address checksum: ' + error.message);
    }

    Console.log('Generating ETH transaction', wallet, to, amount);
    const gasPrices = await this.getFee(wallet);
    Console.log('Gas Prices:', gasPrices);

    const gasLimit = 21000n;
    let totalFees: bigint;

    if (wallet.coinType === CoinType.BNB) {
      totalFees = BigInt(gasPrices.gasPrice) * gasLimit;
    } else {
      totalFees = BigInt(gasPrices.maxFeePerGas) * gasLimit;
    }
    Console.log('Total Fees:', totalFees);

    amount -= totalFees;
    Console.log('Amount after fees:', amount);
    if (amount < 0) {
      throw new SafeError(ErrorType.INSUFFICIENT_FUNDS, formatEther(totalFees));
    }


    const isTestnet = wallet.network === NetworkType.Testnet;
    let chainId = isTestnet ? 11155111 : 1; // Sepolia testnet or Ethereum mainnet
    if (wallet.coinType === CoinType.BNB) {
      chainId = isTestnet ? 97 : 56; // BSC Testnet or BSC Mainnet
    }

    const nonce = await this.getEthNonce(wallet);

    const unsignedTx: TransactionRequest = {
      nonce: parseInt(nonce),
      from: validatedFromAddress,
      to: validatedToAddress,
      value: amount,
      gasLimit,
      chainId,
    };

    if (wallet.coinType === CoinType.BNB) {
      unsignedTx.gasPrice = BigInt(gasPrices.gasPrice);
    } else {
      unsignedTx.maxFeePerGas = BigInt(gasPrices.maxFeePerGas);
      unsignedTx.maxPriorityFeePerGas = BigInt(gasPrices.maxPriorityFeePerGas);
      unsignedTx.type = 2; // EIP-1559 transaction
    }

    Console.log('Unsigned Transaction:', unsignedTx);
    Console.log('Amount to be sent:', formatEther(unsignedTx.value ?? 0n), 'ETH');

    const ethWallet = new EthersWallet(wallet.privateKey);
    const signedTx = await ethWallet.signTransaction(unsignedTx);
    Console.log('Signed Raw Transaction:', signedTx);

    const txid = keccak256(signedTx);
    return { transaction: signedTx, txid, fee: totalFees, amount };
  }

  private async generateERC20Transaction(
    wallet: Wallet,
    tokenAddress: string,
    to: string,
    amount: bigint
  ): Promise<{ transaction: string; txid: string; fee: bigint; amount: bigint }> {
    Console.log('Generating ERC20 transaction', wallet, tokenAddress, to, amount);
    const {
      Wallet: EthersWallet,
      getAddress,
      keccak256,
      Interface,
    } = await import('ethers');

    let validatedFromAddress: string;
    let validatedToAddress: string;
    let validatedTokenAddress: string;

    try {
      validatedFromAddress = getAddress(wallet.address);
      validatedToAddress = getAddress(to);
      validatedTokenAddress = getAddress(tokenAddress);
    } catch (error: any) {
      throw new SafeError(ErrorType.VALIDATION, 'Invalid address checksum: ' + error.message);
    }

    const isTestnet = wallet.network === NetworkType.Testnet;

    const gasPrices = await this.getFee(wallet);
    const iface = new Interface(['function transfer(address to, uint256 value) external returns (bool)']);
    const gasLimit = 100000n; // Typical safe value for ERC20 transfers
    const chainId = isTestnet ? 11155111 : 1;
    const nonce = await this.getEthNonce(wallet);

    // Calculate gas fee estimate
    let fee = BigInt(gasPrices.maxFeePerGas) * gasLimit;

    const unsignedTx: TransactionRequest = {
      nonce: Number(nonce),
      from: validatedFromAddress,
      to: validatedTokenAddress,
      value: 0n,
      gasLimit,
      chainId,
      data: iface.encodeFunctionData('transfer', [validatedToAddress, amount]),
    };

    unsignedTx.maxFeePerGas = BigInt(gasPrices.maxFeePerGas);
    unsignedTx.maxPriorityFeePerGas = BigInt(gasPrices.maxPriorityFeePerGas);
    unsignedTx.type = 2; // EIP-1559 transaction

    Console.log('Unsigned Transaction:', unsignedTx);
    Console.log('Amount of tokens to be sent:', amount.toString());

    const ethWallet = new EthersWallet(wallet.privateKey);
    let signedTx: string;
    try {
      signedTx = await ethWallet.signTransaction(unsignedTx);
    } catch (error) {
      throw new SafeError(ErrorType.UNKNOWN, 'Failed to sign transaction: ' + error);
    }
    Console.log('Signed Raw Transaction:', signedTx);

    const txid = keccak256(signedTx);

    return { transaction: signedTx, txid, fee, amount: amount };
  }

  private async generateSolanaTransaction(
    wallet: Wallet,
    to: string,
    amount: bigint
  ): Promise<{ transaction: string; txid: string; fee: bigint; amount: bigint }> {

    //Todo https://solana.com/docs/core/fees#prioritization-fee
    const { recentBlockhash, lamportsPerSignature } = await this.getSolanaFeeData(
      wallet.network === NetworkType.Testnet
    );
    const { Transaction, SystemProgram, PublicKey, Keypair } = await import('@solana/web3.js');

    let fromKeypair: any;
    let pkey: Uint8Array;

    try {
      pkey = bs58.decode(wallet.privateKey);
    } catch (error) {
      throw new Error("Invalid private key: Base58 decoding failed.");
    }

    if (pkey.length === 32) {
      fromKeypair = Keypair.fromSeed(pkey);
    } else if (pkey.length === 64) {
      fromKeypair = Keypair.fromSecretKey(pkey);
    } else {
      throw new Error("Invalid private key length: Expected 32 or 64 bytes.");
    }

    const transaction = new Transaction();
    transaction.add(
      SystemProgram.transfer({
        fromPubkey: fromKeypair.publicKey,
        toPubkey: new PublicKey(to),
        lamports: amount,
      })
    );

    transaction.recentBlockhash = recentBlockhash;
    transaction.feePayer = fromKeypair.publicKey;

    transaction.sign(fromKeypair);

    if (!transaction.signature) {
      throw new Error('Transaction was not signed properly');
    }
    const txid = bs58.encode(transaction.signature);

    const fee = BigInt(lamportsPerSignature);

    return {
      transaction: bs58.encode(transaction.serialize()),
      txid,
      fee,
      amount,
    };
  }

  private async getSolanaFeeData(
    testnet: boolean
  ): Promise<{ recentBlockhash: any; lamportsPerSignature: any }> {
    const callback = new CallBack('GetSolanaBlockhashAndFee', new EmptyAction());
    const request = { testnet };
    callback.action.request = request;

    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }
    Console.log('GetSolanaBlockhashAndFee', result.action.result);
    return result.action.result;
  }

  private async getEthNonce(wallet: Wallet): Promise<string> {
    const callback = new CallBack('GetEthNonce', new EmptyAction());
    const request = { address: wallet.address, testnet: wallet.network === NetworkType.Testnet };
    callback.action.request = request;

    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }
    Console.log('GetEthNonce', result.action.result);
    return result.action.result;
  }

  private async getUTXOData(wallet: Wallet, amount: bigint): Promise<any> {
    const callback = new CallBack('GetBTCUTXO', new EmptyAction());
    const request = {
      address: wallet.address,
      testnet: wallet.network === NetworkType.Testnet,
      type: wallet.coinType,
      amount: Number(amount),
    };
    callback.action.request = request;

    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }
    Console.log('GetBTCUTXO', result.action.result);
    const data = result.action.result;
    data.utxos.forEach((utxo: any) => {
      utxo.amount = parseInt(utxo.amount, 10);
    });
    return data;
  }

  public async sendTransaction(signedTransaction: string, wallet: Wallet): Promise<any> {
    const callback = new CallBack('PushCryptoTransaction', new EmptyAction());
    const request = { transaction: signedTransaction, type: wallet.coinType, testnet: wallet.network === NetworkType.Testnet };
    callback.action.request = request;

    const result = await this.authzSvc.apiRequest(callback);
    if (result.action.result === 'FAILURE') {
      throw new Error(result.action.reason);
    }
    Console.log('PushCryptoTransaction', result.action.result);
    return result.action.result;
  }

  public generateTranseferData(signedTransaction: string, fee: string, amount: string, wallet: Wallet, recipient: string, hash: string, apiKeyPair: ApiKeyPair | null): ConfigData {
    return this.transactionURLProvider.generateTransferData(signedTransaction, fee, amount, wallet, recipient, hash, apiKeyPair);
  }
}
