import { Component, EventEmitter, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CoinType, CoinTypeArray } from 'src/app/lib/paperWallet';
import { SharedService } from 'src/app/services/shared.service';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'coin-selector-modal',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './coin-selector-modal.component.html',
  styleUrl: './coin-selector-modal.component.scss'
})
export class CoinSelectorModalComponent {
  title = '';
  message = '';
  availableCoins: CoinType[] = [];

  selectedCoin: CoinType = CoinType.BTC;

  @Output() response = new EventEmitter<CoinType|null>();

  constructor(private translation: TranslateService, private sharedSvc: SharedService) {
    const wallets = this.sharedSvc.getWallets();
    Console.log('CoinSelectorModalComponent wallets:', wallets);
    const usedCoins = wallets.map(wallet => wallet.coinType);
    this.availableCoins = CoinTypeArray.filter(coin => !usedCoins.includes(coin));

    Console.log('CoinSelectorModalComponent typeof:', typeof this.availableCoins);
    this.title = this.translation.instant('MODALS.WALLET.SELECT.TITLE');
    this.message = this.translation.instant('MODALS.WALLET.SELECT.MSG');
   }

  selectCoin(coin: CoinType) {
    Console.log('Selected coin:', coin);
    Console.log('Selected coin type :', typeof coin);
    this.selectedCoin = coin;
    this.response.emit(this.selectedCoin);
  }

  getCoinIconPath(coin: CoinType): string {
    return CoinType.getSvgPath(coin);
  }

  cancel() {
    this.response.emit(null);
  }
}
