import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelpService {
  public helpEmiter = new EventEmitter<string>();

  public triggerHelp(code: string) {
    this.helpEmiter.emit(code);
  }
}
