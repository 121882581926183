import { NgIf } from '@angular/common';
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SafeAccess } from 'src/app/interfaces/safeAccess';
import { ApiService } from 'src/app/services/api.service';
import { AuthzService } from 'src/app/services/authz.service';
import { ModalComponent } from "../../modal/modal.component";
import { ErrorService } from 'src/app/services/error.service';
import { SwPush } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';
import { FormsModule } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';
import { LegacyLinkEditorComponent } from './legacylink-editor/legacylink-editor.component';
import { Utilities } from 'src/app/lib/utilities';
import { Console } from 'src/app/lib/console';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

const SECONDS_IN_A_DAY = 86400;

@Component({
  selector: 'app-guard-editor',
  templateUrl: './guard-editor.component.html',
  styleUrls: ['./guard-editor.component.scss'],
  standalone: true,
  imports: [TranslateModule, NgIf, ModalComponent, FormsModule, LegacyLinkEditorComponent]
})
export class GuardEditorComponent implements OnInit {
  @Output() accessCreated = new EventEmitter<SafeAccess>();

  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  @ViewChild(LegacyLinkEditorComponent) legacyLinkComponent!: LegacyLinkEditorComponent;
  paymentURL = environment.paymentURL ? environment.paymentURL : 'https://payments.safe.' + environment.envVar.DOMAIN_NAME;
  changed = false;
  collapsed = true
  isNew = false;
  showEditor = false;
  guardAccess!: SafeAccess;
  passphrase = '';
  legacyAccess: SafeAccess | undefined;
  safeExpiresInDays: number;
  intervalMax = 365;
  failuresMax = 365;
  spareDays = 0;
  legacyDelayMax = 24;
  ExpireTooSoon = false;

  constructor(public apiSvc: ApiService, public authzSvc: AuthzService, public translate: TranslateService, private errorSvc: ErrorService, private swPush: SwPush, private platform: Platform, private router: Router,) {
    if (authzSvc.appConfig.sub) {
      this.safeExpiresInDays = 1825;  // 5 years
    } else {
      this.safeExpiresInDays = Math.floor(((authzSvc.safeExpiryEPOCms - Date.now()) / 1000) / SECONDS_IN_A_DAY) - 7; // days less

    }
    this.ExpireTooSoon = this.safeExpiresInDays < 30;
  }

  ngOnInit(): void {
    try {
      this.apiSvc.getAccesses('guard').then(async (accesses) => {
        Console.log(accesses);
        if (accesses.length > 0) {
          this.isNew = false;
          for (const access of accesses) {
            if (access.type == 'g') {
              this.initAccess(access).then(() => {
                this.showEditor = true;
                this.modalController.displaySpinner(false);
              });
            } else if (access.type == 'l') {
              this.legacyAccess = access;
            } else {
              Console.error('Unknown access type', access);
            }
          }
          if (this.legacyAccess && !this.guardAccess) {//oops, no guard access, we must have recovered with guard
            Console.log('Leugacy access found, but no guard access');
            await this.createNewAccess();
          }

          this.updateSpareDays();
          if (this.spareDays < 7) {
            this.ExpireTooSoon = true;
            this.modalController.displayMessage(this.translate.instant('GUARD.NEED_CREDITS.TITLE'), this.translate.instant('GUARD.NEED_CREDITS.MSG'));
          }

        } else {
          this.isNew = true;
          this.createNewAccess();
        }
      });
    } catch (err) {
      this.errorSvc.process(err);
    }
  }

  async createNewAccess() {
    if (this.ExpireTooSoon) {
      await this.modalController.displayMessage(this.translate.instant('GUARD.TOO_SOON.TITLE'), this.translate.instant('GUARD.TOO_SOON.MSG'));
      this.accessCreated.emit();
      return
    }
    if (!this.authzSvc.FIDO2) {
      await this.modalController.displayMessage(this.translate.instant('GUARD.FIDO2.TITLE'), this.translate.instant('GUARD.FIDO2.MSG'));
      this.accessCreated.emit();
      return;
    }

    try {
      //Set defaults
      this.guardAccess = new SafeAccess();
      this.guardAccess.type = 'g';
      this.guardAccess.interval = 28;
      this.guardAccess.failures = 1;

      this.guardAccess.accessID = uuidv4();
      if (this.legacyAccess) {
        this.guardAccess.legacyId = this.legacyAccess.accessID;
        this.guardAccess.legacyDelay = 1;
        Console.log('Legacy access found, setting legacyId to', this.legacyAccess.accessID);
      }

      let answer = await this.modalController.displayQuestion(this.translate.instant('GUARD.TITLE'), this.translate.instant('GUARD.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('NEXT'));
      if (answer == 'ZERO') {
        this.onCancel();
        return;
      }
      const setPin = await this.modalController.displayQuestion(this.translate.instant('GUARD.PIN.TITLE'), this.translate.instant('GUARD.PIN.MSG'), this.translate.instant('GUARD.PIN.SAME'), this.translate.instant('GUARD.PIN.DIFF'), this.translate.instant('GUARD.PIN.NONE'));
      if (setPin == 'ONE') {
        const clicks = await this.modalController.displayPad(this.translate.instant('GUARD.PIN.SET'));
        if (clicks) {
          this.guardAccess.clicks = clicks;
        } else {
          this.createNewAccess();
          return
        }
      } else if (setPin == 'TWO') {
        //No PIN
        this.guardAccess.clicks = [];
      }

      this.modalController.displaySpinner(true);
      try {
        await this.apiSvc.createAccess(this.guardAccess);
        delete this.guardAccess.clicks;
      } catch (err: any) {
        if (err.message && err.message == 'PIN_SAFEWORD_SAME') {
          await this.modalController.displayMessage(this.translate.instant('GUARD.PIN.TITLE'), this.translate.instant('GUARD.PIN.INUSE'));
          await this.createNewAccess();
          return
        } else {
          throw err;
        }
      }

      //Notifications
      await this.configureNotifications();

      this.updateSpareDays();

      //interval
      let value = await this.modalController.displayNumber(this.translate.instant('GUARD.HEALTH_CHECK.INTERVAL.TITLE'), this.translate.instant('GUARD.HEALTH_CHECK.INTERVAL.MSG'), this.translate.instant('MODALS.NUMBER.DAYS'), Math.min(28, this.intervalMax), 1, this.intervalMax, 1);
      if (value == null) {
        this.delete();
        return;
      }
      this.guardAccess.interval = value;

      this.updateSpareDays();
      //failures
      if (this.guardAccess.pushToken) { //no point in failure checks if no notifications
        value = await this.modalController.displayNumber(this.translate.instant('GUARD.HEALTH_CHECK.FAILURE.TITLE'), this.translate.instant('GUARD.HEALTH_CHECK.FAILURE.MSG'), this.translate.instant('MODALS.NUMBER.DAYS'), Math.min(14, this.failuresMax), 1, this.failuresMax, 1);
        if (value == null) {
          this.delete();
          return;
        }
        this.guardAccess.failures = value;

        this.updateSpareDays();
      } else {
        this.guardAccess.failures = 1;
      }

      //Create accessID and display QRCode
      this.passphrase = Utilities.generateSecurePassword();
      const success = await this.modalController.displayAccessID(this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.QRCODE'), 'access', this.passphrase, this.guardAccess);

      this.showEditor = true;

      if (success) {
        await this.success();
        if (!this.legacyAccess && this.authzSvc.appConfig.tier != 0) {

          //Offer to create a legacy link
          const result = await this.modalController.displayQuestion(this.translate.instant('GUARD.CFG_LEGACY.TITLE'), this.translate.instant('GUARD.CFG_LEGACY.MSG'), this.translate.instant('NO'), null, this.translate.instant('YES'));
          if (result == 'TWO') {
            this.configureLegacyLink();
          }

        }
      } else {
        this.delete();
      }

    } catch (err) {
      this.errorSvc.process(err);
      this.delete();
    }
  }

  async success() {
    this.authzSvc.appConfig.guardSet = true;
    let message = this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.MSG2', { interval: (this.guardAccess.interval! + this.guardAccess.failures!) });
    if (this.guardAccess.pushToken) {
      message = this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.MSG2_WITH_WARN', { interval: this.guardAccess.interval, failures: this.guardAccess.failures })
    }
    await this.modalController.displayMessage(this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.TITLE2'), message);

    if (this.guardAccess.legacyId) {
      await this.modalController.displayMessage(this.translate.instant('GUARD.LEGACY.ENABLED.TITLE'), this.translate.instant('GUARD.LEGACY.ENABLED.MSG', { months: this.guardAccess.legacyDelay }));
    }
  }

  async configureNotifications(save = false) {
    const answer = await this.modalController.displayQuestion(this.translate.instant('GUARD.NOTIFICATION.TITLE'), this.translate.instant('GUARD.NOTIFICATION.ENABLE.MSG2'), this.translate.instant('NO'), null, this.translate.instant('YES'));
    if (answer == 'TWO') {
      if (this.platform.IOS || this.platform.SAFARI || this.platform.WEBKIT) {
        // check if the device is in standalone mode
        const isInStandaloneMode = () => {
          return (
            "standalone" in (window as any).navigator &&
            (window as any).navigator.standalone
          );
        };
        if (!isInStandaloneMode()) {
          await this.modalController.displayMessage(this.translate.instant('GUARD.HOME_SCREEN.TITLE'), this.translate.instant('GUARD.HOME_SCREEN.MSG'));
          // this.accessCreated.emit();

        }
      }

      if (typeof Notification == 'undefined' || !window.PushManager || !this.swPush.isEnabled) {
        await this.modalController.displayMessage(this.translate.instant('GUARD.NOTIFICATION.TITLE'), this.translate.instant('GUARD.NOTIFICATION.MSG'));
        return;
      }

      const permission = Notification.permission;
      if (permission == 'denied') {
        await this.modalController.displayMessage(this.translate.instant('GUARD.NOTIFICATION.ENABLE.TITLE'), this.translate.instant('GUARD.NOTIFICATION.ENABLE.MSG'));
        return;
      }

      const isBrave = ((<any>navigator).brave && await (<any>navigator).brave.isBrave() || false);
      if (isBrave) {
        await this.modalController.displayMessage('Brave', this.translate.instant('GUARD.BRAVE.MSG'));
      }  //register push
      const subscription = await this.modalController.displayNotification(this.translate.instant('GUARD.NOTIFICATION.TITLE'), this.translate.instant('GUARD.NOTIFICATION.NOTICE'));
      if (!subscription) {
        await this.modalController.displayMessage(this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.FAILED.TITLE'), this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.FAILED.MSG'));
        this.delete();
        return;
      }

      this.guardAccess.pushToken = subscription;
      if (!this.guardAccess.failures) {
        this.guardAccess.failures = Math.min(14, this.failuresMax); // 14 days of warnings if possible
        this.updateSpareDays();
      }

      if (save) {
        await this.apiSvc.updateAccess(this.guardAccess);
      }
      await this.modalController.displayMessage(this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.TITLE'), this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.MSG'));
      if (save) {
        await this.success();
      }
    }
  }

  private async initAccess(access: SafeAccess) {
    this.guardAccess = access;
    this.updateSpareDays();
    if (access && !access.pushToken) {
      await this.configureNotifications(true);
    }
  }

  async onSubmit(): Promise<void> {
    this.changed = false;
    try {
      await this.apiSvc.updateAccess(this.guardAccess);
      await this.success();
      this.accessCreated.emit(this.guardAccess);
    } catch (err) {
      this.errorSvc.process(err);
    }
  }

  onCancel(): void {
    this.accessCreated.emit();
  }

  async delete(warn = false): Promise<void> {
    this.authzSvc.appConfig.guardSet = false;
    if (this.guardAccess.accessID) {
      if (warn) {
        let result = await this.modalController.displayQuestion(this.translate.instant('GUARD.DELETE.TITLE'), this.translate.instant('GUARD.DELETE.MSG1'), this.translate.instant('CANCEL'), null, this.translate.instant('DELETE'));
        if (result == 'ZERO') {
          return;
        }
        if (this.guardAccess.legacyId) {
          result = await this.modalController.displayQuestion(this.translate.instant('GUARD.DELETE.TITLE'), this.translate.instant('GUARD.DELETE.MSG2'), this.translate.instant('CANCEL'), null, this.translate.instant('DELETE'));
          if (result == 'ZERO') {
            return;
          }
        }
      }
      if (this.guardAccess.pushToken) {
        try {
          this.swPush.unsubscribe();
        } catch (ignore) {
        }
      }
      await this.apiSvc.deleteAccess(this.guardAccess.accessID);
    }
    this.accessCreated.emit();
  }

  async generate() {
    if (!this.passphrase) {
      this.passphrase = Utilities.generateSecurePassword();
    }

    const success = await this.modalController.displayAccessID(this.translate.instant('GUARD.NOTIFICATION.REGISTRATION.SUCCESS.QRCODE'), 'access', this.passphrase, this.guardAccess);
    Console.log('GuardEditor: generateQR', success);
    return success;
  }

  async configureLegacyLink() {
    this.updateSpareDays();
    this.legacyLinkComponent.createAccess();
  }

  onChanged() {
    this.changed = true;
    this.updateSpareDays();
  }

  onLegacyChanged(legacylink: SafeAccess | undefined) {
    if (legacylink) {
      this.changed = true;
    }
    this.updateSpareDays();
  }

  async extendSafe() {
    this.modalController.displaySpinner(true);
    const at = await this.apiSvc.getCreditTokenAccessToken();
    window.open(`${this.paymentURL}/#/?code=${at}`, '_self');
  }

  private updateSpareDays() {
    if (!this.guardAccess) {
      return;
    }
    let legacyDays = 0;
    if (this.guardAccess.legacyId) { //legacy access is enabled
      legacyDays = this.guardAccess.legacyDelay! * 30;
    }

    this.spareDays = this.safeExpiresInDays - this.guardAccess.interval! - this.guardAccess.failures! - legacyDays - 7; //7 days less for time to extend the safe

    this.failuresMax = Math.min(this.guardAccess.failures! + this.spareDays, 365);
    this.intervalMax = Math.min(this.guardAccess.interval! + this.spareDays, 365);
    this.legacyDelayMax = Math.min(Math.floor((this.spareDays + legacyDays) / 30), 24);
  }
}
