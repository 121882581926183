export class Archive {
  id!: string;
  t = 'Cloud'; //type
  s!: number; // size KB
  m: any; // metadata
  l!: string; // locationID
  p?: number[]; // parts
  fs? = 0; // file size
  recordId?: number | undefined;
  sid = 0; // space id in seconds epoch to ensure unique id and ordering
}

export class Location {
  id!: string;
  region!: string;
  class!: string; //redundancy?
  name?: string
}

export class RecordArchive {
  archiveID!: string;
  name!: string;
}
