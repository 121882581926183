<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-title" [innerHTML]="data.title"></div>
        <div #authBody class="pin-body" >
          <img class="padImage" #imageElement [src]="data.padImage" (click)="onPadClick($event)" />
          <img class="codeImage" *ngIf="data.codeImage" [src]=data.codeImage />
          <div id="pinContainer" class="pinContainer"> <img class="pinImage" #pinElement alt /></div>
        </div>
        <div class="button-container">
          <button [ngClass]="clicks.length==0 ? 'btn-primary': 'btn'"  (click)="cancel()"> {{cancelBtnTxt}}</button>
          <button class="btn" [disabled]="clicks.length==0" (click)="clear()"> {{clearBtnTxt}} </button>
          <button class="btn-primary" [disabled]="clicks.length==0"  (click)="send()"> {{sendBtnTxt}} </button>
        </div>
      </div>
    </div>
  </div>
</div>
