<div *ngIf="loaded" #authModalContainer class="modal-container" >
  <div #authModal id="walletModal" class="modal">
    <div class="auth-main">
      <div class="auth-container" [hidden]="(!mnemonicWords || mnemonicWords.length ==0)  && !address">
        <div id='print-container'>
          <div class="title">
            <div class="title" [ngClass]="{'address': !showMnemonic, 'privatekey': showMnemonic}">
              <img [src]="smallImageURL" />
              <div class="auth-title" [innerHTML]="title" appHelp="MODALS.WALLET.HELP"></div>
            </div>
            <span [ngClass]="{'closeaddress': !showMnemonic, 'closeprivatekey': showMnemonic}" (click)="next()">
              <img src="assets/images/svg/x-btn.svg" alt="close"></span>
          </div>
          <div *ngIf="!readonly" class="button-container">
            <button class="btn toggle" (click)="toggle()">{{btnText}}</button>
          </div>


          <!-- QR Code display -->
          <div *ngIf="!showMnemonic" class="qrcode">
            <qrcode *ngIf="address" appCopyText [showIcon]="false" [copyText]="address" [level]="'H'" [size]='300' [value]="address">
            </qrcode>
          </div>

          <!-- Mnemonics display -->
          <div *ngIf="showMnemonic" class="mnemonic-container">
            <div *ngFor="let word of mnemonicWords; let i = index" class="mnemonic-word" [attr.data-index]="i + 1">
              {{ word }}
            </div>
          </div>

          <div *ngIf="address" #authBody class="auth-body">
            <div class="textHolder notranslate" autocorrect="off" autocapitalize="off" spellcheck="false"
              autocomplete="off">
              <p *ngIf="message" class="message">{{message}} </p>
              <div *ngIf="!showMnemonic" class="button-container">
                <button *ngIf="!balance" class="btn toggle" (click)="getbalance()">{{'MODALS.WALLET.BALANCE'|
                  translate}}</button>
                <span *ngIf="balance">{{'MODALS.WALLET.BALANCE'|
                  translate}}:&nbsp;{{balance}} &nbsp; {{wallet.coinType}}</span>
              </div>
              <div class="address-container">
                <p appCopyText [copyText]="address" class="p-wrap">{{address}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="button-container">
          <div *ngIf="!showMnemonic || readonly">
            <button class="btn" (click)="print()">{{'MODALS.WALLET.PRINT' | translate }}</button>
          </div>
          <button class="btn-primary" (click)="next()"> {{ 'CLOSE' | translate }} </button>
        </div>
      </div>
    </div>
  </div>
</div>
<question-modal #questionemodal *ngIf="showQuestion" [data]="questionData"
  (response)="questionReturn($event)"></question-modal>
<coin-selector-modal #coinSelectorModal *ngIf="showCoinSelector"
  (response)="coinSelectorReturn($event)"></coin-selector-modal>
<input-modal *ngIf="showInput" [data]="inputData" (response)="inputReturn($event)"></input-modal>
<token-balance-list *ngIf="tokens && tokens.length>0" [tokens]="tokens" [balance]="balance"
  (response)="tokenBalanceListReturn()"></token-balance-list>
