import { Component, ElementRef, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import {  NgFor, NgIf } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Console } from 'src/app/lib/console';
@Component({
  selector: 'list-select-modal',
  standalone: true,
  imports: [ NgIf, NgFor],
  templateUrl: './list-select-modal.component.html',
  styleUrl: './list-select-modal.component.scss'
})
export class ListSelectModalComponent {


  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<Item | null>();
  @Input() set data(value: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, items: Item[]  }) {
    const clone = JSON.parse(JSON.stringify(value));
    clone.message = this.sanitizer.bypassSecurityTrustHtml(value.message) as string;
    this.displayData = clone;
  }
  displayData: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, items: Item[] } = { title: '', message: '', btnCancelLable: '', btnOneLable: '', items: [{ label: '', object: '' }] };
  selectedItem: Item | null = null;
  constructor(private sanitizer: DomSanitizer) {
  }

  buttonClick(selected: string) {
    if (selected === 'cancel') {
      this.response.emit(null);
    } else {
      this.response.emit(this.selectedItem);
    }
  }

  listItemClick(item: any) {
    Console.log('item', item);
    this.selectedItem = item;
  }
}

export interface Item {
  label: string;
  object: any;
}
