import { CommitmentPolicy, RawAesKeyringWebCrypto, RawAesWrappingSuiteIdentifier, buildClient } from "@aws-crypto/client-browser";

import { toBase64, fromBase64 } from '@aws-sdk/util-base64-browser'
import { Action } from "../interfaces/callback";
import { Console } from "./console";

const { encrypt, decrypt } = buildClient(
  CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
);

export class SafeCrypto {

  //clientDataKey?: Uint8Array; //ToDo remove this. Get it by decryptring wraqpped data from server rather than persisting it.

  /* The wrapping suite defines the AES-GCM algorithm suite to use. */

  private keyring?: RawAesKeyringWebCrypto;
  private keyringMasterKey!: CryptoKey;
  private ECDHEKey?: CryptoKey;
  public initialized = false;
  public ECDHEDone = false;
  private broadcastChannel;
  public ECDHEKeyRAW?: ArrayBuffer; // Used to send the ECDHE key to the service worker

  constructor(broadcastKeys = true) {
    if (broadcastKeys) {
      this.broadcastChannel = new BroadcastChannel("safe-crypto");
    }
  }

  public static async hashtoB64(text: string): Promise<string> {
    // Encode the string into a Uint8Array buffer
    const encoder = new TextEncoder();
    const buffer = encoder.encode(text);

    // Compute the SHA-256 hash of the buffer
    const hash = await window.crypto.subtle.digest('SHA-256', buffer);

    // Encode the raw hash data as a base64 string
    const uint8array = new Uint8Array(hash);
    const base64Hash = toBase64(uint8array);
    return base64Hash;
  }

  /*
   * Perform an ECDSA key exchange to establish an AES256 GCM session key.
   * This is used to encrypt callback data between the client and the API Server, providing an extra layer of security over SSL.
   * The API Server's public validation key, which is hardcoded in the client, is used to authenticate the API Server.
   * ---
   * Client application files, including the API Server's validation key, are signed during the build process and uploaded to the server.
   * A service worker validates the signatures of any new application versions' files before using them to ensure their integrity.
   * As long as the application is installed from a trusted host the first time...
   * We're doing the best we can. This approach is a significant step ahead of most other SPAs/PWAs and aims to provide an additional layer of security beyond SSL.
   * Data is encrypted client-side and transmitted to the storage systems independently of the API Server.
   * If you begin with a compromised system, just like with any other applications, you're in trouble...
   */
  async doKeyExchange(ECPublicKeyJWK: any, signatureb64: string, serverSigningValidationKey: string) {
    // Import API Servers locally stored signature validation key
    const serverPublicKey = await crypto.subtle.importKey(
      'jwk',
      JSON.parse(serverSigningValidationKey),
      {
        name: 'ECDSA',
        namedCurve: 'P-384',
      },
      false,
      ['verify'],
    );

    // Verify API Servers transmited public ECDHE key is sighned with API servers locally stored signing key
    const u8buffer = fromBase64(signatureb64);
    // to ArrayBuffer
    const signature = u8buffer.buffer.slice(u8buffer.byteOffset, u8buffer.byteLength + u8buffer.byteOffset);

    const encoded = new TextEncoder().encode(JSON.stringify(ECPublicKeyJWK));
    const valid = await crypto.subtle.verify(
      {
        name: "ECDSA",
        hash: { name: "SHA-384" },
      },
      serverPublicKey,
      signature,
      encoded
    );
    if (valid) {
      // import API Server ECDHE public key
      const serverECPublicKey = await this.importJWK(ECPublicKeyJWK);

      // Generate random client ECDHE keypair
      const ourKeys = await this.generateRandomECDHKeys();

      // Derive symetric key from client ECDHE private key and API Servers ECDHE public key
      this.ECDHEKey = await crypto.subtle.deriveKey(
        { name: 'ECDH', public: serverECPublicKey },
        ourKeys.privateKey,
        { name: 'aes-gcm', length: 256 },
        true,
        ['decrypt', 'encrypt'],
      );

      this.ECDHEDone = true;
      // Send our ECDHE key to the serviceworker
      this.ECDHEKeyRAW = await crypto.subtle.exportKey('raw', this.ECDHEKey);
      if (this.broadcastChannel) {
        navigator.serviceWorker.ready.then((registration) => {
          try {
            this.broadcastChannel.postMessage({ action: 'ECDHEKey', ECDHEKeyRAW: this.ECDHEKeyRAW });
          } catch (err) {
            Console.error('ECDHEKey error', err);
          }
        });
      }
      return crypto.subtle.exportKey('jwk', ourKeys.publicKey);
    }
    Console.error('Error validating public key', serverSigningValidationKey, ECPublicKeyJWK);
    throw new Error("Invalid key exchange");
  }


  private async generateRandomECDHKeys(): Promise<CryptoKeyPair> {
    const algorithm = {
      name: 'ECDH',
      namedCurve: 'P-384',
    };

    return crypto.subtle.generateKey(algorithm, true, ['deriveKey', 'deriveBits']);
  }

  private async importJWK(jwk: any,): Promise<CryptoKey> {
    return crypto.subtle.importKey(
      'jwk',
      jwk,
      {
        name: 'ECDH',
        namedCurve: 'P-384',
      },
      false,
      []
    );
  }

  /*
   * Initializes AWSCrypto keyring and WebCrypto
   * Master key = first 128 bits of AES256(passphrase + accessID).
   * Used to encrypt and decrypt the client data key that is then used to reinitialize the keyring with a key not related to the passphrase.
   * Storing the client day key double encrypted in the safe metadata allows for future support for passphrase changees without re-encrypting all the data.
   * It also adds support for passkeys.
    */
  public async unwrapAndSetClientDataMasterKey(wrappedClientDataKey: string) {
    const newMasterKey = await this.decryptClientDataMasterKey(wrappedClientDataKey);
    //this.clientDataKey = newMasterKey;
    await this.initKeyring(newMasterKey, 'clientDataKey');
  }

  /**
  * Initializes AWSCrypto keyring and WebCrypto
  * Master key = first 128 bits of AES256(passphrase + accessID).
  * Used to encrypt and decrypt the client data key that is then used to reinitialize the keyring with a key not related to the passphrase.
  * Storing the client day key double encrypted in the safe metadata allows for future support for passphrase changees without re-encrypting all the data.
  * It also adds support for passkeys.
  * returns the generated safeAccess master key
   */
  public async initCryptoWithPassphrase(passphrase: string, accessIDHash: string): Promise<Uint8Array> {

    const hash = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(passphrase + accessIDHash));
    // Use the first 32 bytes of the hash as the raw key
    const safeAccessMasterKey = (new Uint8Array(hash.slice(0, 32)));
    await this.initKeyring(safeAccessMasterKey, 'passphrase');
    return safeAccessMasterKey;
  }

  /**
   *
   * @param b64masterKey
   * @returns returns the master key
   */
  async initCryptoWithSafeAccessMasterKey(b64masterKey: string): Promise<Uint8Array> {
    const masterKey = fromBase64(b64masterKey);
    await this.initKeyring(masterKey, 'passphrase');
    return masterKey;
  }

  private async initKeyring(keyingData: Uint8Array, keyName: string) {
    if (this.broadcastChannel) {
      navigator.serviceWorker.ready.then((registration) => {
        this.broadcastChannel.postMessage({ action: 'INIT_KEYS', keyingData, keyName });
      });
    }
    try {
      /* Import the plaintext master key into a WebCrypto CryptoKey. */
      const masterKey = await RawAesKeyringWebCrypto.importCryptoKey(
        keyingData,
        RawAesWrappingSuiteIdentifier.AES256_GCM_IV12_TAG16_NO_PADDING
      )

      const keyNamespace = 'aes-namespace'
      const wrappingSuite =
        RawAesWrappingSuiteIdentifier.AES256_GCM_IV12_TAG16_NO_PADDING
      /* Configure the Raw AES keyring. */
      this.keyring = new RawAesKeyringWebCrypto({
        keyName,
        keyNamespace,
        wrappingSuite,
        masterKey,
      });

      this.keyringMasterKey = await crypto.subtle.importKey(
        "raw",
        keyingData,
        "AES-CTR",
        true,
        ["encrypt", "decrypt"]
      );
      this.initialized = true;
    } catch (err) {
      Console.error(err);
    }
  }

  /*
    * Generates a new ClientDataMaster key and encrypts it with the SafeAccessMaster Key.
    * The wrapped key is sent to the server and stored in the safe metadata.
    * The passphrase is not sent to the server or persisted anywhere.
    * The client data key is used to initialize the clients keyring.
    */
  public async generateNewClientDataKey(): Promise<string> {
    if (!this.keyring) {
      throw new Error('keyring must be initialized first');
    }
    const newRandomClientDataKey = window.crypto.getRandomValues(new Uint8Array(32));
    const resultBase64 = await this.encryptClientDataMasterKey(newRandomClientDataKey);
    await this.unwrapAndSetClientDataMasterKey(resultBase64);
    return resultBase64;
  }

  public async encryptClientDataMasterKey(clientDataKey: Uint8Array): Promise<string> {
    if (!this.keyring) {
      throw new Error('keyring must be initialized first');
    }
    const context = {
      safeID: 'UnoLock',
      type: 'clientDataKey',
    }

    //Encrypt
    const { result } = await encrypt(this.keyring!, clientDataKey, {
      encryptionContext: context,
    })

    return toBase64(result);
  }

  // Decodes the client data key wrapped by the SafeAccessMaster Key. From the server.
  public async decryptClientDataMasterKey(wrappedClientKey: string): Promise<Uint8Array> {
    if (!this.keyring) {
      throw new Error('keyring must be initialized first');
    }
    const context = {
      safeID: 'UnoLock',
      type: 'clientDataKey',
    }

    const { plaintext, messageHeader } = await decrypt(this.keyring!, fromBase64(wrappedClientKey))

    /* Grab the encryption context so we can verify it. */
    const { encryptionContext } = messageHeader

    Object.entries(context).forEach(([key, value]) => {
      if (encryptionContext[key] !== value)
        throw new Error('Encryption Context does not match expected values')
    })
    return plaintext;
  }

  /*
    * AWS crypto
    * Used to encrypt Archive data client side
   */
  public async encryptBinary(data: Uint8Array, archiveID: string): Promise<Uint8Array> {
    if (!this.keyring) {
      throw new Error('keyring must be initialized first');
    }
    const context = {
      archiveID: archiveID,
      type: 'archiveData',
    }

    //Encrypt
    const { result } = await encrypt(this.keyring!, data, {
      encryptionContext: context,
    })

    return result;
  }

  /*
   * AWS crypto
   * Used to encrypt Record data client side
  */
  public async encryptString(text: string): Promise<string> {
    /* Encryption context is a *very* powerful tool for controlling and managing access.
  * It is ***not*** secret!
  * Encrypted data is opaque.
  * You can use an encryption context to assert things about the encrypted data.
  * Just because you can decrypt something does not mean it is what you expect.
  * For example, if you are are only expecting data from 'us-west-2',
  * the origin can identify a malicious actor.
  * See: https://docs.aws.amazon.com/encryption-sdk/latest/developer-guide/concepts.html#encryption-context
  */
    const context = {
      safeID: 'UnoLock', // ToDo switch for safeID
    }

    // Compress
    const plainText = new TextEncoder().encode(text);

    //Encrypt
    const { result } = await encrypt(this.keyring!, plainText, {
      encryptionContext: context,
    })

    return toBase64(result);
  }


  /*
  * AWS crypto
  * Used to decrypt Archive data client side
  */
  public async decryptBinary(data: Uint8Array, archiveID: string): Promise<Uint8Array> {
    try {
      const context = {
        archiveID: archiveID,
        type: 'archiveData',
      }
      //Decrypt
      const { plaintext, messageHeader } = await decrypt(this.keyring!, data);

      /* Grab the encryption context so you can verify it. */
      const { encryptionContext } = messageHeader

      Object.entries(context).forEach(([key, value]) => {
        if (encryptionContext[key] !== value) {
          throw new Error('Encryption Context does not match expected values');
        }
      })

      return plaintext;
    } catch (err) {
      Console.error(err);
      throw new Error('Could not decrypt archive data');
    }
  }

  /*
   * AWS crypto
  * Used to decrypt Record data client side
   */
  public async decryptString(encrypted: string): Promise<string> {
    try {
      const context = {
        safeID: 'UnoLock', // ToDo switch for safeID
      }
      //Decrypt
      const { plaintext, messageHeader } = await decrypt(this.keyring!, fromBase64(encrypted))

      /* Grab the encryption context so you can verify it. */
      const { encryptionContext } = messageHeader

      /* Verify the encryption context.
       * If you use an algorithm suite with signing,
       * the Encryption SDK adds a name-value pair to the encryption context that contains the public key.
       * Because the encryption context might contain additional key-value pairs,
       * do not add a test that requires that all key-value pairs match.
       * Instead, verify that the key-value pairs you expect match.
       */
      Object.entries(context).forEach(([key, value]) => {
        if (encryptionContext[key] !== value) {
          throw new Error('Encryption Context does not match expected values');
        }
      })

      const result = new TextDecoder().decode(plaintext);
      return (result!);
    } catch (err) {
      Console.error(err);
      throw new Error('Could not decrypt string');
    }
  }

  /*
  * Webcrypto
  * Used during safe creation.
  * Used to wrap the Safe key that is used to encrypt the Safe metadata on the server.
  * Passphrase(Client key(Safe key(safe))) the Safe key is not persisted server side,
  * and the client key is wrapped by a key derived from the passphrase. Nothing is persisted client side.
  * For decrypting keys in this case we do Not want authentication.
  * Force sending result to server for validation / throttling
  */


  public async encryptServerMetadataKey(safeKey: string): Promise<string> {
    const encoded = new TextEncoder().encode(safeKey);

    // The counter block value must never be reused with a given key.
    const counter = window.crypto.getRandomValues(new Uint8Array(16));
    const ciphertext = await window.crypto.subtle.encrypt(
      {
        name: "AES-CTR",
        counter,
        length: 64
      },
      this.keyringMasterKey,
      encoded
    );

    const combined = Buffer.concat([counter, Buffer.from(ciphertext)]);
    return toBase64(combined);
  }

  /*
  * Webcrypto
  * For keys we do Not want GCM. We want No local error on decrypt.
  * Force sending result to server for validation / throttling
  */
  public async decryptServerMetadataKey(wrappedSafeKey: string): Promise<string> {
    const combined = Buffer.from(wrappedSafeKey, 'base64');

    const counter = combined.subarray(0, 16);
    const ciphertext = combined.subarray(16);

    let decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-CTR",
        counter,
        length: 64
      },
      this.keyringMasterKey,
      ciphertext
    );

    const ret = new TextDecoder().decode(decrypted);
    return ret;
  }

  // Encrypts API Server data passed between client and server.
  public async encryptAction(action: Action): Promise<string> {
    if (!this.ECDHEKey) {
      throw new Error('Key exchage not done')
    }
    const encoded = new TextEncoder().encode(JSON.stringify(action));

    const iv = window.crypto.getRandomValues(new Uint8Array(16));
    const ciphertext = await window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv
      },
      this.ECDHEKey,
      encoded
    );

    const combined = Buffer.concat([iv, Buffer.from(ciphertext)]);
    return toBase64(combined);
  }

  // Decrypts API Server data passed between client and server.
  public async decryptAction(cypherAction: string): Promise<Action> {
    if (!this.ECDHEKey) {
      throw new Error('Key exchage not done')
    }
    const combined = Buffer.from(cypherAction, 'base64');

    const iv = combined.subarray(0, 16);
    const ciphertext = combined.subarray(16);
    let decrypted = await window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv
      },
      this.ECDHEKey,
      ciphertext
    );
    const decoded = new TextDecoder().decode(decrypted);
    return JSON.parse(decoded);
  }

  static async decryptCredentialObject(encryptedData: string, webauthnKey: ArrayBuffer) {
    try {
      const rawKey = webauthnKey;
      // Decode the base64 encoded string
      const combined = fromBase64(encryptedData);

      // Split the iv and ciphertext
      const iv = combined.slice(0, 16);
      const ciphertext = combined.slice(16);

      // Import the key
      const key = await window.crypto.subtle.importKey(
        'raw',
        rawKey,
        'AES-GCM',
        false,
        ['decrypt']
      );

      // Decrypt the ciphertext
      const decrypted = await window.crypto.subtle.decrypt(
        {
          name: "AES-GCM",
          iv
        },
        key,
        ciphertext
      );

      // Decode the decrypted data
      const decoded = new TextDecoder().decode(decrypted);

      // Parse the payload
      const payload = JSON.parse(decoded);
      return payload;
    } catch (error) {
      Console.error("Error during payload decryption:", error);
      throw error;
    }
  }

  private generateKeyEncryptionKey(byteLength: number) {
    return crypto.getRandomValues(new Uint8Array(byteLength));
  }

  /**
   * Perfect Forward Secrecy (PFS) OR MFA for encryption using one-time pad encryption.
   * Wrapps or unwraps the wrapped data keys in the header of an AWS Encryption SDK message with a separate key encryption key (KEK) by XORing the keys.
   * KEK should itself be encrypted client side in the archive metadata and stored on server.
   * Therefor if the serverside archive data is deleted the KEK is also lost. When the KEK is lost the data is lost.
   * To recover the data the KEK must be recovered from the server stored Archive metadata, decrypted with the keyring initialized with the client data master key, then usede to unwrap the wrapped data keys 'XOR' in the data header, then the keyring must be used to decrypt the data.
   * No CLient Masterkey initialized keyring or KEK no data.
   * @param encryptedData the AWS Encryption SDK message
   * @param kek The key encryption key (KEK) to use for wrapping or unwrapping the data keys
   */

  public xorEncryptedDataKeysInHeader(encryptedData: Uint8Array, kek: string | undefined): string {
    let rawKek = kek ? fromBase64(kek) : undefined;
    // Calculate offsets based on AWS Encryption SDK message format version 2
    const versionLength = 1; // Version number length
    const algorithmIdLength = 2; // Algorithm ID length
    const messageIdLength = 32; // Message ID length
    const aadLengthFieldSize = 2; // AAD length field size
    const edkCountFieldSize = 2; // EDK count field size

    // Determine start offset for AAD length
    let offset = versionLength + algorithmIdLength + messageIdLength;

    if (offset + aadLengthFieldSize > encryptedData.length) {
      throw new Error('Invalid encryptedData: insufficient length for AAD length field.');
    }
    const version = encryptedData[0]; // Extract the first byte as version
    if (version !== 2) {
      throw new Error(`Unsupported AWS Encryption SDK version: ${version}`);
    }
    // Determine AAD length
    const aadLength = (encryptedData[offset] << 8) | encryptedData[offset + 1];
    offset += aadLengthFieldSize + aadLength + edkCountFieldSize;

    // Get the number of encrypted data keys
    const edkCount = (encryptedData[offset - 2] << 8) | encryptedData[offset - 1];
    Console.log('edkCount', edkCount);
    // Iterate over each encrypted data key
    for (let i = 0; i < edkCount; i++) {

      const providerIdLength = (encryptedData[offset] << 8) | encryptedData[offset + 1];
      offset += 2 + providerIdLength;

      const providerKeyLength = (encryptedData[offset] << 8) | encryptedData[offset + 1];

      offset += 2 + providerKeyLength;

      const edkLength = (encryptedData[offset] << 8) | encryptedData[offset + 1];
      Console.log('edkLength', edkLength);
      if (!rawKek) {
        Console.log('rawKek not generated, generating new one ');
        rawKek = this.generateKeyEncryptionKey(edkLength);
      }
      offset += 2;
      // Apply XOR to the encrypted data key
      for (let j = 0; j < edkLength; j++) {
        encryptedData[offset + j] ^= rawKek[j % rawKek.length];
      }

      offset += edkLength;
    }

    if (!rawKek) {
      throw new Error('rawKek not generated');
    }
    if (!kek) {
      kek = toBase64(rawKek);
    }
    return kek;
  }

  public xorEncryptedDataKeysInHeaderString(encrypted: string, kek: any): string {
    const encryptedData = fromBase64(encrypted);
    return this.xorEncryptedDataKeysInHeader(encryptedData, kek);
  }

}
