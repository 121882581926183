import { C2CMD } from "src/app/interfaces/C2CMD";
import { C2cmdExecutor } from "src/app/interfaces/C2cmdExecutor";
import { SafeContact } from "src/app/interfaces/safeContact";
import { Console } from "../console";

export class SUB_UPDATE extends C2cmdExecutor {
    async executeCommand(cmd: C2CMD, contact: SafeContact): Promise<C2CMD | null> {
        if (contact.trustLevel !== 'Blocked') {
              Console.log(`Updating push subscription for ${contact.Id} as ${cmd.data}`);
            await this.c2Svc.eyesAddrSvc.setPushSubscription(contact.Id, cmd.data);
        }
        return null;
    }

    public static getCmd(subscription: string): C2CMD {
        if (!subscription) {
            throw new Error('No push subscription.');
        }
        return {
            id: 0,
            type: 'SUB_UPDATE',
            data: subscription,
            response: false,
        }
    }
}