import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ApiService } from 'src/app/services/api.service';
import { ModalComponent } from '../../modal/modal.component';
import { Router, RouterModule } from '@angular/router';
import { Console } from 'src/app/lib/console';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@angular/cdk/platform';
import { SwPush } from '@angular/service-worker';
import { EyesAddressService } from 'src/app/services/eyesAddress.service';
import { C2Service } from 'src/app/services/c2.service';
import { SUB_UPDATE } from 'src/app/lib/C2/SUB_UPDATE';

@Component({
  selector: 'app-eyes-config',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, ModalComponent, RouterModule],
  templateUrl: './eyes-config.component.html',
  styleUrls: ['./eyes-config.component.scss']
})
export class EyesConfigComponent implements OnInit {
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;

  eyesConfigForm: FormGroup;
  currentEyesAddress: string = '';
  currentSigningKey: number | undefined;

  private fb = inject(FormBuilder);
  currentKeyIDs: number[] | undefined;

  constructor(public apiSvc: ApiService, private router: Router, private translate: TranslateService, private platform: Platform, private swPush: SwPush, private addressSvc: EyesAddressService, private c2Svc: C2Service) {
    this.eyesConfigForm = this.fb.group({});
  }

  ngOnInit(): void {
    this.loadCurrentSettings();
  }

  exit() {
    this.router.navigate(['/eyes/inbox']);
  }

  openAddressBook() {
    this.router.navigate(['/eyes/addresses', this.currentEyesAddress]);
  }

  async loadCurrentSettings() {
    try {
      const keys = await this.apiSvc.getEyesPrivateKeys();
      this.currentEyesAddress = this.apiSvc.getAppConfig().eyesAddr;
      this.currentKeyIDs = keys?.privateKeys.map(k => k.id);
      this.currentSigningKey = keys?.signingKey?.id;;
    } catch (error) {
      Console.error('Error loading Eyes-Only configuration:', error);
    }
  }

  async rotateEyesAddress() {
    try {
      await this.apiSvc.rotateEyesAddress();
      await this.loadCurrentSettings();
    } catch (error) {
      Console.error('Failed to rotate Eyes Address:', error);
    }
  }

  async rotateKeys() {
    try {
      await this.apiSvc.rotateEyesKeys();
      await this.loadCurrentSettings();
    } catch (error) {
      Console.error('Failed to rotate keys:', error);
    }
  }

  async registerForNotifications() {

    //ToDo move this into the modal itself

    if (this.platform.IOS || this.platform.SAFARI || this.platform.WEBKIT) {
      // check if the device is in standalone mode
      const isInStandaloneMode = () => {
        return (
          "standalone" in (window as any).navigator &&
          (window as any).navigator.standalone
        );
      };
      if (!isInStandaloneMode()) {
        await this.modalController.displayMessage(this.translate.instant('GUARD.HOME_SCREEN.TITLE'), this.translate.instant('GUARD.HOME_SCREEN.MSG'));
      }
    }

    if (typeof Notification == 'undefined' || !window.PushManager || !this.swPush.isEnabled) {
      await this.modalController.displayMessage(this.translate.instant('GUARD.NOTIFICATION.TITLE'), this.translate.instant('GUARD.NOTIFICATION.MSG'));
      return;
    }

    const permission = Notification.permission;
    if (permission == 'denied') {
      return;
    }

    const isBrave = ((<any>navigator).brave && await (<any>navigator).brave.isBrave() || false);
    if (isBrave) {
      await this.modalController.displayMessage('Brave', this.translate.instant('GUARD.BRAVE.MSG'));
    }  //register push

    const subscription = await this.modalController.displayNotification('Registering for notifications', 'You will reveive a notification on this device when a new Eyes-Only message arrives');
    Console.log(`Registering for notifications: ${subscription}`);

    try {
      if (subscription) {
        // await this.apiSvc.registerEyseMsgNotification(subscription);
        this.addressSvc.setLocalPushSubscription(subscription);
        const contacts = await this.addressSvc.getContacts();
        for (const contact of contacts) {
          const cmd = SUB_UPDATE.getCmd(subscription);
          await this.c2Svc.sendCommand(cmd, contact);
        }

      }
    } catch (error) {
      Console.error('Failed to register for notifications:', error);
    }
  }
}
