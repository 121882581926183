import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { ErrorService } from './error.service';
import { TranslateService } from '@ngx-translate/core';
import { FileService } from './file.service';
import { Archive } from '../interfaces/archive';
import { Console } from '../lib/console';
import base64url from 'base64url';
import { EyesHeader, Message } from '../interfaces/eyesHeader';
import { EyesAddressService } from './eyesAddress.service';
import { EyesFile } from '../lib/eyesFile';
import { C2Service } from './c2.service';

@Injectable({
  providedIn: 'root'
})
export class EyesSenderService {

  constructor(private errorSvc: ErrorService, private apiSvc: ApiService, private fileSvc: FileService, private translate: TranslateService, private addressSvc: EyesAddressService, private C2Svc: C2Service) {
  }

  /**
   *
   * @param archive
   * @param keyData required for conversion to Eyes-Only.
   * @param base64iv required for conversion to Eyes-Only.
   * @returns { newArchive: Archive, emitter: EventEmitter<{size?:number, transferred: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR', error?: any }> }
   */
  async convertArchive(archive: Archive, keyData?: {
    aesKey: CryptoKey;
    b64CompositeCipherText: string;
  }, base64iv?: string): Promise<{ newArchive: Archive, emitter: EventEmitter<{ size?: number, transferred: number, attachmentNumber?: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'ATTACHMENT' | 'COMPLETED' | 'ERROR', error?: any }> }> {
    return this.fileSvc.convertArchive(archive, keyData, base64iv);
  }

  public async sendMessage(header: ArrayBuffer, clearheader: EyesHeader, attachments: EyesFile[], recipientAddress: string, keyData: {
    aesKey: CryptoKey;
    b64CompositeCipherText: string;
  }): Promise<EventEmitter<{ size?: number, transferred: number, attachmentNumber?: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'ATTACHMENT' | 'COMPLETED' | 'ERROR', error?: any }>> {
    const emitter = new EventEmitter<{ transferred: number, attachmentNumber?: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'ATTACHMENT' | 'COMPLETED' | 'ERROR', error?: any }>();
    this.apiSvc.setEyesCryptoKey(keyData);
    this.process(header, clearheader, attachments, recipientAddress, keyData, emitter);
    return emitter;
  }

  private async process(header: ArrayBuffer, clearheader: EyesHeader, attachments: EyesFile[], recipientAddress: string, keyData: {
    aesKey: CryptoKey;
    b64CompositeCipherText: string;
  }, emitter: EventEmitter<{ size?: number, transferred: number, attachmentNumber?: number, stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'HEADER' | 'ATTACHMENT' | 'COMPLETED' | 'ERROR', error?: any }>) {
    //create an archive for the header and each attachment
    const messageID = Date.now();

    const attachmentArchives: string[] = [];
    for (let i = 0; i < attachments.length; i++) {
      let attachmentArchive = new Archive();
      attachmentArchive.t = 'Msg';
      const iv = clearheader.attachments![i].iv;
      attachmentArchive.arr = { key: keyData.b64CompositeCipherText, iv: base64url.toBase64(iv) };
      attachmentArchive.fs = clearheader.attachments![i].size;
      attachmentArchive.l = this.apiSvc.getCurrentLocationID();

      // for use in this Safe while in outbox as metadata is encrypted locally
      attachmentArchive.m = { msgID: messageID, addr: recipientAddress };
      attachmentArchive.m.name = clearheader.attachments![i].name;
      attachmentArchive.m.type = clearheader.attachments![i].type;
      attachmentArchive.sid = 1;
      attachmentArchive = await this.apiSvc.createArchive(attachmentArchive);

      Console.log('Created attachment archive', attachmentArchive);
      //send the attachment

      const stream = await attachments[i].stream();
      if (stream) {
        emitter.emit({ transferred: 0, attachmentNumber: i, stage: 'ATTACHMENT' });
        const progress: any = await this.fileSvc.sendStream(stream, attachmentArchive);
        await this.waitForEmitterCompletion(progress, emitter);
        const archive = await this.apiSvc.getArchive(attachmentArchive.id);
        archive.sid = 1;
        Console.log('Sent attachment', archive);
        if (archive) {
          archive.archiveListID = '';
          archive.m = undefined;
          attachmentArchives[i] = JSON.stringify(archive);
        } else {
          emitter.emit({ transferred: 0, attachmentNumber: i, stage: 'ATTACHMENT', error: 'Error getting archive after upload' });
          this.cleanup(attachmentArchives);
          return;
        }
      } else {
        emitter.emit({ transferred: 0, attachmentNumber: i, stage: 'ATTACHMENT', error: 'Error creating stream for attachment' });
        this.cleanup(attachmentArchives);
        return;
      }
    }
    try {
      Console.log('Sent message attachments', attachmentArchives);
      emitter.emit({ transferred: 0, attachmentNumber: attachments.length, stage: 'HEADER' });

      const contact = await this.addressSvc.getContact(recipientAddress);
      const subscription = contact?.pushSubscription;

      await this.C2Svc.publishMessage(recipientAddress, messageID, base64url.encode(Buffer.from(header)), attachmentArchives, subscription);
  
      emitter.emit({ transferred: 0, attachmentNumber: attachments.length, stage: 'COMPLETED' });
    } catch (e) {
      emitter.emit({ transferred: 0, attachmentNumber: attachments.length, stage: 'ERROR', error: e });
      this.cleanup(attachmentArchives);
    }
  }

  private async cleanup(attachmentArchives: string[]) {
    for (let i = 0; i < attachmentArchives.length; i++) {
      try {
        const archiveID = JSON.parse(attachmentArchives[i]).id;
        await this.apiSvc.deleteArchive(archiveID);
      } catch (e) {
        Console.error('Error deleting attachment archive', e);
      }
    }
    this.apiSvc.setEyesCryptoKey(null);
  }

  private async waitForEmitterCompletion(
    progress: EventEmitter<{
      transferred: number;
      size?: number;
      stage: 'READING' | 'WRITING' | 'ENCRYPTING' | 'COMPLETED' | 'ERROR';
      error?: any;
      attachmentNumber?: number;
    }>
    , emmitter: EventEmitter<{
      size?: number;
      transferred: number;
      attachmentNumber?: number;
      stage: "READING" | "WRITING" | "ENCRYPTING" | "HEADER" | "ATTACHMENT" | "COMPLETED" | "ERROR";
      error?: any;
    }>): Promise<void> {
    return new Promise((resolve, reject) => {
      const subscription = progress.subscribe(event => {
        // Console.log('Progress event', event);
        if (event.stage === 'COMPLETED') {
          subscription.unsubscribe(); // Correct way to clean up
          resolve();

        } else if (event.stage === 'ERROR') {
          subscription.unsubscribe(); // Correct way to clean up
          reject(event.error || new Error("Unknown error occurred during upload"));
        } else {
          emmitter.emit(event);
        }
      });
    });
  }

  // Set a message for temporary storage.
  private message: Message | null = null;
  public dropOffMessage(message: Message) {
    this.message = message;
  }
  public retrievDroppedOffMessage(): Message {
    const message = this.message;
    this.message = null;
    return message!;
  }

  // Hack used to pass File between inbox and the viewer.
  private localFile: File | null = null;

  // Set a local file for temporary storage.
  public setFile(file: File) {
    this.localFile = file;
  }

  // Retrieve and clear the locally stored file.
  public getFile(): File | null {
    const file = this.localFile;
    this.localFile = null;
    return file;
  }

}
