<div #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <h2 class="auth-title" [innerHTML]="title |translate"></h2>
        <div #authBody class="auth-body">
          <div *ngIf="percent" id="progress">
            <span id="percent-text">{{ percent }}%</span>
            <div class="barContainer">
              <div id="bar" [style.width]="percent + '%'">
              </div>
            </div>
          </div>
          <div [innerHTML]="message" class="textHolder notranslate"></div>
        </div>
      </div>
    </div>
  </div>
</div>
