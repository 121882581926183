import { Injectable } from '@angular/core';
import { EyesHeader } from '../interfaces/eyesHeader';
import { EyesAttachment } from '../interfaces/eyesAttachment';
import { ml_kem1024 } from '@noble/post-quantum/ml-kem';
import { ml_dsa65 } from '@noble/post-quantum/ml-dsa';
import base64url from 'base64url';

@Injectable({
  providedIn: 'root'
})
export class EyesEncryptionService {

  /**
   * Seals the header and attachments:
   * - Encrypts the header using the recipient’s ml_kem1024 public key.
   * - Optionally signs the header using your ML‑DSA secret key.
   *
   * @param header The header to be encrypted.
   * @param attachmentFiles Files to attach.
   * @param recipientPublicKey The recipient’s ml_kem1024 public key.
   * @param signingSecretKey Optional ML‑DSA signing secret key.
   * @param signingKeyId Optional identifier (kid) for the signing key to facilitate key rotation.
   * @returns The sealed message as an ArrayBuffer.
   */
  async seal(
    header: EyesHeader,
    attachmentFiles: File[],
    recipientPublicKey: Uint8Array,
    signingSecretKey?: Uint8Array,
    signingKeyId?: string
  ): Promise<ArrayBuffer> {
    const { aesKey, compositeCipherText } = await this.getEncryptionKeys(recipientPublicKey);
    const encryptedFileData: ArrayBuffer[] = [];

    for (const file of attachmentFiles) {
      const { attachment, encryptedFile } = await this.buildAttachment(file, aesKey);
      encryptedFileData.push(encryptedFile);
      header.attachments.push(attachment);
      header.message += `Attachment: ${attachment.name} (${attachment.size} bytes)\n`;
    }

    // --- Optional Signing with ML‑DSA (Dilithium) ---
    if (signingSecretKey) {
      // Create a copy of the header without any previous signature fields.
      const headerForSigning = { ...header };
      delete headerForSigning.signature; // remove any previous signature
      delete headerForSigning.kid;       // remove any previous key identifier

      const headerString = JSON.stringify(headerForSigning);
      const headerBuffer = new TextEncoder().encode(headerString);

      // Sign the header using ml_dsa65.
      const signature = ml_dsa65.sign(signingSecretKey, headerBuffer);
      const signatureBase64 = base64url.encode(Buffer.from(signature));

      // Patch the header with the signature.
      header.signature = signatureBase64;
      // If provided, include the signing key id (kid).
      if (signingKeyId) {
        header.kid = signingKeyId;
      }
    }
    // --- End Optional Signing ---

    const headerIV = this.generateIV();
    const headerBytes = new TextEncoder().encode(JSON.stringify(header));
    const encryptedHeader = await this.encrypt(headerBytes, aesKey, headerIV);
    const finalBuffer = this.encode(compositeCipherText, encryptedHeader, headerIV, encryptedFileData);

    return finalBuffer;
  }

   /**
   * Unseals the encrypted data:
   * - Derives the AES key via ml_kem1024 decapsulation.
   * - Decrypts the header and attachments.
   *
   * Note: This method does not verify the header signature. If a signature is present,
   * the returned metadata will include the signature and the key identifier (kid),
   * and the caller may later call verifyHeaderSignature().
   *
   * @param sealedData The sealed (encrypted) message.
   * @param recipientPrivateKey The recipient’s ml_kem1024 private key.
   * @returns An object containing the decrypted metadata and attachments.
   */
   async unseal(
    sealedData: Uint8Array,
    recipientPrivateKey: Uint8Array
  ): Promise<{
    metadata: any,
    decryptedFiles: ArrayBuffer[]
  }> {
    const { kyberCiphertext, encryptedHeader, headerIV, encryptedFiles } = this.decode(sealedData);
    const aesKey = await this.getDecryptionKey(kyberCiphertext, recipientPrivateKey);

    const decryptedHeader = await this.decrypt(encryptedHeader, aesKey, headerIV);
    const metadata = JSON.parse(new TextDecoder().decode(decryptedHeader));
    // Note: Signature verification is intentionally deferred.

    const decryptedFiles: ArrayBuffer[] = [];
    let fileOffset = 0;
    for (const attachment of metadata.attachments) {
      if (fileOffset + attachment.encryptedLen > encryptedFiles.length) {
        throw new Error(`Insufficient encrypted data for attachment "${attachment.name}".`);
      }
      const fileData = encryptedFiles.slice(fileOffset, fileOffset + attachment.encryptedLen);
      fileOffset += attachment.encryptedLen;

      const fileIVdecoded = new Uint8Array(base64url.toBuffer(attachment.iv));
      const decryptedFile = await crypto.subtle.decrypt(
        { name: "AES-GCM", iv: fileIVdecoded },
        aesKey,
        fileData
      );
      decryptedFiles.push(decryptedFile);
    }

    return { metadata, decryptedFiles };
  }

  /**
   * Unseals the header alone.
   *
   * @param fullBuffer The complete sealed message.
   * @param recipientSecretKey The recipient’s ml_kem1024 private key.
   * @returns An object containing the decrypted header and the encrypted attachments.
   */
  async unsealHeader(
    fullBuffer: ArrayBuffer,
    recipientSecretKey: Uint8Array
  ): Promise<{ header: EyesHeader, encryptedFiles: Uint8Array }> {
    const { kyberCiphertext, encryptedHeader, headerIV, encryptedFiles } = this.decode(new Uint8Array(fullBuffer));
    const aesKey = await this.getDecryptionKey(kyberCiphertext, recipientSecretKey);
    const decryptedHeader = await this.decrypt(encryptedHeader, aesKey, headerIV);
    const header = JSON.parse(new TextDecoder().decode(decryptedHeader)) as EyesHeader;
    // Signature is not verified here; it is returned as-is.
    header.fileDataOffset = 0;
    return { header, encryptedFiles };
  }

  /**
   * Verifies the header signature using the provided verifying keys map.
   *
   * @param metadata The decrypted header metadata that contains a signature and (optionally) a kid.
   * @param verifyingKey senders public verifying key (kid: ML‑DSA public key).
   * @returns True if the signature is valid; otherwise, throws an error.
   */
  async verifyHeaderSignature(
    metadata: any,
    verifyingKey: Uint8Array
  ): Promise<boolean> {
    if (!metadata.signature) {
      throw new Error("No signature present in header.");
    }

    const senderPublicSigningKey = verifyingKey;
    const { signature, kid, ...unsignedHeader } = metadata;
    const headerString = JSON.stringify(unsignedHeader);
    const headerBuffer = new TextEncoder().encode(headerString);
    const signatureBuffer = new Uint8Array(base64url.toBuffer(signature));
    const isValid = ml_dsa65.verify(senderPublicSigningKey, headerBuffer, signatureBuffer);
    if (!isValid) {
      throw new Error("Header signature verification failed.");
    }
    return true;
  }

  /**
  * Unseals an individual attachment.
  *
  * @param header The decrypted header containing attachment info.
  * @param fileNumber The index of the attachment.
  * @param fileBuffer The buffer containing all encrypted file data.
  * @param aesKey The AES-GCM key for decryption.
  */
  async unsealAttachment(header: EyesHeader, fileNumber: number, fileBuffer: ArrayBuffer, aesKey: CryptoKey): Promise<{ attachment: EyesAttachment, file: ArrayBuffer }> {
    // Bounds check: ensure the requested attachment exists.
    if (fileNumber < 0 || fileNumber >= header.attachments.length) {
      throw new Error(`Invalid attachment index: ${fileNumber}. There are only ${header.attachments.length} attachments.`);
    }

    const attachment = header.attachments[fileNumber];
    const fileIV = new Uint8Array(base64url.toBuffer(attachment.iv));

    // Calculate file offset by summing the lengths of preceding attachments.
    let fileOffset = 0;
    for (let i = 0; i < fileNumber; i++) {
      fileOffset += header.attachments[i].encryptedLen;
    }

    // Check that we have enough file data.
    if (fileOffset + attachment.encryptedLen > fileBuffer.byteLength) {
      throw new Error(`Insufficient file data for attachment "${attachment.name}". Expected ${attachment.encryptedLen} bytes at offset ${fileOffset} but fileBuffer is only ${fileBuffer.byteLength} bytes long.`);
    }

    const fileData = fileBuffer.slice(fileOffset, fileOffset + attachment.encryptedLen);

    const decryptedFile = await crypto.subtle.decrypt(
      { name: "AES-GCM", iv: fileIV },
      aesKey,
      fileData
    );

    return { attachment, file: decryptedFile };
  }

  /**
   * Combines into one ArrayBuffer:
   * [Encrypted Files Length (4 bytes)] | [Encrypted Files] | [Header IV (12 bytes)] |
   * [Header Length (4 bytes)] | [Encrypted Header] | [KEM Ciphertext (1568 + 16 salt)]
   */
  private encode(
    compositeCipherText: Uint8Array,
    encryptedHeader: ArrayBuffer,
    headerIV: Uint8Array,
    encryptedFileData: ArrayBuffer[]
  ): ArrayBuffer {
    const totalEncryptedFilesLength = encryptedFileData.reduce((acc, file) => acc + file.byteLength, 0);
    const encryptedFiles = new Uint8Array(totalEncryptedFilesLength);
    let fileBufferOffset = 0;
    for (const file of encryptedFileData) {
      encryptedFiles.set(new Uint8Array(file), fileBufferOffset);
      fileBufferOffset += file.byteLength;
    }

    const encryptedFilesLenBuffer = new Uint32Array([totalEncryptedFilesLength]);
    const maxHeaderSize = 10 * 1024 * 1024; // 10 MB maximum.
    const headerLen = encryptedHeader.byteLength;
    if (headerLen === 0 || headerLen > maxHeaderSize) {
      throw new Error(`Invalid header length: ${headerLen} bytes.`);
    }
    const headerLenBuffer = new Uint32Array([headerLen]);

    const finalSize =
      4 + // Encrypted Files Length field
      totalEncryptedFilesLength +
      headerIV.byteLength +
      headerLenBuffer.byteLength +
      encryptedHeader.byteLength +
      compositeCipherText.byteLength;

    const finalBuffer = new Uint8Array(finalSize);
    let offset = 0;

    finalBuffer.set(new Uint8Array(encryptedFilesLenBuffer.buffer), offset);
    offset += 4;
    finalBuffer.set(encryptedFiles, offset);
    offset += totalEncryptedFilesLength;
    finalBuffer.set(headerIV, offset);
    offset += headerIV.byteLength;
    finalBuffer.set(new Uint8Array(headerLenBuffer.buffer), offset);
    offset += headerLenBuffer.byteLength;
    finalBuffer.set(new Uint8Array(encryptedHeader), offset);
    offset += encryptedHeader.byteLength;
    finalBuffer.set(compositeCipherText, offset);

    return finalBuffer.buffer;
  }

  /**
   * Parses the buffer into its components.
   */
  private decode(buffer: Uint8Array): {
    encryptedFiles: Uint8Array,
    headerIV: Uint8Array,
    encryptedHeader: Uint8Array,
    kyberCiphertext: Uint8Array
  } {
    let offset = 0;
    const dataView = new DataView(buffer.buffer);

    if (buffer.byteLength < 4) {
      throw new Error("Buffer too short for encrypted files length.");
    }
    const encryptedFilesLength = dataView.getUint32(offset, true);
    offset += 4;

    if (offset + encryptedFilesLength > buffer.byteLength) {
      throw new Error("Buffer too short for encrypted files.");
    }
    const encryptedFiles = buffer.slice(offset, offset + encryptedFilesLength);
    offset += encryptedFilesLength;

    if (offset + 12 > buffer.byteLength) {
      throw new Error("Buffer too short for header IV.");
    }
    const headerIV = buffer.slice(offset, offset + 12);
    offset += 12;

    if (offset + 4 > buffer.byteLength) {
      throw new Error("Buffer too short for header length.");
    }
    const headerLen = dataView.getUint32(offset, true);
    offset += 4;

    const maxHeaderSize = 10 * 1024 * 1024; // 10 MB maximum.
    if (headerLen === 0 || headerLen > maxHeaderSize || offset + headerLen > buffer.byteLength) {
      throw new Error(`Invalid header length: ${headerLen} bytes.`);
    }
    const encryptedHeader = buffer.slice(offset, offset + headerLen);
    offset += headerLen;

    const expectedCompositeSize = 1568 + 16; // 1584 bytes for ml_kem1024
    if (offset + expectedCompositeSize !== buffer.byteLength) {
      throw new Error(
        `Invalid composite ciphertext length. Expected ${expectedCompositeSize} bytes, but got ${buffer.byteLength - offset} bytes.`
      );
    }
    const kyberCiphertext = buffer.slice(offset, offset + expectedCompositeSize);

    return { encryptedFiles, headerIV, encryptedHeader, kyberCiphertext };
  }

  /**
   * Obtains encryption keys via ml_kem1024 encapsulation.
   */
  private async getEncryptionKeys(publicKey: Uint8Array): Promise<{ aesKey: CryptoKey, compositeCipherText: Uint8Array }> {
    const { sharedSecret, cipherText } = ml_kem1024.encapsulate(publicKey);

    const baseKey = await crypto.subtle.importKey(
      "raw",
      sharedSecret,
      { name: "HKDF" },
      false,
      ["deriveKey"]
    );

    const salt = crypto.getRandomValues(new Uint8Array(16)); // 128-bit salt
    const info = new TextEncoder().encode("AES-GCM key derivation");

    const aesKey = await crypto.subtle.deriveKey(
      {
        name: "HKDF",
        hash: "SHA-256",
        salt: salt,
        info: info,
      },
      baseKey,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );

    // Prepend the salt to the KEM ciphertext.
    const compositeCipherText = new Uint8Array(salt.byteLength + cipherText.byteLength);
    compositeCipherText.set(salt, 0);
    compositeCipherText.set(cipherText, salt.byteLength);

    return { aesKey, compositeCipherText };
  }

  /**
   * Derives the AES key via ml_kem1024 decapsulation.
   */
  private async getDecryptionKey(compositeCipherText: Uint8Array, privateKey: Uint8Array): Promise<CryptoKey> {
    const salt = compositeCipherText.slice(0, 16);
    const cipherText = compositeCipherText.slice(16);

    const sharedSecret = ml_kem1024.decapsulate(cipherText, privateKey);

    const baseKey = await crypto.subtle.importKey(
      "raw",
      sharedSecret,
      { name: "HKDF" },
      false,
      ["deriveKey"]
    );

    const info = new TextEncoder().encode("AES-GCM key derivation");

    const aesKey = await crypto.subtle.deriveKey(
      {
        name: "HKDF",
        hash: "SHA-256",
        salt: salt,
        info: info,
      },
      baseKey,
      { name: "AES-GCM", length: 256 },
      false,
      ["encrypt", "decrypt"]
    );

    return aesKey;
  }

  /**
   * Encrypts an individual attachment.
   */
  private async buildAttachment(file: File, aesKey: CryptoKey): Promise<{ attachment: EyesAttachment, encryptedFile: ArrayBuffer }> {
    const iv = this.generateIV();
    const fileBuffer = await this.readFileAsArrayBuffer(file);
    const encryptedFile = await this.encrypt(fileBuffer, aesKey, iv);
    const attachment: EyesAttachment = {
      name: file.name,
      size: file.size,
      type: file.type,
      // Encode IV using base64url.
      iv: base64url.encode(Buffer.from(iv)),
      encryptedLen: encryptedFile.byteLength
    };
    return { attachment, encryptedFile };
  }

  /**
   * Reads a file as an ArrayBuffer.
   */
  private readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }

  /**
   * Encrypts data using AES-GCM.
   */
  private async encrypt(data: ArrayBuffer, aesKey: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    return crypto.subtle.encrypt(
      { name: "AES-GCM", iv },
      aesKey,
      data
    );
  }

  /**
   * Decrypts data using AES-GCM.
   */
  private async decrypt(encryptedData: ArrayBuffer, aesKey: CryptoKey, iv: Uint8Array): Promise<ArrayBuffer> {
    return crypto.subtle.decrypt(
      { name: "AES-GCM", iv },
      aesKey,
      encryptedData
    );
  }

  /**
   * Generates a random 12-byte IV for AES-GCM.
   */
  private generateIV(): Uint8Array {
    return crypto.getRandomValues(new Uint8Array(12));
  }
}
