// Adjustments to TransferComponent to Use Largest Unit for UI Input and Display

import { NgIf } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, ValidationErrors, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CopyTextDirective } from 'src/app/components/copyDirective/copy-text.directive';
import { HelpDirective } from 'src/app/components/help/help-directive';
import { AutocompleteDirective } from 'src/app/lib/autoCompleteDirective';
import { Console } from 'src/app/lib/console';
import { Wallet } from 'src/app/lib/wallet';
import { ErrorService, ErrorType, SafeError } from 'src/app/services/error.service';
import { SharedService } from 'src/app/services/shared.service';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/environments/environment';
import { State } from '../../state';
import { QuestionModalComponent } from '../../question-modal/question-modal.component';
import { ApiKeyPair } from 'src/app/interfaces/ApiProvider';
import { ApiKeyModalComponent } from '../ApiKey-modal/apikey-modal.component';
import { AddressType, CoinType } from 'src/app/lib/paperWallet';
const validPattern = /^\d*\.?\d*$/;
@Component({
  selector: 'app-transfer',
  standalone: true,
  imports: [ReactiveFormsModule, TranslateModule, NgIf, HelpDirective, CopyTextDirective, AutocompleteDirective, QuestionModalComponent, ApiKeyModalComponent],
  templateUrl: './transfer.component.html',
  styleUrl: './transfer.component.scss'
})
export class TransferComponent implements OnInit {
  @Input() wallet!: Wallet;
  @Input() apiKeyPair!: ApiKeyPair | null;
  @Output() response = new EventEmitter<boolean>();
  transferForm!: FormGroup;
  balance: bigint = 0n;
  signedTransaction: string = '';
  txid = '';
  fee = '';
  transferedAmount = '';
  feeUnit = '';

  constructor(
    private fb: FormBuilder,
    private walletSvc: WalletService,
    private translate: TranslateService, private errorSvc: ErrorService, private sharedSvc: SharedService) { }

  ngOnInit() {
    this.transferForm = this.fb.group({
      recipient: ['', [Validators.required, this.validateAddress.bind(this)]],
      amount: ['0', [Validators.required, this.validateAmount.bind(this)]]
    });
    this.walletSvc.getBalance(this.wallet).then((balance) => {
      Console.log('Balance:', balance);
      this.balance = balance;
    });
  }

  async addressListChanged() {
    Console.log('Address list changed, saving wallet');
    await this.sharedSvc.saveWallet(this.wallet);
  }

  onSliderChange(event: Event) {
    const target = event.target as HTMLInputElement;
    const value = this.parseBigInt(target.value);
    if (value !== null) {
      const largestUnitValue = this.wallet.convertToLargestAmount(value.toString());
      this.transferForm.patchValue({ amount: largestUnitValue });
    }
  }

  onInputChange(event: Event) {
    const target = event.target as HTMLInputElement;
    let value = target.value;

    // Validate the input value against the pattern
    if (!validPattern.test(value)) {
      // If invalid, remove the last character that caused the failure
      value = value.slice(0, -1);
      target.value = value;
    }

    // Proceed only if the value is valid at this point
    if (validPattern.test(value)) {
      const smallestUnitValue = this.wallet.convertToSmallestAmount(value);
      if (smallestUnitValue !== null && smallestUnitValue >= 0n && smallestUnitValue <= this.balance) {
        this.transferForm.patchValue({ amount: value });
      }
    }
  }

  get amount() {
    return this.transferForm.get('amount');
  }

  validateAddress(control: AbstractControl): ValidationErrors | null {
    const address = control.value;
    if (!address || typeof address !== 'string') {
      return { invalidAddress: true };
    }
    if (!this.wallet.validateAddress(address)) {
      return { invalidAddress: true };
    }
    return null;
  }

  validateAmount(control: AbstractControl): ValidationErrors | null {
    const value = control.value;
    if (typeof value !== 'string' || value.trim() === '') {
      return { required: true };
    }

    const numericValue = value.replace(/[^\d.]/g, '');
    if (numericValue !== value) {
      return { invalidFormat: true };
    }

    const parts = numericValue.split('.');
    if (parts.length > 2) {
      return { invalidFormat: true };
    }

    if (parts[1] && parts[1].length > 18) {
      return { tooManyDecimals: true };
    }

    const bigIntValue = this.wallet.convertToSmallestAmount(value);
    if (bigIntValue === null) {
      return { invalidAmount: true };
    }
    if (bigIntValue <= 0n) {
      return { amountTooSmall: true };
    }
    if (bigIntValue > this.balance) {
      return { insufficientFunds: true };
    }
    return null;
  }

  parseBigInt(value: string): bigint | null {
    try {
      return BigInt(value);
    } catch {
      return null;
    }
  }

  async generateTransaction() {
    try {
      if (this.transferForm.valid) {
        const { recipient, amount } = this.transferForm.value;
        const bigIntAmount = this.wallet.convertToSmallestAmount(amount);
        if (bigIntAmount !== null) {
          Console.log(`Transferring ${bigIntAmount.toString()} ${this.wallet.coinType} to ${recipient}`);
          const result = await this.walletSvc.generateTransaction(this.wallet, recipient, bigIntAmount);
          Console.log(result);
          this.signedTransaction = result.transaction;
          this.txid = result.txid;
          const fee = result.fee.toString();
          let converted = '';
          if (this.wallet.type == AddressType.Ethereum && this.wallet.coinType != CoinType.ETH && this.wallet.coinType != CoinType.BNB) {
            converted = this.wallet.convertToLargestAmount(fee, CoinType.ETH);
            this.feeUnit = 'ETH';
          } else {
            converted = this.wallet.convertToLargestAmount(fee);
          }
          this.fee = converted;
          this.transferedAmount = this.wallet.convertToLargestAmount(result.amount.toString());

        } else {
          this.cancel()
        }
      }
    } catch (err) {
      Console.error(err);
      this.errorSvc.process(err);
    }
  }

  cancel() {
    this.response.emit(false);
  }

  async sendTransaction() {
    try {
      const { recipient } = this.transferForm.value;
      const transactionData = this.walletSvc.generateTranseferData(this.signedTransaction, this.fee, this.transferedAmount, this.wallet, recipient, this.txid, this.apiKeyPair)

      if (this.feeUnit) {
        transactionData.fee = transactionData.fee + ' ' + this.feeUnit;
      }

      const language = this.translate.currentLang;
      transactionData.language = language;

      // Generate the transaction page URL
      let transactionPageUrl = '';
      if (environment.apiURL.toLocaleLowerCase().includes('localhost')) {
        transactionPageUrl = `https://transaction.safe.mike.dev.1two.be`;
      } else {
        const domainname = environment.envVar.DOMAIN_NAME;
        transactionPageUrl = `https://transaction.safe.${domainname}`;
      }
      Console.log('Transaction page URL:', transactionPageUrl);

      // Open new window for transaction confirmation
      let transactionWindow: Window;

      // Function to send data once the new window is ready
      const sendData = () => {
        transactionWindow!.postMessage({
          type: 'TRANSACTION_DATA',
          data: transactionData
        }, transactionPageUrl);
      };
      // Listen for the READY_FOR_DATA message from the new window
      const readyMessageHandler = (event) => {

        if (event.origin === transactionPageUrl && event.data.type === 'READY_FOR_DATA') {
          sendData();
          window.removeEventListener('message', readyMessageHandler);
        }
      };

      window.addEventListener('message', readyMessageHandler);

      // Set up listeners for transaction status updates
      const transactionStatusHandler = (event) => {
        if (event.origin === transactionPageUrl) {
          switch (event.data.type) {
            case 'TRANSACTION_CONFIRMED':
              Console.log('Transaction confirmed:', event.data.result);
              //this.showNotification('Transaction sent successfully');
              window.removeEventListener('message', transactionStatusHandler);
              setTimeout(() => {
                this.response.emit(true);
              }, 1000);
              break;
            case 'TRANSACTION_FAILED':
              Console.error('Transaction failed:', event.data.error);
              //  this.showNotification('Transaction failed: ' + event.data.error);
              window.removeEventListener('message', transactionStatusHandler);
              break;
            case 'TRANSACTION_CANCELLED':
              Console.log('Transaction cancelled');
              //  this.showNotification('Transaction cancelled');
              window.removeEventListener('message', transactionStatusHandler);
              break;
          }
          Console.log('Received message:', event.data);
        }
      };
      window.addEventListener('message', transactionStatusHandler);

      const tab = window.open(transactionPageUrl, '_blank');
      if (!tab) {
        throw new Error('Popup blocked. Please allow popups for this site.');
      }
      transactionWindow = tab;

      // Set up an interval to check if the window has been closed
      const checkWindowClosed = setInterval(() => {
        if (transactionWindow && transactionWindow.closed) {
          clearInterval(checkWindowClosed);
          setTimeout(() => {
            window.removeEventListener('message', readyMessageHandler);
            window.removeEventListener('message', transactionStatusHandler);
            Console.log('Transaction window closed, event listeners removed');
          }, 5000);
        }
      }, 1000);
    } catch (err) {
      Console.error('Failed to process transaction: ', err);
      this.showNotification('Failed to process transaction. Please try again.');
    }
  }

  async manageProvider() {
    const newkey = await this.displayApiKey();
    if (newkey) {
      this.apiKeyPair = newkey;
    }
  }

  private showNotification(message: string) {
    this.errorSvc.process(new SafeError(ErrorType.UNKNOWN, message));
  }

  // ----------------------Question Modal ------------------
  showQuestion = false;
  questionData!: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null };
  private questionResolver: { resolve: Function, reject: Function } | null = null;
  /**
  returns 'ZERO' if first button is pressed
  returns 'ONE' if second button is pressed
  returns 'TWO' if third button is pressed
  */
  public async displayQuestion(title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null): Promise<string> {

    this.questionData = { title, message, btnCancelLable, btnOneLable, btnTwoLable };
    return new Promise<string>((resolve, reject) => {
      this.questionResolver = { resolve, reject };
      this.showQuestion = true;
    });
  }

  // Called by modal from html
  async questionReturn(state: State) {
    this.showQuestion = false;
    const button = state.value;
    if (this.questionResolver) this.questionResolver.resolve(button);
    this.questionResolver = null;
  }

  // ----------------------ApiKey Modal ------------------
  showApiKey = false;
  private apiKeyResolver: { resolve: Function, reject: Function } | null = null;
  public async displayApiKey(): Promise<ApiKeyPair | null> {
    return new Promise<ApiKeyPair | null>((resolve, reject) => {
      this.apiKeyResolver = { resolve, reject };
      this.showApiKey = true;
    });
  }

  // Called by modal from html
  async apiKeyReturn(coinType: ApiKeyPair | null) {
    this.showApiKey = false;
    if (this.apiKeyResolver) this.apiKeyResolver.resolve(coinType);
    this.apiKeyResolver = null;
  }
}
