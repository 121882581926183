import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Console } from 'src/app/lib/console';
import { ApiService } from 'src/app/services/api.service';
import { CopyTextDirective } from '../../copyDirective/copy-text.directive';
import { EyesSenderService } from 'src/app/services/eyesSend.service';
import { EyesAttachment } from 'src/app/interfaces/eyesAttachment';
import { EyesAddressService } from 'src/app/services/eyesAddress.service';
import { FileSizePipe } from 'src/app/pipes/fileSize.pipe';
import { ModalComponent } from '../../modal/modal.component';
import { C2Service } from 'src/app/services/c2.service';
import { Message } from 'src/app/interfaces/eyesHeader';
import { SUB_REQUEST } from 'src/app/lib/C2/SUB_REQUEST';

@Component({
  selector: 'app-eyes-inbox',
  standalone: true,
  imports: [CommonModule, RouterModule, CopyTextDirective, FileSizePipe, ModalComponent],
  providers: [FileSizePipe],
  templateUrl: './eyes-inbox.component.html',
  styleUrl: './eyes-inbox.component.scss'
})
export class EyesInboxComponent implements OnInit, OnDestroy {

  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  newmessages: Message[] = [];
  procmessages: Message[] = [];
  userAddress = '';
  outboundMessages: { messageID: number; a: string; attachments?: string[]; }[] = [];
  pushListenr;

  constructor(private router: Router, private apiSvc: ApiService, private fileSizePipe: FileSizePipe, private c2Svc: C2Service, private eyeSenderSvc: EyesSenderService, private addressSvc: EyesAddressService) {
  }

  async ngOnInit(): Promise<void> {
    if (navigator.serviceWorker) {
      this.pushListenr = (event) => {
        Console.log('Eyes-Only Received push message:', event.data);
        this.updateMessages();
      }

      navigator.serviceWorker.addEventListener('message', this.pushListenr);
    }
    this.updateMessages();
    this.userAddress = this.apiSvc.getAppConfig().eyesAddr;
    this.apiSvc.haveNewMessages = false;
  }

  ngOnDestroy(): void {
    if (this.pushListenr) {
      navigator.serviceWorker.removeEventListener('message', this.pushListenr);
    }
  }

  private async updateMessages(): Promise<void> {
    const { newMessages, procMessages, outMessages } = await this.c2Svc.getEyesMessages();
    this.outboundMessages = outMessages;
    this.newmessages = newMessages;
    this.procmessages = procMessages;

  }

  onCompose(): void {
    this.router.navigate(['/eyes/composer']);
  }

  onOpenLocalFile(event: any): void {
    const file: File = event.target.files[0]; // Get the first selected file
    if (file) {
      this.eyeSenderSvc.setFile(file);
      this.router.navigate(['/eyes/viewer/0']);
    }
  }

  openMessage(message: Message): void {
    this.eyeSenderSvc.dropOffMessage(message);
    this.router.navigate(['/eyes/viewer', message.id]);
  }

  async deleteMessage(message: Message, event: Event) {
    event.stopPropagation();

    const response = await this.modalController.displayQuestion('Delete Message', 'Are you sure you want to delete this message?', 'Cancle', null, 'Yes');
    if (response !== 'TWO') {
      return;
    }
    await this.c2Svc.deleteEyesMessage(message.id);
    this.newmessages = this.newmessages.filter(m => m.id !== message.id);
    this.procmessages = this.procmessages.filter(m => m.id !== message.id);

  }

  async deleteOutBoundMessage(message: { messageID: number; a: string; attachments?: string[]; }) {
    await this.c2Svc.deleteEyesMessage(message.messageID);
    this.outboundMessages = this.outboundMessages.filter(m => m.messageID !== message.messageID);
  }

  async acceptMessage(message: Message) {

    let msg = `<p>Do you want to accept the message from ${message.sender}?</p>`
    if (message.size > 0) {
      const size = this.fileSizePipe.transform(message.size);
      msg += `<p>Attachments: ${size}</p>`
    }
    const response = await this.modalController.displayQuestion('Accept Message', msg, 'Cancle', null, 'Yes');
    if (response !== 'TWO') {
      return;
    }

    if (message.sender != 'Unknown') {
      let name = await this.addressSvc.getName(message.sender);
      if (!name) {
        name = await this.modalController.displayInput('Addres Name', '<p>Assigning a recognizable name to each recipient helps ensure your messages reach the right person while enhancing security.</p><p>Please enter a memorable name for this recipient:</p>', false);
        if (name) {
          // ask for push subscription for notifications
          const contact = await this.addressSvc.setName(message.sender, name);
          if (contact) {
            message.sender = name;
            const cmd = SUB_REQUEST.getCmd();
            this.c2Svc.sendCommand(cmd, contact);
          }
        }
      }
    }

    await this.c2Svc.acceptEyesMessage([message.id]);
    if (message.attachments) {
      let index = -1;
      for (const attachment of message.attachments) {
        index++;
        const archiveID = attachment.id;
        const archive = await this.apiSvc.getArchive(archiveID, true);
        if (!archive) {
          Console.error('Archive not found: ', archiveID);
          continue
        }
        const metadata = archive.m || {};
        const eyesAttachment: EyesAttachment = message.header.attachments[index];
        if (!eyesAttachment) {
          Console.error('Attachment not found: ', index);
          continue;
        }
        metadata.type = eyesAttachment.type;
        metadata.name = eyesAttachment.name;
        archive.m = metadata;
        Console.log('Updating archive metadata: ', metadata);
        message.attachments[index].m = metadata;
        await this.apiSvc.updateArchive(archive);
      }
    }

    this.newmessages = this.newmessages.filter(m => m.id !== message.id);
    this.procmessages.push(message);
    this.openMessage(message);
  }

  openConfig() {
    this.router.navigate(['/eyes/config']);
  }
}
