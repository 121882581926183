import { C2CMD } from "src/app/interfaces/C2CMD";
import { C2cmdExecutor } from "src/app/interfaces/C2cmdExecutor";
import { SafeContact } from "src/app/interfaces/safeContact";
import { Console } from "../console";
import { SUB_UPDATE } from "./SUB_UPDATE";
import { C2Service } from "src/app/services/c2.service";

export class SUB_REQUEST extends C2cmdExecutor {
    async executeCommand(cmd: C2CMD, contact: SafeContact): Promise<C2CMD | null> {
        if (contact.trustLevel !== 'Blocked') {
            if(cmd.data){
                Console.log('Received push subscription:', cmd.data);
                await this.c2Svc.eyesAddrSvc.setPushSubscription(contact.Id, cmd.data);
            }
            const subscription = await this.c2Svc.eyesAddrSvc.getPushSubscription();
            if (subscription) {
                //respond with a SUB_UPDATE command
                const cmd = SUB_UPDATE.getCmd(subscription);
              await this.c2Svc.executeCommand(cmd, contact);
            } else {
                Console.log('No push subscription available.');
            }
        }
        return null;
    }

    public static getCmd(): C2CMD {
        const ourSubscription = C2Service.instance.eyesAddrSvc.getPushSubscription();
        return {
            id: 0,
            type: 'SUB_REQUEST',
            data: ourSubscription || '',
            response: true,
        }
    }
}