import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EyesEncryptionService } from '../../../services/eyesEncryption.service';
import { EyesAddressService } from '../../../services/eyesAddress.service';
import { QuillModule } from 'ngx-quill';
import { NgFor, NgIf } from '@angular/common';
import { EyesHeader } from '../../../interfaces/eyesHeader';
import { ApiService } from 'src/app/services/api.service';
import { Console } from 'src/app/lib/console';
import base64url from 'base64url';
import { EyesSenderService } from 'src/app/services/eyesSend.service';
import { Router } from '@angular/router';
import { ModalComponent } from '../../modal/modal.component';
import { FileSizePipe } from 'src/app/pipes/fileSize.pipe';
import { EyesFile } from 'src/app/lib/eyesFile';
import { TranslateService } from '@ngx-translate/core';
import { Archive } from 'src/app/interfaces/archive';

@Component({
  selector: 'app-eyesonly-composer',
  standalone: true,
  imports: [ReactiveFormsModule, QuillModule, NgIf, NgFor, ModalComponent, FileSizePipe],
  templateUrl: './composer.component.html',
  styleUrls: ['./composer.component.scss']
})
export class EyesonlyComposerComponent implements OnInit {

  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  private isMobile = window.innerWidth < 600;
  messageForm!: FormGroup;
  recipientError: string | null = null;
  savedRecipients: string[] = [];
  recipientName: string | null = null;
  filteredRecipients: string[] = [];
  eyesFiles: EyesFile[] = [];
  quillModules = {
    toolbar: {
      container: this.isMobile ? [
        ['bold', 'italic', 'underline'],        // Simplified toolbar for mobile
        [{ 'list': 'bullet' }, { 'list': 'ordered' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      ] : [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'header': 1 }, { 'header': 2 }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        [{ 'size': ['small', false, 'large', 'huge'] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'font': [] }],
        [{ 'align': [] }],
        ['clean']
      ]
    }
  };
  loading = false;
  errorMessage: string | null = null;
  keys: { privateKeys: { key: string, id: number }[], signingKey: { key: string, id: number } | null } | null = null;
  publicKey: Uint8Array | null = null;
  keyID: number | null = null;

  constructor(
    private fb: FormBuilder,
    private encryptionService: EyesEncryptionService,
    private addressService: EyesAddressService,
    private apiSvc: ApiService,
    private eyesService: EyesSenderService,
    private router: Router,
    private translate: TranslateService,
  ) {

  }

  async ngOnInit(): Promise<void> {
    this.messageForm = this.fb.group({
      recipient: ['', Validators.required],
      subject: ['', Validators.required],
      message: ['', Validators.required],
    });

    await this.loadSavedAddresses();
  }


  /**
   * Verifies a manually entered address.
   */
  async verifyRecipient(address: string) {
    if (!address) return;
    try {
      const publicKey = await this.addressService.getPublicKey(address);
      if (publicKey) {
        this.publicKey = publicKey;
        this.recipientError = null;

        // Check if the address already has a saved name
        const savedName = await this.addressService.getName(address);
        if (savedName) {
          this.recipientName = savedName;
          this.messageForm.get('recipient')!.setValue(address);
        } else {
          // Ask the user for a name for this new recipient
          const newName = await this.modalController.displayInput('Addres Name', '<p>Assigning a recognizable name to each recipient helps ensure your messages reach the right person while enhancing security.</p><p>Please enter a memorable name for this recipient:</p>', false);
          if (newName) {
            this.recipientName = newName;
            await this.addressService.setName(address, newName);
            this.loadSavedAddresses();
            this.messageForm.get('recipient')!.setValue(newName);
          }
        }
      } else {
        this.publicKey = null;
        this.recipientError = 'Invalid recipient address';
      }
    } catch (error) {
      this.publicKey = null;
      this.recipientError = 'Address verification failed';
    }
  }

  /**
 * Allows editing the recipient's name.
 */
  async editRecipientName() {
    const recipient = this.messageForm.get('recipient')!.value;
    if (!recipient) return;

    const newName = await this.modalController.displayInput('Address Name', '<p>Please enter a new name for this recipient:</p>', false);
    if (newName) {
      this.recipientName = newName;
      await this.addressService.setName(recipient, newName);
      this.loadSavedAddresses();
      this.messageForm.get('recipient')!.setValue(newName);
    }
  }

  async deleteAddress() {
    const recipient = this.messageForm.get('recipient')!.value;
    if (!recipient) return;
    await this.addressService.deleteContact(recipient);
    this.loadSavedAddresses();
    this.messageForm.get('recipient')!.setValue('');
  }


  /**
  * Fetch saved addresses and update the list.
  */
  async loadSavedAddresses() {
    try {
      this.savedRecipients = await this.addressService.getContactNames();
      this.filteredRecipients = [...this.savedRecipients];
      Console.log('loadSavedAddresses Saved addresses:', this.savedRecipients);

    } catch (error) {
      console.error('Error fetching saved addresses:', error);
    }
  }

  /**
   * Filters recipient suggestions based on input.
   */
  filterRecipients(event: any) {
    const value = event.target.value.toLowerCase();
    Console.log('Filtering recipients pre:', value);
    this.filteredRecipients = this.savedRecipients.filter(address =>
      address.toLowerCase().includes(value)
    );

    Console.log('Filtered recipients post:', this.filteredRecipients);
  }
  /**
    * Triggers validation when the recipient field loses focus.
    */
  async onRecipientBlur() {
    const recipientValue = this.messageForm.get('recipient')!.value;
    await this.verifyRecipient(recipientValue);
  }

  /**
   * Handles "Enter" key event in the recipient input.
   */
  async onRecipientEnter(event: Event) {
    event.preventDefault();
    if (event instanceof KeyboardEvent && event.key === 'Enter') {
      await this.onRecipientBlur();
    }
  }

  /**
   * fetch the public key.
   * Sets recipientError if no public key is found.
   */
  private async loadPublicKey(): Promise<void> {
    let recipient = this.messageForm.get('recipient')?.value;
    const addrrecipient = await this.addressService.getAddress(recipient);
    if (addrrecipient) {
      recipient = addrrecipient;
    }
    Console.log('Recipient:', recipient);
    if (recipient) {
      try {
        const publicKey = await this.addressService.getPublicKey(recipient);
        if (!publicKey) {
          this.recipientError = 'Recipient address not found.';
          this.publicKey = null;
        } else {
          this.recipientError = null;
          this.publicKey = publicKey;
        }
      } catch (error) {
        console.error('Error fetching public key:', error);
        this.recipientError = 'Error fetching recipient public key.';
      }
    } else {
      this.recipientError = 'Public Key not found';
    }
  }

  /**
   * Handle file selection event.
   */
  onFileChange(event: Event): void {
    Console.log('File selected:', event);
    const input = event.target as HTMLInputElement;
    if (input.files) {
      for (let i = 0; i < input.files.length; i++) {
        const file = input.files.item(i);
        if (file) {
          this.eyesFiles.push(EyesFile.fromFile(file));
        }
      }
    }
  }

  async addArchive(): Promise<void> {
    const replay = await this.modalController.displayQuestion('Add Archive', 'Local File or Safe Archive?', '', 'File', 'Archive');
    if (replay === 'ONE') {
      await this.addLocalFile();
    } else {
      await this.addSafeArchive();
    }
  }

  private async addLocalFile(): Promise<void> {
    try {
      const files = await this.openFileDialog();
      for (const file of files) {
        this.eyesFiles.push(EyesFile.fromFile(file));
      }
    } catch (error) {
      Console.error('Error selecting file:', error);
    }
  }

  private async addSafeArchive(): Promise<void> {
    //Create list of archivs that do not have a record attached
    const all = await this.apiSvc.getArchives();
    const archives = all.filter(x => x.t != 'Records');
    Console.log(archives);
    // turn it into a list of Items for the list-select modal
    const items = archives.map(x => ({ label: `${x.m.name} ${x.m.type}`, object: x }));
    const selected = await this.modalController.displayListSelect(this.translate.instant('RECORDS.FILE.SELECT.TITLE'), this.translate.instant('RECORDS.FILE.SELECT.MSG'), this.translate.instant('CANCEL'), this.translate.instant('RECORDS.FILE.SELECT.SELECT'), items);
    if (selected) {
      const archive = selected.object as Archive;
      if (!this.eyesFiles.find(x => x.name == archive.m.name)) {
        this.eyesFiles.push(EyesFile.fromArchive(archive));
      }
    }
  }

  private openFileDialog(accept = "*", multiple = false): Promise<File[]> {
    return new Promise((resolve, reject) => {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = accept; // e.g., "image/*" for images, "application/pdf" for PDFs
      input.multiple = multiple;
      input.style.display = "none";

      input.addEventListener("change", () => {
        if (input.files && input.files.length > 0) {
          resolve(Array.from(input.files));
        } else {
          reject(new Error("No file selected"));
        }
      });

      document.body.appendChild(input);
      input.click();
      document.body.removeChild(input);
    });
  }

  /**
   * Remove an attachment by its index.
   */
  removeAttachment(index: number): void {
    if (index >= 0 && index < this.eyesFiles.length) {
      this.eyesFiles.splice(index, 1);
    }
  }

  /**
   * Called when the user clicks "Save Message".
   */
  async saveMessage(): Promise<void> {
    Console.log('Saving message...');

    if (this.messageForm.invalid || this.recipientError) {
      return;
    }

    this.loading = true;
    this.errorMessage = null;
    await this.loadPublicKey();
    const { subject, message } = this.messageForm.value;

    try {
      if (!this.publicKey) {
        throw new Error('Recipient public key not found.');
      }

      // Build header object based on your internal header format.
      const header: EyesHeader = {
        subject: subject,
        message: message,
        sender: this.apiSvc.getAppConfig().eyesAddr,
        timestamp: Date.now(),
        attachments: []
      };


      let signingKey: Uint8Array | undefined;
      let signingkeyid: number | undefined;
      const keys = await this.apiSvc.getEyesPrivateKeys();
      if (keys?.signingKey) {
        signingKey = new Uint8Array(base64url.toBuffer(keys.signingKey.key));
        signingkeyid = keys.signingKey.id;

        Console.log('Signing message');
      }

      // Seal (encrypt) the message payload.
      const encryptedPayload = await this.encryptionService.seal(header, this.eyesFiles, this.publicKey, signingKey, signingkeyid);

      // Reset form and attachments on success.
      this.messageForm.reset();
      this.eyesFiles = [];
      this.publicKey = null;
      this.downloadArrayBuffer(encryptedPayload, 'message.bin');
    } catch (error) {
      console.error('Encryption error:', error);
      this.errorMessage = (error as Error).message;
    } finally {
      this.loading = false;
    }
  }

  downloadArrayBuffer(buffer: ArrayBuffer, filename: string, mimeType = 'application/octet-stream'): void {
    Console.log('Downloading file:', filename);
    // Wrap the ArrayBuffer in a Blob.
    const blob = new Blob([buffer], { type: mimeType });

    // Create an object URL for the Blob.
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger the download.
    const a = document.createElement('a');
    a.href = url;
    a.download = filename; // This sets the filename for the download.

    // Append the anchor to the DOM (required for Firefox).
    document.body.appendChild(a);

    // Programmatically click the anchor to trigger the download.
    a.click();

    // Cleanup: remove the anchor and revoke the object URL.
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }


  /**
   * Encrypts headder and attachments individually and upload them as archives of type MSG. Each archive has the recipient address and mesage ID
   * @returns
   */
  async sendMessage(): Promise<void> {
    Console.log('Sending message...');
    // Ensure the recipient has been validated.
    await this.loadPublicKey();
    if (this.messageForm.invalid || this.recipientError) {
      return;
    }

    this.loading = true;
    this.errorMessage = null;

    const { subject, message } = this.messageForm.value;

    try {
      if (!this.publicKey) {
        throw new Error('Recipient public key not found.');
      }
      this.modalController.displaySpinner(true);
      // Build header object based on your internal header format.
      const clearheader: EyesHeader = {
        subject: subject,
        message: message,
        sender: this.apiSvc.getAppConfig().eyesAddr,
        timestamp: Date.now(),
        attachments: []
      };

      let signingKey: Uint8Array | undefined;
      let signingkeyid: number | undefined;
      const keys = await this.apiSvc.getEyesPrivateKeys();

      Console.log('got private Keys:', keys);
      if (keys?.signingKey) {
        signingKey = new Uint8Array(base64url.toBuffer(keys.signingKey.key));
        signingkeyid = keys.signingKey.id;

        Console.log('Signing message');
      }
      Console.log('Public key:', this.publicKey);
      Console.log('Signing key:', signingKey);
      Console.log('Signing key ID:', signingkeyid);

      // Seal (encrypt) the headder and attachments
      const { header, keyData } = await this.encryptionService.sealSeparate(clearheader, this.eyesFiles, this.publicKey, signingKey, signingkeyid);
      //create three archives one for the header and one for each attachment

      let recipient = this.messageForm.get('recipient')?.value;
      const raddr = await this.addressService.getAddress(recipient);
      if (raddr) {
        recipient = raddr;
      }
      const ProgressEvents = await this.eyesService.sendMessage(header, clearheader, this.eyesFiles, recipient, { aesKey: keyData.aesKey, b64CompositeCipherText: keyData.b64CompositeCipherText });
      await this.waitForEmitterCompletion(ProgressEvents);
      // Reset form and attachments on success.
      this.messageForm.reset();
      this.eyesFiles = [];
      this.publicKey = null;
      this.router.navigate(['/eyes/inbox']);
    } catch (error) {
      console.error('Encryption error:', error);
      this.errorMessage = (error as Error).message;
    } finally {
      this.loading = false;
      this.modalController.displaySpinner(false);
    }
  }

  private async waitForEmitterCompletion(
    emitter: EventEmitter<{
      size?: number;
      transferred: number;
      attachmentNumber?: number;
      stage: "READING" | "WRITING" | "ENCRYPTING" | "HEADER" | "ATTACHMENT" | "COMPLETED" | "ERROR";
      error?: any;
    }>): Promise<void> {
    await this.modalController.displaytransferProgress(emitter);
  }
}
