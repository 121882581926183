import { Injectable } from '@angular/core';
import { Console } from '../lib/console';

@Injectable({
  providedIn: 'root'
})

/**
 * Service to extract and store parameters from the initial URL as key value pairs
 * pid is partner id
 * eg: /param1/value1/param2/value2
 */
export class InitializationService {
  private params: { [key: string]: string } = {};

  constructor() {
    this.extractParams();
  }

  private extractParams(): void {
    Console.log('Extracting params from URL', window.location.pathname);
    const pathSegments = window.location.pathname.split('/').filter(segment => segment);
    if (pathSegments.length == 1) {
      //assume pid "Partener ID" is the first segment when only one segment is present
      if(this.isValid(pathSegments[0])){
      this.params['pid'] = pathSegments[0];
      } else {
        Console.error('Invalid partner id', pathSegments[0]);
      }
    } else {
      for (let i = 0; i < pathSegments.length; i += 2) {
        if (i + 1 < pathSegments.length) {
          const key = decodeURIComponent(pathSegments[i]);
          const value = decodeURIComponent(pathSegments[i + 1]);
          if (this.isValid(key) && this.isValid(value)) {
            this.params[key] = value;
          } else {
            Console.error('Invalid key or value', key, value);
          }
        }
      }
    }
    Console.log('Extracted params', this.params);
  }

  private isValid(str: string): boolean {
    const pattern = /^[a-zA-Z0-9]+$/;
    return pattern.test(str);
  }


  getParam(key: string): string {
    return this.params[key];
  }

  getAllParams(): { [key: string]: string } {
    return this.params;
  }
}
