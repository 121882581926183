import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { NgFor, NgIf } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Archive } from 'src/app/interfaces/archive';
import { db } from 'src/app/db/json';

@Component({
  selector: 'app-space-selector',
  templateUrl: './space-selector.component.html',
  styleUrls: ['./space-selector.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, FormsModule, TranslateModule]
})
export class SpaceSelectorComponent implements OnInit {
  @Output() response = new EventEmitter<number[] | null>();
  @Input() currentSpaces: number[] = [];
  @Input() message: { title: string; message: string } | null = null;
  @Input() limit = false;
  hasSelectedSpaces = false;
  allSelected = false;
  spaces: Array<Archive & { name: string }> = [];
  selectedSpaces: { [sid: number]: boolean } = {};
  constructor(private db: db, private translate: TranslateService) { }

  ngOnInit(): void {
    this.spaces = this.db.spaces.map((archive, index) => ({
      ...archive,
      name: archive.m?.spaceName || `Space ${index + 1}`
    }));
    this.resetSelection();
  }

  selectAll(): void {
    this.spaces.forEach(space => {
      this.selectedSpaces[space.sid] = true;
    });
    this.hasSelectedSpaces = true;
    this.allSelected = true;
  }

  deselectAll(): void {
    this.spaces.forEach(space => {
      this.selectedSpaces[space.sid] = false;
    });
    this.hasSelectedSpaces = false;
    this.allSelected = false;
  }

  toggleSpace(sid: number): void {
    this.selectedSpaces[sid] = !this.selectedSpaces[sid];
    this.hasSelectedSpaces = Object.values(this.selectedSpaces).some(selected => selected);
    if (this.hasSelectedSpaces) {
      this.allSelected = Object.values(this.selectedSpaces).every(selected => selected);
    }
  }

  resetSelection(): void {
    if (this.currentSpaces.length > 0) {
      this.spaces.forEach(space => {
        if (this.currentSpaces.includes(space.sid)) {
          this.selectedSpaces[space.sid] = true;
        } else {
          this.selectedSpaces[space.sid] = false;
        }
      });
      this.hasSelectedSpaces = true;
    } else {
      this.spaces.forEach(space => {
        this.selectedSpaces[space.sid] = false;
      });
      this.hasSelectedSpaces = false;
    }
    if (this.hasSelectedSpaces) {
      this.allSelected = Object.values(this.selectedSpaces).every(selected => selected);
    }
  }

  confirm(): void {
    const selectedSids = this.spaces
      .filter(space => this.selectedSpaces[space.sid])
      .map(space => space.sid);
    if (this.limit && selectedSids.length == this.spaces.length) {
      this.response.emit(null);
    } else {
      this.response.emit(selectedSids);
    }
  }

  cancel(): void {
    this.response.emit(null);
  }
}
