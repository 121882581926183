<div class="key-space-manager">
  <div class="space-item allkeys">
    <h3>{{'APP_CONFIG.SPACES.ALL_SPACES' | translate}}</h3>
    <div class="key-list">
      <div *ngFor="let key of allSpacesKeys" class="key-item">
        <label>
          <span class="key-name">{{ key.name || key.accessID }}</span>
          <span (click)="keyAdmin(key)" class="key-type" [class.read-only]="key.mode==='ro'" [class.admin]="key.mode==='admin'">
            {{ getKeyType(key) | translate }}
          </span>
        </label>
      </div>
    </div>
  </div>
  <div class="space-list">
    <div *ngFor="let space of spaces" class="space-item">
      <h3>{{ space.name }}</h3>
      <div class="key-list">
        <div *ngFor="let key of allKeys" class="key-item">
          <label>
            <input type="checkbox" [checked]="isKeyInSpace(space, key)" (change)="toggleKey(space, key)">
            <span class="key-name">{{ key.name || key.accessID }}</span>
            <span (click)="keyAdmin(key)" class="key-type" [class.read-only]="key.mode==='ro'" [class.admin]="key.mode==='admin'">
              {{ getKeyType(key) | translate }}
            </span>
          </label>
        </div>
      </div>
      <button class="btn-danger" (click)="deleteSpace(space)">{{'APP_CONFIG.SPACES.DELETE_BTN' | translate}}</button>
    </div>
  </div>
  <div class="button-container">
    <button class="btn-primary" (click)="close()">{{'CLOSE' | translate}}</button>
  </div>
</div>
<app-modals></app-modals>
