import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { QrCodeComponent } from 'src/app/components/qrcode/qrcode.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorService, ErrorType, SafeError } from 'src/app/services/error.service';
import { NgClass, NgFor, NgIf } from '@angular/common';
import { Console } from 'src/app/lib/console';
import { Wallet } from 'src/app/lib/wallet';
import { HelpDirective } from 'src/app/components/help/help-directive';
import { QuestionModalComponent } from '../question-modal/question-modal.component';
import { State } from '../state';
import { ApiService } from 'src/app/services/api.service';
import { WalletService } from 'src/app/services/wallet.service';
import { AddressType, CoinType } from 'src/app/lib/paperWallet';
import { CoinSelectorModalComponent } from "./coin-selector-modal/coin-selector-modal.component";
import { SharedService } from 'src/app/services/shared.service';
import { InputModalComponent } from "../input-modal/input-modal.component";
import { PrintService } from 'src/app/services/print.service';
import { CryptoUnitConverter } from 'src/app/lib/cryptoUnitConverter';
import { CryptoTokenData } from 'src/app/interfaces/cryptoTokenData';
import { TokenBalanceListComponent } from './token-balance-list/token-balance-list.component';
import { SpinnerService } from 'src/app/services/spinner.service';
import { NetworkService } from 'src/app/services/network.service';
import { CopyTextDirective } from 'src/app/components/copyDirective/copy-text.directive';

@Component({
  selector: 'wallet-modal',
  templateUrl: './wallet.component.html',
  styleUrls: ['./wallet.component.scss'],
  standalone: true,
  imports: [QrCodeComponent, TranslateModule, NgIf, NgFor, NgClass, HelpDirective, QuestionModalComponent, CoinSelectorModalComponent, InputModalComponent, CopyTextDirective, TokenBalanceListComponent]
})

export class WalletModalComponent implements AfterViewInit {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<Wallet | null>();
  @Output() done = new EventEmitter<void>();
  @ViewChild('questionemodal') questionComponent!: QuestionModalComponent;
  @Input() data!: { wallet: Wallet | null };

  address: string | undefined = '';
  imageURL = '';
  smallImageURL = '';
  title = '';
  showMnemonic = false;
  btnText = '';
  readonly = false;
  private showAddressText = '';
  private showMneminicText = '';
  private addressText = '';
  private mnemonicText = '';
  balance = '';
  tokens: CryptoTokenData[] = [];
  wallet!: Wallet;
  message: string | undefined;
  clipboardSupported = navigator.clipboard ? true : false;
  mnemonicWords: string[] = [];
  loaded = false;

  constructor(private errorSvc: ErrorService, private translate: TranslateService, private apiSvc: ApiService, private walletSvc: WalletService, private sharedSvc: SharedService, private printservice: PrintService, private spinnerSvc: SpinnerService, private networkSvc: NetworkService) {
    this.showAddressText = this.translate.instant('MODALS.WALLET.BTN_ADDRESS');
    this.readonly = this.apiSvc.isReadOnly();
    this.showMneminicText = this.translate.instant('MODALS.WALLET.BTN_PRIVATE');
    this.btnText = this.showMneminicText;
    Console.log('WalletModalComponent created')
  }

  async ngAfterViewInit(): Promise<void> {
    try {
      if (!this.data.wallet) {
        const coinType = await this.displayCoinSelector();
        if (!coinType) {
          this.done.emit();
          return;
        }

        this.wallet = await this.createWallet(coinType);

        let message = this.translate.instant('MODALS.WALLET.NEW.MSG');
        await this.displayQuestion(this.translate.instant('MODALS.WALLET.NEW.TITLE'), message, null, null, this.translate.instant('OK'));
      } else {
        this.wallet = Wallet.fromJSON(this.data.wallet);
      }
      this.addressText = this.translate.instant('MODALS.WALLET.ADDRESS', { type: this.wallet?.coinType });
      this.title = this.addressText;
      this.mnemonicText = this.translate.instant('MODALS.WALLET.PRIVATE_KEY', { type: this.wallet?.coinType });
      this.address = this.wallet.address;
      this.imageURL = this.wallet.getIcon();
      this.smallImageURL = this.wallet.getSmallIcon();

      if (this.wallet.type === AddressType.Ethereum) {
        this.message = this.translate.instant('MODALS.WALLET.ETH_MSG');
      } else if (this.wallet.type === AddressType.Solana) {
        this.message = this.translate.instant('MODALS.WALLET.SOL_MSG');
      }

      this.data.wallet = Wallet.toJSON(this.wallet);
      this.response.emit(this.data.wallet);
      this.loaded = true;
    } catch (err: any) {
      Console.error(err);
      const errormsg = err.message || "Unkown";
      this.errorSvc.process(new SafeError(ErrorType.UNKNOWN, errormsg));
      this.response.emit(null);
    }
  }

  private async createWallet(coinType): Promise<Wallet> {

    const currentWallets = this.sharedSvc.getWallets();
    if (currentWallets.some(wallet => wallet.coinType === coinType)) {
      throw new Error('Cointype already exists');
    }
    let wallet: Wallet;
    const isEthereum = CoinType.getAddressType(coinType) === AddressType.Ethereum;
    if (isEthereum) {
      // get the first wllet with  wallet.type === AddressType.Ethereum
      const ethWallet = currentWallets.find(wallet => wallet.type === AddressType.Ethereum);
      if (ethWallet) {
        Console.log('Using existing Ethereum wallet');
        wallet = Wallet.fromJSON(ethWallet);
        wallet.coinType = coinType;
      } else {
        Console.log('Creating new Ethereum wallet');
        wallet = await this.walletSvc.createWallet(coinType);
      }
    } else {
      Console.log('Creating new non etherium wallet');
      wallet = await this.walletSvc.createWallet(coinType);
    }
    return wallet;
  }

  async next() {
    this.address = undefined;
    this.mnemonicWords.length = 0;
    if (! await this.networkSvc.onlineByFetch()) {
      await this.displayQuestion(this.translate.instant('MODALS.WALLET.OFFLINE.TITLE'), this.translate.instant('MODALS.WALLET.OFFLINE.MSG'), null, null, this.translate.instant('OK'));
    }
    if(this.showMnemonic) {
      location.reload();
    }
    this.done.emit();
  }

  async print() {
    this.printservice.print('print-container');
  }

  async toggle() {

    this.mnemonicWords.length = 0;
    this.address = undefined;
    if (!await this.networkSvc.onlineByFetch()) {
      await this.displayQuestion(this.translate.instant('MODALS.WALLET.OFFLINE.TITLE'), this.translate.instant('MODALS.WALLET.OFFLINE.MSG'), null, null, this.translate.instant('OK'));
    }
    if (!this.showMnemonic) {
      const result = await this.displayQuestion(this.translate.instant('MODALS.WALLET.SHOW_PRIVATE.TITLE'), this.translate.instant('MODALS.WALLET.SHOW_PRIVATE.MSG', { type: this.wallet?.type }), null, this.translate.instant('MODALS.WALLET.SHOW_PRIVATE.SHOW'), this.translate.instant('CANCEL'));
      if (result != 'ONE') {
        return;
      }
    }
    try {
      this.showMnemonic = !this.showMnemonic;
      this.btnText = this.showMnemonic ? this.showAddressText : this.showMneminicText;
      this.title = this.showMnemonic ? this.mnemonicText : this.addressText;
      let addr = '';
      if (this.showMnemonic) {
        const resp = await this.displayQuestion(this.translate.instant('MODALS.WALLET.HALF.TITLE'), this.translate.instant('MODALS.WALLET.HALF.MSG'), this.translate.instant('CANCEL'), this.translate.instant('MODALS.WALLET.HALF.FIRST'), this.translate.instant('MODALS.WALLET.HALF.SECOND'));
        Console.log('resp:', resp);
        let half: 'FIRST' | 'SECOND' = 'FIRST';
        if (resp === 'ONE') {
          half = 'FIRST';
        } else if (resp === 'TWO') {
          half = 'SECOND';
        } else {
          this.toggle();
          return;
        }

        // addr = await this.walletSvc.getWalletPrivateKey(this.wallet);
        const encryptedHalfPromis = this.walletSvc.getMnemonic(this.wallet, half);
        let online = await this.networkSvc.onlineByFetch();
        let message = this.translate.instant('MODALS.ACCESS_ID.REAUTHZ') + this.translate.instant('MODALS.WALLET.GO_OFFLINE.MSG1');
        let retry = 5;
        while (online) {
          const offline = await this.displayQuestion(this.translate.instant('MODALS.WALLET.GO_OFFLINE.TITLE'), message, this.translate.instant('CANCEL'), null, this.translate.instant('MODALS.WALLET.GO_OFFLINE.BTN'));
          if (offline === 'ZERO') {
            this.toggle();
            return
          }
          message = this.translate.instant('MODALS.WALLET.GO_OFFLINE.MSG1');
          this.spinnerSvc.showSpinner(true);
          online = await this.networkSvc.onlineByFetch();
          this.spinnerSvc.showSpinner(false);
          if (online) {
            if (!retry--) {
              const skip = await this.displayQuestion(this.translate.instant('MODALS.WALLET.SKIP_OFFLINE.TITLE'), this.translate.instant('MODALS.WALLET.SKIP_OFFLINE.MSG'), this.translate.instant('CANCEL'), null, this.translate.instant('MODALS.WALLET.SKIP_OFFLINE.TITLE'));
              if (skip === 'ZERO') {
                this.toggle();
                return;
              } else {
                online = false;
              }
            } else {
              await this.displayQuestion(this.translate.instant('MODALS.WALLET.GO_OFFLINE.TITLE'), this.translate.instant('MODALS.WALLET.GO_OFFLINE.MSG2'), null, null, this.translate.instant('OK'));
            }
          }
        }
        try {
          const data = await encryptedHalfPromis;
          Console.log('data:', data);
          this.mnemonicWords = data.split(' ');
        } catch (err) {
          await this.displayQuestion(this.translate.instant('MODALS.WALLET.ERROR.TITLE'), this.translate.instant('MODALS.WALLET.ERROR.MSG'), null, null, this.translate.instant('OK'));
          this.toggle();
          return;
        }
      } else {
        addr = this.wallet.address;
      }
      this.address = addr;
    } catch (err: any) {
      Console.error(err);
      if (this.showMnemonic) {
        this.toggle();

      }
    } finally {
      this.showQuestion = false;
    }
  }


  // ----------------------CoinSelector Modal ------------------
  showCoinSelector = false;
  private selectCoinResolver: { resolve: Function, reject: Function } | null = null;
  public async displayCoinSelector(): Promise<CoinType | null> {
    return new Promise<CoinType | null>((resolve, reject) => {
      this.selectCoinResolver = { resolve, reject };
      this.showCoinSelector = true;
    });
  }

  // Called by modal from html
  async coinSelectorReturn(coinType: CoinType | null) {
    this.showCoinSelector = false;
    if (this.selectCoinResolver) this.selectCoinResolver.resolve(coinType);
    this.selectCoinResolver = null;
  }

  // ----------------------Question Modal ------------------
  showQuestion = false;
  questionData!: { title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null };
  private questionResolver: { resolve: Function, reject: Function } | null = null;
  /**
  returns 'ZERO' if first button is pressed
  returns 'ONE' if second button is pressed
  returns 'TWO' if third button is pressed
  */
  public async displayQuestion(title: string, message: string, btnCancelLable: string | null, btnOneLable: string | null, btnTwoLable: string | null): Promise<string> {

    this.questionData = { title, message, btnCancelLable, btnOneLable, btnTwoLable };
    return new Promise<string>((resolve, reject) => {
      this.questionResolver = { resolve, reject };
      this.showQuestion = true;
    });
  }

  // Called by modal from html
  async questionReturn(state: State) {
    this.showQuestion = false;
    const button = state.value;
    if (this.questionResolver) this.questionResolver.resolve(button);
    this.questionResolver = null;
  }

  //-------------- Display Input --------------------------------

  showInput = false;
  inputData!: { title: string, message: string, isPasswd: boolean }
  private inputResolver: { resolve: Function, reject: Function } | null = null;
  public async displayInput(title: string, message: string, isPasswd = true): Promise<string> {
    this.inputData = { title, message, isPasswd };
    return new Promise<string>((resolve, reject) => {
      this.inputResolver = { resolve, reject };
      this.showInput = true;
    });
  }

  // Called by modal from html
  async inputReturn(state: State) {
    this.showInput = false;
    if (this.inputResolver) this.inputResolver.resolve(state.value);
    this.inputResolver = null;
  }

  //----------------------------------------------------
  async getbalance() {
    //set busy cursor while waiting for the balance
    document.body.style.cursor = 'wait';
    this.spinnerSvc.showSpinner(true);
    try {
      const data = await this.walletSvc.getBalance(this.wallet);
      this.tokens.push(...data.tokens);
      const bl = BigInt(data.balance);
      Console.log('Balance:', bl);
      if (bl == 0n) {
        this.balance = '0';
      } else {
        this.balance = CryptoUnitConverter.toLargestUnit(this.wallet.coinType, bl) + ' ' + this.wallet.coinType;
      }
    } finally {
      document.body.style.cursor = 'default';
      this.spinnerSvc.showSpinner(false);
    }
  }

  tokenBalanceListReturn() {
    this.tokens.length = 0;
  }
}

