import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core'; import { State } from '../state';
import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'keypad-modal',
  templateUrl: './keypad-modal.component.html',
  styleUrls: ['./keypad-modal.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgStyle]
})
export class KeypadModalComponent implements AfterViewInit {
  private posClicks: { x: number, y: number }[] = [];

  @ViewChild('imageElement', { static: true }) imageElement!: ElementRef<HTMLImageElement>;
  @ViewChild('pinElement', { static: true }) pinElement!: ElementRef<HTMLImageElement>;
  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLInputElement>
  @Output() response = new EventEmitter<State>();
  @Input() data!: { title: string, padImage: string, codeImage: string | null };

  clearBtnTxt = this.translate.instant('AUTH.PAD.CLEAR');
  sendBtnTxt = this.translate.instant('NEXT');
  cancelBtnTxt = this.translate.instant('CANCEL');
  clicks: { x: number, y: number }[] = [];

  constructor(private translate: TranslateService) {
  }

  ngAfterViewInit(): void {
    if (!this.data.codeImage) {
      //hide the pin image if entering pin
      document.getElementById('pinContainer')!.addEventListener('touchstart', function () {
        Console.log(this.style.filter);
        if (this.style.filter ===  "blur(0px)") { //toggle
           this.style.filter="blur(4px)"; // Make it invisible
        } else {
          this.style.filter="blur(0px)"; // Make it visible
        }
        setTimeout(() => {
          this.style.filter="blur(4px)";
        }, 4000);
      }, false);
    } else {
      //entering captcha, show the pin image
      const element = document.getElementById('pinContainer')!;
      element.style.filter="blur(0px)"; // Make it visible
    }
  }

  onPadClick(event: MouseEvent) {
    const x = event.offsetX;
    const y = event.offsetY;
    this.clicks.push({ x, y });
    const pos = this.getpos(x, y);
    this.posClicks.push(pos);
    this.updatePinImage();
  }

  private updatePinImage() {

    const destCanvas = document.createElement('canvas');

    const srcWidth = this.imageElement.nativeElement.naturalWidth / 4;
    const srcHeight = this.imageElement.nativeElement.naturalHeight / 4;

    const destWidth = (srcWidth / 2) * (this.posClicks.length ? this.posClicks.length : 1);
    const destHeight = srcHeight / 2;

    destCanvas.width = destWidth
    destCanvas.height = destHeight;
    const destCtx = destCanvas.getContext('2d');
    for (let index = 0; index < this.posClicks.length; index++) {
      const click = this.posClicks[index]
      destCtx?.drawImage(this.imageElement.nativeElement, click.x * srcWidth, click.y * srcHeight, srcWidth, srcHeight, index * srcWidth / 2, 0, srcWidth / 2, srcHeight / 2);
    }
    if (this.posClicks.length == 0) {
      destCtx?.fillRect(1, 1, 1, 1);
    }
    const imageSrc = destCanvas.toDataURL();
    this.pinElement.nativeElement.src = imageSrc;
    destCanvas.remove();
  }

  private getpos(cx: number, cy: number) {
    // calculate the x and y position of the clicked box
    const x = (cx / (this.imageElement.nativeElement.width / 4)) | 0;
    const y = (cy / (this.imageElement.nativeElement.height / 4)) | 0;
    return { x, y };
  }

  clear() {
    let pos = this.posClicks.length >0 ? this.posClicks.length -1 : 0;
    this.posClicks.length = pos;
    this.clicks.length = pos;
    this.updatePinImage();
  }

  send() {
    const clicks = this.clicks;
    this.cleanup();
    this.response.emit({ value: clicks });
  }

  cancel() {
    this.cleanup();
    this.response.emit({ value: null });
  }

  private cleanup() {
    this.clicks = [];
    this.pinElement.nativeElement.src = '';
    this.posClicks = [];
  }
}
