import { Injectable } from "@angular/core";
import { Archive } from "src/app/interfaces/archive";
import { AbstractState } from "./abstractState";
import { Console } from "src/app/lib/console";
@Injectable()
export class CompletedState extends AbstractState {
  constructor() {super(); }

  async init() {
    this.title = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.COMPLETED.TITLE');
    this.message = this.modal.translate.instant('MODALS.FILE_UPLOAD.STATE.COMPLETED.MSG');

    this.button2Text = this.modal.translate.instant('DONE');
    //Refresh the cached archive
    if (this.modal.archive) {
      setTimeout(() => {
        this.modal.apiSvc.getArchive(this.modal.archive!.id, true);
      }, 1000);
    }
  }

  override click2(): void {
    this.modal.respond(this.modal.archive);
  }
  override async click1(): Promise<void> {
    const file = this.modal.file;
    const archive: Archive | null = this.modal.archive;
    if (!file || !archive) {
      Console.error('File or archive is null');
      Console.error(file);
      Console.error(archive);
      return;
    }
    const url = `/archive/${archive.id}`;
    await this.testRandomRangeRequests(file, url);
    await this.testSequentialRandomRangeRequests(file, url);
  }

  // Step 3: Function to generate random ranges
  private getRandomRange(fileSize) {
    const start = Math.floor(Math.random() * fileSize);
    const end = start + Math.floor(Math.random() * (fileSize - start));
    return { start, end };
  }

  // Step 4: Fetch random byte ranges from the server and compare it with the local file
  private async testRandomRangeRequests(file, url) {
    const fileSize = file.size;

    for (let i = 0; i < 10; i++) {
      const { start, end } = this.getRandomRange(fileSize);

      try {
        // Fetch data from the server
        const res = await fetch(url, {
          headers: {
            'Range': `bytes=${start}-${end}`,
          },
        });
        if (!res.ok) {
          throw new Error(`Server returned ${res.status} ${res.statusText}`);
        }
        const serverData = new Uint8Array(await res.arrayBuffer());

        // Get local data
        const blob = file.slice(start, end + 1);
        const localData = new Uint8Array(await blob.arrayBuffer());

        // Compare data
        const isMatch = this.compareUint8Arrays(serverData, localData);
        Console.log(`Test ${i + 1}: ${isMatch ? 'Match' : 'Mismatch'}`);
      } catch (error) {
        Console.error('Range test error:', error);
      }
    }
  }
  async testSequentialRandomRangeRequests(file, url) {
    const fileSize = file.size;
    let start = 0;

    while (start < fileSize) {
      // Generate a random end point, ensuring it doesn't exceed the file size
      const end = start + Math.floor(Math.random() * (1024 * 1024)) % (fileSize - start);

      try {
        // Fetch data from the server
        const res = await fetch(url, {
          headers: {
            'Range': `bytes=${start}-${end}`,
          },
        });
        const serverData = new Uint8Array(await res.arrayBuffer());

        // Get local data
        const blob = file.slice(start, end + 1);
        const localData = new Uint8Array(await blob.arrayBuffer());

        // Compare data
        const isMatch = this.compareUint8Arrays(serverData, localData);
        Console.log(`Range ${start}-${end}: ${isMatch ? 'Match' : 'Mismatch'}`);

        if (!isMatch) {
          Console.error('Mismatch found, exiting...');
          break;
        } else {
          Console.log('Sequential Match');
        }

      } catch (error) {
        Console.error('Fetch error:', error);
      }

      // Move to the next range
      start = end + 1;
    }

    Console.log('Test completed');
  }

  private compareUint8Arrays(arr1, arr2) {
    if (arr1.length !== arr2.length) return false;
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
    }
    return true;
  }
}
