import { Console } from "./console";

const API_TIMEOUT = 300000; // 5 minutes for upload part

/**
* {msgId, localID, type: 'request' | 'response', message: any, error: any}
*
* This class creates a BroadcastChannel and handles async requests and responses
*/
export class BroadcastChannelHandler {
  private broadcastChannel!: BroadcastChannel;
  private localID = new Date().getTime(); //good enough for now
  private pendingRequests = new Map();
  private messageID = 1;

  constructor(public name: string, private onmessage: (data: any) => Promise<any>) {
    this.open();
  }

  public open() {
    Console.log('BroadcastChannel open ' + this.name);
    if(this.broadcastChannel) {
      this.broadcastChannel.close();
    }
    this.broadcastChannel = new BroadcastChannel(this.name);
    this.broadcastChannel.onmessage = (event) => {
      this.handleMessage(event);
    }
  }

  public async close() {
    Console.log('BroadcastChannel close '+ this.name);
    this.broadcastChannel.close();
  }

  /**
   *
   * @param event
   */
  private handleMessage(event: any) {
    // If we get our own messages,ignore them
    if (event.data.localID == this.localID) {
      return;
    }

    const data = event.data;

    if (event.data.type == 'request') {
      this.handleRequest(data);
    } else if (event.data.type == 'response') {
      this.handleResponse(data);
    } else {
      Console.error('BroadcastChannel handleMessage improper data '+ this.name, event);
    }
  }

  private async handleRequest(data: any) {
    try {
      const message = data.message;
      data.message = undefined; // in case of error, no need to return the message
      const result = await this.onmessage(message);
      data.message = result;
    } catch (e: any) {
      data.error = e;
    } finally {
      const error = data.error;
      if (error && error.message && error.message == 'Ignore') {
        Console.log('Ignoring request '+this.name, data);
        return;
      }
      data.localID = this.localID;
      data.type = 'response';
      this.broadcastChannel.postMessage(data);
    }
  }

  private handleResponse(data: any) {
    if (data && data.msgId && this.pendingRequests.has(data.msgId)) {
      const { resolve, reject, timeoutref } = this.pendingRequests.get(data.msgId);
      clearTimeout(timeoutref);
      this.pendingRequests.delete(data.msgId);
      if (data.error) {
        reject(data.error);
      } else {
        resolve(data.message);
      }
    } else {
      //Console.error('HandelResponse: handleMessage improper data', data);
    }
  }

  public async postMessage(message: any): Promise<any> {
    let timeoutref: NodeJS.Timeout;
    const timeout = new Promise((_, reject) =>
      timeoutref = setTimeout(() => { reject(new Error('Message timeout')) }, API_TIMEOUT)
    );
    const msgId = this.messageID++; // Generate a unique ID for message
    const request = new Promise((resolve, reject) => {
      this.pendingRequests.set(msgId, { resolve, reject, timeoutref });

      this.broadcastChannel.postMessage({
        type: 'request',
        msgId,
        localID: this.localID,
        message
      });
    });

    try {
      return Promise.race([request, timeout]);
    } catch (error) {
      this.pendingRequests.delete(msgId);
      Console.log('serviceworkerTimeout', message.op);
      Console.log(error);
      throw error;
    }
  }

}
