import { NgIf } from '@angular/common';
import { Component, Input, ViewChild } from '@angular/core';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HelpService } from 'src/app/services/help.service';
import { Console } from 'src/app/lib/console';
import { QuestionModalComponent } from '../../modal/modals/question-modal/question-modal.component';

@Component({
  selector: 'helpComponent',
  standalone: true,
  templateUrl: './help-component.html',
  styleUrl: './help-component.scss',
  imports: [NgIf, QuestionModalComponent, TranslateModule]
})
export class HelpComponent {
  private subscription: any;

  constructor(private translate: TranslateService, private helpSvc: HelpService) { }

  ngOnInit(): void {
    this.subscription = this.helpSvc.helpEmiter.subscribe((code) => { this.process(code) });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  process(code: any) {
    this.messageData.message = this.translate.instant(code + '_MSG');
    this.messageData.title = this.translate.instant(code + '_TITLE');
    this.showHelp = true;
  }

  @ViewChild(QuestionModalComponent, { static: false })
  questionModal!:QuestionModalComponent;
  @Input() code!: string;
  messageData = { title: 'Information', message: '', btnCancelLable: null,btnOneLable: null,btnTwoLable: this.translate.instant('OK') };

  showHelp = false;

  closeHelp() {
    this.showHelp = false;
  }

  closeEvent(event: any) {
    if (event.target.nodeName === 'SUMMARY' || event.target.className === 'help-icon'|| event.target.className === 'pic') {
      return;
    }
    this.closeHelp();
  }
}
