import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

const imgSrc = './assets/images/svg/copy.svg';

@Directive({
  selector: '[appCopyText]',
  standalone: true
})
export class CopyTextDirective {
  @Input() copyText!: string; // The text to copy to the clipboard
  @Input() showIcon: boolean = true; // Controls whether to add the permanent icon

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setStyle(this.el.nativeElement, 'cursor', 'pointer'); // Set cursor on the element
    if (this.showIcon) {
      this.addPermanentIcon();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const textToCopy = this.copyText || this.el.nativeElement.innerText || this.el.nativeElement.textContent;

    // Copy text to clipboard
    navigator.clipboard.writeText(textToCopy).then(() => {
      this.createAnimatedIcon(event);
    });
  }

  private addPermanentIcon() {
    // Check if the icon already exists to avoid duplicates
    const existingIcon = this.el.nativeElement.querySelector('.permanent-copy-icon');
    if (existingIcon) return;

    // Create a span wrapper if necessary to ensure correct positioning
    const wrapper = this.renderer.createElement('span');
    this.renderer.setStyle(wrapper, 'display', 'inline-flex');
    this.renderer.setStyle(wrapper, 'align-items', 'center');

    // Move the original element's content into the wrapper
    while (this.el.nativeElement.firstChild) {
      this.renderer.appendChild(wrapper, this.el.nativeElement.firstChild);
    }

    // Append wrapper back to the element
    this.renderer.appendChild(this.el.nativeElement, wrapper);

    // Create a new image element
    const icon = this.renderer.createElement('img');
    this.renderer.setAttribute(icon, 'src', imgSrc);
    this.renderer.setAttribute(icon, 'class', 'permanent-copy-icon');

    // Apply styles
    this.renderer.setStyle(icon, 'width', '16px');
    this.renderer.setStyle(icon, 'height', '16px');
    this.renderer.setStyle(icon, 'margin-left', '8px');
    this.renderer.setStyle(icon, 'cursor', 'pointer');

    // Append to the wrapper (ensuring it appears at the end)
    this.renderer.appendChild(wrapper, icon);
  }

  private createAnimatedIcon(event: MouseEvent) {
    const parent = document.body;

    // Create a new image element for the animation
    const animatedIcon = document.createElement('img');
    animatedIcon.src = imgSrc;
    animatedIcon.style.position = 'absolute';
    animatedIcon.style.top = `${event.pageY - 16}px`;
    animatedIcon.style.left = `${event.pageX - 16}px`;
    animatedIcon.style.width = '32px';
    animatedIcon.style.height = '32px';
    animatedIcon.style.pointerEvents = 'none';
    animatedIcon.style.transition = 'transform 2s ease-out, opacity 2s ease-out';
    animatedIcon.style.zIndex = '9999';

    // Append the animated icon to the parent
    parent.appendChild(animatedIcon);

    // Apply animation
    setTimeout(() => {
      animatedIcon.style.transform = 'scale(2)';
      animatedIcon.style.opacity = '0';
    }, 10);

    // Remove after animation ends
    setTimeout(() => {
      if (animatedIcon && animatedIcon.parentElement) {
        animatedIcon.parentElement.removeChild(animatedIcon);
      }
    }, 2010);
  }
}
