import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';

import { NgClass, NgIf, NgStyle } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { State } from '../state';
import { Console } from 'src/app/lib/console';

@Component({
  selector: 'input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.scss'],
  standalone: true,
  imports: [NgClass, NgIf, NgStyle, FormsModule, TranslateModule]
})
export class InputModalComponent implements OnInit, OnDestroy, AfterViewInit {

  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>;
  @ViewChild("authBodyInput") inputElement!: ElementRef<HTMLTextAreaElement>;
  @ViewChild("buttonContainer") buttonContainer!: ElementRef<HTMLInputElement>;
  @Output() response = new EventEmitter<State>();
  @Input() data!: { title: string, message: string, isPasswd: boolean };
  title = '';
  message = '';

  realValue = '';
  displayValue = '';
  showPassphrase = false;

  private resizeListent = () => {
    Console.log('resize');
    this.buttonContainer.nativeElement.scrollIntoView(false); // aligned to the bottom of the visible area for soft keyboards
  };

  ngOnInit(): void {
    this.title = this.data.title;
    this.message = this.data.message;
    visualViewport?.addEventListener("resize", this.resizeListent);
    this.showPassphrase = !this.data.isPasswd;
  }

  ngOnDestroy(): void {
    //Small screen soft keyboards are a pain
    visualViewport?.removeEventListener("resize", this.resizeListent);
  }
  ngAfterViewInit(): void {
    this.setfocus();
  }

  reset() {
    this.inputElement.nativeElement.value = '';
    this.realValue = '';
    this.displayValue = '';
    this.inputElement.nativeElement.rows = 1;
    this.setfocus();
  }

  setfocus() {
    this.inputElement.nativeElement.focus();
  }

  onInputChange() {
    const input = this.inputElement.nativeElement;
    if (this.showPassphrase) {
      this.realValue = input.value;
    } else {
      const newValue = input.value;
      this.realValue += newValue.slice(this.displayValue.length);
      this.displayValue = '*'.repeat(this.realValue.length);
    }
  }

  toggleVisibility() {
    this.displayValue = this.showPassphrase ? this.realValue : '*'.repeat(this.realValue.length);
  }

  adjustRows(textarea: HTMLTextAreaElement): void {
    // Resetting the rows to compute the new scrollHeight
    textarea.rows = 1;

    while (textarea.scrollHeight > textarea.clientHeight && textarea.rows < 8) {
      textarea.rows += 1;
    }
  }

  async send() {
    const value = this.realValue
    this.reset();
    this.response.emit({ value: value });
  }

  async cancel(){
    this.reset();
    this.response.emit({ value: null });
  }
}
