<div class="message-composer">
  <form [formGroup]="messageForm">
    <!-- Recipient Address -->
    <div class="form-group">
      <label for="recipient">Recipient Address</label>
      <div class="recipient-input-container">
        <!-- <span class="valid-checkmark" *ngIf="publicKey">✔</span> -->
        <input autocomplete="off" spellcheck="false" type="text" id="recipient" formControlName="recipient"
          placeholder="Enter recipient address" list="recipientList" (input)="filterRecipients($event)"
          (blur)="onRecipientBlur()" (keydown.enter)="onRecipientEnter($event)" />
        <datalist id="recipientList">
          <option *ngFor="let address of filteredRecipients" [value]="address"></option>
        </datalist>
        <button [disabled]="!recipientName" type="button" (click)="editRecipientName()" class="edit-btn"> <img
            src="../../../../assets/images/svg/pen-filled.svg" alt="edit name"></button>
        <button type="button" (click)="deleteAddress()" class="edit-btn"> <img
            src="../../../../assets/images/svg/trash-filled.svg" alt="delete"></button>
      </div>
      <div class="field-error" *ngIf="recipientError">
        {{ recipientError }}
      </div>
    </div>

    <!-- Subject -->
    <div class="form-group">
      <label for="subject">Subject</label>
      <input autocomplete="off" spellcheck="false" type="text" id="subject" formControlName="subject"
        placeholder="Enter subject" />
    </div>

    <!-- Rich Text Message Body -->
    <div class="form-group">
      <label for="message">Message</label>
      <quill-editor spellcheck="false" formControlName="message" [modules]="quillModules" theme="snow" format="json"
        placeholder="Compose your message...">
      </quill-editor>
    </div>

    <!-- File Attachments -->

    <div class="form-group attachments" >
      <label>Attachments</label>
      <div class="attachment-buttonContainer">
        <button type="button" (click)="addArchive()">
          <img src="../../../../assets/images/svg/plus.svg" alt="Add">
        </button>
      </div>
      <!-- List each attachment with a delete option -->
      <div class="attachment-preview" *ngIf="eyesFiles.length">
        <ul>
          <li *ngFor="let file of eyesFiles; let i = index">
            <span>{{ file.name }} ({{ file.size | fileSize }})</span>
            <button type="button" class="edit-btn" (click)="removeAttachment(i)"><img
                src="../../../../assets/images/svg/trash-filled.svg" alt="delete"></button>
          </li>
        </ul>
      </div>
    </div>

    <!-- Error Message for Encryption / Other Errors -->
    <div class="error-message" *ngIf="errorMessage">
      <p>{{ errorMessage }}</p>
    </div>

    <!-- Send Button -->
    <div class="form-group">
      <div class="button-container right">
        <button class="btn-primary" (click)="saveMessage()"
          [disabled]="messageForm.invalid || loading || recipientError">
          {{ loading ? 'Encrypting…' : 'Save' }}
        </button>
        <button class="btn-primary" (click)="sendMessage()"
          [disabled]="messageForm.invalid || loading || recipientError">
          {{ loading ? 'Encrypting…' : 'Send' }}
        </button>
      </div>
    </div>
  </form>
</div>
<app-modals></app-modals>
