import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Location } from 'src/app/interfaces/archive';
import { NgFor, NgIf } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

interface Option {
  label: string;
  region: string;
  selected?: boolean;
}

@Component({
  selector: 'auth-region-modal',
  templateUrl: './region-modal.component.html',
  styleUrls: ['./region-modal.component.scss'],
  standalone: true,
  imports: [NgFor, NgIf, TranslateModule, FormsModule]
})
export class AuthRegionModalComponent implements OnInit {
  @ViewChild("authModalContainer") modalContainer!: ElementRef<HTMLInputElement>
  @Output() reply = new EventEmitter<Location>();
  @Input() locations!: Location[];

  selectedLocation: Location | null = null;
  xRegions: Option[] = [] //replicated
  regions: Option[] = [] //basic
  selectedRegion: Option | null = null;
  selectedOptionsOrder: Option[] = [];

  constructor(private translate: TranslateService) { }

  ngOnInit(): void {
    for (const location of this.locations) {
      if (location.class == 'basic') {
        location.name = this.translateID(location.region);
        if (!this.OptionsHasValue(this.regions, location.region)) {
          const option: Option = {
            label: location.name,
            region: location.region
          }
          this.regions.push(option);
        }
      } else {
        //replicated
        const regions = location.region.split(',');
        location.name = this.translateID(regions[0]) + ' <-> ' + this.translateID(regions[1]);
        for (const region of regions) {
          if (!this.OptionsHasValue(this.xRegions, region)) {
            const label = this.translate.instant('LOCATIONS.XRegion.' + region);
            const option: Option = {
              label: label,
              region: region,
              selected: false
            }
            this.xRegions.push(option);
          }
        }
      }
    }
  }
  private OptionsHasValue(options: Option[], region: string): boolean {
    return options.filter(opt => opt.region == region).length > 0;
  }

  private translateID(regionID: string): string {
    return this.translate.instant('LOCATIONS.' + regionID);
  }

  onXRangeChange(changedOption): void {
    this.selectedRegion = null; //reset single option
    if (changedOption.selected) {
      // Remove the option from the tracking array
      this.selectedOptionsOrder = this.selectedOptionsOrder.filter(opt => opt !== changedOption);
    } else {
      // Add the option to the tracking array
      this.selectedOptionsOrder.push(changedOption);
    }
    // Toggle the selection
    changedOption.selected = !changedOption.selected;

    // If more than two options are selected, uncheck the oldest
    if (this.selectedOptionsOrder.length > 2) {
      const oldestSelected = this.selectedOptionsOrder.shift(); // Remove the oldest
      if (oldestSelected) {
        oldestSelected.selected = false;
      }
    } else {
      // unset selected location if only one option is selected
      this.selectedLocation = null;
    }
    if (this.selectedOptionsOrder.length == 2) {
      this.setLocation(this.selectedOptionsOrder[0].region, this.selectedOptionsOrder[1].region);
    }
  }

  onRangeChange(): void {
    // Reset all checkboxes
    this.xRegions.forEach(opt => opt.selected = false);
    this.selectedOptionsOrder = [];
    if (this.selectedRegion) {
      this.setLocation(this.selectedRegion!.region, undefined);
    }
  }

  async send() {
    if (this.selectedLocation) {
      this.reply.emit(this.selectedLocation);
    }
  }

  private setLocation(region1: string, region2: string | undefined) {
    let location: Location | null = null;
    if (region2) {
      location = this.locations.filter(loc => (loc.region == region1 + ',' + region2) || (loc.region == region2 + ',' + region1))[0];
    } else {
      location = this.locations.filter(loc => loc.region == region1)[0];
    }
    this.selectedLocation = location;
  }

  onSingleCountrySelected(): void {
    this.onRangeChange();
    this.selectedRegion = null;
    this.selectedLocation = null;
  }
}
