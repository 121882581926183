<div *ngIf="showMessage" #authModalContainer class="modal-container">
  <div #authModal class="modal">
    <div class="auth-main">
      <div class="auth-container">
        <div class="auth-body">
          <p>{{title}}</p>
        </div>
        <div #authBody class="input-body">
          <div [innerHTML]="message"></div>
        </div>
      </div>
      <div class="button-container">
        <button class="btn-primary" (click)="ok()"> {{ 'OK' | translate }}</button>
      </div>
    </div>
  </div>
</div>
<auth-region-modal *ngIf='displayModal=="Regions"' [locations]="locations"
  (reply)="createRecords($event)"></auth-region-modal>
<div (mousedown)="hideBlurOverlay($event)" *ngIf="blurOverlay" id="blurOverlay" class="blur-overlay">
  <div class="cover-content">
    <h2>{{'ERRORSVC.HIDE.TITLE'| translate}}</h2>
    <p>{{'ERRORSVC.HIDE.MSG'| translate}}</p>
    <div class="button-container">
      <button class="btn-primary" (mousedown)="supressBlurOverlay=true; captureEvent($event)">{{'ERRORSVC.HIDE.SUPPRESS'| translate}}</button>
    </div>
  </div>
</div>
