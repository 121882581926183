// src/app/services/print.service.ts
import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';


@Injectable({
  providedIn: 'root',
})
export class PrintService {
  private renderer: Renderer2;
  private nonce: string | null;

  constructor(
    private rendererFactory: RendererFactory2,
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.nonce = this.getNonce();
  }

  printV1(printContentId: string): void {
    const printContainer = document.getElementById(printContentId);
    if (!printContainer) {
      console.error(`Print container with ID '${printContentId}' not found.`);
      return;
    }

    // Clone the content to avoid altering the original modal
    const clonedContent = printContainer.cloneNode(true) as HTMLElement;

    // Optional: Remove modal-specific classes or styles
    clonedContent.classList.remove('modal', 'fade', 'show');

    // Remove inline styles that might interfere with printing
    // clonedContent.removeAttribute('style');

    // Optionally, remove any other unnecessary attributes or elements
    // Example: Remove close buttons or action buttons within the modal
    const closeButtons = clonedContent.querySelectorAll('.close, .btn-close, .btn, .closeaddress, .button-container, .title');
    closeButtons.forEach(button => button.remove());

    const content = clonedContent.innerHTML;

    // Create the iframe using Renderer2
    const printIframe = this.renderer.createElement('iframe') as HTMLIFrameElement;

    // Style the iframe to be invisible and not affect layout
    this.renderer.setStyle(printIframe, 'position', 'absolute');
    this.renderer.setStyle(printIframe, 'top', '-10000px');
    this.renderer.setStyle(printIframe, 'left', '-10000px');
    this.renderer.setStyle(printIframe, 'width', '0');
    this.renderer.setStyle(printIframe, 'height', '0');
    this.renderer.setStyle(printIframe, 'border', 'none');

    // Append the iframe to the body
    this.renderer.appendChild(document.body, printIframe);

    // Define the load event handler
    const handlePrint = () => {
      if (printIframe.contentWindow) {
        printIframe.contentWindow.focus();
        printIframe.contentWindow.print();
      }
      // Clean up by removing the iframe after printing
      this.renderer.removeChild(document.body, printIframe);
    };



    // Prepare the HTML content with nonce and centering styles
    const iframeDoc = printIframe.contentDocument || printIframe.contentWindow?.document;
    if (iframeDoc) {
      iframeDoc.open();

      // Start constructing the HTML for the iframe
      let iframeContent = `
        <html>
          <head>
            <meta charset="UTF-8">
            <style nonce="${this.nonce}">
              /* Reset default margins and padding */
              * {
                margin: 0;
                padding: 0;
                box-sizing: border-box;
              }

              /* Container centering */
              .print-content {
                max-width: 800px; /* Adjust based on your design */
                width: 100%;
                margin: 0 auto;   /* Centers the container horizontally */
                padding: 20px;     /* Optional padding */
              }

              /* Optional: Additional print-specific styles */
              @media print {
                body {
                  height: auto; /* Adjust height for printing */
                }
              }
            </style>
      `;

      // Dynamically copy global stylesheets into the iframe
      const globalStyles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
        .map(styleElement => {
          if (styleElement.tagName.toLowerCase() === 'link') {
            const href = (styleElement as HTMLLinkElement).href;
            // Fetch the stylesheet content
            return fetch(href)
              .then(response => response.text())
              .then(css => `<style nonce="${this.nonce}">${css}</style>`)
              .catch(err => {
                console.error(`Failed to fetch stylesheet: ${href}`, err);
                return '';
              });
          } else if (styleElement.tagName.toLowerCase() === 'style') {
            const css = (styleElement as HTMLStyleElement).innerHTML;
            return Promise.resolve(`<style nonce="${this.nonce}">${css}</style>`);
          }
          return Promise.resolve('');
        });

      Promise.all(globalStyles).then(styles => {
        iframeContent += styles.join('\n');
        iframeContent += `
            </head>
            <body>
              <div class="print-content">
                ${content}
              </div>
            </body>
          </html>
        `;
        iframeDoc.write(iframeContent);
        iframeDoc.close();
      });

      // Attach the load event listener using Renderer2's listen method
      if (printIframe.contentDocument?.readyState === "complete") {
        handlePrint();
      } else {
        // Wait for the iframe to fully load
        this.renderer.listen(printIframe, 'load', handlePrint);
      }

    } else {
      console.error('Failed to access iframe document.');
      // Clean up if document access fails
      this.renderer.removeChild(document.body, printIframe);
    }
  }

  print(printContentId: string): void {
    const printContainer = document.getElementById(printContentId);
    if (!printContainer) {
      console.error(`Print container with ID '${printContentId}' not found.`);
      return;
    }

    // Clone content to avoid modifying the original
    const clonedContent = printContainer.cloneNode(true) as HTMLElement;
    clonedContent.classList.remove('modal', 'fade', 'show');

    // Remove unnecessary UI elements
    const closeButtons = clonedContent.querySelectorAll('.close, .btn-close, .btn, .closeaddress, .button-container, .title');
    closeButtons.forEach(button => button.remove());

    const content = clonedContent.innerHTML;

    // Create an iframe dynamically
    const printIframe = this.renderer.createElement('iframe') as HTMLIFrameElement;

    // Ensure Safari recognizes it
    this.renderer.setStyle(printIframe, 'position', 'fixed');
    this.renderer.setStyle(printIframe, 'top', '0');
    this.renderer.setStyle(printIframe, 'left', '0');
    this.renderer.setStyle(printIframe, 'width', '100vw');
    this.renderer.setStyle(printIframe, 'height', '100vh');
    this.renderer.setStyle(printIframe, 'z-index', '-1'); // Still hidden but "visible" to Safari
    this.renderer.setStyle(printIframe, 'background', 'white');
    this.renderer.setStyle(printIframe, 'border', 'none');

    this.renderer.appendChild(document.body, printIframe);

    // Access iframe document
    const iframeDoc = printIframe.contentDocument || printIframe.contentWindow?.document;
    if (!iframeDoc) {
      console.error('Failed to access iframe document.');
      this.renderer.removeChild(document.body, printIframe);
      return;
    }

    iframeDoc.open();

    let iframeContent = `
      <html>
        <head>
          <meta charset="UTF-8">
          <style nonce="${this.nonce}">
            * { margin: 0; padding: 0; box-sizing: border-box; }
            .print-content { max-width: 800px; width: 100%; margin: 0 auto; padding: 20px; }
            @media print { body { height: auto; } }
          </style>
    `;

    // Fetch global stylesheets
    const globalStyles = Array.from(document.querySelectorAll('link[rel="stylesheet"], style'))
      .map(styleElement => {
        if (styleElement.tagName.toLowerCase() === 'link') {
          const href = (styleElement as HTMLLinkElement).href;
          return fetch(href)
            .then(response => response.text())
            .then(css => `<style nonce="${this.nonce}">${css}</style>`)
            .catch(err => {
              console.error(`Failed to fetch stylesheet: ${href}`, err);
              return '';
            });
        } else {
          return Promise.resolve(`<style nonce="${this.nonce}">${(styleElement as HTMLStyleElement).innerHTML}</style>`);
        }
      });

    // Apply styles and trigger printing
    Promise.all(globalStyles).then(styles => {
      iframeContent += styles.join('\n');
      iframeContent += `
          </head>
          <body>
            <div class="print-content">${content}</div>
          </body>
        </html>
      `;

      iframeDoc.write(iframeContent);
      iframeDoc.close();

      // 🔥 **CRITICAL FIX FOR SAFARI: Wait a bit before calling print()**
      setTimeout(() => {
        if (printIframe.contentWindow) {
          printIframe.contentWindow.focus();
          printIframe.contentWindow.print();
        }

        // Clean up
        setTimeout(() => this.renderer.removeChild(document.body, printIframe), 1000);
      }, 500); // Ensures iframe is fully rendered before printing
    });
  }

  private getNonce(): string | null {
    const appRoot = document.querySelector('app-root');
    return appRoot ? appRoot.getAttribute('ngcspnonce') : null;
  }
}

