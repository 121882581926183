import { Pipe, PipeTransform } from '@angular/core';
import { RecordI } from '../interfaces/records';
import { AuthzService } from '../services/authz.service';

@Pipe({
  name: 'recordsTools',
  standalone: true,
})
export class RecordsToolsPipe implements PipeTransform {
  constructor(private authzSvc: AuthzService) { }

  transform(records: RecordI[], type: string): RecordI[] {
    if (type === 'home') {
      if (this.authzSvc.appConfig.filter) {
        return records.filter(record => record.labels.length === 0) // show only records without labels
      } else {
        return records.filter(record => !record.labels.some(label => label.name === 'Wallet')) // show all records without Wallet label
      }
      // return records;
    } else {
      return records.filter(record => record.labels.some(label => label.name === type && label.added))
    }
  }
}
