import { Platform } from '@angular/cdk/platform';
import { NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NgxExtendedPdfViewerModule, pdfDefaultOptions } from 'ngx-extended-pdf-viewer';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Archive } from 'src/app/interfaces/archive';
import { Console } from 'src/app/lib/console';
import { FileSizePipe } from 'src/app/pipes/fileSize.pipe';
import { ApiService } from 'src/app/services/api.service';
import { ErrorService } from 'src/app/services/error.service';
import { FileService } from 'src/app/services/file.service';
import { RecordsService } from 'src/app/services/records.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss'],
  standalone: true,
  imports: [NgIf, TranslateModule, ModalComponent, NgxExtendedPdfViewerModule, FileSizePipe]
})

export class ArchiveComponent implements OnInit {

  local = false;
  readonly = false;
  ios = this.platform.IOS || this.platform.SAFARI || this.platform.WEBKIT
  archive: Archive | null = null;
  url: string | null = null;
  textData = '';
  type = 'unknown';
  videoEventName = '';
  view = false;
  @ViewChild(ModalComponent, { static: false })
  modalController!: ModalComponent;
  file: File | null = null;
  zoom = 'auto';
  isIOSStandalone = false;
  language = 'en';

  constructor(private route: ActivatedRoute, private apiSvc: ApiService, private platform: Platform, private el: ElementRef, private errorSvc: ErrorService,
    public translate: TranslateService, private recordsSvc: RecordsService, private router: Router, public fileSvc: FileService, private sharedSvc: SharedService) {
    pdfDefaultOptions.disableHistory = true;
    pdfDefaultOptions.enableScripting = false;

    this.isIOSStandalone = this.ios &&
      (navigator as any).standalone === true
  }

  async ngOnInit(): Promise<void> {
    const id = this.route.snapshot.paramMap.get('id');
    if (id) {
      this.archive = await this.apiSvc.getArchive(id);
      this.archive.recordId = this.recordsSvc.getRecordByArchiveID(id)?.id;
      this.setType(this.archive);
      Console.log(this.archive);
      if (this.archive.t == 'Local') {
        this.local = true;
      } else {
        this.url = '/archive/' + id;
      }
    } else {
      Console.log("no id");
    }
    this.readonly = this.apiSvc.isReadOnly();
    if (window.innerWidth < 700) {
      this.sharedSvc.setSideBar.next('hidden');
    }
    const nav = this.router.lastSuccessfulNavigation;
    Console.log(nav);
    if (nav && nav.extras && nav.extras.state) {
      Console.log(nav.extras.state);
      this.file = nav.extras.state['file'] as File;
      this.url = '/archive/' + id;
    }

    this.language = this.translate.currentLang;
  }


  async onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file && this.archive) {
      try {
        await this.fileSvc.initFetchLocalFile(file, this.archive.id);
        this.url = '/archive/' + this.archive.id;
        this.file = file;
        setTimeout(() => { this.logVideoEvents(); }, 1000);
      } catch (err) {
        ErrorService.instance.process(err);
      }
    }
  }

  viewArchive() {
    if (this.view) {
      this.view = false;
      this.sharedSvc.setSideBar.next('default');
      return;
    }
    if (this.type == 'text') {
      this.loadTextData(this.url!);
    }
    this.sharedSvc.setSideBar.next('hidden');
    this.view = true;
  }

  logVideoEvents() {
    const videoElement = this.el.nativeElement.querySelector('video');
    if (!videoElement) return;
    const events = [
      'abort',
      'canplay',
      'canplaythrough',
      'durationchange',
      'emptied',
      'ended',
      'error',
      'loadeddata',
      'loadedmetadata',
      'loadstart',
      'pause',
      'play',
      'playing',
      'ratechange',
      'seeked',
      'seeking',
      'stalled',
      'volumechange',
      'waiting'
    ];

    // events.forEach((eventName) => {
    //   this.renderer.listen(videoElement, eventName, (event) => {
    //     if (this.videoEventName == eventName) return; //ignore duplicate events
    //     Console.log(`Video event: ${eventName}`, event);
    //     this.videoEventName = eventName;
    //   });
    // });
  }

  setType(archive: Archive) {
    const type: string = archive.m!.type;
    if (!type) {
      this.type = 'unknown';
      return;
    }
    if (type.includes('pdf')) {
      this.type = 'pdf';
    } else if (type.includes('image')) {
      this.type = 'image';
    } else if (type.includes('video')) {
      this.type = 'video';
      //  setTimeout(() => { this.logVideoEvents(); }, 1000);
    } else if (type.includes('audio')) {
      this.type = 'audio';
    } else if (type.includes('text') || type.includes('json') || type.includes('xml') || type.includes('html') || type.includes('yaml')) {
      this.type = 'text';
    } else {
      this.type = 'unknown';
    }
  }

  private async loadTextData(url: string) {
    try {
      const response = await fetch(url)
      if (!response.ok) {
        this.errorSvc.process(response);
        this.textData = 'Error loading data.';
        return;
      }
      const data = await response.text();
      this.textData = data;
    } catch (error) {
      this.errorSvc.process(error);
      this.textData = 'Error loading data.';
    }
  }

  async deleteArchive() {
    const id = this.archive?.id;
    if (id) {
      const result = await this.modalController.displayQuestion(this.translate.instant('RECORDS.DELETE'), this.translate.instant('RECORDS.DELETE_CONFIRMATION'), this.translate.instant('CANCEL'), null, this.translate.instant('RECORDS.DELETE'));
      if (result != 'TWO') {
        return
      }
      await this.apiSvc.deleteArchive(id);

      //remove and references to this archive in records
      this.recordsSvc.removeArchive(id);
      this.router.navigate(['/archives']);
    }
  }

  async rename() {
    const archive = this.archive;
    if (!archive) return;
    const name = await this.modalController.displayInput(this.translate.instant('RECORDS.RENAME'), this.translate.instant('RECORDS.RENAME_MSG'), false);
    if (name) {
      archive.m.name = name;
      await this.apiSvc.updateArchive(archive);
    }
    Console.log(archive);
  }

  async close() {
    this.router.navigate(['/archives']);
  }

  openRecord() {
    if (this.archive?.recordId) {
      this.router.navigate(['/records', this.archive.recordId]);
    } else {
      Console.log('No record id');
      //ToDo create a record selection dialog
    }
  }
}
