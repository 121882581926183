
<div sideBar class="main-sidebar" (click)="navClicked()">
  <div [class.active]="route.url === '/'" class="item" routerLink="/" (keydown.enter)="navigate('/')">
    <img src="./assets/images/svg/records.svg" alt="records" class="pic">
    <span class="text">{{ 'SIDE_NAV.RECORDS' | translate }} </span>
  </div>

  <div [class.active]="route.url === '/archives'" class="item" routerLink="/archives" (keydown.enter)="navigate('/archives')">
    <img src="./assets/images/svg/archives.svg" alt="configuration" class="pic">
    <span class="text">{{ 'SIDE_NAV.ARCHIVES' | translate }}</span>
  </div>

  <div [class.active]="selectedLabelName === label.name" class="item" *ngFor="let label of Shared.label.list"
    [routerLink]="['label', label.name]" (keydown.enter)="navigateLabel(label.name)">
    <img [src]="label.name == 'Wallet' && apiSvc.getAppConfig().tier!=0 && apiSvc.getAppConfig().tier!=4 ? './assets/images/svg/coin.svg' :'./assets/images/svg/label-white.svg' " alt="label" class="pic">
    <span class="text">{{label.name}}</span>
  </div>

  <div [class.active]="route.url === '/config'" class="item" (click)="navigate('/config')" (keydown.enter)="navigate('/config')">
    <img src="./assets/images/svg/config.svg" alt="configuration" class="pic">
    <span class="text">{{ 'SIDE_NAV.CONFIGURATION' | translate }}</span>
  </div>

</div>
<div *ngIf="navMode==0" class="placeholder"></div>
